import React, {useState, useEffect} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const ExamPatternAddEdit = (props) => {
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };
    const params = useParams();
    let initialFormData = Object.freeze({});
    const [formData, updateFormData] = useState(initialFormData);
    const [classes, setClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [topics, setTopics] = useState([]);
    const [taxonomies, setTaxonomies] = useState([]);

    useEffect(() => {
        async function classData() {
            setIsLoading(true);
            await axios.get(`https://nodeapi.uniqtoday.com/api/getGrades`, jwtHeader)
                .then((response) => {
                    setClasses(response.data.content)
                    isLoading(false)
                }).catch((error) => {
                    console.log(error);
                });
        }

        classData();

        async function taxData() {
            setIsLoading(true)
            await axios.get(`https://nodeapi.uniqtoday.com/api/getSkills`,jwtHeader)
                .then((response) => {
                    setTaxonomies(response.data.Content)
                    isLoading(false)
                }).catch((error) => {
                    console.log(error);
                })
        }

        taxData();

        if (params.id !== 'new') {
            async function itemData() {
                setIsLoading(true)
                await axios.get(`https://nodeapi.uniqtoday.com/api/getExamPatternById/${params.id}`, jwtHeader)
                    .then((response) => {
                        updateFormData(response.data.content)
                        isLoading(false)
                    }).catch((error) => {
                        console.log(error);
                    })
            }

            itemData();
        }
    }, [])

    const handleChangeGrade = async (e) => {
        setIsLoading(true)
        let exam_id = parseInt(e.target.value);
        await axios.get(`https://nodeapi.uniqtoday.com/api/getSubjectByGrade/${exam_id}`, jwtHeader)
            .then((response) => {
                setSubjects(response.data.Subjects)
                isLoading(false)
            }).catch((error) => {
                console.log(error);
            })
    }

    const handleChangeSubject = async (e) => {
        setIsLoading(true)
        let subject_id = parseInt(e.target.value);
        await axios.get(`https://nodeapi.uniqtoday.com/api/getTopicsbySubjId/${subject_id}`, jwtHeader)
            .then((response) => {
                // handle success
                setTopics(response.data.Topics)
                isLoading(false)
            }).catch((error) => {
                // handle error
                console.log(error);
            })
    }

    const handleChange = (e) => {

        updateFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch((formData.ct_exam_pattern_id) ? 'https://nodeapi.uniqtoday.com/api/setExamPattern' : 'https://nodeapi.uniqtoday.com/api/addExamPattern', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem('admin_token')
            },
            body: JSON.stringify(formData),
        });
        navigate('/exam-pat-setup');
    }

    let topicsData;
    if (topics) {
        topicsData = topics.map((res, index) => (
            <option value={res.id}>{res.topic_name}</option>
        ))
    }
    let subData;
    if (subjects) {
        subData = subjects.map((res, index) => (
            <option value={res.subject_id}>{res.subject_name}</option>
        ))
    }
    const title = <h5>{formData.ct_exam_pattern_id ? 'Edit Exam Pattern' : 'Add Exam Pattern'}</h5>;

    return (
        <Col sm={12}>
            <h5>Basic Collapse</h5>
            <hr/>
            <Card>
                <Card.Header>
                    {title}
                </Card.Header>
                <div id="basic-collapse">
                    <Card.Body>
                        <>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formExam">
                                        <Form.Label>Exam </Form.Label>
                                        <Form.Control name="exam_id" as="select" className="mb-3"
                                                      onChange={e => {
                                                          handleChangeGrade(e);
                                                          handleChange(e)
                                                      }} value={formData.exam_id || ''}>
                                            <option>Select Exam</option>
                                            {classes.map((res, index) => (
                                                <option value={res.id}>{res.class_exam_cd}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formSubject">
                                        <Form.Label>Subject </Form.Label>
                                        <Form.Control as="select" name="subject_id" className="mb-3"
                                                      onChange={e => {
                                                          handleChangeSubject(e);
                                                          handleChange(e)
                                                      }} value={formData.subject_id || ''}>
                                            <option>Select Subject</option>
                                            {subData}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formSubject">
                                        <Form.Label>Topic </Form.Label>
                                        <Form.Control as="select" name="topic_id" className="mb-3"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.topic_id || ''}>
                                            <option>Select Subject</option>
                                            {topicsData}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label>Exam type</Form.Label>
                                        <Form.Control as="select" className="mb-3" name="exam_type"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.exam_type || ''}>
                                            <option>Select Exam type</option>
                                            <option value="Scholarship">Scholarship</option>
                                            <option value="Mocktest">Mocktest</option>
                                            <option value="Assignment">Assignment</option>
                                            <option value="Competition">Competition</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label>Question Taxonomy</Form.Label>
                                        <Form.Control as="select" className="mb-3" name="skill_id"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.skill_id || ''}>
                                            <option>Select Question Taxonomy</option>
                                            {(taxonomies).map((res, index) => (
                                                <option value={res.skill_id}>{res.skill_name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.NoOfQuestion">
                                        <Form.Label>Question Count</Form.Label>
                                        <Form.Control type="number" placeholder="Question Count"
                                                      name="questions_cnt"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.questions_cnt || ''}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.NoOfQuestion">
                                        <Form.Label>Mark Count</Form.Label>
                                        <Form.Control type="number" placeholder="Mark Count" name="marks_cnt"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.marks_cnt || ''}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.NoOfQuestion">
                                        <Form.Label>Time in Min </Form.Label>
                                        <Form.Control type="number" placeholder="Time in Min" name="time_in_min"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.time_in_min || ''}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.NoOfQuestion">
                                        <Form.Label>Simple Question Count</Form.Label>
                                        <Form.Control type="number"
                                                      placeholder="Simple Question Count" name="simple_ques_cnt"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.simple_ques_cnt || ''}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.NoOfQuestion">
                                        <Form.Label>Medium Question Count</Form.Label>
                                        <Form.Control type="number"
                                                      placeholder="Medium Question Count" name="medium_ques_cnt"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.medium_ques_cnt || ''}/>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="exampleForm.NoOfQuestion">
                                        <Form.Label>Complex Question Count</Form.Label>
                                        <Form.Control type="number"
                                                      placeholder="Complex Question Count"
                                                      name="complex_ques_cnt"
                                                      onChange={e => {
                                                          handleChange(e)
                                                      }} value={formData.complex_ques_cnt || ''}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button onClick={handleSubmit} className="btn-align-center"
                                    variant="primary">Submit</Button>
                        </>
                    </Card.Body>
                </div>
            </Card>
        </Col>
    )
}

export default ExamPatternAddEdit;