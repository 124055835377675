import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Container } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
import custom_config from '../../../ckeditor.config';
export default function QuestionAdd(props) {

  const navigate = useNavigate();
  const input = props.input;

  const [editorQuestionState, setEditorQuestionState] = useState("");
  const [editorOption1State, setEditorOption1State] = useState("");
  const [editorOption2State, setEditorOption2State] = useState("");
  const [editorOption3State, setEditorOption3State] = useState("");
  const [editorOption4State, setEditorOption4State] = useState("");
  const [editorAnswerState, setEditorAnswerState] = useState("");
  const [editorExplanationState, setEditorExplanationState] = useState("");
  const [editorRefTextState, setEditorRefTextState] = useState("");

  useEffect(() => {
    if (input.question_desc !== undefined && editorQuestionState === "") {
      setEditorQuestionState(input.question_desc);
      if (input.question_options !== null) {
        const options = JSON.parse(input.question_options);
        setEditorOption1State(options[0]);
        setEditorOption2State(options[1]);
        setEditorOption3State(options[2]);
        setEditorOption4State(options[3]);
      }
    }
  }, [input])

  const handleSubmit = async () => {
    let answer;
    var convertedOptionsState = [editorOption1State, editorOption2State, editorOption3State, editorOption4State];
    answer = {
      question_desc: editorQuestionState,
      question_options: JSON.stringify(convertedOptionsState),
    };
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (props.id === 0) {
      await axios
        .post(
          site_ip + '/createFeedbackQuestionsList',
          { ...input, ...answer },
          jwtHeader
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Question added successfully.',
              icon: 'success',
            });
            navigate('/admin/feedback-question-listing');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      await axios
        .post(site_ip + '/setFeedbackQuestionsList', { ...input, ...answer }, jwtHeader)
        .then(function (response) {
          // console.log(response);
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Question Updated successfully.',
              icon: 'success',
            });
            navigate('/admin/feedback-question-listing');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };


  return (
    <Aux>
      <Row style={{ margin: 0 }}>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Question</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        config={custom_config}
                        editor={Editor}
                        data={editorQuestionState}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setEditorQuestionState(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Option 1</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                          config={custom_config}
                          editor={Editor}
                          data={editorOption1State}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorOption1State(data)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Option 2</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                          config={custom_config}
                          editor={Editor}
                          data={editorOption2State}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorOption2State(data)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Option 3</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                          config={custom_config}
                          editor={Editor}
                          data={editorOption3State}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorOption3State(data)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Option 4</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                          config={custom_config}
                          editor={Editor}
                          data={editorOption4State}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorOption4State(data)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}
