

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table, Container, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';


import API from '../../API';
import Aux from '../../../hoc/_Aux';

import '../master.css';
export default function ConceptList(props) {
  
  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [editorialBoardMembers, setEditorialBoardMembers] = useState([]);


  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };

  const [units, setUnits] = useState([]);

  useEffect(()=>{
    fetchSubjects();
    fetchCategories();
  }, [])

  const handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const initialValues = {
    member_first_name: '',
    speciality_id: '',
    subject_id: '',
  };
  const [search, setSearch] = useState(initialValues);
  

  const handleSearch = async () => {
    await axios
      .post(`${site_ip}/searchEditorialBoard`, search, jwtHeader)
      .then((res) => {
        setEditorialBoardMembers(res.data.Data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) =>{
    navigate('/admin/edit-editorial-board-member/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        member_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deleteEditorialBoard/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            handleSearch();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Aux>
      <br />
      <Container fluid>
      <Row>
          <Col>
            <Card>
              <Card.Header as="h4">Search Editorial Board Member</Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                  <Col md={3}>
                      <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          as="select"
                          name="speciality_id"
                          className="mb-3"
                          value={search.speciality_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Category</option>
                          {categories?.map((val) => (
                            <option key={val.category_id} value={val.category_id}>
                              {val.category_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Subject </Form.Label>
                        <Form.Control
                          as="select"
                          name="subject_id"
                          className="mb-3"
                          value={search.subject_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Subject</option>
                          {subjects.map((val) => (
                            <option key={val.subject_id} value={val.subject_id}>
                              {val.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          name="member_first_name"
                          value={search.member_first_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3} className="mt-1">
                      <Button
                        variant="primary"
                        className="mt-4"
                        onClick={handleSearch}
                      >
                        Search{' '}
                      </Button>
                      <Button
                        variant="warning"
                        className="mt-4 mb-0 m-3"
                        onClick={() => setSearch(initialValues)}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center" style={{marginTop:"15px"}}>
          <Col md={6}>
            <h4>Editorial Board Member List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/add-editorial-board-member')}
            >
              Add Editorial Board Member
            </Button>
          </Col>
        </Row>
        <Row >
          <Col xl={12} className="mt-3">
              <table className="customTable">
                <thead>
                 <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    {/* <th>Topic</th> */}
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>  
                <tbody>
                  {editorialBoardMembers?.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td style={{textAlign:'center'}}>{index + 1}</td>
                        <td>{val.member_first_name}</td>
                        <td>{val.member_last_name}</td>
                        <td>{val.member_email}</td>
                        <td>{val.mobile_number}</td>
                        <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(val.member_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(val.member_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>  
              </table>
          </Col>
        </Row>                    
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
