import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';

import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
import API from '../../API';
export default function TemplateList(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const [show, setShow] = useState(false);  
  useEffect(() => {
    getTemplateList()
  }, [])

  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = () => {
    axios
      .get(site_ip + '/getEmailTemplates', jwtHeader)
      .then((res) => {
        setTemplateList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }

  const removeRecord = () => {

  }

  const renderQuestion = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )
  };

  const columns = [
    {
      name: 'S. No.',
      selector: (row, index) => index + 1,
      width: "100px"
    },
    {
      name: 'Template Name',
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          {row?.email_template_name}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: 'Action',
      width: "100px",
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.email_template_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/email-template/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Email Template</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/email-template')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add New Template</button>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={templateList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}