import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Table,
  Container,
  Modal
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';


import API from '../API';
import { site_ip } from '../../globalSetting';

export default function Commentary(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);


  const [commentries, setCommentries] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [category, setCategory] = useState([]);
  const [course, setCourse] = useState([]);
  const fetchCommentries = async () => {
    try {
      setCommentries(await API.fetchCommentries());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategories = async () => {
    try {
      setCategory(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCourses = async () => {
    try {
      setCourse(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubject = async (subject_id) => {
    try {
      setCommentries(await API.fetchCommentriesBySubject(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCommentries();
    fetchSubjects();
    fetchCourses();
    fetchCategories();
  }, []);
  const [search, setSearch] = useState({
    category_id: '',
    subject_id: '',
    course_id: '',
    case_name: '',
    date_from: '',
    date_to: '',
    patient_name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearch = async () => {
    await axios
      .post(`${site_ip}/searchExpertCommentaries`, search, jwtHeader)
      .then((res) => {
        setCommentries(res.data.Data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) =>{
    navigate('/admin/edit-commentary/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        commentary_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deleteExpertsCommentary/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            handleSearch();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header as="h4">Search Expert Commentries</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        name="category_id"
                        value={search.category_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Category</option>
                        {category?.map((item, key) => (
                          <option key={key} value={item.category_id}>
                            {item.category_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Course</Form.Label>
                      <Form.Control
                        name="course_id"
                        value={search.course_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Course</option>
                        {course?.map((item, key) => (
                          <option key={key} value={item.course_id}>
                            {item.course_title}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        name="subject_id"
                        value={search.subject_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Subject</option>
                        {subjects?.map((item, key) => (
                          <option key={key} value={item.subject_id}>
                            {item.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Date From</Form.Label>
                      <Form.Control
                        name="date_from"
                        value={search.date_from}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Date To</Form.Label>
                      <Form.Control
                        name="date_to"
                        value={search.date_to}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Case Name</Form.Label>
                      <Form.Control
                        name="case_name"
                        value={search.case_name}
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Patient Name</Form.Label>
                      <Form.Control
                        name="patient_name"
                        value={search.patient_name}
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mt-1">
                    <Button
                      variant="primary"
                      className="mt-4"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                    <Button
                      variant="warning"
                      className="mt-4 mb-0 m-2"
                      onClick={() =>
                        setSearch({
                          category_id: '',
                          subject_id: '',
                          course_id: '',
                          case_name: '',
                          date_from: '',
                          date_to: '',
                          patient_name: '',
                        })
                      }
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={3}>
          <div style={{backgroundColor:"#22b1e8", height:"45px", display:"flex", alignItems:"center", padding:"5px"}}>
            <p style={{fontSize:"20px", fontWeight:"bold", marginBottom:"0px", color:"#ffffff"}}>Filter By Subjects</p>            
          </div>
          <div style={{border:"1px solid #22b1e8", padding:"5px"}}>
            {subjects?.map((item, index) => (
                <div key={index} style={{minHeight:"45px", display:"flex", alignItems:"center", backgroundColor: search.subject_id === item.subject_id ? "#22b1e8" : "#ffffff", color: search.subject_id === item.subject_id ? "#ffffff" : "#22b1e8"}}>
                  <a
                    
                    onClick={() => handleSubject(item.subject_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.subject_name}
                  </a>
                </div>  
              ))}             
          </div> 
        </Col>
        <Col md={9}>
          <div >
          {/* <Card> */}
            {/* <Card.Header as="h5">Expert Commentary List</Card.Header>
            <Card.Body>
              <Table striped hover> */}
              <table className="customTable"> 
                <thead>
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Case Name</th>
                    <th>Expert Name</th>
                    <th>Patient Name</th>
                    <th width="80px" style={{textAlign:'center'}} >Action</th>
                  </tr>
                </thead>
                <tbody>
                  {commentries?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.case_name}</td>
                      <td>{item.expert_name}</td>
                      <td>{item.patient_name}</td>
                      <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(item.commentary_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(item.commentary_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>  
              {/* </Table>
            </Card.Body>
          </Card> */}
          </div>
        </Col>
      </Row>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Container>
  );
}
