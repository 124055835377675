import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
 
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
import API from '../../API';
export default function QuestionList(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const blankValues = {
    course_id: '',
    subject_id: '',
    book_id: '',
    difficulty_level_id: '',
    chapter_id: ''
  }

  const [values, setValues] = useState(blankValues);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    fetchSubjects();
    fetchCourses();
    getQuestions(values)
  }, [])

  const [subjects, setSubjects] = useState([]);
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };

  const [courses, setCourses] = useState([]);
  const fetchCourses = async () => {
    try {
      setCourses(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };

  const [questionList, setQuestionList] = useState([]);
  const getQuestions = (req) => {
    axios
      .post(site_ip + '/searchQuestionPara', req, jwtHeader)
      .then((res) => {
        setQuestionList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }

  const removeRecord = () => {

  }

  const renderQuestion = (text) => {
    return(
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
    )
};

  const columns = [
    {
      name: 'Question ID',
      selector: row => row.questions_para_id,
      width:"100px"
    },
    {
      name: 'Question Para',
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          {renderQuestion(row.question_para_text)}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: 'Action',
      width:"200px",
      cell: (row) => (
        <div style={{ display:"flex", paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.questions_para_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
          <button style={{ marginLeft:"5px", padding: "5px" }} onClick={() => navigate("/admin/osce-question/sub-question/"+row.questions_para_id)} className="primary-plus-button">
            Sub Question(s)
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/osce-question/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Question List</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/osce-question')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add Question</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col className="search-row">
            <Form.Control
              as="select"
              className="search-form-element"
              name="course_id"
              onChange={handleChange}
              value={values.course_id}
            >
              <option value="">Select Course</option>
              {courses?.map((item, key) => (
                <option key={key} value={item.course_id}>
                  {item.course_title}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="subject_id"
              onChange={handleChange}
              value={values.subject_id}
            >
              <option value="">Select Subject</option>
              {subjects?.map((item, key) => (
                <option key={key} value={item.subject_id}>
                  {item.subject_name}
                </option>
              ))}
            </Form.Control>
            <button onClick={() => getQuestions(values)} className="primary-plus-button">Search</button>
            <div className='spacer' />
            <button onClick={() => { getQuestions(blankValues); setValues(blankValues) }} className="secondary-button">Reset</button>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={questionList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}