import React, {useEffect, useState, useRef} from 'react'
import axios from 'axios'
import {Row, Col, Form, Button, Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom'
// import '../../common/commoncss.css';
import swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'
import { site_ip } from '../../../globalSetting';
import EmailEditor from 'react-email-editor';

const Add = (props) => {
    const emailEditorRef = useRef(null);
    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const {design, html} = data;
            // console.log(data.design); return
            handleSubmit(html, design)
        });
    };

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const onReady = () => {
        // editor is ready
        console.log('onReady');
    };


    const navigate = useNavigate()
    const [values, setValues] = useState({
        email_template_name: "",
        user_group_id: "",
        mail_from: "",
        mail_to: "",
        mail_cc: "",
        mail_bcc: "",
        mail_subject: "",
        mail_body: ""
    })
    const [error, setError] = useState('')
    const [userGroups, setUserGroup] = useState([])
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    useEffect(() => {
        getUserGroups();
    }, [])

    const handleChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        let data = values
        data[name] = value
        setValues(data)
    }

    const getUserGroups = () => {
        axios.get(
            site_ip + `/getUserGroups`,
            jwtHeader
        )
            .then((response) => {
                setUserGroup(response.data.Content)
            })
            .catch(() => {
                alert('Something went wrong. Please try again.')
            })
    }

    const handleSubmit = (html, json_fmt) => {
        if (
            values.email_template_name.trim() === ""
        ) {
            setError('Please fill all required Fields')
        } else {
            setError('');
            axios({
                method: 'POST',
                url: site_ip + '/addEmailTemplate',
                data: {
                    email_template_name: values.email_template_name.trim(),
                    user_group_id: values.user_group_id.trim(),
                    mail_from: values.mail_from.trim(),
                    mail_to: values.mail_to.trim(),
                    mail_cc: values.mail_cc.trim(),
                    mail_bcc: values.mail_bcc.trim(),
                    mail_subject: values.mail_subject.trim(),
                    mail_body: html,
                    mail_body_json: json_fmt
                },
                headers: {'access-token': localStorage.getItem('admin_token')}

            })
                .then(() => {
                    new swal({
                        title: "Done!",
                        text: "New template created successfully",
                        icon: "success",
                        timer: 2000,
                        button: false
                    }).then(() => {
                        navigate('/admin/email-template-listing')
                    })
                })
                .catch((error) => {
                    console.log(error);
                    //setMessage('Invalid credentials');
                })
        }
    }

    return (
        <div className="contentBoxAdmin" style={{marginTop: '10px'}}>
            <Row style={{margin:0}}>
                <Col md={6}><h3>Email Template</h3></Col>
                <Col md={6}>
                    <Link to="/admin/emailtemplate-list" className="publicProfileLink btn  pull-right"
                          style={{backgroundColor: '#c26a00', color: "#ffffff"}}>
                        View
                    </Link>
                </Col>
                {error && <Alert variant="danger">{error}</Alert>}{' '}
                <Col md={6} style={{marginBottom: "10px"}}>
                    <label>Template Name <span style={{color: "#ff0000"}}>*</span></label>
                    <Form.Control type="text" name="email_template_name" onChange={handleChange} placeholder=""/>
                </Col>
                <Col md={6} style={{marginBottom: "10px"}}>
                    <label>User Group</label>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={handleChange}
                        name="user_group_id"
                    >
                        <option>Select User Group</option>
                        {userGroups.map((item, index) => {
                            return (
                                <option key={index} value={item.user_group_id}>
                                    {item.user_group_name}
                                </option>
                            )
                        })}
                    </Form.Select>
                </Col>
                <Col md={6} style={{marginBottom: "10px"}}>
                    <label>Subject</label>
                    <Form.Control type="text" name="mail_subject" onChange={handleChange} placeholder=""/>
                </Col>
                <Col md={12} style={{marginBottom: "10px"}}>
                    <label style={{marginTop: '20px'}}>Email Template </label>
                </Col>
                <Col md={12}>
                    <EmailEditor minHeight={"75vh"} ref={emailEditorRef} onLoad={onLoad} onReady={onReady}/>
                </Col>
                <Col md={12}
                     style={{marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Button onClick={exportHtml}
                            style={{float: 'right', backgroundColor: '#c26a00', color: "#ffffff", border: 'none'}}
                            type="button">
                        Export Template & Save
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Add
