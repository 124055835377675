import React, { useState, useEffect } from 'react';
import { Button, Container, Col, Form, Row, Table, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import API from '../API';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';


export default function FacultyMembersList(props) {
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    member_first_name: '',
    speciality_id: '',
    subject_id: '',
  });
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [facultyMembers, setFacultyMembers] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchSubjects();
    fetchCategories();
    fetchFacultyMembers();
    // eslint-disable-next-line
  }, []);
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFacultyMembers = async () => {
    try {
      setFacultyMembers(await API.fetchFacultyMembers());
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    await axios
      .post(site_ip + '/searchFacultyMembers', search, jwtHeader)
      .then(function (response) {
        setFacultyMembers(response.data.Data);
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editButton = (id) =>{
    navigate('/admin/edit-faculty/'+id)
  }

  return (
    <Aux>
      <Container fluid>
        <br />
        <Row>
          <Col md={6}>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/add-faculty')}
            >
              Add Faculty Members
            </Button>
          </Col>
        </Row>
        <br />
        <Card>
            <Card.Header as="h4">Faculty Members List</Card.Header>
            <Card.Body> 
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="speciality_id"
                        value={search.speciality_id}
                        onChange={handleChange}
                      >
                        <option>Select Category</option>
                        {categories?.map((option) => (
                          <option value={option.category_id}>
                            {option.category_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="subject_id"
                        value={search.subject_id}
                        onChange={handleChange}
                      >
                        <option>Select Subject</option>
                        {subjects?.map((option) => (
                          <option value={option.subject_id}>
                            {option.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-3"
                        name="member_first_name"
                        value={search.member_first_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={3}>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-3"
                        name="last_name"
                        value={search.last_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col> */}

                  <Col md={3} className="mt-4">
                    <Button
                      variant="primary"
                      className="mt-1 mb-0"
                      onClick={fetchUsers}
                    >
                      Search
                    </Button>
                    <Button
                      variant="warning"
                      onClick={() =>
                        setSearch({
                          member_first_name: '',
                          speciality_id: '',
                          subject_id: '',
                        })
                      }
                      className="mt-1 mb-0 m-3"
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
            </Card.Body>            
          </Card>
          <Row>
          <Col className="mt-3">
          <table className="customTable">
                <thead>
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {facultyMembers?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1)}</td>
                      <td>{item.member_first_name +' '+ item.member_last_name}</td>
                      <td>{item.member_email}</td>
                      <td>{item.mobile_number}</td>
                      <td>
                        <div style={{display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(item.member_id)}>
                            <EditIcon />
                          </Button>
                          {/* <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(item.video_id)}>
                            <DeleteForeverIcon />
                          </Button> */}
                        </div> 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            {/* <div className="p-3 border bg-white">
              <Table striped hover size="sm" responsive className="mt-4">
                <thead className="lt-head">
                  <tr>
                    <th scope="row">Sr No</th>
                    <th>Action</th>
                    <th>Name </th>
                    <th>Email</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {facultyMembers?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <td>
                          <Link to={'/admin/edit-faculty/' + user.member_id}>
                            Edit
                          </Link>
                        </td>
                        <td>
                          {user.member_first_name +
                            ' ' +
                            ' ' +
                            user.member_last_name}
                        </td>
                        <td>{user.member_email}</td>
                        <td>{user.mobile_number}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div> */}
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
