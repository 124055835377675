import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "../user.css"
import { useLocation, useNavigate } from 'react-router-dom';
const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const getInitialRoute = (path) =>{
        let path_arr = path.split("/")
        if(path_arr[1] === "section"){
            return true
        }else{
            return false
        }
    }

    return (
        <div className="sticky-header">
            <Row style={{ margin: 0 }}>
                <Col className="fullwidth-menu">
                    <nav role="navigation">
                        <ul>
                            <li className={location.pathname === "/product/1/assessment" || getInitialRoute(location.pathname) ? "active" : ""} onClick={() => navigate("/product/1/assessment")} ><a href="/" onClick={(e) => { e.preventDefault();  }}>Assessment</a></li>
                            <li className={location.pathname === "/product/2/case_vignetts" ? "active" : ""} onClick={() => navigate("/product/2/case_vignetts")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Case Vignetts</a></li>
                            <li className={location.pathname === "/product/3/digital_atlas" ? "active" : ""} onClick={() => navigate("/product/3/digital_atlas")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Digital Atlas</a></li>
                            <li className={location.pathname === "/product/4/expert_commentaries" ? "active" : ""} onClick={() => navigate("/product/4/expert_commentaries")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Expert Commentaries</a></li>
                            <li className={location.pathname === "/product/5/expert_panel_videos" ? "active" : ""} onClick={() => navigate("/product/5/expert_panel_videos")}><a href="/" onClick={(e) => { e.preventDefault();  }} style={{whiteSpace:"nowrap"}} >Expert Panel Videos</a></li>
                            <li className={location.pathname === "/product/6/eBooks" ? "active" : ""} onClick={() => navigate("/product/6/eBooks")}><a href="/" onClick={(e) => { e.preventDefault();  }}>eBooks</a></li>
                            {/* <li className={location.pathname === "/product/7/news" ? "active" : ""} onClick={() => navigate("/product/7/news")}><a href="/" onClick={(e) => { e.preventDefault();  }}>News</a></li> */}
                            <li className={location.pathname === "/product/8/podcasts" ? "active" : ""} onClick={() => navigate("/product/8/podcasts")} ><a href="/" onClick={(e) => { e.preventDefault();  }}>Podcasts</a></li>
                            <li className={location.pathname === "/product/9/procedure_guide" ? "active" : ""} onClick={() => navigate("/product/9/procedure_guide")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Procedure Guide</a></li>
                        </ul>
                    </nav>

                </Col>
            </Row>
            <div style={{backgroundColor:"#f8f9fa", padding:"5px", borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc"}}>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <div className="main-searchbox-div">
                                <input className="header-search-box"></input>
                                <button>
                                    <i class="fas fa-search"></i><span style={{marginLeft:"10px"}}>Search</span>
                                </button>
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Header;