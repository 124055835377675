import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Container } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../../hoc/_Aux';
import API from '../../API';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
export default function QuestionAdd(props) {
  const navigate = useNavigate();
  const input = props.input;
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const [courses, setCourses] = useState(null);
  const fetchCoursesData = async () => {
    try {
      setCourses(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const [subject, setSubject] = useState(null);
  const fetchSubjectData = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [books, setBooks] = useState([]);
  const fetchSubjectBooks = async (subject_id) => {
    try {
      setBooks(await API.fetchSubjectBooks(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [chapters, setChapters] = useState([]);
  const fetchChapters = async (book_id) => {
    try {
      setChapters(await API.fetchChapters(book_id));
    } catch (error) {
      console.log(error);
    }
  };
  
  const [complexity, setComplexity] = useState(null);
  const fetchComplexityData = async () => {
    await axios.get(`${site_ip}/getQuestionComplexity`, jwtHeader).then((res) => {
      // console.log(res);
      setComplexity(res.data.Data);
    });
  };
  
  useEffect(() => {
    fetchSubjectData();
    fetchComplexityData();
    fetchCoursesData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    props.handleChange(name, value)
    if (name === 'subject_id') {
      fetchSubjectBooks(value);
    }
    if (name === 'book_id') fetchChapters(value);
  };

  const handleSubmit = () =>{
    props.changeStep(2)
  }


  return (
    <Aux>
      <Row style={{margin:0}}>
        <Col>
          <Card>
            <Card.Body>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Course</Form.Label>
                      <Form.Control
                        as="select"
                        name="course_id"
                        value={input.course_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Course</option>
                        {courses?.map((item) => {
                          return (
                            <option
                              key={item.course_id}
                              value={item.course_id}
                            >
                              {item.course_title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        as="select"
                        name="subject_id"
                        value={input.subject_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Subject</option>
                        {subject?.map((item) => {
                          return (
                            <option
                              key={item.subject_id}
                              value={item.subject_id}
                            >
                              {item.subject_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Book</Form.Label>
                      <Form.Control
                        as="select"
                        name="book_id"
                        value={input.book_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Book</option>
                        {books?.map((item) => {
                          return (
                            <option key={item.book_id} value={item.book_id}>
                              {item.book_title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Chapter</Form.Label>
                      <Form.Control
                        as="select"
                        name="chapter_id"
                        value={input.chapter_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Chapter</option>
                        {chapters?.map((item) => {
                          return (
                            <option
                              key={item.chapter_id}
                              value={item.chapter_id}
                            >
                              {item.chapter_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Difficulty Level</Form.Label>
                      <Form.Control
                        as="select"
                        name="difficulty_level"
                        value={input.difficulty_level}
                        onChange={handleChange}
                      >
                        <option value="">Select Difficulty</option>
                        {complexity?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.complexity_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSubmit}>
                  Next
                </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}
