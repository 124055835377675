import React from "react"
import { Modal } from "react-bootstrap";

const ErrorModal = (props) =>{
    return(
        <Modal size={"sm"} centered show={props.errorMessage === null ? false : true} onHide={() => props.setErrorMessage(null)}>
            <Modal.Header>
                <Modal.Title><span style={{ color: "#ff0000" }}>Error!</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontSize:"16px", marginBottom:"0px"}}>{props.errorMessage}</p></Modal.Body>
            <Modal.Footer>
                <button
                    style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                    className="btn btn-sm" variant="secondary" onClick={() => props.setErrorMessage(null)}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ErrorModal;