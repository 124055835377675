import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import API from '../../API';
import ExamQuestionType from "./ExamQuestionType";
import ExamQuestionMeta from "./ExamQuestionMeta";
import ExamQuestionText from "./ExamQuestionText";

export default function AddQuestion(props) {
    const params = useParams();
    const [activeTab, setActiveTab] = useState(1)
    const [questionId, setQuestionId] = useState(0);
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };
    const [input, setInputs] = useState({
        exam_id: '',
        course_id: '',
        subject_id: '',
        book_id: '',
        chapter_id: '',
        topic_id: '',
        question_type_id: '',
        ques_taxonomy_catg: '',
        difficulty_level: '',
        tags: '',
        marks: '',
        negative_marking: '',
        time_allowed: '',
        ques_status_cd: '',
        question_para_id: params?.osce_id === undefined ? '' : params?.osce_id
    });

    const handleChange = (name, value) => {
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {
        if (params.question_id !== undefined) {
            setQuestionId(params.question_id);
            fetchQuestionById(params.question_id)
        }
    }, [])

    const fetchQuestionById = async (question_id) => {
        try {
          let data = await API.fetchQuestionById(question_id);
          
          try{
            let option = data?.answers?.split(",").map(function(t){return parseInt(t)})
            data = {...data, answers: option}
          }catch(e){
            data = {...data, answers: []}
          }
          setInputs(data);
        
        } catch (error) {
          console.log(error);
        }
      };

    return (
        <div>
            <Container fluid>
                <div>
                    <Row style={{ margin: 0 }}>
                        <Col>
                            <Button onClick={() => setActiveTab(1)} className={activeTab === 1 ? "instituionTabSection active" : "instituionTabSection"}>Question Type</Button>
                            {questionId !== 0 || input?.question_type_id !== "" ? <Button onClick={() => setActiveTab(2)} className={activeTab === 2 ? "instituionTabSection active" : "instituionTabSection"}>Question Meta</Button> : null}
                            {questionId !== 0 || input?.question_type_id !== "" ? <Button onClick={() => setActiveTab(3)} className={activeTab === 3 ? "instituionTabSection active" : "instituionTabSection"}>Question</Button> : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* Edit Institution */}
                            {activeTab === 1 ? <ExamQuestionType id={questionId} handleChange={handleChange} input={input} changeStep={(val) => setActiveTab(val)} /> : null}
                            {activeTab === 2 ? <ExamQuestionMeta id={questionId} handleChange={handleChange} input={input} changeStep={(val) => setActiveTab(val)} /> : null}
                            {activeTab === 3 ? <ExamQuestionText id={questionId} handleChange={handleChange} input={input} changeStep={(val) => setActiveTab(val)} /> : null}
                            {/* Sections */}
                            {/* {activeTab === 2 ? <Sections id={courseId} /> : null} */}
                            {/* Subscriptions */}
                            {/* {activeTab === 3 ? <Lessons id={courseId} /> : null} */}
                            {/* Assessments */}
                            {/* {activeTab === 4 ? <Assessments id={courseId} /> : null} */}
                            {/* FAQs */}
                            {/* {activeTab === 5 ? <FAQs id={courseId} /> : null} */}
                            {/* Section Assessment */}
                            {/* {activeTab === 6 ? <SectionAssessment id={courseId} /> : null} */}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
