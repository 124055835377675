import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';

import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
import API from '../../API';
export default function QuestionList(props) {
  const params = useParams();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const blankValues = {
    question_para_id: params?.para_id 
  }

  const [values, setValues] = useState(blankValues);
  
  useEffect(() => {
    if (params.para_id !== undefined) {
      getQuestions(values)
    }
}, [])

  const [questionList, setQuestionList] = useState([]);
  const getQuestions = (req) => {
    axios
      .post(site_ip + '/searchQuestionByOSCEId', req, jwtHeader)
      .then((res) => {
        setQuestionList(res.data.Questions);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }

  const removeRecord = () => {

  }

  const renderQuestion = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )
  };

  const columns = [
    {
      name: 'Question ID',
      selector: row => row.question_id,
      width: "100px"
    },
    // {
    //   name: 'Subject',
    //   cell: (row) => (
    //     <div>
    //       {row.subject_name}
    //     </div>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: false,
    //   width: "200px"
    // },
    {
      name: 'Question',
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          {renderQuestion(row.question)}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: 'Action',
      width: "100px",
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.question_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/question/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Question List</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/question/osce/'+params?.para_id)} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add Question</button>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={questionList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}