
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table, Container, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import API from '../../API';
import Aux from '../../../hoc/_Aux';
import '../master.css';
export default function ConceptList(props) {
  
  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const [subjects, setSubjects] = useState([]);
  const fetchSubjects = async (id) => {
    try {
      setSubjects(await API.fetchSubjectByExamId(id));
    } catch (error) {
      console.log(error);
    }
  };

  const [exams, setExams] = useState([]);
  const fetchExams = async () => {
    try {
      setExams(await API.fetchExams());
    } catch (error) {
      console.log(error);
    }
  };

  const [units, setUnits] = useState([]);

  useEffect(()=>{
    fetchExams();
  }, [])

  const handleChange = (e) => {
    if(e.target.name === "exam_id"){
      setSearch({ ...search, [e.target.name]: e.target.value, ['subject_id'] : ""});  
      fetchSubjects(e.target.value)
    }else{
      setSearch({ ...search, [e.target.name]: e.target.value });
    }
    
  };

  const initialValues = {
    subject_id: '',
    exam_id: ''
  };
  const [search, setSearch] = useState(initialValues);
  
  const handleSearch = async () => {
    // console.log('object');
    await axios
      .post(`${site_ip}/getExamSubjectConcepts`, search, jwtHeader)
      .then((res) => {
        setUnits(res.data.Data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) =>{
    navigate('/admin/concept-edit/'+id)
  }


  const handleClose = () => setShow(false);

  const removeRecord = async() => {
    let data = {
      concept_id: selectedRecord
    }
    handleClose();
      await axios
      .post(site_ip + '/deleteExamSubjectConcept',data,jwtHeader)
      .then(function (response) {
          Swal.fire({
              title: 'Success',
              text: 'Record removed successfully',
              icon: 'success',
          });
          handleSearch();
      })
      .catch(function (error) {
          console.log('error' + error);
      });
 }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Aux>
      <br />
      <Container fluid>
      <Row>
          <Col>
            <Card>
              <Card.Header as="h4">Search Concept</Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Exam</Form.Label>
                        <Form.Control
                          as="select"
                          name="exam_id"
                          className="mb-3"
                          value={search.exam_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Exam</option>
                          {exams?.map((val) => (
                            <option key={val.exam_id} value={val.exam_id}>
                              {val.exam_cd}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Subject </Form.Label>
                        <Form.Control
                          as="select"
                          name="subject_id"
                          className="mb-3"
                          value={search.subject_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Subject</option>
                          {subjects.map((val) => (
                            <option key={val.subject_id} value={val.subject_id}>
                              {val.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    
                    <Col md={3} className="mt-1">
                      <Button
                        variant="primary"
                        className="mt-4"
                        onClick={handleSearch}
                      >
                        Search{' '}
                      </Button>
                      <Button
                        variant="warning"
                        className="mt-4 mb-0 m-3"
                        onClick={() => setSearch(initialValues)}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center" style={{marginTop:"15px"}}>
          <Col md={6}>
            <h4>Concept List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/concept-add')}
            >
              Add Concept
            </Button>
          </Col>
        </Row>
        <Row >
          <Col xl={12} className="mt-3">
              <table className="customTable">
                <thead>
                 <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Concept Name</th>
                    <th>Subject</th>
                    <th>Exam</th>
                    <th>Unit</th>
                    <th>Topic</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>  
                <tbody>
                  {units?.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td style={{textAlign:'center'}}>{index + 1}</td>
                        <td>{val.concept_name}</td>
                        <td>{val.subject_name}</td>
                        <td>{val.exam_name}</td>
                        <td>{val.unit_name}</td>
                        <td>{val.topic_name}</td>
                        <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(val.concept_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(val.concept_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>  
              </table>
          </Col>
        </Row>                    
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
