import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Card, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../globalSetting';
import API from '../API';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { useNavigate } from 'react-router-dom';
import {fileUpload} from '../Common/fileUploads';

export default function EditCourse(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    category_id: '',
    subject_id: '',
    course_title: '',
    course_short_desc: '',
    access_type_cd: '',
    keywords: '',
    overview: '',
    course_image: '',
    thumb_image: '',
    course_video: '',
    duration: '',
    price: '',
    strike_out_price: '',
    is_active: ''
  });
  
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  
  const [courseImage, setCourseImage] = useState(null);
  const [courseVideo, setCourseVideo] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [uploading, setUploading] = useState(false);


  const fetchCourseById = async (commentary_id) => {
    try {
      const data = await API.fetchCourseById(commentary_id);
    //   console.log(data)
      setValues(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  function handleChange(e) {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
  const [subjects, setSubjects] = useState([]);
  const getSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [categories, setCategories] = useState([]);
  const getCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchCourseById(props.id);  
    getSubjects();
    getCategories();
  }, []);

  const handleCourse = async (e) => {
    e.preventDefault();
    

    if(values.course_title.trim() === ""){
      alert("Please fill required fields");
      return
    }else if(values.category_id === ""){
      alert("Please fill required fields");
      return
    }else if(values.subject_id === ""){
      alert("Please fill required fields");
      return
    }else if(values.access_type_cd === ""){
      alert("Please fill required fields");
      return
    }else if(values.is_active === ""){
      alert("Please fill required fields");
      return
    }else if(values.access_type_cd === "Paid" && values.price.trim() === ""){
      alert("Please fill required fields");
      return
    }
    setUploading(true)

    let imageAttachment = null;
    if(courseImage !== null){
      imageAttachment = await fileUpload(courseImage[0])
    }

    let videoAttachment = null;
    if(courseVideo !== null){
      videoAttachment = await fileUpload(courseVideo[0])
    }

    let thumbnailAttachment = null;
    if(thumbnailImage !== null){
      thumbnailAttachment = await fileUpload(thumbnailImage[0])
    }

    const attachments = {
      course_image: imageAttachment === null ? values.course_image : imageAttachment,
      course_video: videoAttachment === null ? values.course_video : videoAttachment,
      thumb_image: thumbnailAttachment === null ? values.thumb_image : thumbnailAttachment,
    }
    

    setUploading(false);
    await axios
      .post(
        `${site_ip}/setCourses`,
        { ...values, ...attachments},
        jwtHeader
      )
      .then((res) => {
        if (res.data.Status === 200) {
          Swal.fire('Course Added');
          navigate('/admin/courses');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Container fluid>
        <Card style={{marginTop:"15px"}}>
          <Card.Header as="h4">Update Course</Card.Header>
          <Card.Body>
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Category <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="category_id"
                          value={values.category_id}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Category</option>
                          {categories?.map((item, key) => (
                            <option key={key} value={item.category_id}>
                              {item.category_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Subject <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="subject_id"
                          value={values.subject_id}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Subject</option>
                          {subjects?.map((item, key) => (
                            <option key={key} value={item.subject_id}>
                              {item.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Access Type <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="access_type_cd"
                          value={values.access_type_cd}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Access Type</option>
                          <option value="Free">Free</option>
                          <option value="Paid">Paid</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Active ? <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="is_active"
                          value={values.is_active}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select</option>
                          <option value={"1"}>Yes</option>
                          <option value={"0"}>No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Course Title <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="course_title"
                          value={values.course_title}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label> Slug</Form.Label>
                        <Form.Control
                          name="course_slug"
                          value={values.course_slug}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Keywords</Form.Label>
                        <Form.Control
                          name="keywords"
                          value={values.keywords}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Duration(in hrs)</Form.Label>
                        <Form.Control
                          name="duration"
                          value={values.duration}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Duration</option>
                          <option value="1">1 Hour</option>
                          <option value="2">2 Hours</option>
                          <option value="3">3 Hours</option>
                          <option value="4">4 Hours</option>
                          <option value="5">5 Hours</option>
                          <option value="6">6 Hours</option>
                          <option value="7">7 Hours</option>
                          <option value="8">8 Hours</option>
                          <option value="9">9 Hours</option>
                          <option value="10">10 Hours</option>
                          <option value="11">11 Hours</option>
                          <option value="12">12 Hours</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Number of Sections</Form.Label>
                        <Form.Control
                          name="no_of_sections"
                          value={values.no_of_sections}
                          onChange={handleChange}
                          className="mb-3"
                          type="number"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Instruction Level</Form.Label>
                        <Form.Control
                          name="instructor_id"
                          value={values.instructor_id}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Price <span style={{color:"#ff0000"}}>*</span> (Required, if Paid)</Form.Label>
                        <Form.Control
                          name="price"
                          value={values.price}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Strike Out Price</Form.Label>
                        <Form.Control
                          name="strike_out_price"
                          value={values.strike_out_price}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label> Short Description</Form.Label>
                        <Form.Control
                          name="course_short_desc"
                          value={values.course_short_desc}
                          onChange={handleChange}
                          className="mb-3"
                          as="textarea"
                          style={{height:"100px"}}
                        />
                      </Form.Group>
                    </Col>        
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Overview</Form.Label>
                        <Form.Control
                          name="overview"
                          value={values.overview}
                          onChange={handleChange}
                          className="mb-3"
                          as="textarea"
                          style={{height:"100px"}}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} style={{marginTop:"15px", marginBottom:"15px"}}>
                    <Form.Group>
                      <Form.Label>Course Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="course_image"
                        className="mb-3"
                        // value={values.case_pdf_file_link}
                        onChange={(e) => {
                          setCourseImage(e.target.files);
                        }}
                      />
                    </Form.Group>
                    {values?.course_image !== null && values?.course_image !== "" ? <img src={values?.course_image} style={{height:"100px", width:"100px"}} /> : null}
                  </Col>
                  <Col md={4} style={{marginTop:"15px"}}>
                    <Form.Group>
                      <Form.Label>Course Video</Form.Label>
                      <Form.Control
                        type="file"
                        className="mb-3"
                        accept="video/mp4,video/x-m4v,video/*"
                        name="course_video"
                        // value={values.case_html_file_link}
                        onChange={(e) => {
                          setCourseVideo(e.target.files);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} style={{marginTop:"15px"}}>
                    <Form.Group>
                      <Form.Label>Thumbnail Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"  
                        className="mb-3"
                        name="thumb_image"
                        // value={values.case_video_names}
                        onChange={(e) => {
                          setThumbnailImage(e.target.files);
                        }}
                      />
                    </Form.Group>
                    {values?.thumb_image !== null && values?.thumb_image !== "" ? <img src={values?.thumb_image} style={{height:"100px", width:"100px"}} /> : null}
                  </Col>
                  </Row>
                  <Button type="submit" onClick={handleCourse}>
                    Submit
                  </Button>
                </Form>
          </Card.Body>
        </Card>
      </Container>
      <Modal show={uploading}>
        <Modal.Body style={{textAlign:'center'}}>Please wait, while we are uploading.</Modal.Body>
      </Modal>
    </>
  );
}
