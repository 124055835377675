import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Card, Col, Row } from 'react-bootstrap';
import axios from 'axios';

import { site_ip } from '../../globalSetting';
import API from '../API';
import { useNavigate } from 'react-router-dom';
export default function AddTestSeries(props) {
  const navigate = useNavigate();
  const initialValues = {
    category: '',
    questions_count: '',
    result_schedule_ran_yn: '',
    series_type: '',
    status: '',
    subject_id: '',
    subscription_type: '',
    test_series_name: '',
    time_allowed: '',
    time_unit: '',
  };
  const [values, setValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };
  const [subjects, setSubjects] = useState([]);
  const getSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    try {
      setCategory(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubjects();
    getCategory();
  }, []);

  const handleSubmit = async (e) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    e.preventDefault();
    await axios
      .post(
        `${site_ip}/addTestSeries`,
        {
          category: values.category,
          questions_count: values.questions_count,
          result_schedule_ran_yn: values.result_schedule_ran_yn,
          series_type: values.series_type,
          status: values.status,
          subject_id: values.subject_id,
          subscription_type: values.subscription_type,
          test_series_name: values.test_series_name,
          time_allowed: values.time_allowed,
          time_unit: values.time_unit,
        },
        jwtHeader
      )
      .then((response) => {
        console.log(response);
        if (response.data.Status === 400) {
          navigate({
            pathname: '/admin/test-questions',
            state: {
              test_series_id: values.test_series_id,
              questions_count: values.questions_count,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Container fluid>
        <br />
        <Card>
          <Card.Header as="h4">New Test Series</Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Test Series Name</Form.Label>
                    <Form.Control
                      name="test_series_name"
                      value={values.test_series_name}
                      onChange={handleChange}
                      className="mb-3"
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Questions Count</Form.Label>
                    <Form.Control
                      name="questions_count"
                      value={values.questions_count}
                      onChange={handleChange}
                      className="mb-3"
                      type="number"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Time Allowed</Form.Label>
                    <Form.Control
                      name="time_allowed"
                      value={values.time_allowed}
                      onChange={handleChange}
                      className="mb-3"
                      type="number"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Time Unit</Form.Label>
                    <Form.Control
                      name="time_unit"
                      value={values.time_unit}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Time Unit</option>
                      <option value="Minutes">Minutes</option>
                      <option value="Hours">Hours</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Category</option>
                      {category?.map((item, key) => (
                        <option key={key} value={item.category_id}>
                          {item.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* <Col md={3}>
                  <Form.Group>
                    <Form.Label>Industry</Form.Label>
                    <Form.Control
                      name="industry_id"
                      value={values.industry_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Industry</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      name="job_id"
                      value={values.job_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Job</option>
                    </Form.Control>
                  </Form.Group>
                </Col> */}
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      name="subject_id"
                      value={values.subject_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Subject</option>
                      {subjects?.map((item, key) => (
                        <option key={key} value={item.subject_id}>
                          {item.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subscription Type</Form.Label>
                    <Form.Control
                      name="subscription_type"
                      value={values.subscription_type}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Subscription Type</option>
                      <option value="Paid">Paid</option>
                      <option value="Free">Free</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Series Type</Form.Label>
                    <Form.Control
                      name="series_type"
                      value={values.series_type}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Series Type</option>
                      <option value="Open">Open</option>
                      <option value="Live">Live</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Status</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Result Schedule</Form.Label>
                    <Form.Control
                      name="result_schedule_ran_yn"
                      value={values.result_schedule_ran_yn}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Button type="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
