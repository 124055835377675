import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Card, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { site_ip } from '../../globalSetting';
import API from '../API';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { useNavigate, useParams } from 'react-router-dom';
import { fileUpload } from '../Common/fileUploads';

export default function EditCommentary(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState({
    commentary_id: '',
    case_name: '',
    // abstract: '',
    category_id: '',
    subject_id: '',
    topic_id: '',
    course_id: '',
    expert_name: '',
    patient_name: '',
    patient_navigate: '',
    case_video_names: '',
    case_suggested_reading: '',
    case_html_file_link: '',
    case_pdf_file_link: '',
  });
  const [patientnavigate, setPatientnavigate] = useState("");
  const [suggestedReading, setSuggestedReading] = useState("");
  const [abstract, setAbstract] = useState("");

  const [pdf, setPDF] = useState(null);
  const [html, setHTML] = useState(null);
  const [video, setVideo] = useState(null);
  const [uploading, setUploading] = useState(false);

  function handleChange(e) {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === 'subject_id') getTopics(e.target.value);
  }
  const fetchCommentaryById = async (commentary_id) => {
    try {
      const data = await API.fetchCommentaryById(commentary_id);
      setValues(data);
      if (data.subject_id !== "") getTopics(data.subject_id)
      setPatientnavigate(data.patient_navigate?.length !== undefined ? data.patient_navigate : "");
      setSuggestedReading(data.case_suggested_reading?.length !== undefined ? data.case_suggested_reading : "");
      setAbstract(data.abstract?.length !== undefined ? data.abstract : "");
    } catch (error) {
      console.log(error);
    }
  };

  const [subjects, setSubjects] = useState([]);
  const getSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [categories, setCategories] = useState([]);
  const getCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const [courses, setCourses] = useState([]);
  const getCourses = async () => {
    try {
      setCourses(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const [topics, setTopics] = useState([]);
  const getTopics = async (subject_id) => {
    try {
      setTopics(await API.fetchTopics(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCommentaryById(params.commentary_id);
    getSubjects();
    getCategories();
    getCourses();
  }, []);

  const handleCommentary = async (e) => {
    e.preventDefault();

    if (values.case_name.trim() === "") {
      alert("Please fill required fields");
      return;
    } else if (values.expert_name.trim() === "") {
      alert("Please fill required fields");
      return;
    } else if (values.patient_name.trim() === "") {
      alert("Please fill required fields");
      return;
    }

    setUploading(true);


    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const caseDetails = {
      patient_navigate: patientnavigate,
      case_suggested_reading: suggestedReading,
      abstract: abstract
    };

    let pdfAttachment = null;
    if (pdf !== null) {
      pdfAttachment = await fileUpload(pdf)
    }

    let htmlAttachment = null;
    if (html !== null) {
      // const resultArray = await Promise.all(html.map(async (i) => multipleFileUpload(i)));
      htmlAttachment = await uploadBulkFiles();
    }

    let videoAttachment = null;
    if (video !== null) {
      videoAttachment = await fileUpload(video)
    }

    const attachments = {
      case_video_names: videoAttachment === null ? values.case_video_names : videoAttachment,
      case_html_file_link: htmlAttachment === null ? values.case_html_file_link : htmlAttachment,
      case_pdf_file_link: pdfAttachment === null ? values.case_pdf_file_link : pdfAttachment,
    }

    setUploading(false);
    await axios
      .post(`${site_ip}/setExpertsCommentary`, { ...values, ...caseDetails, ...attachments }, jwtHeader)
      .then((res) => {
        // console.log(res);
        if (res.data.Status === 200) {
          Swal.fire('Expert Commmentary Updated');
          navigate('/admin/commentary');
        }
      })
      .catch((err) => console.log(err));
  };

  const multipleFileUpload = async (attachment) => {
    const s3_url =
      'https://derma-news.s3.ap-south-1.amazonaws.com/' +
      attachment.webkitRelativePath;
    const target = { Bucket: 'derma-news', Key: attachment.webkitRelativePath, Body: attachment };
    const creds = {
      accessKeyId: 'AKIATT4SF2SQDEUQB7J7',
      secretAccessKey: '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',
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'ap-south-1', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress);
      });

      await parallelUploads3.done();
      return s3_url;

    } catch (e) {
      console.log(e);
    }
  }

  // useEffect(()=>{
  //   if(html !== null && html.length > 0){
  //     uploadBulkFiles();
  //   }
  // }, html)

  const uploadBulkFiles = async () => {
    let all_files = [];
    for (let i = 0; i < html.length; i++) {
      all_files.push(html[i])
    }
    await Promise.all(all_files.map(async (i) => multipleFileUpload(i)));
    // make the url
    let webkitRelativePath_for_file = all_files[0].webkitRelativePath;
    // Split
    let uri_part = webkitRelativePath_for_file.split("/");
    let folder_url = 'https://derma-news.s3.ap-south-1.amazonaws.com/' + uri_part[0] + "/index.html";
    return folder_url;
  }


  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header as="h4">Edit Commentary</Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      name="category_id"
                      value={values.category_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Category</option>
                      {categories?.map((item, key) => (
                        <option key={key} value={item.category_id}>
                          {item.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      name="subject_id"
                      value={values.subject_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Subject</option>
                      {subjects?.map((item, key) => (
                        <option key={key} value={item.subject_id}>
                          {item.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Course</Form.Label>
                    <Form.Control
                      name="course_id"
                      value={values.course_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Course</option>
                      {courses?.map((item, key) => (
                        <option key={key} value={item.course_id}>
                          {item.course_title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Topic</Form.Label>
                    <Form.Control
                      name="topic_id"
                      value={values.topic_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Topic</option>
                      {topics?.map((item, key) => (
                        <option key={key} value={item.topic_id}>
                          {item.topic_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Case Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                    <Form.Control
                      name="case_name"
                      value={values.case_name}
                      onChange={handleChange}
                      className="mb-3"
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Expert Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                    <Form.Control
                      name="expert_name"
                      value={values.expert_name}
                      onChange={handleChange}
                      className="mb-3"
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Patient Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                    <Form.Control
                      name="patient_name"
                      value={values.patient_name}
                      onChange={handleChange}
                      className="mb-3"
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Form.Label>Abstract</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        editor={Editor}
                        data={abstract}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setAbstract(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                {/* <Col md={12} style={{marginTop:"15px"}}>
                  <Form.Group>
                    <Form.Label>Patient navigate</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        editor={Editor}
                        data={patientnavigate}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPatientnavigate(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col> */}
                <Col md={12} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Form.Label>Suggested Reading</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        editor={Editor}
                        data={suggestedReading}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setSuggestedReading(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={4} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Form.Label>PDF File</Form.Label>
                    <Form.Control
                      type="file"
                      accept="application/pdf"
                      name="case_pdf_file_link"
                      className="mb-3"
                      // value={values.case_pdf_file_link}
                      onChange={(e) => {
                        setPDF(e.target.files[0]);
                        handleChange(e);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Form.Label>HTML files</Form.Label>
                    <Form.Control
                      type="file"
                      className="mb-3"
                      name="case_html_file_link"
                      directory=""
                      webkitdirectory=""
                      multiple
                      onChange={(e) => {
                        setHTML(e.target.files);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Form.Label>Video file</Form.Label>
                    <Form.Control
                      type="file"
                      accept="video/mp4,video/x-m4v,video/*"
                      className="mb-3"
                      name="case_video_names"
                      // value={values.case_video_names}
                      onChange={(e) => {
                        setVideo(e.target.files[0]);
                        handleChange(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Button type="submit" onClick={handleCommentary}>
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Modal show={uploading}>
          <Modal.Body style={{ textAlign: 'center' }}>Please wait, while we are uploading.</Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
