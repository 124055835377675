import React, {Component} from 'react';
import Aux from "../../hoc/_Aux";
import {Button, Card, Col, Dropdown, Form, Row} from "react-bootstrap";


const ExamDashboard = () => {

    return (
        <Aux>
            <Row>
            </Row>
        </Aux>
    );
}

export default ExamDashboard;