import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tabs,
  Tab,
  Table,
  Container,
} from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useNavigate } from 'react-router-dom';
import Aux from '../../hoc/_Aux';
import API from '../API';
import { site_ip } from '../../globalSetting';
import moment from 'moment';
export default function AddWebinar(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    webinar_title: '',
    category_id: '',
    subject_id: '',
    topic_id: '',
    course_id: '',
    webinar_date: '',
    duration: '',
    image_name: '',
    webinar_filename: '',
    webinar_link: '',
    webinar_type: 'open',
    participant_count_allowed: '',
    webinar_tag: '',
    registration_allowed_till_date: '',
    status: '1',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'subject_id') {
      fetchSubjectTopics(value);
    }
  };
  const [webinarId, setWebinarId] = useState('');
  const [aboutSpeakerState, setAboutSpeakerState] = useState("");
  const [webinarDesc, setWebinarDesc] = useState("");
  const [pollQuestionState, setPollQuestionState] = useState("");
  const [editorOption1State, setEditorOption1State] = useState("");
  const [editorOption2State, setEditorOption2State] = useState("");
  const [editorOption3State, setEditorOption3State] = useState("");
  const [editorOption4State, setEditorOption4State] = useState("");
  const [speaker, setSpeaker] = useState({
    speaker_name: '',
    speaker_image: '',
    speaker_credentials: '',
  });
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setSpeaker((prevState) => ({ ...prevState, [name]: value }));
  };
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const [subjects, setSubjects] = useState([]);
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [courses, setCourses] = useState([]);
  const fetchCourses = async () => {
    try {
      setCourses(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const [topics, setTopics] = useState([]);
  const fetchSubjectTopics = async (subject_id) => {
    try {
      setTopics(await API.fetchSubjectTopics(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCategories();
    fetchSubjects();
    fetchCourses();
  }, []);

  const [key, setKey] = useState('w-details');
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const handleWebinarDetails = async () => {
    if (
      !values.webinar_title ||
      !values.webinar_date ||
      !values.registration_allowed_till_date ||
      !values.status
    )
      alert('All fields are required');
    else
      await axios
        .post(
          site_ip + '/addWebinar',
          {
            webinar_title: values.webinar_title,
            category_id: values.category_id,
            subject_id: values.subject_id,
            topic_id: values.topic_id,
            course_id: values.course_id,
            webinar_desc: webinarDesc,
            webinar_date: values.webinar_date,
            duration: values.duration,
            image_name: values.image_name,
            webinar_filename: values.webinar_filename,
            webinar_link: values.webinar_link,
            webinar_type: values.webinar_type,
            participant_count_allowed: values.participant_count_allowed,
            webinar_tag: values.webinar_tag,
            registration_allowed_till_date:
              values.registration_allowed_till_date,
            status: values.status,
          },
          jwtHeader
        )
        .then(function (response) {
          // console.log(response.data);
          if (response.data.Status === 200) {
            Swal.fire('Webinar Details Added Successfully');
            navigate('/admin/webinars')
            // setKey('s-details');
            // setWebinarId(response.data.Data);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
  };
  const [speakerList, setSpeakerList] = useState([]);
  const handleSpeakerDetails = async () => {
    if (
      !speaker.speaker_name ||
      // !speaker.speaker_image ||
      !speaker.speaker_credentials
    )
      alert('All fields are required');
    else
      await axios
        .post(
          site_ip + '/addWebinarSpeakers',
          {
            speaker_name: speaker.speaker_name,
            speaker_image: speaker.speaker_image,
            speaker_credentials: speaker.speaker_credentials,
            webinar_id: 5,
            about_speaker: aboutSpeakerState,
          },
          jwtHeader
        )
        .then(function (response) {
          // console.log(response.data);
          if (response.data.Status === 200) {
            Swal.fire({
              title:
                'Speaker Details Added Successfully. Do you want to add more?',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Yes! Add More',
              denyButtonText: `No! just save this one`,
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire('Saved! Continue', '', 'success');
              } else if (result.isDenied) {
                Swal.fire('Speaker Details saved', '', 'info');
                setKey('w-poll');
              }
              setSpeaker({
                speaker_name: '',
                speaker_image: '',
                speaker_credentials: '',
              });
              setAboutSpeakerState("");
            });
          }
          setSpeakerList(response.data.Data);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
  };
  const handleWebinarPollSetup = async () => {
    var convertedOptionsState = [
      editorOption1State,
      editorOption2State,
      editorOption3State,
      editorOption4State,
    ];
    // if (webinarId === '') alert('All fields are required');
    // else
    await axios
      .post(
        site_ip + '/addWebinarPoll',
        {
          poll_question: pollQuestionState,
          poll_options: convertedOptionsState,
          webinar_id: webinarId,
        },
        jwtHeader
      )
      .then(function (response) {
        // console.log(response.data);
        if (response.data.Status === 200) {
          Swal.fire('Webinar Poll Setup Done Successfully');
          setKey('w-contents');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [content, setContent] = useState({
    webinar_recorded_video: '',
  });
  const handleContent = (e) => {
    const { name, value } = e.target;
    setContent((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleWebinarContent = async () => {
    await axios
      .post(
        site_ip + '/addWebinarContent',
        {
          webinar_id: webinarId,
          webinar_recorded_video: content.webinar_recorded_video,
        },
        jwtHeader
      )
      .then(function (response) {
        // console.log(response.data);
        if (response.data.Status === 200) {
          Swal.fire('Webinar Content Added Successfully');
          navigate('/admin/webinars');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  async function handleSpeakerDelete(speaker_id) {
    await axios
      .get(site_ip + '/deleteWebinarSpeaker/' + speaker_id, jwtHeader)
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  }

  return (
    <Aux>
      <Container fluid>
        {/* <Tabs
          id="controlled-tab"
          activeKey={key}
          className="mb-3"
          variant="pills"
        >
          <Tab eventKey="w-details" title="Webinar Details">
            
          </Tab>
          <Tab eventKey="s-details" title="Speaker Details">
            <Card>
              <Card.Header as="h4">
                Add Speaker
                {speakerList.length !== 0
                  ? 's (' + speakerList.length + ')'
                  : null}
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Speaker Name</Form.Label>
                        <Form.Control
                          name="speaker_name"
                          value={speaker.speaker_name}
                          onChange={handleChange2}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Speaker Image</Form.Label>
                        <Form.Control
                          name="speaker_image"
                          value={speaker.speaker_image}
                          onChange={handleChange2}
                          className="mb-3"
                          type="file"
                          accept="image/*"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Credentials</Form.Label>
                        <Form.Control
                          name="speaker_credentials"
                          value={speaker.speaker_credentials}
                          onChange={handleChange2}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>About Speaker</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <Editor
                            editorState={aboutSpeakerState}
                            onEditorStateChange={setAboutSpeakerState}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Button variant="primary" onClick={handleSpeakerDetails}>
                    Add Speaker Details
                  </Button>
                </Form>
                <br />
                {speakerList.length !== 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Speaker Name</th>
                        <th>Image Name</th>
                        <th>Credentials</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {speakerList.map((speaker, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{speaker.speaker_name}</td>
                          <td>{speaker.speaker_image}</td>
                          <td>{speaker.speaker_credentials}</td>
                          <td>
                            <Button
                              onClick={() =>
                                handleSpeakerDelete(speaker.speaker_id)
                              }
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : null}
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="w-poll" title="Poll Setup">
            <Card>
              <Card.Header as="h4">Add Poll Setup</Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Poll Question</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <Editor
                            editorState={pollQuestionState}
                            onEditorStateChange={setPollQuestionState}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Option 1</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <Editor
                            editorState={editorOption1State}
                            onEditorStateChange={setEditorOption1State}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Option 2</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <Editor
                            editorState={editorOption2State}
                            onEditorStateChange={setEditorOption2State}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Option 3</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <Editor
                            editorState={editorOption3State}
                            onEditorStateChange={setEditorOption3State}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Option 4</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <Editor
                            editorState={editorOption4State}
                            onEditorStateChange={setEditorOption4State}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Button variant="primary" onClick={handleWebinarPollSetup}>
                    Add Poll Setup
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="w-contents" title="Content">
            <Card>
              <Card.Header as="h4">Add Content</Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Recorded Video</Form.Label>
                        <Form.Control
                          name="webinar_recorded_video"
                          value={content.webinar_recorded_video}
                          onChange={handleContent}
                          className="mb-3"
                          type="file"
                          accept="video/*"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Button variant="primary" onClick={handleWebinarContent}>
                    Add Content
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs> */}
        <Card>
              <Card.Header as="h4">Add Webinar Details</Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          name="category_id"
                          value={values.category_id}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Category</option>
                          {categories?.map((item, key) => (
                            <option key={key} value={item.category_id}>
                              {item.category_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          name="subject_id"
                          value={values.subject_id}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Subject</option>
                          {subjects?.map((item, key) => (
                            <option key={key} value={item.subject_id}>
                              {item.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                          name="topic_id"
                          value={values.topic_id}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Topic</option>
                          {topics?.map((item, key) => (
                            <option key={key} value={item.topic_id}>
                              {item.topic_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Course</Form.Label>
                        <Form.Control
                          name="course_id"
                          value={values.course_id}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select Course</option>
                          {courses?.map((item, key) => (
                            <option key={key} value={item.course_id}>
                              {item.course_title}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Webinar Title <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="webinar_title"
                          value={values.webinar_title}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Webinar Link</Form.Label>
                        <Form.Control
                          name="webinar_link"
                          value={values.webinar_link}
                          onChange={handleChange}
                          className="mb-3"
                          type="url"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Webinar Tag</Form.Label>
                        <Form.Control
                          name="webinar_tag"
                          value={values.webinar_tag}
                          onChange={handleChange}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Last Registration Date <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          min={moment(new Date()).format('YYYY-MM-DD')}
                          name="registration_allowed_till_date"
                          value={values.registration_allowed_till_date}
                          onChange={handleChange}
                          className="mb-3"
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Webinar Date <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          min={values.registration_allowed_till_date === "" ? moment(new Date()).format('YYYY-MM-DD') : values.registration_allowed_till_date}
                          name="webinar_date"
                          value={values.webinar_date}
                          onChange={handleChange}
                          className="mb-3"
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Webinar Duration</Form.Label>
                        <Form.Control
                          name="duration"
                          value={values.duration}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="0">Select Duration</option>
                          <option value="30">30 mins</option>
                          <option value="60">1 hour</option>
                          <option value="90">1 hour 30 mins</option>
                          <option value="120">2 hours</option>
                          <option value="150">2 hours 30 mins</option>
                          <option value="180">3 hours</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Thumbnail Image</Form.Label>
                        <Form.Control
                          name="image_name"
                          value={values.image_name}
                          onChange={handleChange}
                          className="mb-3"
                          type="file"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Webinar Filename</Form.Label>
                        <Form.Control
                          name="webinar_filename"
                          value={values.webinar_filename}
                          onChange={handleChange}
                          className="mb-3"
                          type="file"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={9}>
                      <Form.Group>
                        <Form.Label>Webinar Description</Form.Label>
                        <div
                          style={{
                            border: '1px solid #ced4da',
                            padding: '2px',
                            minHeight: '300px',
                          }}
                        >
                          <CKEditor
                            editor={Editor}
                            data={webinarDesc}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setWebinarDesc(data)
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Status <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Webinar Type</Form.Label>
                        <Form.Control
                          name="webinar_type"
                          value={values.webinar_type}
                          onChange={handleChange}
                          className="mb-3"
                          as="select"
                        >
                          <option value="">Select</option>
                          <option value="open">Open</option>
                          <option value="closed">Closed</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label> Max Participants Count </Form.Label>
                        <Form.Control
                          name="participant_count_allowed"
                          value={values.participant_count_allowed}
                          onChange={handleChange}
                          className="mb-3"
                          type="number"
                          min={1}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Button variant="primary" onClick={handleWebinarDetails}>
                    Add Webinar Details
                  </Button>
                </Form>
              </Card.Body>
            </Card>                  
      </Container>
    </Aux>
  );
}
