import React, { useEffect } from 'react';
import Aux from '../hoc/_Aux';
import { useNavigate } from 'react-router-dom';
const Logout = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    // localStorage.removeItem('user_id');
    window.sessionStorage.clear();
    window.sessionStorage.clear();
    // navigate.replace('');
    // this.navigate('/auth/signin');
    window.location.href = '/admin';
    // eslint-disable-next-line
  }, []);
  return <Aux>Logging you out...</Aux>;
};
export default Logout;
