import React, { useState, useEffect } from 'react';
import { Button, Container, Col, Row, Table, Modal } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../globalSetting';


import API from '../API';
import Aux from '../../hoc/_Aux';
import '../common.css';
export default function InstitutionList(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    fetchPublisherData();
    // eslint-disable-next-line
  }, []);
  const fetchPublisherData = async () => {
    try {
      setInstitutions(await API.fetchInstitutions());
    } catch (error) {
      console.log(error);
    }
  };

  const editButton = (id) =>{
    navigate('/admin/edit-institution/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        institution_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deleteInstitution/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            fetchPublisherData();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Aux>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <h4> Institution List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/add-institution')}
            >
              Add Institution
            </Button>
          </Col>
        </Row>
        {/* <Row>
        <Col md={12} style={{ marginTop: '15px' }}>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="role_id"
                    value={search.role_id}
                    onChange={handleChange1}
                  >
                    <option value="">Select Role</option>
                    {roles?.map((value) => (
                      <option key={value.role_id} value={value.role_id}>
                        {value.role_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Publisher</Form.Label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="publisher_id"
                    value={search.publisher_id}
                    onChange={handleChange1}
                  >
                    <option value="">Select Publisher</option>
                    {institution?.map((value) => (
                      <option
                        key={value.publisher_id}
                        value={value.publisher_id}
                      >
                        {value.publisher_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="user_name"
                    value={search.user_name}
                    onChange={handleChange1}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="user_email"
                    value={search.user_email}
                    onChange={handleChange1}
                  />
                </Form.Group>
              </Col>
              <Col md={5}></Col>
              <Col md={7}>
                <Button variant="primary">Search</Button>
                <Button
                  variant="secondary"
                  onClick={resetFilters}
                  style={{ marginLeft: '15px' }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}
        <Row>
          <Col xl={12} className="mt-3">
              <table className="customTable">
                <thead>
                 <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Institution Name</th>
                    <th>City</th>
                    <th>Mobile</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>  
                <tbody>
                  {institutions?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td style={{textAlign:'center'}}>{index + 1}</td>
                        <td>{user.institute_name}</td>
                        <td>{user.city}</td>
                        <td>{user.phone}</td>
                        <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(user.institution_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(user.institution_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>  
              </table>
          </Col>
        </Row>
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
