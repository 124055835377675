import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../../hoc/_Aux';
import API from '../../API';
import { site_ip } from '../../../globalSetting';
import { useParams, useNavigate } from 'react-router-dom';

export default function TopicEdit(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    topic_name: '',
    subject_id: '',
    topic_id: '',
    topic_desc: '',
  });
  const [subjects, setSubjects] = useState([]);
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTopicById = async (topic_id) => {
    try {
      setData(await API.fetchTopicById(topic_id));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSubjects();
    fetchTopicById(params.topic_id);
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (data.topic_name !== '' && data.subject_id !== '') {
      await axios
        .post(`${site_ip}/setTopic`, data, jwtHeader)
        .then((response) => {
          //   console.log(response);
          if (response.data.Status === 200) {
            Swal.fire('Successfully Updated');
            navigate('/admin/topic-listing');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('Please fill all the fields');
    }
  };

  return (
    <Aux>
      <br />
      <Container fluid>
        <Row>
          <Col>
            <div align="right" className="mb-3">
              <Button
                onClick={() => navigate('/admin/topic-listing')}
              >
                Topics List
              </Button>
            </div>
            <Card>
              <Card.Header>
                <Card.Title style={{color:"#ffffff"}}>Edit Topic</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Subject </Form.Label>
                        <Form.Control
                          as="select"
                          name="subject_id"
                          className="mb-3"
                          value={data.subject_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Subject</option>
                          {subjects.map((val) => (
                            <option key={val.subject_id} value={val.subject_id}>
                              {val.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Topic </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Topic"
                          name="topic_name"
                          value={data.topic_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Topic Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="topic_desc"
                          value={data.topic_desc}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button onClick={handleSubmit} variant="primary">
                    Update Topic
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
