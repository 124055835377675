import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Container, Modal } from 'react-bootstrap';
import axios from 'axios';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import moment from 'moment';
import API from '../API';
import { site_ip } from '../../globalSetting';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';


export default function Webinars(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const initialValues = {
    webinar_name: '',
    course_id: '',
    subject_id: '',
    owner_id: '',
    date_from: '',
    date_to: '',
    status: '',
  };
  const [search, setSearch] = useState(initialValues);
  const [videos, setVideos] = useState([]);
  const [subject, setSubject] = useState([]);
  const [course, setCourse] = useState([]);
  const [owner, setOwner] = useState([]);

  useEffect(() => {
    getVideos();
    getSubjects();
    getCourses();
    getOwners();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  const getVideos = async () => {
    try {
      setVideos(await API.fetchWebinars());
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubject = async (subject_id) => {
    try {
      setVideos(await API.fetchWebinarsBySubject(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const getSubjects = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const getCourses = async () => {
    try {
      setCourse(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const getOwners = async () => {
    try {
      setOwner(await API.fetchOwners());
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = async () => {
    
    await axios
      .post(`${site_ip}/searchWebinars`, search, jwtHeader)
      .then((res) => {
        setVideos(res.data.Webinars);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) =>{
    navigate('/admin/edit-webinar/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        course_id: selectedRecord
      }
      handleClose();
        await axios
        .get(site_ip + '/deleteWebinar/'+selectedRecord,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            handleSearch();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

   const changeDateFormat = (date) =>{
    return moment(date).format("MMMM Do YYYY")
  }

  return (
    <Container fluid>
      <br />

      <Row>
        <Col>
          <Card>
            <Card.Header as="h4">Search Webinars</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Course</Form.Label>
                      <Form.Control
                        name="course_id"
                        value={search.course_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Course</option>
                        {course?.map((item, key) => (
                          <option key={key} value={item.course_id}>
                            {item.course_title}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        name="subject_id"
                        value={search.subject_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Subject</option>
                        {subject?.map((item, key) => (
                          <option key={key} value={item.subject_id}>
                            {item.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Owner</Form.Label>
                      <Form.Control
                        name="owner_id"
                        value={search.owner_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Owner</option>
                        {owner?.map((item, key) => (
                          <option key={key} value={item.admin_id}>
                            {item.admin_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Date From</Form.Label>
                      <Form.Control
                        name="date_from"
                        value={search.date_from}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Date To</Form.Label>
                      <Form.Control
                        name="date_to"
                        value={search.date_to}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Webinar Name</Form.Label>
                      <Form.Control
                        name="webinar_name"
                        value={search.webinar_name}
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        name="status"
                        value={search.status}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mt-1">
                    <Button
                      variant="primary"
                      className="mt-4"
                      onClick={handleSearch}
                    >
                      Search{' '}
                    </Button>
                    <Button
                      variant="warning"
                      className="mt-4 mb-0 m-3"
                      onClick={() => setSearch(initialValues)}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={3}>
          <div style={{backgroundColor:"#22b1e8", height:"45px", display:"flex", alignItems:"center", padding:"5px"}}>
            <p style={{fontSize:"20px", fontWeight:"bold", marginBottom:"0px", color:"#ffffff"}}>Filter By Subjects</p>            
          </div>
          <div style={{border:"1px solid #22b1e8", padding:"5px"}}>
            {subject?.map((item, index) => (
                <div key={index} style={{minHeight:"45px", display:"flex", alignItems:"center", backgroundColor: search.subject_id === item.subject_id ? "#22b1e8" : "#ffffff", color: search.subject_id === item.subject_id ? "#ffffff" : "#22b1e8"}}>
                  <a
                    
                    onClick={() => handleSubject(item.subject_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.subject_name}
                  </a>
                </div>  
              ))}             
          </div>                 
        
        </Col>
        <Col md={9}>
          <Row>
            {/* {videos.map((item, index)=>{
              return(
                <CourseIcon item={item} key={index} editButton={editButton}  deleteEntry={deleteEntry} />
              )
            })} */}
            <table className="customTable">
                <thead>
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Title</th>
                    {/* <th>Category</th> */}
                    <th>Topic</th>
                    <th>Last Registration Date</th>
                    <th>Webinar Date</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {videos?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1)}</td>
                      <td>{item.webinar_title}</td>
                      {/* <td>{item.category_name}</td> */}
                      <td>{item.topic_name}</td>
                      <td>{changeDateFormat(item.registration_allowed_till_date)}</td>
                      <td>{changeDateFormat(item.webinar_date)}</td> 
                      <td>
                        <div style={{display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(item.webinar_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(item.webinar_id)}>
                            <DeleteForeverIcon />
                          </Button>
                        </div> 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </Row>
        </Col>
      </Row>
      <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
              Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
          </Modal.Footer>
      </Modal>
    </Container>
  );
}

const CourseIcon = (props) =>{
  const item = props.item;
  return(
    <Col md={6} style={{marginBottom:"15px"}}>
        <div style={{marginLeft:"15px", marginRight:"15px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding:"15px", position:"relative"}}>
          <img
              src="../img/upcoming-video-thumbnail.jpg"
              alt="alt"
              width="100%"
              style={{ borderRadius: '5px', cursor: 'pointer' }}
          />
          <p style={{fontSize:"20px", fontWeight:"bold", color:"#22b1e8", marginTop:"15px", marginBottom:"5px"}}>{item.webinar_title}</p>
          {/* <p style={{fontSize:"16px", color:"#22b1e8", marginTop:"5px", marginBottom:"5px"}}>Subject :<b>{item.subject_name}</b></p>
          <p style={{fontSize:"16px", color:"#22b1e8", marginTop:"5px"}}>Access Type :<b>{item.access_type_cd}</b></p> */}
          <div style={{position:"absolute", top:"5px", right:"5px"}}>
            <Button variant="success" className="rowActionButton" onClick={()=> props.editButton(item.webinar_id)}>
              <EditIcon />
            </Button>
            <Button variant="danger" className="rowActionButton" onClick={()=> props.deleteEntry(item.webinar_id)}>
              <DeleteForeverIcon />
            </Button>
          </div>
        </div>
    </Col>
  )
}