import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DataTable from 'react-data-table-component';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';
import { fileUpload } from '../../../Common/fileUploads';

export default function SupplymentryFiles(props) {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        book_id: props.bookId,
        content_type_id: "",
        chapter_id: null,
        content_file_name: ""
    }
    const [confirmationModal, setConfirmationModal] = useState(0);
    const [uploadSectionVisible, setUploadSectionVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);
    const [contentFile, setContentFile] = useState(null);
    const handleChange = (e) => {
        let { name, value } = e.target;
        let data = { ...values }
        data[name] = value;
        setValues(data)
    }

    useEffect(() => {
        getContentType();
        if (props.bookId !== 0) {
            getJournalFiles(props.bookId)
        }
    }, [])

    const [contentType, setContentType] = useState([]);
    const getContentType = () => {
        axios
            .get(site_ip + '/getContentType', jwtHeader)
            .then(function (response) {
                setContentType(response.data.Contents);
            })
            .catch(function (error) {
                console.log(error.message);
            });
    };

    const [fileList, setFileList] = useState([]);
    const getJournalFiles = (id) => {
        axios
            .get(site_ip + '/getBookSupplementaryMaterial/' + id, jwtHeader)
            .then((res) => {
                setFileList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = async () => {
        let req = { ...values };
        if (req.content_title === "") {
            setErrorMessage("Please add Content title.")
            return;
        } else if (values.book_supl_id === undefined && contentFile === null) {
            setErrorMessage("Please select a file to upload.")
            return;
        }
        
        // check if file is selected
        if (contentFile !== null) {
            let fileUrl = await fileUpload(contentFile[0])
            req = { ...req, content_file_name: fileUrl }
        }

        if (values.book_supl_id === undefined) {
            axios
                .post(site_ip + '/createBookSupplementaryMaterial', [req], jwtHeader)
                .then((res) => {
                    setSuccessMessage("New supplymentry filed added successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setBookSupplementaryMaterial', req, jwtHeader)
                .then((res) => {
                    setSuccessMessage("Supplementry file details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        setUploadSectionVisible(false);
        setValues(blankValues);
        getJournalFiles(props.bookId)
    }

    const columns = [
        {
            name: 'File Title',
            selector: row => row.content_type_name,
        },
        {
            name: 'File',
            selector: row => row.content_file_name,
            cell: (row) => (
                <a href={row.content_file_name}>{row.content_file_name}</a>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: false,
        },
        {
            name: 'Action',
            cell: (row) => (
                <div style={{ display:"flex", paddingTop: "5px", paddingBottom: "5px" }}>
                    <button style={{ padding: "5px" }} onClick={() => clickHandler(row)} className="primary-plus-button">
                        <EditIcon style={{ fontSize: "20px" }} />
                    </button>
                    <button style={{ padding: "5px", marginLeft: "5px", backgroundColor: "#ff4d4d" }} onClick={() => setConfirmationModal(row?.book_supl_id)} className="primary-plus-button">
                        <DeleteOutlineIcon style={{ fontSize: "20px" }} />
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: false,
        },
    ];

    const deleteRecord = () => {
        let req = { book_supl_id: confirmationModal };
        axios.post(site_ip + "/deleteBookSupplFile", req, jwtHeader)
            .then((res) => {
                getJournalFiles(props.bookId)
                setConfirmationModal(0)
            }).catch((err) =>
                console.log(err)
            )
    }

    const clickHandler = (row) => {
        setValues(row);
        setUploadSectionVisible(true);
    }

    const cancelAddEdit = () => {
        setValues(blankValues);
        setUploadSectionVisible(false);
    }

    return (
        <Aux>
            {uploadSectionVisible ?
                <div className='new-content-section'>
                    <Row className="underline">
                        <Col className="page-title-container">
                            <p>Upload File</p>
                        </Col>
                        <Col className="page-title-right">
                            <button onClick={() => cancelAddEdit()} className="secondary-button">Cancel</button>
                        </Col>
                    </Row>
                    <Row className="underline-dashed">
                        <Col style={{ paddingTop: "8px" }}>
                            <p style={{ marginBottom: "0px", fontSize: "12px", fontWeight: "bold" }}>(Allowed Formats : .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .wav, .mp3, .mp4, .txt, .jpg, .jpeg, .png or .gif)</p>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "8px" }}>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td><div>Content Type</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.content_type_id}
                                                as="select"
                                                name="content_type_id"
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {contentType.map((value, index) => (
                                                    <option value={value.content_type_id} key={index}>
                                                        {value.content_type_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Content File</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="file"
                                                name="cover_image1"
                                                onChange={(e) => setContentFile(e.target.files)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div style={{ display: "flex" }}>
                            <button onClick={() => onSubmit()} className="primary-plus-button">{values.book_supl_id === undefined ? "Add" : "Update"} File</button>
                            <div className='spacer' />
                            <button onClick={() => cancelAddEdit()} className="secondary-button">Cancel</button>
                        </div>
                    </div>
                    <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                    <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
                </div> : null}
            <div style={{ height: "10px" }}></div>
            <div className='new-content-section'>
                <Row className="underline">
                    <Col className="page-title-container">
                        <p>Supplementary Files</p>
                    </Col>
                    <Col className="page-title-right">
                        <button onClick={() => setUploadSectionVisible(true)} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Upload Supplementary File</button>
                    </Col>
                </Row>
                <div style={{ height: "8px" }}></div>
                <DataTable
                    className="table table-bordered"
                    columns={columns}
                    data={fileList}
                    pagination
                    dense
                />
                <Modal centered show={confirmationModal === 0 ? false : true} onHide={() => setConfirmationModal(0)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure to remove this record?</Modal.Body>
                    <Modal.Footer>
                        <button
                            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="secondary" onClick={() => setConfirmationModal(0)}>
                            Close
                        </button>
                        <button
                            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Aux>
    );
}
