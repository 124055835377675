import React, { useEffect, useState } from "react"
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Col, Row } from "react-bootstrap";
import "./book.css";
import { useParams, useNavigate } from "react-router-dom";

const BookDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const [bookDetails, setBookDetails] = useState({})
    useEffect(() => {
        getBookById(params?.id);
        if(params?.section_name === "authors"){
            getAuthorsByBookId(params?.id)
        }
    }, [])

    const getBookById = (id) => {
        axios
            .get(site_ip + '/getBookById/' + id, jwtHeader)
            .then((res) => {
                setBookDetails(res.data.Books[0]);

            })
            .catch((err) => console.log(err));
    };

    const [authors, setAuthors] = useState([]);
    const getAuthorsByBookId = (id) => {
        axios
            .get(site_ip + '/getBookAuthorByBook/' + id, jwtHeader)
            .then((res) => {
                let authors_list = res.data.Content;
                authors_list.sort((a,b) => parseInt(a.author_disp_seq) - parseInt(b.author_disp_seq));
                let authors = [];
                authors_list.map((item)=>{
                    // let auth = item?.salutation+" "+item?.first_name+" "+item?.last_name
                    let auth = item?.first_name+" "+item?.last_name
                    let obj= {
                        full_name: auth,
                        other_details : item 
                    }
                    authors.push(obj)
                })
                setAuthors(authors);
            })
            .catch((err) => console.log(err));
    };

    const sectionName = (key) => {
        if (key === "dedication") {
            return "Dedication";
        } else if (key === "preface") {
            return "Preface";
        } else if (key === "description") {
            return "Description";
        } else if (key === "copyright") {
            return "Copyright";
        } else if (key === "forward") {
            return "Forward";
        } else if (key === "overview") {
            return "Overview";
        } else if (key === "supplementary_information") {
            return "Supplementary Information";
        }
    }

    const sectionContent = (key) => {
        if (key === "dedication") {
            return (
                <div className="padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.dedication}</RawHTML>
                </div>
            )
        } else if (key === "preface") {
            return (
                <div className="padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.preface}</RawHTML>
                </div>
            )
        } else if (key === "description") {
            return (
                <div className="padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.book_desc}</RawHTML>
                </div>
            )
        } else if (key === "copyright") {
            return (
                <div className="padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.copyright_details}</RawHTML>
                </div>
            )
        } else if (key === "forward") {
            return (
                <div className="padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.foreward}</RawHTML>
                </div>
            )
        } else if (key === "overview") {
            return (
                <div className="padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.book_overview}</RawHTML>
                </div>
            )
        } else if (key === "supplementary_information") {
            return (
                <div className="chapter-content-box padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.book_suppl_details}</RawHTML>
                </div>
            )
        } else if (key === "supplementary_information") {
            return (
                <div className="chapter-content-box padding-15 custom-text-css">
                    <RawHTML>{bookDetails?.book_suppl_details}</RawHTML>
                </div>
            )
        }
    }

    return (
        <>
            <Container style={{ marginTop: "25px",  }}>
                <Row >
                    <Col>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/home") }}>Home</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/books/list") }}>Books</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/books/" + params?.id) }}>{bookDetails?.book_title}</a>
                    </Col>
                </Row>
                <Row >
                    <Col md={2}>
                        <div>
                            <img src={bookDetails?.book_logo} className="book-detail-logo" style={{maxWidth:"75%"}} />
                        </div>
                    </Col>
                    {/* <Col md={1}></Col> */}
                    <Col md={10}>
                        <div style={{paddingTop:"10px"}}>
                            <p className="black label-2 mb-5 book-main-title">{sectionName(params?.section_name)}</p>
                        </div>
                        <Row style={{paddingTop:"20px"}}>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="far fa-file-pdf"></i> Download Chapter PDF</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-share-alt"></i> Share</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-book"></i> Get Citation</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-search"></i> Search Book</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-pencil-alt"></i> Annotate</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{borderTop:"1px solid #ccc", marginTop:"30px", paddingTop:"30px"}}>
                    {params?.section_name !== "authors" ? 
                    <Col style={{ borderTop: "1px solid #ccc" }}>
                        {sectionContent(params?.section_name)}
                    </Col> :
                    <Col>
                        {authors?.map((item, index)=>{
                            return(
                                <BookAuthor key={index} item={item} />
                            )
                        })}
                    </Col> }
                </Row>
            </Container>
        </>
    )
}

export default BookDetails

const RawHTML = ({ children, className = "" }) => {
    return (
        children !== undefined ?
            <div className={className}
                dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }} /> : <div />
    )
}

const BookAuthor = ({item, className = "" }) =>{
    return(
        <div style={{marginBottom:"30px"}}>
            <h3>{item?.full_name}</h3>
            {item?.other_details?.affiliations !== "" && item?.other_details?.affiliations !== null ? 
            <div className={className}
            dangerouslySetInnerHTML={{ __html: item?.other_details?.affiliations.replace(/\n/g, '<br />') }} /> : null }
            {item?.other_details?.profile_details !== "" && item?.other_details?.profile_details !== null ? 
            <div className={className}
            dangerouslySetInnerHTML={{ __html: item?.other_details?.profile_details.replace(/\n/g, '<br />') }} /> : null }
        </div>
    )
}

