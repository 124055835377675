import React, { useEffect, useState } from 'react';
import {
  Table,
  Form,
  Button,
  Container,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { site_ip } from '../../globalSetting';
import API from '../API';

const TestSeries = () => {
  const [data, setData] = useState([]);
  // const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const fetchTestData = async () => {
    const response = await axios.get(site_ip + '/getTestSeries', jwtHeader);
    setData(response.data.Content);
    console.log(response);
  };
  useEffect(() => {
    fetchTestData();
  }, []);
  const initialValues = {
    test_series_name: '',
    category_id: '',
    subject_id: '',
  };
  const [search, setSearch] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };
  const [subject, setSubject] = useState([]);
  const getSubjects = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    try {
      setCategory(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubjects();
    getCategory();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${site_ip}/searchTestSeries`,
        {
          test_series_name: search.test_series_name,
          category: search.category_id,
          subject_id: search.subject_id,
        },
        jwtHeader
      )
      .then((res) => {
        // console.log(res);
        setData(res.data.Admin);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Container fluid>
        <br />
        <Card>
          <Card.Header as="h4">Search Test Series</Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Test Series Name</Form.Label>
                    <Form.Control
                      name="test_series_name"
                      value={search.test_series_name}
                      onChange={handleChange}
                      className="mb-3"
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      name="category_id"
                      value={search.category_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Category</option>
                      {category?.map((item, key) => (
                        <option key={key} value={item.category_id}>
                          {item.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      name="subject_id"
                      value={search.subject_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Subject</option>
                      {subject?.map((item, key) => (
                        <option key={key} value={item.subject_id}>
                          {item.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Button
                    variant="primary"
                    className="mt-4"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Header as="h4">
            Test Series Listing
            <Button variant="dark" style={{ float: 'right' }}>
              <Link to="/admin/add-test-series" style={{ color: '#fff' }}>
                New Test Series
              </Link>
            </Button>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Test Series Name</th>
                  <th>Test Series Type</th>
                  <th>Time Allowed</th>
                  <th>Subscription Type</th>
                  <th>Questions Count</th>
                  {/* <th>Start Test</th> */}
                  <th>Edit Series</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.test_series_name}</td>
                    <td>{item.series_type}</td>
                    <td>{item.time_allowed + ' ' + item.time_unit} </td>
                    <td>{item.subscription_type}</td>
                    <td>{item.questions_count}</td>
                    {/* <td>
                      <Link
                        to={{
                          pathname: '/admin/instruction-page',
                          state: item.test_series_id,
                        }}
                      >
                        Start
                      </Link>
                    </td> */}
                    <td>
                      <Link
                        to={{
                          pathname:
                            '/admin/edit-test-series/' + item.test_series_id,
                          // state: item,
                        }}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default TestSeries;
