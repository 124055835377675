import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Card, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../globalSetting';
import API from '../API';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import {fileUpload} from '../Common/fileUploads';

export default function LessonAdd(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [values, setValues] = useState({
    course_id: props.course_id,
    section_id: '',
    lesson_name: '',
    lesson_desc: '',
    lesson_duration: '',
    lesson_duration_unit: '',
    lesson_thubmnail_img_name: '',
    lesson_video: '',
    lesson_tags: '',
    content_type: '',
    quiz_ind: ''
  });
  
  const [courseImage, setCourseImage] = useState(null);
  const [courseVideo, setCourseVideo] = useState(null);
  const [uploading, setUploading] = useState(false);


  function handleChange(e) {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
  const [sections, setSections] = useState([]);
  
  const getSections = async() =>{
    await axios
    .get(site_ip + '/getCourseSections/'+props.course_id, jwtHeader)
    .then(function (response) {
        if(response.data.Sections.length !== 0){
            setSections(response.data.Sections)
        }
    })
    .catch(function (error) {
        console.log('error' + error);
    });
   }
  
  useEffect(() => {
    getSections();
  }, []);

  const handleCourse = async (e) => {
    e.preventDefault();
    

    if(values.lesson_name.trim() === ""){
      alert("Please fill lesson name");
      return
    }

    if(values.section_id === ""){
        alert("Please select Section name");
        return
    }

    if(values.lesson_duration === "" || values.lesson_duration < 1){
        alert("Invalid session duration.");
        return
    }

    if(values.lesson_duration_unit === "" || values.lesson_duration_unit < 1){
        alert("Please select duration unit.");
        return
    }
    setUploading(true)

    

    let imageAttachment = null;
    if(courseImage !== null){
      imageAttachment = await fileUpload(courseImage[0])
    }

    let videoAttachment = null;
    if(courseVideo !== null){
      videoAttachment = await fileUpload(courseVideo[0])
    }

    const attachments = {
        lesson_thubmnail_img_name: imageAttachment,
        lesson_video: videoAttachment
    }

    setUploading(false);

    await axios
      .post(
        `${site_ip}/createCourseLesson`,
        { ...values, ...attachments},
        jwtHeader
      )
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire('New Lesson Added successfully');
          props.showCompleteList()  
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <Form>
            <Row style={{marginTop:"15px"}}>
            <Col md={4}>
                <Form.Label>Section <span style={{color:"#ff0000"}}>*</span></Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                    name="section_id"
                    value={values.section_id}
                    onChange={handleChange}
                    className="mb-3"
                    as="select"
                >
                    <option value="">Select Section</option>
                    {sections?.map((item, key) => (
                    <option key={key} value={item.section_id}>
                        {item.section_name}
                    </option>
                    ))}
                </Form.Control>
            </Col>
            <Col md={4}>
                <Form.Label>Lesson Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
            </Col>
            <Col md={8}> 
                <Form.Control
                    name="lesson_name"
                    value={values.lesson_name}
                    onChange={handleChange}
                    className="mb-3"
                    type="text"
                />
            </Col>
            <Col md={4}>
                <Form.Label>Description</Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                    name="lesson_desc"
                    value={values.lesson_desc}
                    onChange={handleChange}
                    className="mb-3"
                    as="textarea"
                    style={{height:"100px"}}
                />
            </Col>
            <Col md={4}>
                <Form.Label>Duration <span style={{color:"#ff0000"}}>*</span></Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                    name="lesson_duration"
                    value={values.lesson_duration}
                    onChange={handleChange}
                    className="mb-3"
                    type="number"
                    min={1}
                />
            </Col>
            <Col md={4}>
                <Form.Label>Duration Unit <span style={{color:"#ff0000"}}>*</span></Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                    name="lesson_duration_unit"
                    value={values.lesson_duration_unit}
                    onChange={handleChange}
                    className="mb-3"
                    as="select"
                >
                    <option value="">Select Duration</option>
                    <option value="Minutes">Minute</option>
                    <option value="Hours">Hour</option>
                </Form.Control>
            </Col>
            <Col md={4}>
                <Form.Label>Lesson Tags</Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                    name="lesson_tags"
                    value={values.lesson_tags}
                    onChange={handleChange}
                    className="mb-3"
                    type="text"
                />
            </Col>
            <Col md={4}>
                <Form.Label>Content Type</Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                    name="content_type"
                    value={values.content_type}
                    onChange={handleChange}
                    className="mb-3"
                    type="text"
                />
            </Col>
            <Col md={4} style={{marginTop:"15px"}}>
                <Form.Label>Lesson Image</Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                type="file"
                accept="image/*"
                name="course_image"
                className="mb-3"
                onChange={(e) => {
                    setCourseImage(e.target.files);
                }}
                />
            </Col>
            <Col md={4} style={{marginTop:"15px"}}>
                <Form.Label>Lesson Video</Form.Label>
            </Col>
            <Col md={8}>
                <Form.Control
                type="file"
                className="mb-3"
                accept="video/mp4,video/x-m4v,video/*"
                name="course_video"
                onChange={(e) => {
                    setCourseVideo(e.target.files);
                }}
                />
            </Col>
            </Row>
            <Row>
                <Col style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:"15px", marginBottom:"15px"}}>
                    <Button type="submit" onClick={handleCourse}>
                        Add
                    </Button>
                </Col>
            </Row>
        </Form>
      </div>
      <Modal show={uploading}>
        <Modal.Body style={{textAlign:'center'}}>Please wait, while we are uploading.</Modal.Body>
      </Modal>
    </>
  );
}
