import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
export default function CategoryAdd(props) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    category_name: '',
    is_active: 'true',
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    e.preventDefault();
    if (data.category_name !== '' && data.is_active !== '')
      await axios
        .post(`${site_ip}/createCategories`, data, jwtHeader)
        .then((response) => {
          // console.log(response);
          if (response.data.Status === 200) {
            Swal.fire('Successfully Added');
            navigate('/admin/category-listing');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    else alert('Please fill all the fields');
  };

  return (
    <Aux>
      <Container fluid>
        <Row>
          <Col>
            <div align="right">
              <Button
                onClick={() => navigate('/admin/category-listing')}
              >
                List Category
              </Button>
            </div>
            <br />
            <Card>
              <Card.Header>
                <Card.Title as="h5">Add Category</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          type="text"
                          name="category_name"
                          value={data.category_name}
                          className="mb-3"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label> Active?</Form.Label>
                        <Form.Control
                          as="select"
                          name="is_active"
                          value={data.is_active}
                          className="mb-3"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="mt-4">
                      <Button
                        onClick={handleSubmit}
                        variant="primary"
                        className="mt-1"
                      >
                        Add Category
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
