import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Button, Image, Card, Form } from 'react-bootstrap';
import './user.css';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { site_ip } from '../config';
export default function Home() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginTab, setLoginTab] = useState(true)
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const blankValues = {
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    user_email: "",
    user_mobile: "",
    address: "",
    city: "",
    state_id: "",
    country_id: "",
    zipcode: "",
    gender: "",
    institution_id: "",
    doc_spec_id: ""
  }
  const [registerValues, setRegisterValues] = useState(blankValues)

  const handleChange = (e) => {
    let { name, value } = e.target;
    setRegisterValues({ ...registerValues, [name]: value })
  }

  useEffect(() => {
    if (params.type !== undefined) {
      if (params.type === "register") {
        setLoginTab(false)
      }
    }
    getProfession()
  }, [])

  const [specilization, setSpecilization] = useState([])
  const getProfession = () => {
    axios
      .get(site_ip + '/getDoctorSpecialisation')
      .then((res) => {
        setSpecilization(res.data.Content)
      })
      .catch((err) =>
        console.log("")
      );
  };

  const userLogin = () => {
    let req = {
      user_email: userEmail,
      password: userPassword
    }
    axios
      .post(site_ip + '/auth/siteSignin', req)
      .then((res) => {
        if (res.data?.token !== undefined) {
          localStorage.setItem("endUserToken", res.data?.token);
          localStorage.setItem("endUser", JSON.stringify(res.data?.content));
          localStorage.setItem("endUserName", res.data?.content?.first_name);
          localStorage.setItem("user_id", res.data?.content?.user_id);
          if (location?.state !== null) {
            navigate("/user/news/" + location?.state?.id + "/" + location?.state?.name)
          } else {
            navigate("/product/1/assessment")
          }

        } else {
          alert("Invalid credentials.")
        }
      })
      .catch((err) =>
        alert("Invalid credentials.")
      );
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (mobile.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (pass) => {
    if (pass.trim().length < 8) {
      return false;
    }
    return true;
  };

  const createAccount = () => {

    if (registerValues.first_name?.trim() === "") {
      alert('Please enter a valid email address.');
      return
    } else if (registerValues.last_name?.trim() === "") {
      alert('Please enter a valid mobile number.');
      return
    } else if (!validateEmail(registerValues.user_email)) {
      alert('Please enter a valid email address.');
      return
    } else if (!phonenumber(registerValues.user_mobile)) {
      alert('Please enter a valid mobile number.');
      return
    } else if (!validatePassword(registerValues.password)) {
      alert('Password must be minimum 8 character long');
      return;
    }
    
    axios
      .post(site_ip + '/auth/signup', registerValues)
      .then((res) => {
        console.log(res)
        if(res.data.Success === 200){
          alert("Congratulations! you have successfully registered. Please login to continue.")
          setRegisterValues(blankValues)
          setLoginTab(true)
        }else{
          alert(res.data.message)
        }

      })
      .catch((err) =>
        alert("Invalid credentials.")
      );
  };
  

  return (
    <div>
      <div className='login-page'>
        <Container fluid="md">
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div className='login-box'>
                <div style={{ textAlign: 'center' }}>
                  <img src="../../images/DKN.png" className="login-logo"></img>
                </div>
                <Row>
                  <div style={{ height: "20px" }} />
                  <Col style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <a href="/" className={loginTab ? 'tablink' : 'tablink active'} onClick={(e) => { e.preventDefault(); setLoginTab(false) }}>Create Account</a>
                    <a href="/" className={loginTab ? 'tablink active' : 'tablink'} onClick={(e) => { e.preventDefault(); setLoginTab(true) }}>Log In</a>
                  </Col>
                </Row>
                {loginTab ?
                  <>
                    <Row>
                      <Col>
                        <p className="loginpage-heading">Log In To Your Account</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={8}>
                        <p className="login-page-label">Email</p>
                        <input
                          className="loginpage-input"
                          placeholder=''
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Password</p>
                        <input
                          type={"password"}
                          className="loginpage-input"
                          placeholder=''
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                        <div style={{ textAlign: 'center', marginTop: "40px", marginBottom: "20px" }}>
                          <button className="user-primary-btn" onClick={() => userLogin()}>Log In</button>
                        </div>
                        <Row>
                          <Col md={5} style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}></div>
                          </Col>
                          <Col md={2} style={{ textAlign: 'center', justifyContent: 'center', display: "flex" }}>
                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>or</p>
                          </Col>
                          <Col md={5} style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} style={{ textAlign: 'center', justifyContent: 'center', display: "flex", margin: "15px 0px" }}>
                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>Continue With:</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="social-media-buttons">
                              <img src="../../images/google.png" />
                              Google
                            </button>
                            <div style={{ width: "20px" }} />
                            <button className="social-media-buttons">
                              <img src="../../images/facebook.png" />
                              Facebook
                            </button>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </> :
                  <>
                    <Row>
                      <Col>
                        <p className="loginpage-heading">Sign Up for a Free Account</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={8}>
                        <p className="login-page-label">First Name</p>
                        <input
                          className="loginpage-input"
                          placeholder=''
                          name="first_name"
                          value={registerValues?.first_name}
                          onChange={handleChange}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Last Name</p>
                        <input
                          className="loginpage-input"
                          placeholder=''
                          name="last_name"
                          value={registerValues?.last_name}
                          onChange={handleChange}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Mobile</p>
                        <input
                          className="loginpage-input"
                          placeholder=''
                          name="user_mobile"
                          value={registerValues?.user_mobile}
                          onChange={handleChange}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Email</p>
                        <input
                          className="loginpage-input"
                          placeholder=''
                          name="user_email"
                          value={registerValues?.user_email}
                          onChange={handleChange}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Password</p>
                        <input
                          type="password"
                          className="loginpage-input"
                          placeholder=''
                          name="password"
                          value={registerValues?.password}
                          onChange={handleChange}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Profession</p>
                        <select className="loginpage-input" name="doc_spec_id" value={registerValues?.doc_spec_id} onChange={handleChange}>
                          <option value="">--Select--</option>
                          {specilization?.map((item, index)=>{
                            return(
                              <option key={item?.doc_spec_id} value={item?.doc_spec_id}>{item?.specilisation}</option>    
                            )
                          })}
                        </select>
                        <div style={{ height: "20px" }} />
                        <div style={{ display: 'flex', marginTop: "15px", alignItems: 'center' }}>
                          <Form.Check checked={checked} onChange={() => null} onClick={() => setChecked(!checked)}  />
                          <p className="tnc-text">I agree to <a href="/" >Terms of Use</a> and <a href="/">Privacy Policy</a>.</p>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: "40px", marginBottom: "20px" }}>
                          <button disabled={!checked} className="user-primary-btn" style={{ width: "180px", opacity: checked ? 1 : 0.5 }} onClick={() => createAccount()} >Create an Account</button>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </>}
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
