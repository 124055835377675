import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
// import { Buffer } from "buffer";
window.Buffer = window.Buffer || require("buffer").Buffer;
export const fileUpload = async (attachment) => {
    const s3_url =
        'https://derma-news.s3.ap-south-1.amazonaws.com/' +
        attachment.name;
    const target = { Bucket: 'derma-news', Key: attachment.name, Body: attachment };
    const creds = {
        accessKeyId: 'AKIATT4SF2SQDEUQB7J7',
        secretAccessKey: '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',
    };

    try {
        const parallelUploads3 = new Upload({
            client: new S3Client({ region: 'ap-south-1', credentials: creds }),
            leavePartsOnError: false, // optional manually handle dropped parts
            params: target,
        });

        parallelUploads3.on('httpUploadProgress', (progress) => {
            console.log(progress,'rahul');
        });

        await parallelUploads3.done();
        return s3_url;

    } catch (e) {
        console.log(e);
        return "image not found."
    }

    // 

}

