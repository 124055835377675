import React from "react"
import { Modal, Button } from "react-bootstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const SuccessModal = (props) =>{
    return(
        <Modal size="sm" centered show={props.successMessage === null ? false : true} backdrop="static">
            <Modal.Body>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
                    <CheckCircleIcon style={{ fontSize: "60px", color: "#4caf50" }}/>
                    <p style={{ fontSize: "20px", color: "#4caf50", fontWeight: "bold", marginTop: "10px", marginBottom: "10px" }}>SUCCESS</p>
                    <p style={{ textAlign: 'center', fontSize: "15px", marginTop: "40px" }}>{props.successMessage}</p>
                    <Button className={"theme-primary-btn mb-15 mr-15"} onClick={() => props.setSuccessMessage(null)} >Okay</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModal