import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';
import { fileUpload } from '../../../Common/fileUploads';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

export default function SupplymentryFiles(props) {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        journal_id: props.journalId,
        content_title: "",
        content_file_name: "",
        chapter_url: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);
    const [pdfFile, setPDFFile] = useState(null);
    const [epubFile, setEPUBFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [html, setHTML] = useState(null);
    useEffect(() => {
        if (props.chapterId !== 0) {
            getChapterById(props.chapterId)
        }
    }, [])

    const getChapterById = (id) => {
        axios
            .get(site_ip + '/getBookChapterById/' + id, jwtHeader)
            .then((res) => {
                setValues(res.data.Content[0]);
            })
            .catch((err) => console.log(err));
    };

    const multipleFileUpload = async (attachment) => {
        const s3_url =
            'https://derma-books.s3.ap-south-1.amazonaws.com/' +
            attachment.webkitRelativePath;
        const target = { Bucket: 'derma-books', Key: attachment.webkitRelativePath, Body: attachment };
        const creds = {
            accessKeyId: 'AKIATT4SF2SQDEUQB7J7',
            secretAccessKey: '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',
        };
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({ region: 'ap-south-1', credentials: creds }),
                leavePartsOnError: false, // optional manually handle dropped parts
                params: target,
            });

            parallelUploads3.on('httpUploadProgress', (progress) => {
                console.log(progress);
            });

            await parallelUploads3.done();
            return s3_url;

        } catch (e) {
            console.log(e);
        }
    }

    const uploadBulkFiles = async () => {
        let all_files = [];
        for (let i = 0; i < html.length; i++) {
            all_files.push(html[i])
        }
        await Promise.all(all_files.map(async (i) => multipleFileUpload(i)));
        // make the url
        let webkitRelativePath_for_file = all_files[0].webkitRelativePath;
        // Split
        let uri_part = webkitRelativePath_for_file.split("/");
        let folder_url = 'https://derma-books.s3.ap-south-1.amazonaws.com/' + uri_part[0] + "/index.html";
        return folder_url;
    }

    const onSubmit = async () => {
        let req = { ...values };



        // check if file is selected
        if (pdfFile !== null) {
            let fileUrl = await fileUpload(pdfFile[0])
            req = { ...req, pdf_filename: fileUrl }
        }

        if (epubFile !== null) {
            let fileUrl = await fileUpload(epubFile[0])
            req = { ...req, epub_filename: fileUrl }
        }

        if (audioFile !== null) {
            let fileUrl = await fileUpload(audioFile[0])
            req = { ...req, audio_filename: fileUrl }
        }

        let htmlAttachment = null;
        if (html !== null) {
            htmlAttachment = await uploadBulkFiles();
        }

        // chapter_url

        axios
            .post(site_ip + '/setBookChapters', req, jwtHeader)
            .then((res) => {
                setSuccessMessage("Files updated successfully.")
            })
            .catch((err) => console.log(err));
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        getChapterById(props.chapterId)
    }

    return (
        <Aux>
            <div className='new-content-section' style={{ backgroundColor: "#f5f5f5" }}>
                <Row className="underline">
                    <Col className="page-title-container">
                        <p>Upload File</p>
                    </Col>
                    <Col className="page-title-right">
                        {/* <button onClick={() => cancelAddEdit()} className="secondary-button">Cancel</button> */}
                    </Col>
                </Row>
                <div style={{ paddingTop: "8px" }}>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        HTML Folder
                                        <p style={{ marginBottom: "0px", fontSize: "12px", fontWeight: "bold" }}>(HTML files with CSS/Image)</p>
                                    </div>
                                </td>
                                <td >
                                    <div className="col-md-5">
                                        <Form.Control
                                            type="file"
                                            name="case_html_file_link"
                                            directory=""
                                            webkitdirectory=""
                                            multiple
                                            onChange={(e) => {
                                              setHTML(e.target.files);
                                            }}
                                        />
                                        {/* {values?.pdf_filename === null || values?.pdf_filename === "" ? null :
                                            <a style={{ textDecoration: "underline", color: "blue" }} href={values?.pdf_filename}>{values?.pdf_filename}</a>} */}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        PDF File
                                        <p style={{ marginBottom: "0px", fontSize: "12px", fontWeight: "bold" }}>(Allowed Formats : .pdf)</p>
                                    </div>
                                </td>
                                <td >
                                    <div className="col-md-5">
                                        <Form.Control
                                            type="file"
                                            accept='application/pdf'
                                            name="cover_image1"
                                            onChange={(e) => setPDFFile(e.target.files)}
                                        />
                                        {values?.pdf_filename === null || values?.pdf_filename === "" ? null :
                                            <a style={{ textDecoration: "underline", color: "blue" }} href={values?.pdf_filename}>{values?.pdf_filename}</a>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        EPUB File
                                        <p style={{ marginBottom: "0px", fontSize: "12px", fontWeight: "bold" }}>(Allowed Formats : .epub)</p>
                                    </div>
                                </td>
                                <td >
                                    <div className="col-md-5">
                                        <Form.Control
                                            type="file"
                                            accept='.epub'
                                            name="cover_image1"
                                            onChange={(e) => setEPUBFile(e.target.files)}
                                        />
                                        {values?.epub_filename === null || values?.epub_filename === "" ? null :
                                            <a style={{ textDecoration: "underline", color: "blue" }} href={values?.epub_filename}>{values?.epub_filename}</a>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        Audio File
                                        <p style={{ marginBottom: "0px", fontSize: "12px", fontWeight: "bold" }}>(Allowed Formats : .wav, .mp3)</p>
                                    </div>
                                </td>
                                <td >
                                    <div className="col-md-5">
                                        <Form.Control
                                            type="file"
                                            accept='audio/wav,audio/mp3'
                                            name="cover_image1"
                                            onChange={(e) => setAudioFile(e.target.files)}
                                        />
                                        {values?.audio_filename === null || values?.audio_filename === "" ? null :
                                            <a style={{ textDecoration: "underline", color: "blue" }} href={values?.audio_filename}>{values?.audio_filename}</a>}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <div style={{ display: "flex" }}>
                        <button onClick={() => onSubmit()} className="primary-plus-button">Upload Files</button>
                    </div>
                </div>
                <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
            </div>
        </Aux>
    );
}
