import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import "../user.css"
import { useLocation, useNavigate } from 'react-router-dom';
const SecondaryHeader = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        let username = localStorage.getItem("endUserName");
        if (username !== null) {
            setUserName(username)
        } else {
            // navigate("/user/auth/login")
        }
    }, [])

    const logmeOut = () => {
        localStorage.clear();
        navigate("/user/auth/login")
    }

    return (
        <div style={{}} className="secondary-header">
            <div>
                <Row style={{ margin: 0 }}>
                    <Col md={1}>

                    </Col>
                    <Col md={5} style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <a href="/" onClick={(e) => e.preventDefault()}>Support</a>
                        <div className="menu-divider" />
                        <a href="/" onClick={(e) => e.preventDefault()}>Subscribe</a>
                        <div className="menu-divider" />
                        <a href="/" onClick={(e) => e.preventDefault()}>English</a> */}
                    </Col>
                    <Col md={5}>
                        {userName !== null ?
                            <div className="usergreeting-div">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        Hello <b>{userName}</b>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item href="#/action-1" style={{ color: "#000000" }}>About You</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2" style={{ color: "#000000" }}>Professional Information</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3" style={{ color: "#000000" }}>Newsletters & Alerts</Dropdown.Item>
                                        <Dropdown.Item href="/" onClick={(e) => { e.preventDefault(); logmeOut() }} style={{ color: "#000000" }}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> :
                            <div className="usergreeting-div">
                                <p className="usergreeting-text"><a style={{ color: "#1b1b1b", marginRight: "15px" }} href="/" onClick={(e) => { e.preventDefault(); navigate("/user/auth/register") }}>REGISTER</a></p>
                                <p className="usergreeting-text"><a style={{ color: "#1b1b1b", marginRight: "0px" }} href="/" onClick={(e) => { e.preventDefault(); navigate("/user/auth/login") }}>LOGIN</a></p>
                            </div>}
                    </Col>
                    <Col md={1}>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SecondaryHeader;