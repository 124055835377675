import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
 
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
export default function BookList(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'All']
  const [activeAlpha, setActiveAlpha] = useState("All")
  const [show, setShow] = useState(false);

  const blankValues = {
    subject_id: "",
    category_id: "",
    publisher_id: "",
    book_title:"",
    isbn:""
  }
  const [values, setValues] = useState(blankValues);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    // getCategoryList();
    getSubjectList();
    getPublishers();
    getBooks(values)
  }, [])

  useEffect(() => {
    let new_filter = { ...values, book_title: activeAlpha === "All" ? "" : activeAlpha }
    getBooks(new_filter)
  }, [activeAlpha])

  const [bookList, setBooksList] = useState([]);
  const getBooks = (req) => {
    let new_filter = { ...req, book_title: activeAlpha === "All" ? req?.book_title : activeAlpha }
    axios
      .post(site_ip + '/getBookSearch', new_filter, jwtHeader)
      .then((res) => {
        setBooksList(res.data.Books);
      })
      .catch((err) => console.log(err));
  };

  const [categoryList, setCategoryList] = useState([]);
  const getCategoryList = () => {
    axios
      .get(site_ip + '/getCategories', jwtHeader)
      .then((res) => {
        setCategoryList(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const [subjectList, setSubjectList] = useState([]);
  const getSubjectList = () => {
    axios
      .get(site_ip + '/getSubjects', jwtHeader)
      .then((res) => {
        setSubjectList(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  const [publishersList, setPublisersList] = useState([]);
  const getPublishers = () => {
        let req = {};
        axios
            .get(site_ip + '/getPublishers', jwtHeader)
            .then((res) => {
                setPublisersList(res.data.Data);
            })
            .catch((err) => console.log(err));
    };

  const handleClose = () => {
    setShow(false)
  }

  const removeRecord = () => {

  }

  const columns = [
    {
      name: 'ISBN',
      selector: row => row.isbn,
    },
    {
      name: 'Book Title',
      selector: row => row.book_title,
    },
    {
      name: 'DOI',
      selector: row => row.doi,
    },
    {
      name: 'Publisher',
      selector: row => row.publisher_name,
    },
    {
      name: 'Subject',
      selector: row => row.subject_name,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.book_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/book/edit/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Book List</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/book/add')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add Book</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col className="search-row">
            <Form.Control
              type="text"
              className="search-form-element"
              name="isbn"
              placeholder='ISBN'
              value={values.isbn}
              onChange={handleChange}
            ></Form.Control>
            {/* <Form.Control
              type="text"
              className="search-form-element"
              name="publisher_name"
              placeholder='Publisher Name'
              value={values.publisher_name}
              onChange={handleChange}
            ></Form.Control> */}
            {/* <Form.Control
              as="select"
              className="search-form-element"
              name="category_id"
              onChange={handleChange}
              value={values.category_id}
            >
              <option>Select Category</option>
              {categoryList.map((item, index) => {
                return (
                  <option key={index} value={item.category_id}>{item.category_name}</option>
                )
              })}
            </Form.Control> */}
            <Form.Control
              as="select"
              className="search-form-element"
              name="subject_id"
              onChange={handleChange}
              value={values.subject_id}
            >
              <option>Select Subject</option>
              {subjectList.map((item, index) => {
                return (
                  <option key={index} value={item.subject_id}>{item.subject_name}</option>
                )
              })}
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="publisher_id"
              onChange={handleChange}
              value={values.publisher_id}
            >
              <option>Select Publisher</option>
              {publishersList.map((item, index) => {
                return (
                  <option key={index} value={item.publisher_id}>{item.publisher_name}</option>
                )
              })}
            </Form.Control>
            <button onClick={() => getBooks(values)} className="primary-plus-button">Search</button>
            <div className='spacer' />
            <button onClick={() => { getBooks(blankValues); setValues(blankValues) }} className="secondary-button">Reset</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col>
            <div className="alphabet-filter">
              {alphabets.map((item, index) => {
                return (
                  <button key={index} onClick={() => setActiveAlpha(item)} className={item === activeAlpha ? "alpha-filter active" : "alpha-filter"}>{item}</button>
                )
              })}
            </div>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={bookList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}