import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Aux from '../../../hoc/_Aux';
import API from '../../API';
import { site_ip } from '../../../globalSetting';

import { useParams, useNavigate } from 'react-router-dom';

export default function SkillEdit(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({
    skill_id: '',
    skill_name: '',
    skill_objectives: '',
  });
  const fetchSkillById = async (skill_id) => {
    try {
      setData(await API.fetchSkillById(skill_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSkillById(params.skill_id);
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (data.skill_name !== '' && data.skill_objectives !== '') {
      await axios
        .post(`${site_ip}/setSkills`, data, jwtHeader)
        .then((response) => {
          console.log(response);
          if (response.data.Status === 200) {
            Swal.fire('Successfully Updated');
            navigate('/admin/skill-listing');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('Please fill all the fields');
    }
  };
  return (
    <Aux>
      <br />
      <Container fluid>
        <Row>
          <Col>
            <div align="right" className="mb-3">
              <Button
                onClick={() => navigate('/admin/skill-listing')}
              >
                Skills List
              </Button>
            </div>
            <Card>
              <Card.Header>
                <Card.Title as="h5" style={{color:"#ffffff"}}>Edit Skill</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Skill Name</Form.Label>
                        <Form.Control
                          name="skill_name"
                          value={data.skill_name}
                          type="text"
                          className="mb-3"
                          placeholder="Enter Skill Name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Skill Objectives</Form.Label>
                        <Form.Control
                          name="skill_objectives"
                          value={data.skill_objectives}
                          type="text"
                          className="mb-3"
                          placeholder="Enter Skill Objectives"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button onClick={handleSubmit} variant="primary">
                    Update Skill
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
