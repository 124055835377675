import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table, Container, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import '../master.css';
import Aux from '../../../hoc/_Aux';
import API from '../../API';
import {tableCustomStyles} from "../../../customTableStyle";
import DataTable from 'react-data-table-component';

export default function Skills(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);


  const [skills, setSkills] = useState([]);
  const fetchSkills = async () => {
    try {
      setSkills(await API.fetchSkills());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const editButton = (id) =>{
    navigate('/admin/skill-edit/'+id)
  }


  const handleClose = () => setShow(false);

  const removeRecord = async() => {
    let data = {
      skill_id: selectedRecord
    }
    handleClose();
      await axios
      .post(site_ip + '/deleteSkill',data,jwtHeader)
      .then(function (response) {
          Swal.fire({
              title: 'Success',
              text: 'Record removed successfully',
              icon: 'success',
          });
          fetchSkills();
      })
      .catch(function (error) {
          console.log('error' + error);
      });
 }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

   const columns = [
    {
      name: 'Skill',
      selector: row => row.skill_name,
    },
    {
      name: 'Objectives',
      selector: row => row.skill_objectives,
    },
    {
      name: 'Topic',
      selector: row => row.topic_name,
    },
    {
      name: 'Action',
      width: "120px",
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Button variant="success" className="rowActionButton" onClick={()=> editButton(row.skill_id)}>
            <EditIcon />
          </Button>
          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(row.skill_id)}>
            <DeleteForeverIcon />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  return (
    <Aux>
      <br />
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <h4>Skill List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/skill-add')}
            >
              Add Skill
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{height:"15px"}} />
            <DataTable
              customStyles={tableCustomStyles}
              columns={columns}
              data={skills}
              pagination
              dense
            />
          </Col>
        </Row>
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
