import React, {useEffect, useState} from 'react';
import Aux from "../../hoc/_Aux";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import MultiselectCheckbox from "react-multiselect-checkbox";
import axios from "axios";
import {Base64} from 'js-base64';
import '../../config'
import config from "../../config";

let Ids = [];
let ct_exam_id = '';
let queIdFromDb = [];
let quesCount = ''

const ExamsSetup = (props) => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };

    let emptySearch = {
        class_id: props.location.state.grade_id,
        subject_id: "",
        topic_id: "",
        question_id: "",
        skill_id: "",
        dificulty_level_id: "",
    }

    const [subjects, setSubjects] = useState([]);
    const [topics, setTopics] = useState([]);
    const [taxonomies, setTaxonomies] = useState([]);
    const [questions, setQuestions] = useState([]);
    let [item, setItem] = useState(emptySearch);
    const [questionCount, setQuestionCount] = useState('');


    // console.log(props.location.state.questionCount)
    const getSubjectByGrade = async () => {
        await axios.get(`https://nodeapi.uniqtoday.com/api/getSubjectByGrade/${props.location.state.grade_id}`, jwtHeader)
            .then((response) => {
                // handle success
                setSubjects(response.data.Subjects)
                setQuestionCount(props.location.state.questionCount)
                // console.log(response);
            }).catch((error) => {
                // handle error
                console.log(error);
            });
    }

    const getTaxonomy = async () => {
        await axios.get(`https://nodeapi.uniqtoday.com/api/getSkills`, jwtHeader)
            .then((response) => {
                setTaxonomies(response.data.Content)
            }).catch((error) => {
                console.log(error);
            });
    }

    const setExamsSchedule = async () => {

        await fetch('https://nodeapi.uniqtoday.com/api/setExamsSchedule', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem('admin_token')
            },
            body: JSON.stringify({
                exam_url: `http://localhost:3000${config.basename}/auth/common_exam_reg/${Base64.encode(props.location.state.getInsertedIds)}`,
                ct_exam_id: props.location.state.getInsertedIds
            }),
        }).then(response => response.json())
            .then(data => {
            }).catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        getSubjectByGrade();
        getTaxonomy();
        setExamsSchedule();
    }, [])

    const handleChange = (e) => {
        setItem({...item, [e.target.name]: e.target.value});
    };


    const handleChangeBySubject = async (e) => {
        e.preventDefault();
        await axios.post('https://nodeapi.uniqtoday.com/api/getTopicsbySubjId', {
            class_id: item.class_id,
            subject_id: e.target.value
        }, jwtHeader).then((response) => {
            // handle success
            setTopics(response.data.Topics)
        }).catch((error) => {
            // handle error
            console.log(error);
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(item); return
        await axios.post('https://nodeapi.uniqtoday.com/api/searchQuestions', {
            class_id: item.class_id,
            subject_id: item.subject_id,
            topic_id: item.topic_id,
            question_id: item.question_id,
            skill_id: item.skill_id,
            dificulty_level_id: item.dificulty_level_id
        }, jwtHeader).then((response) => {
            // handle success
            console.log(response.data);
            setQuestions(response.data.Questions)
        }).catch((error) => {
            // handle error
            console.log(error);
        });
    }


    ct_exam_id = props.location.state.getInsertedIds;
    let subData;
    let topicData;
    let questionData;
    quesCount = questionCount
    subData = subjects.map((res, index) => (
        <option value={res.subject_id}>{res.subject_name}</option>
    ))
    if (topics) {
        topicData = topics.map((res, index) => (
            <option value={res.id}>{res.topic_name}</option>
        ))
    }
    if (questions) {
        questionData = questions.map((res, index) => (
            <tr>
                <th scope="row"><Form.Group>
                    <input type="checkbox" className="select-checkbox" value={res.question_id} name="questions"/>
                </Form.Group></th>
                <td>{index + 1}</td>
                <td>{res.question_id}</td>
                <td>{res.question}</td>
            </tr>
        ))
    }

    return (
        <Aux>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Search Questions</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row>

                                    <Col md={2}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Subject </Form.Label>
                                            <Form.Control as="select" name="subject_id" className="mb-3"
                                                          onChange={e => {
                                                              handleChangeBySubject(e);
                                                              handleChange(e)
                                                          }}>
                                                <option>Select Subject</option>
                                                {subData}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Topic</Form.Label>
                                            <Form.Control as="select" name="topic_id"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}>
                                                <option>Select Topic</option>
                                                {topicData}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Question Taxonomy</Form.Label>
                                            <Form.Control as="select" className="mb-3" name="skill_id"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}>
                                                <option>Select Question Taxonomy</option>
                                                {taxonomies.map((res, index) => (
                                                    <option value={res.skill_id}>{res.skill_name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Difficulty Level</Form.Label>
                                            <Form.Control as="select" name="dificulty_level_id"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}>
                                                <option>Select Difficulty Level</option>
                                                <option value="1">Simple</option>
                                                <option value="2">Medium</option>
                                                <option value="3">Complex</option>
                                                <option value="4">Student Rank</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Question Id</Form.Label>
                                            <Form.Control type="text" className="mb-3" name="question_id"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} className="mt-4">
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Button type={'submit'} variant="primary">
                                                Search
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Total Question Count
                                                : {questionCount}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Selected Question Count : {queIdFromDb.length}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Questions Listing</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th scope="row">
                                        <MultiselectCheckbox
                                            checkboxes=".table .select-checkbox"
                                            sync=".table .row"
                                            onNotAllChecked={(selectedMap) => {
                                                Ids = selectedMap.orderedByI();
                                                // console.log("My Array", Ids);
                                                // console.log("Not all checked", state.questionsIds);
                                            }}
                                            onAllChecked={(selectedMap) => {
                                                Ids = selectedMap.orderedByI();
                                                // console.log("All checked", selectedMap.orderedByI());
                                            }}
                                            onAllUnchecked={(selectedMap) => {
                                                Ids = selectedMap.orderedByI();
                                                // console.log("All unchecked", selectedMap.orderedByI());
                                            }}
                                        />
                                    </th>
                                    <th>Sr. No</th>
                                    <th>Question Id</th>
                                    <th>Question</th>
                                </tr>
                                </thead>
                                <tbody>
                                {questionData}
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={handleQuestionSubmit}>
                                Create
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Aux>
    );
}


async function handleQuestionSubmit() {
    let QuesIds = [];
    let quesData = [];
    Ids.map((res, index) => {
        QuesIds.push(res.value)
    })
    // console.log(Ids);

    if (queIdFromDb.length > 0) {
        QuesIds.map((res, index) => {
            queIdFromDb.push(res)
        })
    } else {
        queIdFromDb = QuesIds
    }
    console.log(quesCount, queIdFromDb)
    if (queIdFromDb.length > quesCount) {
        alert('Selected question count greater than actual question count!')
        return false;
    } else {
        // let question = ["5788", "5840", "5857", "5859", "5725", "5788"];
        for (let i = 0; i < queIdFromDb.length; i++) {
            await axios.post('https://nodeapi.uniqtoday.com/api/getQuestionById', {question_id: queIdFromDb[i]}).then((response) => {
                // handle success

                quesData.push(
                    {
                        question_id: response.data.Questions[0].question_id,
                        exam_id: response.data.Questions[0].class_id,
                        subject_id: response.data.Questions[0].subject_id,
                        subject_name: response.data.Questions[0].subject_name,
                        topic_id: response.data.Questions[0].topic_id,
                        topic_name: response.data.Questions[0].topic_name,
                        question_type_id: response.data.Questions[0].template_type,
                        question: response.data.Questions[0].question,
                        question_options: response.data.Questions[0].question_options,
                        answers: response.data.Questions[0].answers,
                        difficulty_level_id: response.data.Questions[0].difficulty_level,
                        complexity_name: response.data.Questions[0].complexity_name,
                        skill_id: response.data.Questions[0].skill_id,
                        ct_exam_id: ct_exam_id
                    }
                )
            }).catch((error) => {
                // handle error
                console.log(error);
            });
        }

// console.log(quesData); return
        await fetch('https://nodeapi.uniqtoday.com/api/addExamQuestionSet', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(quesData),
        }).then(response => response.json())
            .then(data => {
                alert('Data inserted successfully!')
            }).catch(error => {
                console.log(error)
            });
        window.location.reload(false);
    }
}

export default ExamsSetup;