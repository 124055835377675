import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

import API from '../../API';
import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
export default function ConceptAdd(props) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    exam_id: '',
    subject_id: '',
    unit_id: '',
    topic_id: '',
    concept_name: '',
  });

  const [subjects, setSubjects] = useState([]);
  const fetchSubjects = async (id) => {
    try {
      setSubjects(await API.fetchSubjectByExamId(id));
    } catch (error) {
      console.log(error);
    }
  };

  const [exams, setExams] = useState([]);
  const fetchExams = async () => {
    try {
      setExams(await API.fetchExams());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const [topics, setTopics] = useState([]);
  const fetchSubjectTopics = async (subject_id) => {
    try {
      setTopics(await API.fetchSubjectTopics(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [units, setUnits] = useState([]);
  const fetchExamSubjectUnits = async (subject_id, exam_id) => {
    await axios
      .post(
        site_ip + '/getExamSubjectUnits',
        {
          exam_id: exam_id,
          subject_id: subject_id,
        },
        jwtHeader
      )
      .then((res) => setUnits(res.data.Data))
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (data.exam_id !== '' && data.subject_id !== '')
      fetchExamSubjectUnits(data.subject_id, data.exam_id);
  }, [data.exam_id, data.subject_id]);

  const handleChange = (e) => {
    if(e.target.name === "exam_id"){
      setData({ ...data, [e.target.name]: e.target.value, ['subject_id'] : "", ["topic_id"]:"", ["unit_id"]:""});  
      fetchSubjects(e.target.value)
    }else if(e.target.name === "subject_id"){
      setData({ ...data, [e.target.name]: e.target.value, ["topic_id"]:"", ["unit_id"]:""});  
      fetchSubjectTopics(e.target.value);
    }else{
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };


  const handleSubmit = async (e) => {
    if (
      data.exam_id !== '' ||
      data.subject_id !== '' ||
      data.unit_id !== '' ||
      data.concept_name !== ''
    ) {
      await axios
        .post(`${site_ip}/createExamSubjectConcepts`, data, jwtHeader)
        .then((response) => {
          if (response.data.Status === 200) {
            Swal.fire('Successfully Added');
            navigate('/admin/concept-listing');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('Please fill required fields');
    }
  };
  return (
    <Aux>
      <br />
      <Container fluid>
        <Row>
          <Col>
            <div align="right" className="mb-3">
              <Button
                onClick={() => navigate('/admin/concept-listing')}
              >
                Concepts List
              </Button>
            </div>
            <Card>
              <Card.Header>
                <Card.Title as="h5">Add Concept</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Exam <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          as="select"
                          name="exam_id"
                          className="mb-3"
                          value={data.exam_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Exam</option>
                          {exams?.map((val) => (
                            <option key={val.exam_id} value={val.exam_id}>
                              {val.exam_cd}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Subject <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          as="select"
                          name="subject_id"
                          className="mb-3"
                          value={data.subject_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Subject</option>
                          {subjects?.map((val) => (
                            <option key={val.subject_id} value={val.subject_id}>
                              {val.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                          as="select"
                          name="topic_id"
                          className="mb-3"
                          value={data.topic_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Topic</option>
                          {topics?.map((val) => (
                            <option key={val.topic_id} value={val.topic_id}>
                              {val.topic_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Unit <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          as="select"
                          name="unit_id"
                          className="mb-3"
                          value={data.unit_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Unit</option>
                          {units?.map((val) => (
                            <option key={val.unit_id} value={val.unit_id}>
                              {val.unit_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Concept <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="concept_name"
                          className="mb-3"
                          value={data.concept_name}
                          type="text"
                          placeholder="Enter Concept"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button onClick={handleSubmit} variant="primary">
                    Add Concept
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
