import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import DataTable from 'react-data-table-component';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';
import moment from 'moment';

export default function Prices(props) {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        book_format_id: "",
        book_id: props.bookId,
        eff_start_date: "",
        eff_end_date: "",
        created_by: localStorage.getItem('user_id')
    }

    const blankRegion = {
        region_id: "",
        currency_id: "",
        price: "",
    }

    const [prices, setPrices] = useState([blankRegion])
    const [confirmationModal, setConfirmationModal] = useState(0);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);
    const [list, setList] = useState(true);
    const handleChange = (e) => {
        let { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    const handlePriceChange = (name, value, index) => {
        let price_list = [...prices];
        let price = { ...price_list[index] };
        price[name] = value;
        price_list[index] = price;
        setPrices(price_list);
    }

    const addOneRow = () => {
        let lists = [...prices];
        lists.push(blankRegion);
        setPrices(lists);
    }

    const removeOneRow = (index) => {
        let lists = [...prices];
        lists.splice(index, 1);
        setPrices(lists);
    }

    useEffect(() => {
        if (props.bookId !== 0) {
            getBookPrices(props.bookId)
        }
        getContentType();
        getCurrencies();
        getRegions();
    }, [])

    const [currencies, setCurrencies] = useState([])
    const getCurrencies = () => {
        axios
            .get(site_ip + '/getCurrencies', jwtHeader)
            .then((res) => {
                setCurrencies(res.data.Contents);
            })
            .catch((err) => console.log(err));
    };

    const [regions, setRegions] = useState([])
    const getRegions = () => {
        axios
            .get(site_ip + '/getRegions', jwtHeader)
            .then((res) => {
                setRegions(res.data.Contents);
            })
            .catch((err) => console.log(err));
    };


    const [priceList, setPriceList] = useState([]);
    const getBookPrices = (id) => {
        axios
            .get(site_ip + '/getBookPrice/' + id, jwtHeader)
            .then((res) => {
                setPriceList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const [contentType, setContentType] = useState([]);
    const getContentType = () => {
        axios
            .get(site_ip + '/getContentType', jwtHeader)
            .then((res) => {
                setContentType(res.data.Contents);
            })
            .catch((err) => console.log(err));
    };

    const validPrices = () => {
        let status = true;
        let lists = [...prices];

        lists.map((item) => {
            if (item.region_id === "" || item.currency_id === "" || item.price === "") {
                status = false;
            }
        })
        return status;
    }

    const onSubmit = async () => {
        let req = { ...values };

        if (req.book_format_id === "") {
            setErrorMessage("Please select book format.");
            return;
        } else if (req.eff_start_date === "") {
            setErrorMessage("Please add effective start date.");
            return;
        } else if (req.eff_end_date === "") {
            setErrorMessage("Please add effective end date");
            return;
        } else if (!validPrices()) {
            setErrorMessage("Please add valid regions, currency & prices.");
            return;
        }

        // Make request
        if (req.book_price_id === undefined) {
            let data = [];
            let lists = [...prices];
            lists.map((item) => {
                let ele = {
                    region_id: item.region_id,
                    currency_id: item.currency_id,
                    price: item.price,
                    book_format_id: values.book_format_id,
                    book_id: values.book_id,
                    eff_start_date: values.eff_start_date,
                    eff_end_date: values.eff_end_date,
                }
                data.push(ele)
            })

            axios
                .post(site_ip + '/createBookPrice', data, jwtHeader)
                .then((res) => {
                    setSuccessMessage("Book Prices added successfully.")
                })
                .catch((err) => console.log(err));
        }else{
            let data = [];
            let lists = [...prices];
            lists.map((item) => {
                let ele = {
                    region_id: item.region_id,
                    currency_id: item.currency_id,
                    price: item.price,
                    book_price_id:values.book_price_id,
                    book_format_id: values.book_format_id,
                    book_id: values.book_id,
                    eff_start_date: values.eff_start_date,
                    eff_end_date: values.eff_end_date,
                }
                data.push(ele)
            })
            
            axios
                .post(site_ip + '/setBookPrice', data[0], jwtHeader)
                .then((res) => {
                    setSuccessMessage("Book Price details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        getBookPrices(props.bookId)
        setList(!list)
    }

    const columns = [
        {
            name: 'Content Type',
            selector: row => row.content_type_name,
        },
        {
            name: 'Effective Start Date',
            selector: row => moment(row.eff_start_date).format("DD-MMM-YYYY"),
        },
        {
            name: 'Effective End Date',
            selector: row => moment(row.eff_end_date).format("DD-MMM-YYYY"),
        },
        {
            name: 'Region',
            selector: row => row.region_name,
        },
        {
            name: 'Currency',
            selector: row => row.currency_name,
        },
        {
            name: 'Price',
            selector: row => row.price,
        },
        {
            name: 'Action',
            cell: (row) => (
                <div style={{ paddingTop: "5px", paddingBottom: "5px", display:"flex" }}>
                    <button style={{ padding: "5px" }} onClick={() => clickHandler(row)} className="primary-plus-button">
                        <EditIcon style={{ fontSize: "20px" }} />
                    </button>
                    <button style={{ padding: "5px", marginLeft: "5px", backgroundColor: "#ff4d4d" }} onClick={() => setConfirmationModal(row?.book_price_id)} className="primary-plus-button">
                        <DeleteOutlineIcon style={{ fontSize: "20px" }} />
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: false,
        },
    ];

    const deleteRecord = () => {
        let req = { book_price_id: confirmationModal };
        axios.post(site_ip + "/deleteBookPrice", req, jwtHeader)
            .then((res) => {
                getBookPrices(props.bookId)
                setConfirmationModal(0)
            }).catch((err) =>
                console.log(err)
            )
    }


    const clickHandler = (row) => {
        let ele = {
            book_price_id: row?.book_price_id,
            book_format_id: row?.book_format_id,
            book_id: row?.book_id,
            eff_start_date: row?.eff_start_date,
            eff_end_date: row?.eff_end_date,
            created_by: localStorage.getItem('user_id')
        }
        setValues(ele);

        let ele1 = {
            region_id: row?.region_id,
            currency_id: row?.currency_id,
            price: row?.price,
        }
        setPrices([ele1])
        setList(!list)
    }


    return (
        <Aux>
            {!list ?
                <div className='new-content-section'>
                    <Row className="underline">
                        <Col className="page-title-container">
                            <p>Add Price</p>
                        </Col>
                        <Col className="page-title-right">
                            <button onClick={() => setList(!list)} className="secondary-button">Show List</button>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "8px" }}>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            Book Format <span style={{ color: "#ff0000" }}>*</span>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.book_format_id}
                                                as="select"
                                                name="book_format_id"
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Content Type</option>
                                                {contentType.map((item) => {
                                                    return (
                                                        <option value={item.content_type_id} key={item.content_type_id}>{item.content_type_name}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Effective Start Date: <span style={{ color: "#ff0000" }}>*</span>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="date"
                                                value={values?.eff_start_date}
                                                name="eff_start_date"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Effective End Date: <span style={{ color: "#ff0000" }}>*</span>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="date"
                                                value={values?.eff_end_date}
                                                min={values?.eff_start_date !== "" ? values?.eff_start_date : null}
                                                name="eff_end_date"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div style={{ paddingTop: "8px" }}>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th>Region</th>
                                    <th>Currency</th>
                                    <th>Price</th>
                                    <th></th>
                                </tr>
                                {prices.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ width: "25%", paddingTop: "5px" }}>
                                                <Form.Control
                                                    value={item?.region_id}
                                                    as="select"
                                                    name="region_id"
                                                    onChange={(e) => handlePriceChange("region_id", e.target.value, index)}
                                                >
                                                    <option value="">Select Region</option>
                                                    {regions.map((item) => {
                                                        return (
                                                            <option value={item.region_id} key={item.region_id}>{item.region_name}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <Form.Control
                                                    value={item?.currency_id}
                                                    as="select"
                                                    name="currency_id"
                                                    onChange={(e) => handlePriceChange("currency_id", e.target.value, index)}
                                                >
                                                    <option value="">Select Currency</option>
                                                    {currencies.map((item) => {
                                                        return (
                                                            <option value={item.currency_id} key={item.currency_id}>{item.currency_name}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <Form.Control
                                                    type="number"
                                                    value={item?.price}
                                                    name="price"
                                                    min={0}
                                                    onChange={(e) => handlePriceChange("price", e.target.value, index)}
                                                />
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                {index > 0 ?
                                                    <button onClick={() => removeOneRow(index)} className="secondary-button">Remove</button> : null}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {values.book_price_id === undefined ?
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: "100%" }}>
                                <button onClick={() => addOneRow()} className="primary-plus-button">Add More</button>
                            </div> : null}
                    </div>
                    <div style={{ display: "flex" }}>
                        <button onClick={() => onSubmit()} className="primary-plus-button">
                            {values.book_price_id === undefined ? "Save All" : "Update Record"} 
                        </button>
                    </div>
                    <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                    <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
                </div> :
                <div className='new-content-section'>
                    <Row className="underline">
                        <Col className="page-title-container">
                            <p>Book Price</p>
                        </Col>
                        <Col className="page-title-right">
                            <button onClick={() => { setValues(blankValues); setList(!list) }} className="secondary-button">Add Price</button>
                        </Col>
                    </Row>
                    <DataTable
                        className="table table-bordered"
                        columns={columns}
                        data={priceList}
                        pagination
                        dense
                    />
                </div>}
                <Modal centered show={confirmationModal === 0 ? false : true} onHide={() => setConfirmationModal(0)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure to remove this record?</Modal.Body>
                    <Modal.Footer>
                        <button
                            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="secondary" onClick={() => setConfirmationModal(0)}>
                            Close
                        </button>
                        <button
                            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>

        </Aux>
    );
}
