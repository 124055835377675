import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ExamQuestionMeta from "./ExamQuestionMeta";
import ExamQuestionText from "./ExamQuestionText";

import axios from 'axios';
import { site_ip } from '../../../globalSetting';

export default function AddQuestion(props) {
    const params = useParams();
    const [activeTab, setActiveTab] = useState(1)
    const [questionId, setQuestionId] = useState(0);

    const [input, setInputs] = useState({
        question_para_text: "",
        question_images: "",
        subject_id:"",
        difficulty_level_id:"",
        book_id:"",
        chapter_id:"",
        course_id:"",
    });

    const handleChange = (name, value) => {
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {
        if (params.question_id !== undefined) {
            setQuestionId(params.question_id);
            fetchQuestionById(params.question_id)
        }
    }, [])

    const fetchQuestionById = async (question_id) => {

        const jwtHeader = {
            headers: { 'access-token': localStorage.getItem('admin_token') },
          };
          await axios
            .get(`${site_ip}/getQuestionParaById/`+question_id, jwtHeader)
            .then((res) => {
                console.log(res.data.Data)
                setInputs(res.data.Data);      
            })
            .catch((err) => console.log(err));
      };

    return (
        <div>
            <Container fluid>
                <div>
                    <Row style={{ margin: 0 }}>
                        <Col>
                            <Button onClick={() => setActiveTab(1)} className={activeTab === 1 ? "instituionTabSection active" : "instituionTabSection"}>Question Meta</Button>
                            <Button onClick={() => setActiveTab(2)} className={activeTab === 2 ? "instituionTabSection active" : "instituionTabSection"}>Question</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {activeTab === 1 ? <ExamQuestionMeta id={questionId} handleChange={handleChange} input={input} changeStep={(val) => setActiveTab(val)} /> : null}
                            {activeTab === 2 ? <ExamQuestionText id={questionId} handleChange={handleChange} input={input} changeStep={(val) => setActiveTab(val)} /> : null}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
