import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Form, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import "../commoncss.css"
import API from '../../API';
const AssessmentSection = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const [subjectList, setSubjectList] = useState([]);
    const [activeSubject, setActiveSubject] = useState(null);
    const [searchedString, setSearchString] = useState("");
    const [queryString, setQueryString] = useState("");
    const [bookList, setBookList] = useState([])
    useEffect(() => {
        getSubjects();

    }, [])

    const blankValues = {
        course_id: '',
        subject_id: '',
        topic_id: '',
        difficulty_level_id: '',
        question_id: '',
        question: '',
        question_count: 4
    }

    const [values, setValues] = useState(blankValues);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === 'subject_id') fetchTopicData(value);
    };

    const getSubjects = () => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectList(res.data.content);
                let path_arr = location.pathname?.split("/")
                if(path_arr[1] === "section"){
                    let section_id = params?.section;
                    let option = res.data.content.filter((item) => parseInt(item?.subject_id) === parseInt(section_id))
                    if(option.length > 0){
                        setActiveSubject(option[0])
                    }else{
                        setActiveSubject(res.data.content[0])    
                    }
                }else{
                    setActiveSubject(res.data.content[0])
                }
                
            })
            .catch((err) => console.log(err));
    };


    const selectSection = (id) => {

    }

    const [subjects, setSubjects] = useState([]);
    const fetchSubjects = async () => {
        try {
            setSubjects(await API.fetchSubjects());
        } catch (error) {
            console.log(error);
        }
    };

    const [courses, setCourses] = useState([]);
    const fetchCourses = async () => {
        try {
            setCourses(await API.fetchCourses());
        } catch (error) {
            console.log(error);
        }
    };
    const [topics, setTopics] = useState(null);
    const fetchTopicData = async (subject_id) => {
        try {
            setTopics(await API.fetchSubjectTopics(subject_id));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSubjects();
        fetchCourses();
    }, [])

    const getQuestions = (req) => {
        req = {...req, subject_id: activeSubject?.subject_id}
        axios
            .post(site_ip + '/searchQuestionBank', req, jwtHeader)
            .then((res) => {
                console.log(res.data.Questions)   
                navigate('/user/assessment/instruction', {
                    state: { data: res.data.Questions, test_type: 'Custom' },
                });
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <div style={{ padding: "15px 0px" }}>
                <Row style={{ margin: "0px" }}>
                    <Col md={1}></Col>
                    <Col md={3}>
                        <div className="section-box">
                            <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000", padding: "15px", marginBottom: "0px" }}>SECTION</p>
                            {subjectList.map((item, index) => {
                                return (
                                    <button key={index} className={activeSubject?.subject_id === item?.subject_id ? "section-item active" : "section-item"} onClick={() => { selectSection(item.subject_id); setActiveSubject(item) }} >
                                        <p style={{ padding: "15px", marginBottom: "0px" }}>{item.subject_name}</p>
                                    </button>
                                )
                            })}
                        </div>
                    </Col>
                    <Col md={7}>
                        <Row style={{ margin: 0, marginBottom: "15px" }}>
                            {/* <Col style={{ display: "flex", alignItems: "center" }} xs={12}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Assessment</p>
                            </Col> */}
                            <Col xs={12}>
                                <Row className="assessment-setup-box" style={{marginTop:"0px"}}>
                                    <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Assessment</p>
                                    <Col md={12}>
                                        <p>Section</p>
                                        <span style={{marginTop:"0px!important", fontSize:"15px"}}>{activeSubject?.subject_name}</span>
                                        {/* <Form.Control
                                            as="select"
                                            name="subject_id"
                                            onChange={handleChange}
                                            value={values.subject_id}
                                        >
                                            <option value="">Select Subject</option>
                                            {subjects?.map((item, key) => (
                                                <option key={key} value={item.subject_id}>
                                                    {item.subject_name}
                                                </option>
                                            ))}
                                        </Form.Control> */}
                                    </Col>
                                    <Col md={12}>
                                        <p>Nos. of Questions</p>
                                        <Form.Control
                                            as="select"
                                            name="question_count"
                                            onChange={handleChange}
                                            value={values.question_count}
                                        >
                                            <option value={4}>4</option>
                                            {/* <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option> */}
                                        </Form.Control>
                                    </Col>
                                    {/* <Col md={12}>
                                        <p>Select Course</p>
                                        <Form.Control
                                            as="select"
                                            name="course_id"
                                            onChange={handleChange}
                                            value={values.course_id}
                                        >
                                            <option value="">Select Course</option>
                                            {courses?.map((item, key) => (
                                                <option key={key} value={item.course_id}>
                                                    {item.course_title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col> */}

                                    {/* <Col md={12}>
                                        <p>Select Topic</p>
                                        <Form.Control
                                            as="select"
                                            name="topic_id"
                                            onChange={handleChange}
                                            value={values.topic_id}
                                        >
                                            <option value="">Select Topic</option>
                                            {topics?.map((item, key) => (
                                                <option key={key} value={item.topic_id}>
                                                    {item.topic_name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col> */}
                                    <Col md={12} style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
                                        <button onClick={() => getQuestions(values)} className="primary-plus-button" style={{ padding: "10px 15px" }}>Start</button>
                                        <div className='spacer' />
                                        <button onClick={() => { getQuestions(blankValues); setValues(blankValues) }} style={{ padding: "10px 15px" }} className="secondary-button">Reset</button>
                                    </Col>
                                </Row>
                            </Col>
                            {/* <AssessmentSetup /> */}
                            {/* <Col>
                                <div className="search-round-div">
                                    <input placeholder='Search videos...' value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                                    <button style={{cursor:"pointer"}} onClick={() => searchMedicalCases(null)}>Search</button>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </div>
        </div>
    )
}

export default AssessmentSection;

