import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
const News = () => {
    const params = useParams();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const [subjectList, setSubjectList] = useState([]);
    const [activeSubject, setActiveSubject] = useState(null);
    const [searchedString, setSearchString] = useState("");
    const [queryString, setQueryString] = useState("");
    const [bookList, setBookList] = useState([])
    useEffect(() => {
        getSubjects();
        getBooks();
    }, [])

    const getSubjects = () => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectList(res.data.content);
            })
            .catch((err) => console.log(err));
    };

    const getBooks = () => {
        axios
            .post(site_ip + '/getBookSearch', {}, jwtHeader)
            .then((res) => {
                setBookList(res.data.Books);
            })
            .catch((err) => console.log(err));
    };

    const searchBooks = (subject_id) => {
        let req;
        if (subject_id !== null) {
            setSearchString("");
            req = {
                subject_id: subject_id
            }
        } else {
            setActiveSubject(null)
            if (queryString.trim() === "") {
                return
            }
            req = {
                book_title: queryString.trim()
            }
            setSearchString(queryString.trim())
        }
        axios
            .post(site_ip + '/getBookSearch', req, jwtHeader)
            .then((res) => {
                setBookList(res.data.Books);
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Container>
                <div style={{ padding: "15px 0px" }}>
                    <Row>
                        <Col md={3}>
                            <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Subjects</p>
                            {subjectList.map((item, index) => {
                                return (
                                    <button key={index} className="news-subject-item" onClick={() => { searchBooks(item.subject_id); setActiveSubject(item) }} >{item.subject_name}</button>
                                )
                            })}
                        </Col>
                        <Col md={9}>
                            
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default News;

const BookThumbnail = (props) => {
    
    const item = props.item;
    const navigate = useNavigate();
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        getAuthorsByBookId(item?.book_id)
    }, [])

    const getAuthorsByBookId = (id) => {
        axios
            .get(site_ip + '/getBookAuthorByBook/' + id, props.jwtHeader)
            .then((res) => {
                let authors_list = res.data.Content;
                authors_list.sort((a, b) => b.author_disp_seq - a.author_disp_seq);
                let authors = [];
                authors_list.map((item) => {
                    let auth = item?.salutation + " " + item?.first_name + " " + item?.last_name
                    authors.push(auth)
                })
                setAuthors(authors);
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className='subject-thumbnail-div'>
            <Row>
                <Col md={4}>
                    <a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/books/" + item.book_id) }}>
                        <div className='subject-thumbnail-img-div'>
                            <img src={item.book_logo} />
                        </div>
                    </a>
                </Col>
                <Col md={8}>
                    <p className="suject-thumbnail-category">{item.subject_name}</p>
                    <p className="suject-thumbnail-name"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/books/" + item.book_id) }}>{item.book_title}</a></p>
                    <p className="book-preface-text">{authors.join(", ")}</p>
                    <div style={{ height: "20px" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                            {<p className="suject-thumbnail-category">Nos. of Chapters: <b>{item.no_of_chapters}</b></p>}
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            {<p className="suject-thumbnail-category">{item.publisher_name} | {item.publishing_year}</p>}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}