import React, { useState } from 'react';
import '../../admin.css';

import { useParams, useNavigate } from 'react-router-dom';
import CreateChapter  from './Components/CreateChapter';
import Authors from './Components/Authors';
import FileContent from './Components/FileContent';

const JournalAdd = () => {
    const params = useParams();
    const navigate = useNavigate();
    const chapterId = params.id === undefined ? 0 : params.id;
    const [activeTab, setActiveTab] = useState(1);

    const openInEditMode = (id) =>{
        navigate('/admin/book/chapter/edit/' + id)
    }

    return (
        <>
            <div className="tab-div">
                <button onClick={() => setActiveTab(1)} className={activeTab === 1 ? "tab-div-btn active" : "tab-div-btn"}>{chapterId === 0 ? "Add Chapter" : "Edit Chapter"}</button>
                {chapterId !== 0 ? <button onClick={() => setActiveTab(2)} className={activeTab === 2 ? "tab-div-btn active" : "tab-div-btn"}>Author Info</button> : null }
                {chapterId !== 0 ? <button onClick={() => setActiveTab(3)} className={activeTab === 3 ? "tab-div-btn active" : "tab-div-btn"}>Chapter Content Upload</button> : null }
            </div>
            <div>
                {activeTab === 1 ? <CreateChapter chapterId={chapterId} moveToNextStep={(val) => openInEditMode(val)}  /> : null }
                {activeTab === 2 ? <Authors chapterId={chapterId} /> : null }
                {activeTab === 3 ? <FileContent chapterId={chapterId} /> : null }
            </div>
        </>
    )
}

export default JournalAdd;