import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import "../commoncss.css"
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;

const SubmitDigitalAtlas = () => {
  const params = useParams();
  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  const [subjectList, setSubjectList] = useState([]);
  const [disclaimer, setDisclaimer] = useState(false);
  
  const [legend, setLegend] = useState("");
  const [files, setFiles] = useState([null])
  const [selectedSections, setSelectedSections] = useState([])

  const manageSection = (section) => {
    let selected_sections = [...selectedSections];
    let index = selected_sections.indexOf(section);
    if (index !== -1) {
      selected_sections.splice(index, 1)

    } else {
      selected_sections.push(section)
    }
    setSelectedSections(selected_sections)
  }

  const addRemoveFiles = (type, index) => {
    let file_arr = [...files];
    if (type === 'add') {
      file_arr.push(null)
    } else {
      file_arr.splice(index, 1)
    }
    setFiles(file_arr)
  }

  const handleFileChange = (file, index) => {
    let file_arr = [...files];
    file_arr[index] = file;
    setFiles(file_arr);
  }

  const blankValues = {
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    author_phone: "",
    country_id: "",
    state_id: "",
    city_id: "",
    email: "",
    url: "",
    orcid: "",
    role_id: "",
    affiliations: "",
    display_status: "",
    profile_details: "",
    profile_pic: "",
    created_by: "",
    inst_hospital_clinic_name:"",
    designation:"",
    qualification:""
  }
  const [values, setValues] = useState(blankValues)

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "country_id") {
      setValues({ ...values, [name]: value, state_id: "", city_id: "" })
      getStateList(value)
    } else if (name === "state_id") {
      setValues({ ...values, [name]: value, city_id: "" })
      getCities(value)
    } else {
      setValues({ ...values, [name]: value })
    }
  }



  useEffect(() => {
    getSubjects();
    getCountryList();
    getDesignationList();
    getQualificationList();
  }, [])

  const [qualificationList, setQualificationList] = useState([]);
  const getQualificationList = () => {
    axios
      .get(site_ip + '/getQualifications', jwtHeader)
      .then((res) => {
        setQualificationList(res.data.Contents);
      })
      .catch((err) => console.log(err));
  };

  const [designationList, setDesignationList] = useState([]);
  const getDesignationList = () => {
    axios
      .get(site_ip + '/getDesignations', jwtHeader)
      .then((res) => {
        setDesignationList(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const [countryList, setCountryList] = useState([]);
  const getCountryList = () => {
    axios
      .get(site_ip + '/getCountries', jwtHeader)
      .then((res) => {
        setCountryList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const [stateList, setStateList] = useState([]);
  const getStateList = (id) => {
    axios
      .get(site_ip + '/getStateByCountry/' + id, jwtHeader)
      .then((res) => {
        setStateList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const [cityList, setCityList] = useState([]);
  const getCities = (id) => {
    axios
      .get(site_ip + '/getCities/' + id, jwtHeader)
      .then((res) => {
        setCityList(res.data.States);
      })
      .catch((err) => console.log(err));
  };

  const getSubjects = () => {
    axios
      .get(site_ip + '/getSubjects', jwtHeader)
      .then((res) => {
        console.log(res);
        setSubjectList(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async() => {

    // Look for images
    let total_images = [];
    files?.map((item)=>{
      if(item !== null){
        total_images?.push(item)
      }
    })

    if(values?.first_name?.trim() === ""){
      alert("Please enter your first name.")
      return
    }else if(values?.last_name?.trim() === ""){
      alert("Please enter your last name")
      return
    }else if(values?.inst_hospital_clinic_name?.trim() === ""){
      alert("Please enter your Insitute/Clinic name.")
      return
    }else if(values?.qualification === ""){
      alert("Please select your qualification.")
      return
    }else if(values?.designation === ""){
      alert("Please select your designation.")
      return
    }else if(values?.country_id === ""){
      alert("Please select a country.")
      return
    }else if(values?.state_id === ""){
      alert("Please select a state.")
      return
    }else if(values?.city_id === ""){
      alert("Please select a city.")
      return
    }else if (!validateEmail(values.email.trim())) {
      alert('Please enter valid email address');
      return
    } else if (!phonenumber(values.author_phone)) {
      alert('Please enter valid mobile number');
      return
    }else if (!phonenumber(values.author_phone)) {
      alert('Please enter valid mobile number');
      return
    }else if (total_images?.length === 0) {
      alert('Please add atleast one image.');
      return
    }else if (legend?.trim() === "") {
      alert('Please add image legend.');
      return
    }else if (selectedSections?.length === 0) {
      alert('Please select atleast one section for images.');
      return
    }

    await axios
      .post(
        `${site_ip}/createAuthor`,
        values,
        jwtHeader
      )
      .then((res) => {
        console.log(res)
        if (res.data.Status === 200) {
          uploadImages(res.data?.Content?.author_id, total_images)
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadImages = async(author_id, selected_image) => {

    let images = [];
    if(selected_image.length >= 1){
      let url = await fileUpload(selected_image[0], author_id)
      let ele = {
        image_name: url,
        image_desc: legend?.trim()
      }
      images.push(ele)
    }

    if(selected_image.length >= 2){
      let url = await fileUpload(selected_image[1], author_id)
      let ele = {
        image_name: url,
        image_desc: legend?.trim()
      }
      images.push(ele)
    }

    if(selected_image.length >= 3){
      let url = await fileUpload(selected_image[2], author_id)
      let ele = {
        image_name: url,
        image_desc: legend?.trim()
      }
      images.push(ele)
    }

    if(selected_image.length >= 4){
      let url = await fileUpload(selected_image[3], author_id)
      let ele = {
        image_name: url,
        image_desc: legend?.trim()
      }
      images.push(ele)
    }

    if(selected_image.length >= 5){
      let url = await fileUpload(selected_image[4], author_id)
      let ele = {
        image_name: url,
        image_desc: legend?.trim()
      }
      images.push(ele)
    }

    let values = {
      author_id: author_id,
      display_status: 0,
      images:images,
      subjects: selectedSections
    }
    
    await axios
      .post(
        `${site_ip}/createDigitalAtlas`,
        values,
        jwtHeader
      )
      .then((res) => {
        if (res.data.Status === 200) {
          alert("Congratulations! your form successfully submitted.")
          navigate("/product/3/digital_atlas")
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div style={{ padding: "15px 0px" }}>
        <Row style={{ margin: "0px" }}>
          <Col md={1}></Col>
          <Col md={10}>
            <p style={{ fontWeight: "bold", fontSize: "24px", marginBottom: "0px", textAlign: 'center' }}>DIGITAL ATLAS</p>
            <p style={{ fontWeight: "bold", fontSize: "24px", textAlign: 'center' }}>SUBMISSION PORTAL</p>
            <div style={{ backgroundColor: "#f5f5f5", padding: "15px", border: "1px solid #ccc" }}>
              <p style={{ fontSize: "14px" }}>Kindly provide high-resolution images (more than 300 dpi) along with 150–200 words legend describing the image and pertinent details of the presented case.</p>
              <p style={{ fontSize: "14px" }}>Upload option (Max 5 at a time).</p>
              {files?.map((item, index) => {
                return (
                  <Row key={index} style={{ marginBottom: "15px" }}>
                    <Col>
                      <Form.Control
                        type="file"
                        accept='image/*'
                        onChange={(e) => handleFileChange(e.target.files[0], index)}
                      />
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {files?.length < 5 && ((files?.length - 1) === index) ?
                        <>
                          <button onClick={() => addRemoveFiles("add")} className="primary-plus-button" style={{ padding: "10px 15px" }}>Add More</button>
                          <div className='spacer' />
                        </> : null}
                      {files?.length > 1 ?
                        <button onClick={() => { addRemoveFiles("remove", index) }} className="secondary-button" style={{ padding: "10px 15px" }}>Remove</button> : null}
                    </Col>
                  </Row>
                )
              })}
              <Row>
                <p style={{ fontSize: "14px" }}>Legend </p>
                <Col>
                  <Form.Control
                    as="textarea"
                    style={{ height: "200px" }}
                    value={legend}
                    onChange={(e) => setLegend(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ fontSize: "11px", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                  ({400 - legend?.length} remaining)
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: "14px" }}>Kindly select one or more of the following sections for tagging the image:</p>
                </Col>
              </Row>
              <Row>
                {subjectList?.map((item, index) => {
                  return (
                    <Col md={4} key={item?.subject_id}>
                      <Form.Check
                        type={"checkbox"}
                        onChange={() => null}
                        onClick={() => manageSection(item?.subject_id)}
                        label={item?.subject_name}
                      />
                    </Col>
                  )
                })}

              </Row>
            </div>
            <div style={{ backgroundColor: "#f5f5f5", padding: "15px", border: "1px solid #ccc", marginTop: "15px" }}>
              <p style={{ fontWeight: "bold" }}>Your Details</p>
              <Row>
                <Col md={12}>
                  {/* {formErrors.length > 0 && (<div className="alert alert-danger">
                    {formErrors.map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                  </div>
                  )} */}
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3" className="text-end">
                      Author Name
                    </Form.Label>
                    <Col sm="9">
                      <Row>
                        <Col>
                          <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={values?.firstName}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="text"
                            name="last_name"                            
                            placeholder="Last Name"
                            value={values?.last_name}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3" className="text-end">
                      Author Credentials
                    </Form.Label>
                    <Col sm="9">
                    <Col className='mb-3'>
                        <Form.Control
                          type="text"
                          name="inst_hospital_clinic_name"
                          placeholder="Institute"
                          value={values?.inst_hospital_clinic_name}
                          onChange={handleChange}
                        />
                      </Col>  
                      <Col className='mb-3'>
                        <Form.Control
                          value={values?.qualification}
                          as="select"
                          name="qualification"
                          onChange={handleChange}
                        >
                          <option value="">--Select Qualification--</option>
                          {qualificationList.map((value, index) => (
                            <option value={value.qualification_id} key={index}>
                              {value.qualification_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Control
                          value={values?.designation}
                          as="select"
                          name="designation"
                          onChange={handleChange}
                        >
                          <option value="">--Select Designation--</option>
                          {designationList.map((value, index) => (
                            <option value={value.designation_id} key={index}>
                              {value.designation}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Control
                          value={values?.country_id}
                          as="select"
                          name="country_id"
                          onChange={handleChange}
                        >
                          <option value="">--Select Country--</option>
                          {countryList.map((value, index) => (
                            <option value={value.country_id} key={index}>
                              {value.country_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Control
                          value={values?.state_id}
                          as="select"
                          name="state_id"
                          onChange={handleChange}
                        >
                          <option value="">--Select State--</option>
                          {stateList.map((value, index) => (
                            <option value={value.state_id} key={index}>
                              {value.state_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Control
                          value={values?.city_id}
                          as="select"
                          name="city_id"
                          onChange={handleChange}
                        >
                          <option value="">--Select City--</option>
                          {cityList.map((value, index) => (
                            <option value={value.city_id} key={index}>
                              {value.city_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3" className="text-end">
                      Contact Information
                    </Form.Label>
                    <Col sm="9">
                      <Row>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="Email ID"
                            value={values?.email}
                            name="email"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="Contact Number"
                            value={values?.author_phone}
                            name="author_phone"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3" className="text-end">
                      Disclaimer
                    </Form.Label>
                    <Col sm="9">
                      <Row style={{ marginTop: "8px", marginLeft: "5px" }}>
                        <Form.Check
                          type='checkbox'
                          label="Agree"
                          checked={disclaimer}
                          onChange={(e) => setDisclaimer(!disclaimer)}
                        />
                      </Row>
                      <Row>
                        <Form.Label>
                          Copyright and Disclaimer
                          : This is to certify that the shared image(s) is not taken from any journal/s, online websites, image stores, books or any other resource. I assure that all the images provided are from my own repertoire of images, and wherever appropriate consent/permission has been obtained from the subjects to use the image making all efforts to conceal their identities.
                          I, hereby transfer, assign, or otherwise convey all copyright ownership and IP rights, for these images to Knowledge Bridge Pvt. Ltd. (Content and Technology Partner of IADVL Karnataka initiative Dermatology Knowledge Network).
                        </Form.Label>
                      </Row>
                      <Button disabled={!disclaimer} className='mb-3' variant="primary" type="button" onClick={() => handleSubmit()} >Submit</Button>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={1}></Col>
        </Row>
      </div>
    </div>
  )
}

export default SubmitDigitalAtlas;


const fileUpload = async (attachment, author) => {
    const s3_url =
        'https://derma-digital-atlas.s3.ap-south-1.amazonaws.com/' + author + '/' + 
        attachment.name;
    const target = { Bucket: 'derma-digital-atlas', Key: author +"/"+ attachment.name, Body: attachment };
    const creds = {
        accessKeyId: 'AKIATT4SF2SQDEUQB7J7',
        secretAccessKey: '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',
    };

    try {
        const parallelUploads3 = new Upload({
            client: new S3Client({ region: 'ap-south-1', credentials: creds }),
            leavePartsOnError: false, // optional manually handle dropped parts
            params: target,
        });

        parallelUploads3.on('httpUploadProgress', (progress) => {
            console.log(progress,'rahul');
        });

        await parallelUploads3.done();
        return s3_url;

    } catch (e) {
        console.log(e);
        return "image not found."
    }

    // 

}

