import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
export default function AuthorList(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'All']
  const [activeAlpha, setActiveAlpha] = useState("All")
  const [show, setShow] = useState(false);
  
  const blankValues = {
    first_name: "",
    email:"",
    country_id: ""
  }
  const [values, setValues] = useState(blankValues);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    getCountryList();
  }, [])

  useEffect(() => {
    let new_filter = { ...values, first_name: activeAlpha === "All" ? "" : activeAlpha }
    getAuthors(new_filter)
  }, [activeAlpha])

  const [authorList, setAuthorList] = useState([]);
  const getAuthors = (req) => {
    let new_filter = { ...req, first_name: activeAlpha === "All" ? req?.first_name : activeAlpha }
    axios
      .post(site_ip + '/searchAuthor', new_filter, jwtHeader)
      .then((res) => {
        setAuthorList(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const [countryList, setCountryList] = useState([]);
  const getCountryList = () => {
    axios
      .get(site_ip + '/getCountries', jwtHeader)
      .then((res) => {
        setCountryList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }

  const removeRecord = () => {

  }

  const columns = [
    {
      name: 'First Name',
      selector: row => row.first_name,
    },
    {
      name: 'Last Name',
      selector: row => row.last_name,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Phone',
      selector: row => row.author_phone,
    },
    {
      name: 'Country',
      selector: row => row.country_name,
    },
    {
      name: 'Action',
      width:"100px",
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.author_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/edit-author/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Author List</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/add-author')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add Author</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col className="search-row">
            <Form.Control
              type="text"
              className="search-form-element"
              name="first_name"
              placeholder='First Name'
              value={values.first_name}
              onChange={handleChange}
            ></Form.Control>
            <Form.Control
              type="text"
              className="search-form-element"
              name="email"
              placeholder='Email'
              value={values.email}
              onChange={handleChange}
            ></Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="country_id"
              onChange={handleChange}
              value={values.country_id}
            >
              <option>Select Country</option>
              {countryList.map((item, index) => {
                return (
                  <option key={index} value={item.country_id}>{item.country_name}</option>
                )
              })}
            </Form.Control>
            <button onClick={() => getAuthors(values)} className="primary-plus-button">Search</button>
            <div className='spacer' />
            <button onClick={() => { getAuthors(blankValues); setValues(blankValues) }} className="secondary-button">Reset</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col>
            <div className="alphabet-filter">
              {alphabets.map((item, index) => {
                return (
                  <button key={index} onClick={() => setActiveAlpha(item)} className={item === activeAlpha ? "alpha-filter active" : "alpha-filter"}>{item}</button>
                )
              })}
            </div>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={authorList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}