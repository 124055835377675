import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Container } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../../hoc/_Aux';
import API from '../../API';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
import custom_config from '../../../ckeditor.config';
export default function QuestionAdd(props) {
  const navigate = useNavigate();
  const input = props.input;
  const [editorQuestionState, setEditorQuestionState] = useState("");
  useEffect(() => {
    if(input.questions_para_id !== undefined){
      setEditorQuestionState(input.question_para_text)
    }  
  }, [input]);
  
  
  const handleSubmit = async () => {
    
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    let req = {...input};
    if(props.id === 0){
    await axios
        .post(
          site_ip + '/createQuestionPara',
          {...req, question_para_text: editorQuestionState},
          jwtHeader
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New question para added successfully.',
              icon: 'success',
            });
            navigate('/admin/osce-question-listing');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }else{
      await axios
        .post(
          site_ip + '/setQuestionPara',
          {...req, question_para_text: editorQuestionState},
          jwtHeader
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Question para details updated successfully.',
              icon: 'success',
            });
            navigate('/admin/osce-question-listing');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };


  return (
    <Aux>
      <Row style={{margin:0}}>
        <Col>
          <Card>
            <Card.Body>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>Question Para</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                          config={custom_config}
                          editor={Editor}
                          data={editorQuestionState}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorQuestionState(data)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSubmit}>
                  {props.id === 0 ? "Submit Question" : "Update Question"}
                </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}
