const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight:"bold",
        color:"#ffffff",
        paddingLeft: '0 8px',
        // justifyContent: 'center',
        paddingLeft:"15px",
        backgroundColor: '#22b1e8',
        border:0,
        minHeight:"40px"
      },
    },
  }
  export { tableCustomStyles };