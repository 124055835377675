import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import Aux from '../../../hoc/_Aux';
export default function QuestionAdd(props) {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };
    const input = props.input;    
    const [questionType, setQuestionType] = useState([]);
    const fetchQuestionTypeData = async () => {
        await axios.get(`${site_ip}/getQuestionType`, jwtHeader).then((res) => {
            // console.log(res);
            setQuestionType(res.data.content);
        });
    };
    useEffect(() => {
        fetchQuestionTypeData();
    }, []);

    const handleSubmit = () =>{
        props.changeStep(2)
    }


    return (
        <Aux>
            <Row style={{ margin: 0 }}>
                <Col>
                    <Card>
                        <Card.Body>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Question Type</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="question_type_id"
                                                value={input.question_type_id}
                                                onChange={(e) => props.handleChange("question_type_id", e.target.value)}
                                            >
                                                <option value="">Select Question Type</option>
                                                {questionType?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.question_type}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Button variant="primary" onClick={handleSubmit} disabled={input?.question_type_id !== "" ? false : true} >
                                            Next
                                        </Button>             
                                    </Col>
                                </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Aux>
    );
}
