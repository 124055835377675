import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';

import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';

export default function Sections(props) {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        book_id: props.bookId,
        volume_id:"",
        section_no: "",
        section_name: "",
        no_of_chapters: "",
        no_of_pages: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [sectionList, setSectionList] = useState([blankValues]);
    const [confirmationModal, setConfirmationModal] = useState(0);
    useEffect(() => {
        getVolumeById(props.bookId)
        getSectionById(props.bookId)
    }, [])

    const getSectionById = (id) => {
        axios
            .get(site_ip + '/getBookSection/' + id, jwtHeader)
            .then((res) => {
                setSectionList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const [volumeList, setVolumeList] = useState([blankValues]);
    const getVolumeById = (id) => {
        axios
            .get(site_ip + '/getBookVolume/' + id, jwtHeader)
            .then((res) => {
                setVolumeList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const addOneRow = () => {
        let lists = [...sectionList];
        lists.push(blankValues);
        setSectionList(lists);
    }

    const removeOneRow = (index) => {
        let lists = [...sectionList];
        lists.splice(index, 1);
        setSectionList(lists);
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        getSectionById(props.bookId)
    }

    const handleClose = () => {
        setConfirmationModal(0);
    }

    const onDeleteRequest = (id, index) => {
        if (id === 0) {
            removeOneRow(index)
        } else {
            setConfirmationModal(id)
        }
    }

    const deleteRecord = () => {
        let req = { section_id: confirmationModal };
        axios.post(site_ip + "/deleteBookSection", req, jwtHeader)
            .then((res) => {
                getSectionById(props.bookId)
                setConfirmationModal(0)
            }).catch((err) =>
                console.log(err)
            )
    }

return (
    <Aux>
        <div className='new-content-section'>
            <div>
                <Row>
                    <Col>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <p>Volume</p>
                                    </th>
                                    <th>
                                        <p>Section No.</p>
                                    </th>
                                    <th>
                                        <p>Section Name</p>
                                    </th>
                                    <th>
                                        <p>No of Chapters</p>
                                    </th>
                                    <th>
                                        <p>No of Pages</p>
                                    </th>
                                    <th>
                                        <p>Action</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sectionList.map((item, index) => {
                                    return (
                                        <Section volumeList={volumeList} item={item} key={index} setSuccessMessage={(val) => setSuccessMessage(val)} onDeleteRequest={(id) => onDeleteRequest(id, index)} />
                                    )
                                })}
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: "100%" }}>
                            <button onClick={() => addOneRow()} className="primary-plus-button">Add More</button>
                        </div>
                    </Col>

                </Row>
                <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure to remove this record?</Modal.Body>
                    <Modal.Footer>
                        <button
                            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button
                            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
                <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
            </div>
        </div>
    </Aux>
);
}

const Section = (props) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(props.item);

    useEffect(() => {
        setValues(props.item)
    }, [props.item])
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const addVolume = () => {
        let req = { ...values };
        if (!validPositiveNumber(req.section_no)) {
            setErrorMessage("Please add section number.")
            return;
        }
        if (req.section_name.trim() === "") {
            setErrorMessage("Please add section name.")
            return;
        }
        if (!validPositiveNumber(req.no_of_chapters)) {
            setErrorMessage("Please add nos. of chapters.")
            return;
        }
        if (!validPositiveNumber(req.no_of_pages)) {
            setErrorMessage("Please add nos. of pages.")
            return;
        }
        const jwtHeader = {
            headers: { 'access-token': localStorage.getItem('admin_token') },
        };

        if (values.section_id === undefined) {
            axios
                .post(site_ip + '/createBookSection', [req], jwtHeader)
                .then((res) => {
                    props.setSuccessMessage("New Section added successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setBookSection', req, jwtHeader)
                .then((res) => {
                    props.setSuccessMessage("Section details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    const validPositiveNumber = (number) => {
        // if (number === "") {
        //     return true;
        // } else {
            var positiveNumber = /^(0|\+?[1-9]\d*)$/;
            if (String(number).match(positiveNumber)) {
                return true;
            } else {
                return false;
            }
        // }
    };

    return (
        <tr>
            {/* volumeList */}
            <td style={{ width: "15%" }}>
                <div style={{ paddingTop: "10px", position: "relative" }}>
                    <Form.Control
                        value={values?.volume_id}
                        as="select"
                        name="volume_id"
                        onChange={(e) => handleChange("volume_id", e.target.value)}
                    >
                        <option value="">--Select--</option>
                        {props.volumeList.map((item, index)=>{
                            return(
                                <option value={item.volume_id} key={item.volume_id}>{item.volume_name}</option>
                            )
                            
                        })}
                    </Form.Control>
                </div>
            </td>
            <td style={{ width: "10%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="number"
                        value={values?.section_no}
                        name="section_no"
                        onChange={(e) => handleChange("section_no", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "45%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        value={values?.section_name}
                        name="section_name"
                        onChange={(e) => handleChange("section_name", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "10%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="number"
                        value={values?.no_of_chapters}
                        name="no_of_chapters"
                        onChange={(e) => handleChange("no_of_chapters", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "10%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="number"
                        value={values?.no_of_pages}
                        name="no_of_pages"
                        onChange={(e) => handleChange("no_of_pages", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ textAlign: 'center', width: "15px" }}>
                <div style={{ display: "flex" }}>
                    <button style={{ padding: "5px", marginTop: "5px", marginRight: "10px", width: "43px", display: "flex", justifyContent: "center" }} onClick={() => addVolume()} className="primary-plus-button">
                        {values.section_id === undefined ? "Add" : "Update"}
                    </button>
                    <button style={{ padding: "5px", marginTop: "5px", backgroundColor: "#ff4d4d" }} onClick={() => props.onDeleteRequest(values.section_id === undefined ? 0 : values.section_id)} className="primary-plus-button">
                        Remove
                    </button>
                </div>
            </td>
            <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
        </tr>
    )
}