import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
  Pagination,
} from 'react-bootstrap';
import { site_ip } from '../../globalSetting';
// import Swal from 'sweetalert2';
import axios from 'axios';
import $ from 'jquery';
import API from '../API';
import { useNavigate } from 'react-router-dom';

function ExamsSetupAdd(props) {
  const navigate = useNavigate();
  let queIdFromDb = props.location.state.questions_count;
  const test_series_id = props.location.state.test_series_id;
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const [checkedItems, setCheckedItems] = useState([]);
  const [added_questions, setAddedQuestions] = useState([]);
  const intialValues = {
    subject_id: '',
    question_id: '',
    dificulty_id: '',
    topic_id: '',
    skill_id: '',
    question: '',
  };

  const [search, setSearch] = useState(intialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Check is it subject
    if (name === 'subject_id') {
      fetchTopicData(value);
    }
  };

  const [topic, setTopic] = useState(null);
  const fetchTopicData = (subject) => {
    axios
      .get(site_ip + '/getSubjectTopics/' + subject, jwtHeader)
      .then(function (response) {
        // console.log(response);
        setTopic(response.data.Student);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [subject, getSubject] = useState(null);
  const fetchSubjectData = async () => {
    try {
      getSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };

  const [skill, getSkill] = useState(null);
  const fetchSkillData = () => {
    axios
      .get(site_ip + '/getSkills', jwtHeader)
      .then(function (response) {
        getSkill(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  useEffect(() => {
    fetchSkillData();
    fetchSubjectData();
    handleSearch();
  }, []);

  const [questions, getQuestionList] = useState(null);

  var { subData, topicData, questionData } = [];

  if (subject) {
    subData = subject.map((res, index) => (
      <option value={res.subject_id}>{res.subject_name}</option>
    ));
  }
  if (topic) {
    topicData = topic.map((res, index) => (
      <option value={res.topic_id}>{res.topic_name}</option>
    ));
  }

  const handleCheckboxClick = (e, i, id) => {
    const newCheckboxes = [...checkedItems];
    let check_val = e.target.value;
    let it_status = newCheckboxes.includes(parseInt(check_val));
    if (!it_status) {
      if (newCheckboxes.length < queIdFromDb) {
        newCheckboxes.push(parseInt(e.target.value));
        setCheckedItems(newCheckboxes);
      } else {
        alert(
          'You cannot add more than ' +
          queIdFromDb +
          ' questions. If you want to add more question than increase Question Count.'
        );
        e.preventDefault();
      }
    } else {
      let index = newCheckboxes.indexOf(parseInt(e.target.value));
      newCheckboxes.splice(index, 1);
      setCheckedItems(newCheckboxes);
    }
  };
  const handleAllCheck = (e) => {
    let newCheckboxes = [];
    for (let index = 0; index < queIdFromDb; index++) {
      if (e.target.checked) {
        $('#check_' + index).prop('checked', true);
        newCheckboxes = questions
          .filter((data, i) => i <= index)
          .map((data) => data.question_id);
        setCheckedItems(newCheckboxes);
      } else {
        $('#check_' + index).prop('checked', false);
        setCheckedItems(newCheckboxes);
      }
    }
  };

  const renderQuestion = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  };

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsPerPage] = useState(10);

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const currentQuestions = questions?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );
  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(questions?.length / questionsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (currentQuestions) {
    questionData = currentQuestions.map((res, index) => (
      <tr>
        <th scope="row">
          <Form.Group>
            <Form.Check
              checked={checkedItems.includes(res.question_id)}
              key={res.question_id}
              type="checkbox"
              onClick={(e) => handleCheckboxClick(e, index, res.question_id)}
              id={'check_' + index}
              value={res.question_id}
            />
          </Form.Group>
        </th>
        <td>{(currentPage - 1) * 10 + index + 1}</td>
        <td>{res.question_id}</td>
        <td>{renderQuestion(res.question)}</td>
        <td>
          {res.question_type_id === 1
            ? 'Multi Select'
            : res.question_type_id === 2
              ? 'Single Choice'
              : res.question_type_id === 3
                ? 'True / False'
                : res.question_type_id === 4
                  ? 'Short Description'
                  : res.question_type_id === 5
                    ? 'Descriptive Answer'
                    : null}
        </td>
        <td>{res.subject_name}</td>
      </tr>
    ));
  }
  const handleSearch = () => {
    axios({
      method: 'post',
      url: site_ip + '/searchQuestionBank',
      data: search,
      headers: { 'access-token': localStorage.getItem('admin_token') },
      dataType: 'json',
    })
      .then(function (response) {
        getQuestionList(response.data.Questions);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const handleSubmit = async (e) => {
    console.log(parseInt(queIdFromDb) - parseInt(checkedItems.length));
    if (queIdFromDb > checkedItems.length) {
      let count = parseInt(queIdFromDb) - parseInt(checkedItems.length);
      alert('Please select ' + count + ' more questions.');
      return;
    }
    let data = [];
    checkedItems.map((item) => {
      data.push({
        test_series_id: test_series_id,
        question_id: item,
        created_user: '',
      });
    });
    axios({
      method: 'post',
      url: site_ip + '/addTestSeriesQuestions',
      headers: { 'access-token': localStorage.getItem('admin_token') },
      data: data,
      dataType: 'json',
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.Success === 200) {
          navigate('/admin/test-series');
        } else {
          alert('Request Failed');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Questions Count :{' '}
                        {questions !== null ? questions.length : 0}
                      </b>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      <b>Questions Required : {queIdFromDb}</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      <b>Questions Selected : {checkedItems.length}</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header>
              <Card.Title as="h5">Search Questions</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Subject </Form.Label>
                      <Form.Control
                        as="select"
                        name="subject_id"
                        className="mb-3"
                        onChange={handleChange}
                        value={search.subject_id}
                      >
                        <option>Select Subject</option>
                        {subData}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        as="select"
                        name="topic_id"
                        onChange={handleChange}
                        value={search.topic_id}
                      >
                        <option>Select Topic</option>
                        {topicData}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Skill</Form.Label>
                      <Form.Control
                        name="skill_id"
                        value={search.skill_id}
                        onChange={handleChange}
                        as="select"
                      >
                        <option>Select Skill Id</option>
                        {skill?.map((value) => (
                          <option value={value.skill_id}>
                            {value.skill_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Difficulty </Form.Label>
                      <Form.Control
                        as="select"
                        name="dificulty_id"
                        onChange={handleChange}
                        value={search.dificulty_id}
                      >
                        <option value="">Select Difficulty Level</option>
                        <option value="1">Simple</option>
                        <option value="2">Medium</option>
                        <option value="3">Complex</option>
                        <option value="4">Student Rank</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Question</Form.Label>
                      <Form.Control
                        name="question"
                        value={search.question}
                        onChange={handleChange}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-4">
                    <Button
                      variant="primary"
                      className="mt-1"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header>
              <Card.Title as="h5">Questions Listing</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th scope="row">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          name="checkall"
                          onClick={handleAllCheck}
                        />
                      </Form.Group>
                    </th>
                    <th>Sr. No</th>
                    <th>Question Id</th>
                    <th>Question</th>
                    <th>Question Type</th>
                    <th>Subject Name</th>
                  </tr>
                </thead>
                <tbody>{questionData}</tbody>
              </Table>

              <Row>
                <Pagination>
                  <Button
                    variant="secondary"
                    onClick={() => prevPage(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  {pageNumbers.map((num) => (
                    <Pagination.Item
                      onClick={() => paginate(num)}
                      active={num === currentPage}
                    >
                      {num}
                    </Pagination.Item>
                  ))}
                  <Button
                    variant="secondary"
                    onClick={() => nextPage(currentPage - 1)}
                  >
                    Next
                  </Button>
                </Pagination>
              </Row>
              <Row>
                <Col md={5}></Col>
                <Col md={3}>
                  <Button onClick={handleSubmit} variant="primary">
                    SAVE
                  </Button>
                </Col>
                <Col md={4}></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ExamsSetupAdd;
