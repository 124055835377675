import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import "../commoncss.css"
const ProductSection = () => {
    const params = useParams();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const [subjectList, setSubjectList] = useState([]);
    const [activeSubject, setActiveSubject] = useState(null);
    const [searchedString, setSearchString] = useState("");
    const [queryString, setQueryString] = useState("");
    const [bookList, setBookList] = useState([])
    useEffect(() => {
        getSubjects();

    }, [])

    const getSubjects = () => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectList(res.data.content);
            })
            .catch((err) => console.log(err));
    };


    const selectSection = (id) => {

    }

    return (
        <div>
            <div style={{ padding: "15px 0px" }}>
                <Row style={{ margin: "0px" }}>
                    <Col md={1}></Col>
                    <Col md={3}>
                        <div className="section-box">
                            <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000", padding: "15px", marginBottom: "0px" }}>SECTION</p>
                            {subjectList.map((item, index) => {
                                return (
                                    <button key={index} className="section-item" onClick={() => { selectSection(item.subject_id); setActiveSubject(item) }} >
                                        <p style={{ padding: "15px", marginBottom: "0px" }}>{item.subject_name}</p>
                                    </button>
                                )
                            })}
                        </div>
                    </Col>
                    <Col md={7}>
                        <Row style={{ margin: 0, marginBottom: "15px" }}>
                            <Col style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Procedure Guide</p>
                            </Col>
                            {/* <Col>
                                <div className="search-round-div">
                                    <input placeholder='Search videos...' value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                                    <button style={{cursor:"pointer"}} onClick={() => searchMedicalCases(null)}>Search</button>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </div>
        </div>
    )
}

export default ProductSection;

