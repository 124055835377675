import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';

import { site_ip } from '../../globalSetting';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import API from '../API';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';


export default function Videos(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const initialValues = {
    video_name: '',
    course_id: '',
    subject_id: '',
    owner_id: '',
  };
  const [search, setSearch] = useState(initialValues);
  const [course, setCourse] = useState([]);
  const [subject, setSubject] = useState([]);
  const [videos, setVideos] = useState([]);
  const [owner, setOwner] = useState([]);

  useEffect(() => {
    getVideos();
    getSubjects();
    getCourses();
    getOwners();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  const getVideos = async () => {
    try {
      setVideos(await API.fetchVideos());
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubject = async (subject_id) => {
    try {
      setVideos(await API.fetchVideosBySubject(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const getSubjects = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const getCourses = async () => {
    try {
      setCourse(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const getOwners = async () => {
    try {
      setOwner(await API.fetchOwners());
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = async () => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    await axios
      .post(
        `${site_ip}/searchVideos`,
        {
          video_name: search.video_name,
          course_id: search.course_id,
          subject_id: search.subject_id,
          owner_id: search.owner_id,
        },
        jwtHeader
      )
      .then((res) => {
        setVideos(res.data.Data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) =>{
    navigate('/learner/video/'+id)
  }

  return (
    <Container fluid>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Header as="h4">Search Videos</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Video Name</Form.Label>
                      <Form.Control
                        name="video_name"
                        value={search.video_name}
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Course</Form.Label>
                      <Form.Control
                        name="course_id"
                        value={search.course_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Course</option>
                        {course?.map((item, key) => (
                          <option key={key} value={item.course_id}>
                            {item.course_title}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        name="subject_id"
                        value={search.subject_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Subject</option>
                        {subject?.map((item, key) => (
                          <option key={key} value={item.subject_id}>
                            {item.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Owner</Form.Label>
                      <Form.Control
                        name="owner_id"
                        value={search.owner_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Owner</option>
                        {owner?.map((item, key) => (
                          <option key={key} value={item.admin_id}>
                            {item.admin_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Button
                      variant="primary"
                      className="mt-4"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      variant="dark"
                      className="mt-4"
                      onClick={() => navigate('add-video')}
                    >
                      New Video
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={3} style={{ margin: '10px' }}>
          <div style={{backgroundColor:"#22b1e8", height:"45px", display:"flex", alignItems:"center", padding:"5px"}}>
            <p style={{fontSize:"20px", fontWeight:"bold", marginBottom:"0px", color:"#ffffff"}}>Filter By Subjects</p>            
          </div>
          <div style={{border:"1px solid #22b1e8", padding:"5px"}}>
            {subject?.map((item, index) => (
                <div key={index} style={{minHeight:"45px", display:"flex", alignItems:"center", backgroundColor: search.subject_id === item.subject_id ? "#22b1e8" : "#ffffff", color: search.subject_id === item.subject_id ? "#ffffff" : "#22b1e8"}}>
                  <a
                    
                    onClick={() => handleSubject(item.subject_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.subject_name}
                  </a>
                </div>  
              ))}             
          </div>
        </Col>
        <Col md={8} style={{ margin: '10px' }}>
          <Row>
            {/* {videos?.map((item) => (
              <Col md={6}>
                <Card
                  style={{
                    border: '3px',
                    margin: '10px',
                    background: '#DDD4F1',
                  }}
                >
                  <Card.Body>
                    <div className="img">
                      <img
                        onClick={() =>
                          navigate('/learner/video/' + item.video_id)
                        }
                        src="https://cf-images.ap-southeast-1.prod.boltdns.net/v1/static/6197335189001/d75d3def-1f2f-47b7-94ab-4114726ec7ab/3d3ba397-f3f7-4a5d-8713-1a03af7ff754/1280x720/match/image.jpg"
                        alt="alt"
                        width="100%"
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                      />
                      <PlayCircleFilledIcon
                        sx={{ color: 'white', fontSize: 60 }}
                        style={{
                          top: '30%',
                          left: '50%',
                          position: 'absolute',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </div>
                    <br />
                    <h4>{item.video_title}</h4>

                    <p>
                      <b>Video Type :</b> {item.video_type}
                    </p>
                    <span>Dec 21</span>
                  </Card.Body>
                </Card>
              </Col>
            ))} */}
            <table className="customTable">
                <thead>
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Title</th>
                    {/* <th>Subject</th>
                    <th>Access Type</th> */}
                    {/* <th width="80px" style={{textAlign:'center'}}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {videos?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1)}</td>
                      <td>{item.video_title}</td>
                      {/* <td>{item.subject_name}</td>
                      <td>{item.access_type_cd}</td> */}
                      {/* <td>
                        <div style={{display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(item.video_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(item.video_id)}>
                            <DeleteForeverIcon />
                          </Button>
                        </div> 
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
