import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import API from '../API';

import axios from 'axios';
import { site_ip } from '../../globalSetting';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';



export default function Courses(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);



  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const getCourses = async () => {
    try {
      setCourses(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const getSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubject = async (subject_id) => {
    try {
      setCourses(await API.fetchCoursesBySubject(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCourses();
    getSubjects();
  }, []);
  const [search, setSearch] = useState('');
  const handleSearch = () => {
    const newFilter = courses.filter((value) => {
      return value.course_title.toLowerCase().includes(search.toLowerCase());
    });
    if (search === '') {
      setCourses(courses);
    } else {
      setCourses(newFilter);
    }
  };

  const editButton = (id) =>{
    navigate('/admin/edit-course/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        course_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deleteCourse/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            getCourses();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <>
      <br />
      <Row>
        <Col md={3}></Col>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              name="searchterm"
              placeholder="Search Courses"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="mb-3"
              type="text"
            />
          </Form.Group>
        </Col>
        <Col md={1}>
          <Button variant="primary" onClick={handleSearch}>
            Search
          </Button>
        </Col>
        <Col md={2}></Col>
        <Col md={2}>
          <Button
            variant="dark"
            onClick={() => navigate('/admin/add-course')}
          >
            New Course
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={3} style={{ margin: '10px' }}>
          <div style={{backgroundColor:"#22b1e8", height:"45px", display:"flex", alignItems:"center", padding:"5px"}}>
            <p style={{fontSize:"20px", fontWeight:"bold", marginBottom:"0px", color:"#ffffff"}}>Filter By Subjects</p>            
          </div>
          <div style={{border:"1px solid #22b1e8", padding:"5px"}}>
            {subjects?.map((item, index) => (
                <div key={index} style={{minHeight:"45px", display:"flex", alignItems:"center", backgroundColor: search.subject_id === item.subject_id ? "#22b1e8" : "#ffffff", color: search.subject_id === item.subject_id ? "#ffffff" : "#22b1e8"}}>
                  <a
                    
                    onClick={() => handleSubject(item.subject_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.subject_name}
                  </a>
                </div>  
              ))}             
          </div>
        </Col>
        <Col md={8} style={{ margin: '10px' }}>
          <Row>
            {/* {courses.map((item, index)=>{
              return(
                <CourseIcon item={item} key={index} editButton={editButton}  deleteEntry={deleteEntry} />
              )
            })} */}

            <table className="customTable">
                <thead>
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Title</th>
                    <th>Subject</th>
                    <th>Access Type</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {courses?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1)}</td>
                      <td>{item.course_title}</td>
                      <td>{item.subject_name}</td>
                      <td>{item.access_type_cd}</td>
                      <td>
                        <div style={{display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(item.course_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(item.course_id)}>
                            <DeleteForeverIcon />
                          </Button>
                        </div> 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </Row>
        </Col>
      </Row>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}


const CourseIcon = (props) =>{
  const item = props.item;
  return(
    <Col md={6} style={{marginBottom:"15px"}}>
        <div style={{marginLeft:"15px", marginRight:"15px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding:"15px", position:"relative"}}>
          <img
              src="https://learn.docmode.org/asset-v1:DRL+DRL008+2021_Aug_DRL008+type@asset+block@LC_14th_Aug_2021-03.jpg"
              alt="alt"
              width="100%"
              style={{ borderRadius: '5px', cursor: 'pointer' }}
          />
          <p style={{fontSize:"20px", fontWeight:"bold", color:"#22b1e8", marginTop:"15px", marginBottom:"5px"}}>{item.course_title}</p>
          <p style={{fontSize:"16px", color:"#22b1e8", marginTop:"5px", marginBottom:"5px"}}>Subject :<b>{item.subject_name}</b></p>
          <p style={{fontSize:"16px", color:"#22b1e8", marginTop:"5px"}}>Access Type :<b>{item.access_type_cd}</b></p>
          <div style={{position:"absolute", top:"5px", right:"5px"}}>
            <Button variant="success" className="rowActionButton" onClick={()=> props.editButton(item.course_id)}>
              <EditIcon />
            </Button>
            <Button variant="danger" className="rowActionButton" onClick={()=> props.deleteEntry(item.course_id)}>
              <DeleteForeverIcon />
            </Button>
          </div>
        </div>
    </Col>
  )
}
