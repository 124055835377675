import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';

export default function BookReview(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'All']
  const [activeAlpha, setActiveAlpha] = useState("All")
  const [show, setShow] = useState(false);

  const blankValues = {
    book_id: "",
    book_title: "",
    review_type: "",
    review_rating:""
  }

  const [values, setValues] = useState(blankValues);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    getBooks();
    getBookReview(blankValues);
  }, [])

  const [bookReviewList, setBookReviewList] = useState([]);
  const getBookReview = (req) => {
    axios
      .post(site_ip + '/searchBookReview', req, jwtHeader)
      .then((res) => {
        setBookReviewList(res.data.Content);
      })
      .catch((err) => console.log(err));

  };

  const [bookList, setBookList] = useState([]);
  const getBooks = () => {
    axios
      .post(site_ip + '/getBookSearch', {}, jwtHeader)
      .then((res) => {
        setBookList(res.data.Books);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setShow(false)
  }

  const removeRecord = () => {

  }

  const columns = [
    {
      name: 'Book',
      selector: row => row.book_title,
    },
    {
      name: 'Review Type',
      selector: row => row.review_type,
    },
    {
      name: 'Rating',
      selector: row => row.review_rating,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.book_review_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/book/review/edit/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Book Reviews</p>
          </Col>
          <Col className="page-title-right">
            {/* <button onClick={() => navigate('/admin/book/review/add')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Create Book Review</button> */}
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col className="search-row">
            <Form.Control
              as="select"
              className="search-form-element"
              name="book_id"
              onChange={handleChange}
              value={values.book_id}
            >
              <option value={""}>Select Book</option>
              {bookList.map((item, index) => {
                return (
                  <option key={index} value={item.book_id}>{item.book_title}</option>
                )
              })}
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="review_type"
              onChange={handleChange}
              value={values.review_type}
            >
              <option value={""}>Select Type</option>
              <option value="Doody">Doody</option>
              <option value="Other">Other</option>
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="review_rating"
              onChange={handleChange}
              value={values.review_rating}
            >
              <option value={""}>Select Rating</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Control>
            <button onClick={() => getBookReview(values)} className="primary-plus-button">Search</button>
            <div className='spacer' />
            <button onClick={() => { getBookReview(blankValues); setValues(blankValues) }} className="secondary-button">Reset</button>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={bookReviewList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}