import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import API from '../../API';
import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
export default function AddPublishers(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    publisher_name: '',
    publisher_code: '',
    publisherheader_logo: '',
    publisherfooter_logo: '',
    contact_no: '',
    skype_id: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: '',
    pin_code: '',
    city_name: '',
    state_id: '',
    country_id: '',
  });

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'country_id') {
      fetchStateData(value);
    }
  };

  const fetchCountryData = async () => {
    try {
      setCountry(await API.fetchCountryList());
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStateData = async (countryid) => {
    try {
      setState(await API.fetchStateByCountry(countryid));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const proceed = async () => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (
      values.publisher_name === '' ||
      values.contact_no === '' ||
      values.pin_code === '' ||
      values.country_id === '' ||
      values.state_id === '' ||
      values.city_name === '' ||
      values.publisher_code === ''
    )
      alert('Please fill all the fields');
    else {
      await axios
        .post(site_ip + '/createPublishers', values, jwtHeader)
        .then(function (response) {
          console.log(response);
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Publisher Created Successfully',
              icon: 'success',
            });
            navigate('/admin/manage-publishers');
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  return (
    <Aux>
      <Container fluid>
        <br />
        <Card>
          <Card.Header>
            <Card.Title as="h5">Add Publisher</Card.Title>
          </Card.Header>
          <Card.Body>
        <Form>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Publisher Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="publisher_name"
                  value={values.publisher_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Publisher Code <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="publisher_code"
                  value={values.publisher_code}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Header Logo</Form.Label>
                <Form.Control
                  type="file"
                  className="mb-3"
                  name="publisherheader_logo"
                  value={values.publisherheader_logo}
                  onChange={handleChange}
                  accept="image/png, image/jpeg"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Footer Logo</Form.Label>
                <Form.Control
                  type="file"
                  className="mb-3"
                  name="publisherfooter_logo"
                  value={values.publisherfooter_logo}
                  onChange={handleChange}
                  accept="image/png, image/jpeg"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Contact Number <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="contact_no"
                  value={values.contact_no}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Skype ID</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="skype_id"
                  value={values.skype_id}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Facebook ID</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="facebook"
                  value={values.facebook}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Instagram ID</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="instagram"
                  value={values.instagram}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Twitter ID</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="twitter"
                  value={values.twitter}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>{' '}
            <Col md={3}>
              <Form.Group>
                <Form.Label>Linkedin ID</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="linkedin"
                  value={values.linkedin}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Pin Code <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="pin_code"
                  value={values.pin_code}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>City <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="city_name"
                  value={values.city_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Country <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="country_id"
                  value={values.country_id}
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>
                  {country?.map((value) => (
                    <option key={value.country_id} value={value.country_id}>
                      {value.country_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>State <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {state?.map((value) => (
                    <option key={value.state_id} value={value.state_id}>
                      {value.state_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button onClick={proceed}>Submit</Button>
        </Form>
        </Card.Body>
        </Card>
      </Container>
    </Aux>
  );
}
