import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ExamQuestionText from "./ExamQuestionText";
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
export default function AddQuestion(props) {
    const params = useParams();
    const [questionId, setQuestionId] = useState(0);
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    const [input, setInputs] = useState({
        // question_desc: "",
        // question_options: ""
    });

    const handleChange = (name, value) => {
        setInputs((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {
        if (params.question_id !== undefined) {
            setQuestionId(params.question_id);
            fetchQuestionById(params.question_id)
        }
    }, [])

    const fetchQuestionById = async (question_id) => {
        try {
            await axios
                .get(
                    site_ip + '/getFeedbackQuestionById/'+question_id,
                    jwtHeader
                )
                .then(function (response) {
                    // console.log(response);
                    setInputs(response.data?.Content)
                })
                .catch(function (error) {
                    console.log('error' + error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Container fluid>
                <div>
                    <Row style={{ margin: 0 }}>
                        <Col>
                            <Button className={"instituionTabSection active"}>Question</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ExamQuestionText id={questionId} handleChange={handleChange} input={input}/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
