import React, { useState, useEffect } from 'react';
import { Container, Col, Form, Row, Button, Card, Image } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../../globalSetting';
import Aux from '../../../hoc/_Aux';
import API from '../../API';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { useParams, useNavigate } from 'react-router-dom';

export default function EditFacultyMembers(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    member_id: '',
    member_pic: "",
    member_first_name: '',
    member_last_name: '',
    gender: '',
    member_email: '',
    mobile_number: '',
    role_id: '',
    publisher_id: '',
    subject_id: '',
    speciality_id: '',
    status: '',
  });
  const [editorQualificationState, setEditorQualificationState] = useState("");
  const [editorBioState, setEditorBioState] = useState("");
  const fetchUsers = async (member_id) => {
    try {
      const data = await API.fetchEditorialBoardById(member_id);
      setValues(data);
      // console.log(data);
      setEditorQualificationState(data.member_qualifications);
      setEditorBioState(data.member_bio);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUsers(params.member_id);
    //eslint-disable-next-line
  }, []);

  const [publisher, setPublisher] = useState([]);
  const [roles, setRoles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [image, setImage] = useState(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchRoles = async () => {
    try {
      setRoles(await API.fetchRoles());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPublishers = async () => {
    try {
      setPublisher(await API.fetchPublishers());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPublishers();
    fetchRoles();
    fetchSubjects();
    fetchCategories();
  }, []);

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const proceed = async () => {
    const editors = {
      member_qualifications: editorQualificationState,
      member_bio: editorBioState
    }
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (
      values.member_first_name === '' ||
      values.member_last_name === '' ||
      values.role_id === '' ||
      values.member_email === '' ||
      values.mobile_number === '' ||
      values.status === '' ||
      values.gender === '' ||
      values.publisher_id === '' ||
      values.password === ''
    )
      alert('Please fill all the fields');
    else if (!validateEmail(values.member_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.mobile_number)) {
      alert('Please enter valid mobile number');
    } else {

      let imageAttachment = null;
      let profile = {
        member_pic: values.member_pic
      }
      if (image !== null) {
        imageAttachment = await fileUpload(image)
        profile = {
          member_pic: imageAttachment
        }
      }

      await axios
        .post(
          site_ip + '/setEditorialBoardMember',
          { ...values, ...editors, ...profile },
          jwtHeader
        )
        .then(function (response) {
          //   console.log(response);
          if (response.data.Status) {
            Swal.fire({
              title: 'Success',
              text: 'User Updated',
              icon: 'success',
            });
            navigate('/admin/editorial-board-listing');
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const fileUpload = async (attachment) => {
    const s3_url =
      'https://derma.s3.ap-south-1.amazonaws.com/' +
      attachment.name;
    const target = { Bucket: 'derma', Key: attachment.name, Body: attachment };
    const creds = {
      accessKeyId: 'AKIAWUYVKQ2U4TARTTST',
      secretAccessKey: '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',
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'ap-south-1', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress);
      });

      await parallelUploads3.done();
      return s3_url;

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Aux>
      <Container fluid>
        <br />
        <Card>
          <Card.Header>
            <Card.Title as="h5">Edit Editorial Board Member</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form>


              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      name="member_first_name"
                      value={values.member_first_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      name="member_last_name"
                      value={values.member_last_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Profile Picture</Form.Label>
                    <Form.Control
                      type="file"
                      className="mb-3"
                      accept="image/*"
                      name="case_images_names"
                      // value={values.case_images_names}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        // handleChange(e);
                      }}
                    />
                  </Form.Group>
                  {values.member_pic !== null ?
                    <Image src={values.member_pic} style={{ height: "50px", width: "50px" }} /> : null}
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                    >
                      <option>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="email"
                      name="member_email"
                      value={values.member_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="number"
                      name="mobile_number"
                      value={values.mobile_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="select"
                      name="role_id"
                      value={values.role_id}
                      onChange={handleChange}
                    >
                      <option>Select Role</option>
                      {roles.map((value) => (
                        <option key={value.role_id} value={value.role_id}>
                          {value.role_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Publisher</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="publisher_id"
                      value={values.publisher_id}
                      onChange={handleChange}
                    >
                      <option>Select Publisher</option>
                      {publisher?.map((option) => (
                        <option value={option.publisher_id}>
                          {option.publisher_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Publisher</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="publisher_id"
                      value={values.publisher_id}
                      onChange={handleChange}
                    >
                      <option>Select Publisher</option>
                      {publisher?.map((option) => (
                        <option value={option.publisher_id}>
                          {option.publisher_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="speciality_id"
                      value={values.speciality_id}
                      onChange={handleChange}
                    >
                      <option>Select Category</option>
                      {categories?.map((option) => (
                        <option value={option.category_id}>
                          {option.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="subject_id"
                      value={values.subject_id}
                      onChange={handleChange}
                    >
                      <option>Select Subject</option>
                      {subjects?.map((option) => (
                        <option value={option.subject_id}>
                          {option.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      className="mb-3"
                      as="select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Qualification</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        editor={Editor}
                        data={editorQualificationState}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setEditorQualificationState(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Bio</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        editor={Editor}
                        data={editorBioState}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setEditorBioState(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Button className="mt-1" onClick={proceed}>
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </Aux>
  );
}
