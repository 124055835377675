import React, { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import routes from './routes';
import Login from './content/login';
// import Signup from './content/Signup';
import Logout from './content/Logout';
import TopBar from './content/TopBar';
import LeftSideBar from './content/LeftSideBar';

//Additional Admin Pages
import ExamDashboard from './content/Exam/ExamDashboard';
import ExamsListing from './content/Exam/ExamsListing';
import ExamsSetup from './content/Exam/ExamsSetup';
import ExamStudentPrizeList from './content/Exam/ExamStudentPrizeList';
import ExamStudentRankingList from './content/Exam/ExamStudentRankingList';
import PaymentDetailsListing from './content/Exam/PaymentDetailListing';
import QuestionPatternSetup from './content/Exam/ExamPatternSetup';
import StudentAppearedForExam from './content/Exam/StudentAppearedForExam';
import StudentRegListByExam from './content/Exam/StudentRegListByExam';
import StudentScore from './content/Exam/StudentScore';
import ExamSetupLevel1 from './content/Exam/ExamSetupLevel1';
import ExamPatternAddEdit from './content/Exam/ExamPatternAddEdit';

// Books
import BookListing from './content/Books/Book/View';

import BookAdd from './content/Books/Book/Add';

// Book Review
import BookReviewList from './content/Books/Bookreview/bookreview';

import BookReviewAdd from './content/Books/Bookreview/Add';

// Chapters
import BookChapterList from './content/Books/Chapter/View';

import BookChapterAdd from './content/Books/Chapter/Add';

// Podcast
import BookPodcastList from './content/Books/BookPodcast/View';

import BookPodcastAdd from './content/Books/BookPodcast/Add';

// Videos
import BookVideoList from './content/Books/Video/View';

import BookVideoAdd from './content/Books/Video/ManageVideos';

//file upload
import FileUpload from './content/MedicalCase/FileUpload.jsx';

import Dashboard from './content/Dashboard';

//Categories
import CategoryAdd from './content/Master/Categories/Add';
import CategoryListing from './content/Master/Categories/View';
import CategoryEdit from './content/Master/Categories/Edit';
//Subjects
import SubjectAdd from './content/Master/Subjects/Add';
import SubjectListing from './content/Master/Subjects/View';
import SubjectEdit from './content/Master/Subjects/Edit';
//Topics
import TopicAdd from './content/Master/Topics/Add';
import TopicListing from './content/Master/Topics/View';
import TopicEdit from './content/Master/Topics/Edit';
//Question Tags
import QuestionTagAdd from './content/Master/QuestionsTags/Add';
import QuestionTagListing from './content/Master/QuestionsTags/View';
import QuestionTagEdit from './content/Master/QuestionsTags/Edit';
//Skills
import SkillAdd from './content/Master/Skills/Add';
import SkillListing from './content/Master/Skills/View';
import SkillEdit from './content/Master/Skills/Edit';
//Concepts
import ConceptAdd from './content/Master/Concepts/Add';
import ConceptListing from './content/Master/Concepts/View';
import ConceptEdit from './content/Master/Concepts/Edit';
//Units
import UnitAdd from './content/Master/Units/Add';
import UnitListing from './content/Master/Units/View';
import UnitEdit from './content/Master/Units/Edit';
//Publishers
import AddPublishers from './content/Master/Publisher/Add';
import PublishersList from './content/Master/Publisher/View';
import EditPublishers from './content/Master/Publisher/Edit';
//Institution
import AddInstitution from './content/Institution/Add';
import InstitutionsList from './content/Institution/View';
import EditInstitution from './content/Institution/InstitutionEdit';
import WIP from './content/Institution/WIP';
//Editorial Board
import AddEditorialBoard from './content/Master/EditorialBoard/Add';
import EditorialBoardList from './content/Master/EditorialBoard/View';
import EditEditorialBoard from './content/Master/EditorialBoard/Edit';

// Authors
import Authors from './content/Master/Author/View';
import AddAuthor from './content/Master/Author/Add';
//Courses
import Courses from './content/Courses/Courses';
import CourseDetails from './content/Courses/CourseDetails';
import AddCourses from './content/Courses/AddCourses';
import EditCourse from './content/Courses/EditCourseSection';
//Faculty
import AddFaculty from './content/Faculty/Add';
import FacultyList from './content/Faculty/View';
import EditFaculty from './content/Faculty/Edit';
//Expert Videos
import Videos from './content/Videos/Videos';
import VideoDetails from './content/Videos/VideoDetails';
import AddVideos from './content/Videos/AddVideos';

//Webinars
import Webinars from './content/Webinars/Webinars';
import AddWebinar from './content/Webinars/AddWebinar';
import EditWebinar from './content/Webinars/EditWebinar';
import WebinarDetails from './content/Webinars/WebinarDetails';

//Medical Cases
import MedicalCaseCreate from './content/MedicalCase/CreateCase';
import MedicalCaseEdit from './content/MedicalCase/EditCase';
import MedicalCases from './content/MedicalCase/MedicalCase';

// News
import NewsCreate from './content/News/CreateNews';
import NewsEdit from './content/News/EditNews';
import News from './content/News/News';

//Expert Commentary
import Commentary from './content/Commentary/Commentary';
import EditCommentary from './content/Commentary/EditCommentary';
import AddCommentary from './content/Commentary/AddCommentary';
import AddCommentaryFiles from './content/Commentary/AddCommentaryFiles';

import DigitalAtlasList from './content/DigitalAtlas/List';

//Questions
// import ExamQuestionAdd from './content/Exam/Questions/ExamQuestionAdd';
import ExamQuestionSearch from './content/Exam/Questions/View';
// import ExamQuestionEdit from './content/Exam/Questions/ExamQuestionEdit';
import ExamQuestion from './content/Exam/Questions/ExamQuestion';

//Questions
import OSCEExamQuestion from './content/Exam/OSCEQuestions/ExamQuestion';
// import OSCEQuestionAdd from './content/Exam/OSCEQuestions/ExamQuestionAdd';
import OSCEQuestionSearch from './content/Exam/OSCEQuestions/View';
import OSCEParaQuestions from './content/Exam/OSCEQuestions/ParaQuestions';
// import OSCEQuestionEdit from './content/Exam/OSCEQuestions/ExamQuestionEdit';

//Questions
import FeedbackQuestionSearch from './content/Feedback/Questions/View';
import FeedbackQuestion from './content/Feedback/Questions/ExamQuestion';

// Email Template
import EmailTemplateSearch from './content/EmailTemplate/Template/View';
import EmailTemplate from './content/EmailTemplate/Template/AddTemplate';
import EmailTemplateEdit from './content/EmailTemplate/Template/EditTemplate';

// Static Content
import StaticContentSearch from './content/StaticContents/Content/View';
import StaticContent from './content/StaticContents/Content/AddContent';

//Test Series
import TestSeries from './content/tests/TestSeries';
import TestSetupEdit from './content/tests/TestSetupEdit';
import AddTestSeries from './content/tests/AddTestSeries';
import EditTestSeries from './content/tests/EditTestSeries';

// Users
import AdminUsers from './content/users/AdminUsers';
import AddPublisherUsers from './content/users/PublisherUsers/Add';
import PublisherUsersList from './content/users/PublisherUsers/View';
import EditPublisherUsers from './content/users/PublisherUsers/Edit';
import AddInstitutionUsers from './content/users/InstitutionUsers/Add';
import InstitutionUsersList from './content/users/InstitutionUsers/View';
import EditInstitutionUsers from './content/users/InstitutionUsers/Edit';

import AddSiteUsers from './content/users/SiteUsers/Add';
import SiteUsersList from './content/users/SiteUsers/View';
import EditSiteUsers from './content/users/SiteUsers/Edit';


function App() {
  // const Route = ({
  //   component: Component,
  //   layout: Layout,
  //   ...rest
  // }) => (
  //   <Route
  //     {...rest}
  //     render={(props) => (
  //       <Layout>
  //         <Component {...props} />
  //       </Layout>
  //     )}
  //   />
  // );
  const BlankLayout = ({ children }) => <>{children}</>;
  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <LeftSideBar />
      <div className="right-block">
        <div className="content-wrapper">{children}</div>
      </div>
    </>
  );

  return (
    <Routes>
      {/* <Route
        exact
        path="/admin"
        layout={BlankLayout}
        element={<Login}
      /> */}
      <Route
        exact
        path="/"
        element={<Login />}
      />
      <Route
        exact
        path="/login"
        layout={BlankLayout}
        element={<Login />}
      />
      <Route
        exact
        path="/logout"
        layout={BlankLayout}
        element={<Logout />}
      />
      <Route
        exact
        path="/file-upload"
        layout={LayoutOfAfterLogin}
        element={<FileUpload />}
      />

      <Route
        path="/dashboard"
        exact
        element={LayoutOfAfterLogin(<Dashboard />)}
      />
      <Route
        path="/category-listing"
        exact
        element={LayoutOfAfterLogin(<CategoryListing />)}
      />
      <Route
        path="/category-add"
        exact
        element={LayoutOfAfterLogin(<CategoryAdd />)}
      />
      <Route
        path="/category-edit/:category_id"
        exact
        element={LayoutOfAfterLogin(<CategoryEdit />)}
      />
      <Route
        path="/subject-listing"
        exact
        element={LayoutOfAfterLogin(<SubjectListing />)}
      />
      <Route
        path="/subject-add"
        exact
        element={LayoutOfAfterLogin(<SubjectAdd />)}
      />
      <Route
        path="/subject-edit/:subject_id"
        exact
        element={LayoutOfAfterLogin(<SubjectEdit />)}
      />
      <Route
        path="/topic-listing"
        exact
        element={LayoutOfAfterLogin(<TopicListing />)}
      />
      <Route
        path="/topic-add"
        exact
        element={LayoutOfAfterLogin(<TopicAdd />)}
      />
      <Route
        path="/topic-edit/:topic_id"
        exact
        element={LayoutOfAfterLogin(<TopicEdit />)}
      />
      <Route
        path="/questiontag-listing"
        exact
        element={LayoutOfAfterLogin(<QuestionTagListing />)}
      />
      <Route
        path="/questiontag-add"
        exact
        element={LayoutOfAfterLogin(<QuestionTagAdd />)}
      />
      <Route
        path="/questiontag-edit/:tag_id"
        exact
        element={LayoutOfAfterLogin(<QuestionTagEdit />)}
      />
      <Route
        path="/skill-listing"
        exact
        element={LayoutOfAfterLogin(<SkillListing />)}
      />
      <Route
        path="/skill-add"
        exact
        element={LayoutOfAfterLogin(<SkillAdd />)}
      />
      <Route
        path="/skill-edit/:skill_id"
        exact
        element={LayoutOfAfterLogin(<SkillEdit />)}
      />
      <Route
        path="/concept-listing"
        exact
        element={LayoutOfAfterLogin(<ConceptListing />)}
      />
      <Route
        path="/concept-add"
        exact
        element={LayoutOfAfterLogin(<ConceptAdd />)}
      />
      <Route
        path="/concept-edit/:concept_id"
        exact
        element={LayoutOfAfterLogin(<ConceptEdit />)}
      />
      <Route
        path="/unit-listing"
        exact
        element={LayoutOfAfterLogin(<UnitListing />)}
      />
      <Route
        path="/unit-add"
        exact
        element={LayoutOfAfterLogin(<UnitAdd />)}
      />
      <Route
        path="/unit-edit/:unit_id"
        exact
        element={LayoutOfAfterLogin(<UnitEdit />)}
      />
      <Route
        path="/manage-publishers"
        exact
        element={LayoutOfAfterLogin(<PublishersList />)}
      />
      <Route
        path="/add-publisher"
        exact
        element={LayoutOfAfterLogin(<AddPublishers />)}
      />
      <Route
        path="/edit-publisher/:publisher_id"
        exact
        element={LayoutOfAfterLogin(<EditPublishers />)}
      />
      <Route
        path="/manage-institutions"
        exact
        element={LayoutOfAfterLogin(<InstitutionsList />)}
      />
      <Route
        path="/add-institution"
        exact
        element={LayoutOfAfterLogin(<AddInstitution />)}
      />
      <Route
        path="/edit-institution/:institution_id"
        exact
        element={LayoutOfAfterLogin(<EditInstitution />)}
      />
      <Route
        path="/manage-institutions-ipaddressess"
        exact
        element={LayoutOfAfterLogin(<WIP />)}
      />
      <Route
        path="/manage-institutions-subscriptions"
        exact
        element={LayoutOfAfterLogin(<WIP />)}
      />
      <Route
        path="/editorial-board-listing"
        exact
        element={LayoutOfAfterLogin(<EditorialBoardList />)}
      />
      <Route
        path="/add-editorial-board-member"
        exact
        element={LayoutOfAfterLogin(<AddEditorialBoard />)}
      />
      <Route
        path="/edit-editorial-board-member/:member_id"
        exact
        element={LayoutOfAfterLogin(<EditEditorialBoard />)}
      />
      <Route
        path="/authors"
        exact
        element={LayoutOfAfterLogin(<Authors />)}
      />
      <Route
        path="/add-author"
        exact
        element={LayoutOfAfterLogin(<AddAuthor />)}
      />
      <Route
        path="/edit-author/:author_id"
        exact
        element={LayoutOfAfterLogin(<AddAuthor />)}
      />
      <Route
        path="/courses"
        exact
        element={LayoutOfAfterLogin(<Courses />)}
      />
      <Route
        path="/add-course"
        exact
        element={LayoutOfAfterLogin(<AddCourses />)}
      />
      <Route
        path="/edit-course/:id"
        exact
        element={LayoutOfAfterLogin(<EditCourse />)}
      />
      <Route
        path="/course/:course_id"
        exact
        element={LayoutOfAfterLogin(<CourseDetails />)}
      />
      <Route
        path="/faculty-listing"
        exact
        element={LayoutOfAfterLogin(<FacultyList />)}
      />
      <Route
        path="/add-faculty"
        exact
        element={LayoutOfAfterLogin(<AddFaculty />)}
      />
      <Route
        path="/edit-faculty/:member_id"
        exact
        element={LayoutOfAfterLogin(<EditFaculty />)}
      />
      <Route
        path="/videos"
        exact
        element={LayoutOfAfterLogin(<Videos />)}
      />
      <Route
        path="/add-video"
        exact
        element={LayoutOfAfterLogin(<AddVideos />)}
      />
      <Route
        path="/video/:video_id"
        exact
        element={LayoutOfAfterLogin(<VideoDetails />)}
      />
      <Route
        path="/webinars"
        exact
        element={LayoutOfAfterLogin(<Webinars />)}
      />
      <Route
        path="/add-webinar"
        exact
        element={LayoutOfAfterLogin(<AddWebinar />)}
      />
      <Route
        path="/webinar/:webinar_id"
        exact
        element={LayoutOfAfterLogin(<WebinarDetails />)}
      />
      <Route
        path="/edit-webinar/:webinar_id"
        exact
        element={LayoutOfAfterLogin(<EditWebinar />)}
      />
      <Route
        path="/medical-case"
        exact
        element={LayoutOfAfterLogin(<MedicalCases />)}
      />
      <Route
        path="/create-medical-case"
        exact
        element={LayoutOfAfterLogin(<MedicalCaseCreate />)}
      />
      <Route
        path="/edit-medical-case/:case_id"
        exact
        element={LayoutOfAfterLogin(<MedicalCaseEdit />)}
      />
      <Route
        path="/news"
        exact
        element={LayoutOfAfterLogin(<News />)}
      />
      <Route
        path="/create-news"
        exact
        element={LayoutOfAfterLogin(<NewsCreate />)}
      />
      <Route
        path="/edit-news/:news_id"
        exact
        element={LayoutOfAfterLogin(<NewsEdit />)}
      />
      <Route
        path="/commentary"
        exact
        element={LayoutOfAfterLogin(<Commentary />)}
      />
      <Route
        path="/add-commentary"
        exact
        element={LayoutOfAfterLogin(<AddCommentary />)}
      />
      <Route
        path="/add-commentary-files"
        exact
        element={LayoutOfAfterLogin(<AddCommentaryFiles />)}
      />
      <Route
        path="/edit-commentary/:commentary_id"
        exact
        element={LayoutOfAfterLogin(<EditCommentary />)}
      />
      <Route
        path="/question-listing"
        exact
        element={LayoutOfAfterLogin(<ExamQuestionSearch />)}
      />
      {/* <Route
        path="/question-add"
        exact
        element={LayoutOfAfterLogin(<ExamQuestionAdd />)}
      /> */}
      {/* <Route
        path="/question-edit/:question_id"
        exact
        element={LayoutOfAfterLogin(<ExamQuestionEdit />)}
      /> */}
      <Route
        path="/question"
        exact
        element={LayoutOfAfterLogin(<ExamQuestion />)}
      />
      <Route
        path="/question/:question_id"
        exact
        element={LayoutOfAfterLogin(<ExamQuestion />)}
      />
      <Route
        path="/feedback-question-listing"
        exact
        element={LayoutOfAfterLogin(<FeedbackQuestionSearch />)}
      />
      <Route
        path="/feedback-question"
        exact
        element={LayoutOfAfterLogin(<FeedbackQuestion />)}
      />
      <Route
        path="/feedback-question/:question_id"
        exact
        element={LayoutOfAfterLogin(<FeedbackQuestion />)}
      />
      <Route
        path="/email-template-listing"
        exact
        element={LayoutOfAfterLogin(<EmailTemplateSearch />)}
      />
      <Route
        path="/email-template"
        exact
        element={LayoutOfAfterLogin(<EmailTemplate />)}
      />
      <Route
        path="/email-template/:template_id"
        exact
        element={LayoutOfAfterLogin(<EmailTemplateEdit />)}
      />
      <Route
        path="/static-content-listing"
        exact
        element={LayoutOfAfterLogin(<StaticContentSearch />)}
      />
      <Route
        path="/static-content"
        exact
        element={LayoutOfAfterLogin(<StaticContent />)}
      />
      <Route
        path="/static-content/:content_id"
        exact
        element={LayoutOfAfterLogin(<StaticContent />)}
      />

      <Route
        path="/question/osce/:osce_id"
        exact
        element={LayoutOfAfterLogin(<ExamQuestion />)}
      />
      <Route
        path="/osce-question-listing"
        exact
        element={LayoutOfAfterLogin(<OSCEQuestionSearch />)}
      />
      <Route
        path="/osce-question"
        exact
        element={LayoutOfAfterLogin(<OSCEExamQuestion />)}
      />
      <Route
        path="/osce-question/:question_id"
        exact
        element={LayoutOfAfterLogin(<OSCEExamQuestion />)}
      />
      <Route
        path="/osce-question/sub-question/:para_id"
        exact
        element={LayoutOfAfterLogin(<OSCEParaQuestions />)}
      />
      {/* <Route
        path="/osce-question-add"
        exact
        element={LayoutOfAfterLogin(<OSCEQuestionAdd />)}
      />
      <Route
        path="/osce-question-edit/:question_id"
        exact
        element={LayoutOfAfterLogin(<OSCEQuestionEdit />)}
      /> */}
      <Route
        path="/test-series"
        exact
        element={LayoutOfAfterLogin(<TestSeries />)}
      />
      <Route
        path="/add-test-series"
        exact
        element={LayoutOfAfterLogin(<AddTestSeries />)}
      />
      <Route
        path="/edit-test-series/:id"
        exact
        element={LayoutOfAfterLogin(<EditTestSeries />)}
      />
      <Route
        path="/test-questions"
        exact
        element={LayoutOfAfterLogin(<TestSetupEdit />)}
      />
      <Route
        path="/manage-admin-users"
        exact
        element={LayoutOfAfterLogin(<AdminUsers />)}
      />
      <Route
        path="/manage-publisher-users"
        exact
        element={LayoutOfAfterLogin(<PublisherUsersList />)}
      />
      <Route
        path="/add-publisher-user"
        exact
        element={LayoutOfAfterLogin(<AddPublisherUsers />)}
      />
      <Route
        path="/edit-publisher-user/:user_id"
        exact
        element={LayoutOfAfterLogin(<EditPublisherUsers />)}
      />
      <Route
        path="/manage-site-users"
        exact
        element={LayoutOfAfterLogin(<SiteUsersList />)}
      />
      <Route
        path="/add-site-user"
        exact
        element={LayoutOfAfterLogin(<AddSiteUsers />)}
      />
      <Route
        path="/edit-site-user/:user_id"
        exact
        element={LayoutOfAfterLogin(<EditSiteUsers />)}
      />
      <Route
        path="/manage-institution-users"
        exact
        element={LayoutOfAfterLogin(<InstitutionUsersList />)}
      />
      <Route
        path="/add-institution-user"
        exact
        element={LayoutOfAfterLogin(<AddInstitutionUsers />)}
      />
      <Route
        path="/edit-institution-user/:user_id"
        exact
        element={LayoutOfAfterLogin(<EditInstitutionUsers />)}
      />
      <Route
        exact
        path="/exam-dashboard"
        layout={LayoutOfAfterLogin}
        element={<ExamDashboard />}
      />
      <Route
        exact
        path="/exam-setup"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<ExamsSetup />)}
      />
      <Route
        exact
        path="/exam-student-prize-list"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<ExamStudentPrizeList />)}
      />
      <Route
        exact
        path="/exam-student-ranking-list"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<ExamStudentRankingList />)}
      />
      <Route
        exact
        path="/payment-detail-listing"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<PaymentDetailsListing />)}
      />
      <Route
        exact
        path="/exam-pat-setup"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<QuestionPatternSetup />)}
      />
      <Route
        exact
        path="/exam-pat-add-edit/:id"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<ExamPatternAddEdit />)}
      />
      <Route
        exact
        path="/student-appeared-for-exam"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<StudentAppearedForExam />)}
      />
      <Route
        exact
        path="/student-reg-list-by-exam"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<StudentRegListByExam />)}
      />
      <Route
        exact
        path="/student-score"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<StudentScore />)}
      />
      <Route
        exact
        path="/exam-listing"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<ExamsListing />)}
      />
      <Route
        exact
        path="/exam-setup-level1"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<ExamSetupLevel1 />)}
      />
      <Route
        exact
        path="/books"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookListing />)}
      />
      <Route
        exact
        path="/book/add"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookAdd />)}
      />
      <Route
        exact
        path="/book/edit/:book_id"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookAdd />)}
      />
      <Route
        exact
        path="/book/review"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookReviewList />)}
      />
      <Route
        exact
        path="/book/review/add"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookReviewAdd />)}
      />
      <Route
        exact
        path="/book/review/edit/:id"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookReviewAdd />)}
      />
      <Route
        exact
        path="/book/chapters"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookChapterList />)}
      />
      <Route
        exact
        path="/book/chapter/add"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookChapterAdd />)}
      />
      <Route
        exact
        path="/book/chapter/edit/:id"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookChapterAdd />)}
      />
      <Route
        exact
        path="/book/podcast"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookPodcastList />)}
      />
      <Route
        exact
        path="/book/podcast/add"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookPodcastAdd />)}
      />
      <Route
        exact
        path="/book/podcast/edit/:id"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookPodcastAdd />)}
      />
      <Route
        exact
        path="/book/video"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookVideoList />)}
      />
      <Route
        exact
        path="/book/video/add"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookVideoAdd />)}
      />
      <Route
        exact
        path="/book/video/edit/:id"
        layout={LayoutOfAfterLogin}
        element={LayoutOfAfterLogin(<BookVideoAdd />)}
      />
      <Route
        path="/digital-atlas/listing"
        exact
        element={LayoutOfAfterLogin(<DigitalAtlasList />)}
      />
    </Routes>
  );
}

export default App;
