import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Plus from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import API from '../API';

import LessonAdd from './LessonAdd';
import LessonEdit from './LessonEdit';

const Lessons = (props) => {
  
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };

    const [activeSection, setActiveSection] = useState(1);
    const [show, setShow] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(0);
    const [editLesson, setEditLesson] = useState();
   const blankLesson = {
       course_id: props.id,
       section_name: '',
       section_seq: '',
       no_of_lessons: ''
   }
   
   const[lessonData, setLessonData] = useState([]); 
   

   useEffect(()=>{
        getEntries()
   }, [])

   const getEntries = async() =>{
    await axios
    .get(site_ip + '/getCourseLessons/'+props.id, jwtHeader)
    .then(function (response) {
        if(response.data.Lessons.length !== 0){
            setLessonData(response.data.Lessons)
        }else{
            setLessonData([])
        }
    })
    .catch(function (error) {
        console.log('error' + error);
    });
   }

   


   const handleClose = () => setShow(false);

   const removeRecord = async() => {
      handleClose();
        await axios
        .get(site_ip + '/deleteCourseSections/'+selectedRecord, jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Section Details removed successfully',
                icon: 'success',
            });
            getEntries();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (section_id, index) =>{
        if(section_id === undefined){
            let all_ips = [...lessonData];
            all_ips.splice(index, 1);
            setLessonData(all_ips);
        }else{
            // Need to hit API
            setSelectedRecord(section_id);
            setShow(true);
        }
   }

   const showCompleteList = () =>{
    setActiveSection(1)
    getEntries()
   }

   return (
    <Aux>
      <div>  
        <Row>
            <Col className="text-end">
                {activeSection === 1 ?
                <Button variant="dark" onClick={()=> setActiveSection(2)}>
                   <Plus /> New Lesson
                </Button> : null }
                {activeSection === 2 || activeSection === 3 ?
                <Button variant="dark" onClick={()=> setActiveSection(1)}>
                   Lesson List
                </Button> : null }
            </Col>
        </Row>
        <Row className="rangeBox">
            {activeSection === 3 ? 
            <Col>
                <LessonEdit course_id={props.id} item={editLesson} showCompleteList={() => showCompleteList()} showList={()=> activeSection(1)} />
            </Col> : null }
            {activeSection === 2 ? 
            <Col>
                <LessonAdd course_id={props.id} showCompleteList={() => showCompleteList()} showList={()=> activeSection(1)} />
            </Col> : null }
            {activeSection === 1 ?   
            <Col>
                <TableHeader />
                {lessonData.map((item, index)=>{
                    return(
                        <LessonItem key={index} item={item} editLesson={() => {setEditLesson(item); setActiveSection(3)}} deleteEntry={(section_id) => deleteEntry(section_id, index)}/>
                    )
                })}
            </Col> : null }
        </Row>
        </div>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}

const TableHeader = () =>{

    return(
        <Row className="tableRow" style={{paddingTop:"5px", paddingBottom:"5px"}}>
            {/* <Col xs={2} style={{fontWeight:'bold', paddingLeft:"20px"}}>
                Course
            </Col> */}
            <Col xs={5} style={{fontWeight:'bold'}}>
                Lesson Name
            </Col>
            <Col xs={5} style={{fontWeight:'bold'}}>
                Section Name
            </Col>
            <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'center', fontWeight:'bold', }}>
                Actions    
            </Col>
        </Row>
    )
}

const LessonItem = (props) =>{
    const [item, setItem] = useState(props.item);

    useEffect(()=>{
        setItem(props.item)
    }, [props.item])
    
    return(
        <Row className="tableRow">
            <Col xs={5}>
                <p>{item.lesson_name}</p>
            </Col>
            <Col xs={5}>
                <p>{item.section_name}</p>
            </Col>
            <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Button variant="success" className="rowActionButton" onClick={()=> props.editLesson()}>
                   <EditIcon />
                </Button>
                <Button variant="danger" className="rowActionButton" onClick={()=> props.deleteEntry(item?.section_id)}>
                   <DeleteForeverIcon />
                </Button>    
            </Col>
        </Row>
    )
}

export default Lessons 
