let menu_items;
menu_items = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'menu1',
          title: 'Dashboard',
          url: '/admin/dashboard',
        },
        {
          id: 'menu2',
          title: 'Master',
          children: [
            {
              id: 'submenu1',
              title: 'Categories',
              url: '/admin/category-listing',
            },
            {
              id: 'submenu2',
              title: 'Subjects',
              url: '/admin/subject-listing',
            },
            {
              id: 'submenu3',
              title: 'Topics',
              url: '/admin/topic-listing',
            },
            {
              id: 'submenu4',
              title: 'Questions Tag',
              url: '/admin/questiontag-listing',
            },
            {
              id: 'submenu5',
              title: 'Skills',
              url: '/admin/skill-listing',
            },
            {
              id: 'submenu6',
              title: 'Units',
              url: '/admin/unit-listing',
            },
            {
              id: 'submenu7',
              title: 'Concepts',
              url: '/admin/concept-listing',
            },
            {
              id: 'submenu8',
              title: 'Publisher',
              url: '/admin/manage-publishers',
            },
            {
              id: 'submenu9',
              title: 'Editorial Board',
              url: '/admin/editorial-board-listing',
            },
            {
              id: 'submenu10',
              title: 'Authors',
              url: '/admin/authors',
            },
          ],
        },
        {
          id: 'menu15',
          title: 'Institution',
          children: [
            {
              id: 'submenu151',
              title: 'Institution List',
              url: '/admin/manage-institutions',
            },
            {
              id: 'submenu152',
              title: 'Institution Users',
              url: '/admin/manage-institution-users',
            },
            // {
            //   id: 'submenu153',
            //   title: 'IP Addresses',
            //   url: '/admin/manage-institutions-ipaddressess',
            // },
            {
              id: 'submenu154',
              title: 'Usage Reports',
              url: '/admin/manage-institutions-subscriptions',
            },
          ],
        },
        {
          id: 'menu5',
          title: 'Medical Cases',
          children: [
            {
              id: 'submenu1',
              title: 'Search and Edit',
              url: '/admin/medical-case',
            },
            {
              id: 'submenu2',
              title: 'Create Medical Case',
              url: '/admin/create-medical-case',
            },
          ],
        },
        {
          id: 'menu6',
          title: 'Expert Commentary',
          children: [
            {
              id: 'submenu1',
              title: 'Search and Edit',
              url: '/admin/commentary',
            },
            {
              id: 'submenu2',
              title: 'Add Commentary',
              url: '/admin/add-commentary',
            },
          ],
        },
        {
          id: 'menu12',
          title: 'News',
          children: [
            {
              id: 'submenu1',
              title: 'Search and Edit',
              url: '/admin/news',
            },
            {
              id: 'submenu2',
              title: 'Create News',
              url: '/admin/create-news',
            },
          ],
        },
        {
          id: 'menu4',
          title: 'Webinar',
          children: [
            {
              id: 'submenu1',
              title: 'Search and Edit',
              url: '/admin/webinars',
            },
            {
              id: 'submenu2',
              title: 'Add Webinar',
              url: '/admin/add-webinar',
            },
          ],
        },
        {
          id: 'menu3',
          title: 'Courses',
          children: [
            {
              id: 'submenu1',
              title: 'Courses',
              url: '/admin/courses',
            },
            {
              id: 'submenu2',
              title: 'Faculty',
              url: '/admin/faculty-listing',
            },
            // {
            //   id: 'submenu3',
            //   title: 'Modules',
            //   url: '#',
            // },
            {
              id: 'submenu4',
              title: 'Videos',
              url: '/admin/videos',
            },
          ],
        },
        {
          id: 'menu7',
          title: 'Questions',
          children: [
            {
              id: 'submenu1',
              title: 'Question Listing',
              url: '/admin/question-listing',
            },
            {
              id: 'submenu2',
              title: 'Add Question',
              url: '/admin/question',
            },
            {
              id: 'submenu3',
              title: 'OSCE Questions',
              url: '/admin/osce-question-listing',
            },
            {
              id: 'submenu4',
              title: 'Add OSCE Question',
              url: '/admin/osce-question',
            },
          ],
        },
        {
          id: 'menu8',
          title: 'Exams',
          children: [
            {
              id: 'submenu1',
              title: 'Exam Pattern Setup',
              url: '/admin/exam-pat-setup',
            },
            {
              id: 'submenu2',
              title: 'Exams Setup',
              url: '/admin/exam-setup-level1',
            },
            {
              id: 'submenu3',
              title: 'Exams Listing',
              url: '/admin/exam-listing',
            },
          ],
        },
        {
          id: 'menu18',
          title: 'Books',
          children: [
            {
              id: 'Books-List',
              title: 'Books',
              url: '/admin/books',
            },
            {
              id: 'Books-Review',
              title: 'Book Review',
              url: '/admin/book/review',
            },
            {
              id: 'Books-Chapters',
              title: 'Chapters',
              url: '/admin/book/chapters',
            },
            {
              id: 'Books-Podcast',
              title: 'Podcasts',
              url: '/admin/book/podcast',
            },
            {
              id: 'Books-Videos',
              title: 'Videos',
              url: '/admin/book/video',
            },
          ],
        },
        {
          id: 'menu9',
          title: 'Test Series',
          url: '/admin/test-series',
        },
        {
          id: 'menu10',
          title: 'Users',
          children: [
            {
              id: 'submenu1',
              title: 'Admin Users',
              url: '/admin/manage-admin-users',
            },
            {
              id: 'submenu2',
              title: 'Publisher Users',
              url: '/admin/manage-publisher-users',
            },
            {
              id: 'submenu3',
              title: 'Site Users',
              url: '/admin/manage-site-users',
            },
          ],
        },
        {
          id: 'menu11',
          title: 'Marketing',
          children: [
            {
              id: 'submenu1',
              title: 'Sitemap generation',
              url: '#',
            },
            {
              id: 'submenu2',
              title: 'Feedback Questions',
              url: '/admin/feedback-question-listing',
            },
            {
              id: 'submenu3',
              title: 'Email Templates',
              url: '/admin/email-template-listing',
            },
            {
              id: 'submenu4',
              title: 'Static Contents',
              url: '/admin/static-content-listing',
            },
          ],
        },
        {
          id: 'menu12',
          title: 'Others',
          children: [
            {
              id: 'submenu1',
              title: 'Reports',
              url: '#',
            },
            {
              id: 'submenu2',
              title: 'Analytics',
              url: '#',
            },
            {
              id: 'submenu3',
              title: 'Communication',
              url: '#',
            },
            {
              id: 'submenu4',
              title: 'Digital Atlas',
              url: '/admin/digital-atlas/listing',
            },
            // {
            //   id: 'submenu4',
            //   title: 'Marketing',
            //   url: '#',
            // },
          ],
        },
        {
          id: 'profile',
          title: 'Profile',
          children: [
            {
              id: 'submenu1',
              title: 'Profile',
              url: '#',
            },
            {
              id: 'logout',
              title: 'Logout',
              url: '/admin/logout',
            },
          ],
        },
      ],
    },
  ],
};

export default menu_items;
