import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table, Container, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import '../master.css';
import Aux from '../../../hoc/_Aux';
import API from '../../API';
import {tableCustomStyles} from "../../../customTableStyle";
import DataTable from 'react-data-table-component';
export default function CategoryList(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);


  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const editButton = (id) =>{
    navigate('/admin/category-edit/'+id)
  }


  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        category_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deleteCategories',data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            fetchCategories();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   } 

   const columns = [
    {
      name: 'Category',
      selector: row => row.category_name,
    },
    {
      name: 'Active',
      selector: row => row.is_active ? 'Yes' : 'No',
    },
    {
      name: 'Action',
      width: "120px",
      cell: (row) => (
        <div style={{ paddingTop: "5px", paddingBottom: "5px", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Button variant="success" className="rowActionButton" onClick={()=> editButton(row.category_id)}>
            <EditIcon />
          </Button>
          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(row.category_id)}>
            <DeleteForeverIcon />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  return (
    <Aux>
      <Container fluid>
        <Row className="align-items-center" style={{marginBottom:"10px"}}>
          <Col md={6}>
            <h4>Category List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button variant="dark" onClick={() => navigate('/admin/category-add')}>
              Add Category
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{height:"15px"}} />
            <DataTable
              customStyles={tableCustomStyles}
              columns={columns}
              data={categories}
              pagination
              dense
            />
          </Col>
        </Row>
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
