import React, {useState, useEffect} from "react";
import "./Exam.css";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import Aux from "../../hoc/_Aux";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {Redirect} from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const ExamSetupLevel1 = (props) => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };

    const navigate = useNavigate();
    const emptyExam = {
        grade_id: "",
        exam_type: "",
        exam_name: "",
        exam_instructions: "",
        exam_objectives: "",
        exam_image: "",
        questions_count: "",
        ct_exam_pattern_id: "",
        exam_date: "",
        start_time: "",
        end_time: "",
        result_date: "",
        auto_select_yn: "",
        complexity_level: "",
        exam_free_paid_ind: "",
        exam_price: "",
        exam_url: "",
        registration_open_date_f: "",
        registration_open_date_t: "",
        custom_exam_pattern: "",
        question_ids: "",
        status: "",
        result_schedule_ran_yn: "",
        allow_review_yn: "",
        allow_instant_score_yn: "",
        negative_marking_yn: "",
        time_for_each_question: "",
        shuffle_ques_yn: "",
        enable_unique_ques_for_s: "",
        capture_candidate_photo: "",
        remote_proctoring_yn: "",
    }

    const [item, setItem] = useState(emptyExam);
    const [classes, setClasses] = useState([]);
    const [getInsertedId, setGetInsertedId] = useState('')

   const getExams = async () => {
       await axios.get(`https://nodeapi.uniqtoday.com/api/getGrades`, jwtHeader)
           .then((response) => {
               // handle success
               setClasses(response.data.content)
           }).catch((error) => {
               // handle error
               console.log(error);
           })
   }


    useEffect( () => {
        getExams();
    }, [])

    const handleChange = (e) => {
        setItem({...item, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await fetch((item.ct_exam_id) ? 'https://nodeapi.uniqtoday.com/api/setExamsSchedule' : 'https://nodeapi.uniqtoday.com/api/addExamsSchedule', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem('admin_token')
            },
            body: JSON.stringify(item),
        }).then(response => response.json())
            .then(data => {
                navigate('/exam-setup',
                    {state: {grade_id: item.grade_id, getInsertedIds: data.ct_exam_id, questionCount: item.questions_count}
                })
            })


    }

    let gardeData;
    if (classes) {
        gardeData = classes.map((res, index) => (
            <option value={res.id}>{res.class_exam_cd}</option>
        ))
    }
    return (
        <Aux>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5"/>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row className="col-md-12">
                                    <ul className="events">
                                        <li>
                                            <time className="col-md-3">
                                                <div className="arrow-steps clearfix">
                                                    <div className="step current"><span> Basics : </span></div>
                                                </div>
                                            </time>
                                            <span className="col-md-9 row">
                                            <Col md={6}>

                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>Exam : </Form.Label>
                                                        <Form.Control as="select" name="grade_id" required onChange={e => {
                                                            handleChange(e)
                                                        }} rules={{ required: true }}>
                                                            <option>Select Exam</option>
                                                            {gardeData}
                                                        </Form.Control>
                                                    </Form.Group>
                                            </Col>
                                             <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Allow Instant Score view : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="allow_instant_score_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio21"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="allow_instant_score_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio22"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Allow Candidate to review Exam : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="allow_review_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio23"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="allow_review_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio24"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Negative Marking : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="negative_marking_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio25"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="negative_marking_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio26"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                            <Col md={6}>
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>Exam Type: </Form.Label>
                                                        <Form.Control as="select" name="exam_type" onChange={e => {
                                                            handleChange(e)
                                                        }}>
                                                            <option>Select Exam Type</option>
                                                            <option value="Scholarship">Scholarship</option>
                                                            <option value="Assignment">Assignment</option>
                                                            <option value="TestSeries">TestSeries</option>
                                                            <option value="MockTest">MockTest</option>
                                                            <option value="Competition">Competition</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                            </Col>
                                                <Col md={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <Form.Label>Exam Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Exam Name" name="exam_name"
                                                                  onChange={e => {
                                                                      handleChange(e)
                                                                  }}/>
                                                </Form.Group>
                                            </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Exam Date</Form.Label>
                                            <Form.Control type="date" placeholder="Exam Date" name="exam_date"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}/>
                                        </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Start Time</Form.Label>
                                            <Form.Control type="time" placeholder="Start Time" name="start_time"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }} step={2}/>
                                        </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>End Time</Form.Label>
                                            <Form.Control type="time" placeholder="End Time" name="end_time"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }} step={2}/>
                                        </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Result Date</Form.Label>
                                            <Form.Control type="date" placeholder="Result Date" name="result_date"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}/>
                                        </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Exam Price</Form.Label>
                                            <Form.Control type="text" placeholder="Exam Price" name="exam_price"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}/>
                                        </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Registration Start Date</Form.Label>
                                            <Form.Control type="date" name="registration_open_date_from"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}/>
                                        </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Registration End Date</Form.Label>
                                            <Form.Control type="date" name="registration_open_date_to" onChange={e => {
                                                handleChange(e)
                                            }}/>
                                        </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Exam Image</Form.Label>
                                            <Form.Control type="file" name="exam_image" onChange={e => {
                                                handleChange(e)
                                            }}/>
                                        </Form.Group>
                                                </Col>
                                        </span>
                                        </li>
                                        <hr/>
                                        <li>
                                            <time className="col-md-3">
                                                <div className="arrow-steps clearfix">
                                                    <div className="step current"><span> Question Type : </span>
                                                    </div>
                                                </div>
                                            </time>
                                            <span className="col-md-9 row">
                                           <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Timer for Each Question : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="time_for_each_question" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio27"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="time_for_each_question" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio28"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Enable unique question for each candidate : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="enable_unique_ques_for_student" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio29"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="enable_unique_ques_for_student" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio30"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Shuffle question : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="shuffle_ques_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio31"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="shuffle_ques_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio32"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="formBasicPassword">
                                                        <Form.Label>Question Count</Form.Label>
                                                        <Form.Control type="number" placeholder="Question Count"
                                                                      name="questions_count" onChange={e => {
                                                            handleChange(e)
                                                        }}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>Complexity Level</Form.Label>
                                                        <Form.Control as="select" name="complexity_level"
                                                                      onChange={e => {
                                                                          handleChange(e)
                                                                      }}>
                                                            F
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Auto Select : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="auto_select_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio37"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="auto_select_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio38"
                                                                value="No"
                                                            />
                                                    </Form.Group>
                                                </Col>
                                        </span>
                                        </li>
                                        <hr/>
                                        <li>
                                            <time className="col-md-3">
                                                <div className="arrow-steps clearfix">
                                                    <div className="step current"><span> Proctoring : </span></div>
                                                </div>
                                            </time>
                                            <span className="col-md-9 row">
                                             <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Enable Remote Proctoring : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="remote_proctoring_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio33"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                nname="remote_proctoring_yn" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio34"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                                 <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Capture Candidate Photo : </Form.Label><br/>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="Yes"
                                                                name="capture_candidate_photo" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio35"
                                                                value="Yes"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                type="radio"
                                                                label="No"
                                                                name="capture_candidate_photo" onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                                id="supportedRadio36"
                                                                value="No"
                                                            />
                                                </Form.Group>
                                             </Col>
                                        </span>
                                        </li>
                                        <hr/>
                                        <li>
                                            <time className="col-md-3">
                                                <div className="arrow-steps clearfix">
                                                    <div className="step current"><span> About Exam : </span></div>
                                                </div>
                                            </time>
                                            <span className="col-md-9 row">
                                            <Col md={6}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam Objective</Form.Label>
                                            <CKEditor
                                                editor={Editor}
                                                data=""
                                                name="exam_objectives" 
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log("Editor is ready to use!", editor);
                                                }}
                                                // onChange={(event, editor) => {
                                                //     const data = editor.getData();
                                                //     console.log({event, editor, data});
                                                // }}
                                                onBlur={(event, editor) => {
                                                    console.log("Blur.", editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    console.log("Focus.", editor);
                                                }}
                                            />
                                        </Form.Group>

                                </Col>
                                <Col md={6}>

                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam Instruction</Form.Label>
                                            <CKEditor
                                                editor={Editor}
                                                data=""
                                                name="exam_instructions" 
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log("Editor is ready to use!", editor);
                                                }}
                                                // onChange={(event, editor) => {
                                                //     const data = editor.getData();
                                                //     console.log({event, editor, data});
                                                // }}
                                                onBlur={(event, editor) => {
                                                    console.log("Blur.", editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    console.log("Focus.", editor);
                                                }}
                                            />
                                        </Form.Group>
                                        </Col>
                                        </span>
                                        </li>
                                    </ul>
                                </Row>
                                <Col>
                                    <div align="right">
                                        <Button type="submit" align="left" variant="success">Next</Button>
                                    </div>
                                </Col>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Aux>
    );
}

export default ExamSetupLevel1;