import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
export default function VideoList(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'All']

  const [show, setShow] = useState(false);

  const blankValues = {
    institution_name: "",
    institution_code: "",
    subscription_id: ""
  }

  const [values, setValues] = useState(blankValues);
  const [confirmationModal, setConfirmationModal] = useState(0);
  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    getVideoList();
  }, [])

  const [videoList, setVideoList] = useState([]);
  const getVideoList = () => {
    axios
      .get(site_ip + '/getVideoDatabase', jwtHeader)
      .then((res) => {
        setVideoList(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setConfirmationModal(0)
  }

  const deleteRecord = () => {
    let req = { video_id: confirmationModal };
    axios.post(site_ip + "/deleteVideoDatabase", req, jwtHeader)
      .then((res) => {
        getVideoList();
        setConfirmationModal(0)
      }).catch((err) =>
        console.log(err)
      )
  }

  const columns = [
    {
      name: 'Video Number',
      selector: row => row.video_number,
    },
    {
      name: 'Video Title',
      selector: row => row.video_title,
    },
    {
      name: 'File',
      selector: row => <a href={row.video_url}>Download</a>,
    },
    {
      name: 'Access Type',
      selector: row => row.access_type_id,
    },
    {
      name: 'Downloadable',
      selector: row => row.downloadable === "Y" ? "Yes" : "No",
    },
    {
      name: 'Action',
      cell: (row) => (
        // <div style={{ paddingTop: "5px", paddingBottom: "5px", display:"flex" }}>
        //   <button style={{ padding: "5px", marginRight:"5px" }} onClick={() => clickHandler(row.video_id)} className="primary-plus-button">
        //     <EditIcon style={{ fontSize: "20px" }} />
        //   </button>
        // </div>
        <>
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <button style={{ padding: "5px" }} onClick={() => clickHandler(row.video_id)} className="primary-plus-button">
              <EditIcon style={{ fontSize: "20px" }} />
            </button>
          </div>
          <div style={{ paddingTop: "5px", paddingBottom: "5px", marginLeft: "10px" }}>
            <button style={{ padding: "5px", backgroundColor: "#ff4d4d" }} onClick={() => setConfirmationModal(row.video_id)} className="primary-plus-button">
              <DeleteOutlineIcon style={{ fontSize: "20px" }} />
            </button>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/book/video/edit/' + id);
  }

  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Video List</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/book/video/add')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add Video</button>
          </Col>
        </Row>
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={videoList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}