import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import { useNavigate, useParams } from 'react-router-dom';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { fileUpload } from '../../../Common/fileUploads';

export default function CreateBook(props) {
    const navigate = useNavigate();
    const params = useParams();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
     };

    const blankValues = {
        book_title: "",
        book_sub_title: "",
        book_logo: "",
        isbn: "",
        doi: "",
        publisher_id: "",
        category_id: "",
        subject_id: "",
        publish_status: "Staging",
        language_id: "",
        access_type_id: "Free",
        volume: "",
        book_overview: "",
        book_suppl_info: "",
        edition: "",
        no_of_volumes: "",
        book_type: "",
        show_chapters: "N",
        no_of_chapters: "",
        featured: "N",
        publishing_year: "",
        no_of_pages: "",
        bisac: "",
        online_publish_date: "",
        print_publish_date: "",
        display_status: "",
        downloadable: "Y",
        copyright_details: "",
        dedication: "",
        foreward: "",
        new_release_ind: "N",
        preface: "",
        book_desc: "",
        primary_authors: "",
        content_type_id: "",
        price: "",
        created_by: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);
    const [newBookId, setNewBookId] = useState(0);
    // Fields
    const [copyright, setCopyright] = useState("");
    const [overview, setOverview] = useState("");
    const [supplyinfo, setSupplyInfo] = useState("");
    const [bookLogo, setBookLogo] = useState(null);
    const handleChange = (e) => {
        let { name, value } = e.target;
        let data = { ...values }
        if (name === "journal_id") {
            data[name] = value;
            data["volume_id"] = "";
            data["issue_id"] = "";
            setValues(data);
        } else if (name === "volume_id") {
            data[name] = value;
            data["issue_id"] = "";
            setValues(data);
        } else {
            data[name] = value;
            setValues(data)
        }
    }

    const handleChangeByName = (name, value) => {
        let data = { ...values }
        data[name] = value;
        setValues(data)
    }

    useEffect(() => {
        getCategoriesList();
        getContentTypeList();
        getLanguageListing();
        getSubjectListing();
        getPublishers();
        if (params.book_id !== undefined) {
            getBookById(params.book_id)
        }
    }, [])

    const getBookById = (id) => {
        axios
            .get(site_ip + '/getBookById/'+id, jwtHeader)
            .then((res) => {
                setValues(res.data.Books);
                setOverview(res.data.Books.book_overview === null ? "" : res.data.Books.book_overview);
                setCopyright(res.data.Books.copyright_details === null ? "" : res.data.Books.copyright_details)
                setSupplyInfo(res.data.Books.book_suppl_details === null ? "" : res.data.Books.book_suppl_details)

            })
            .catch((err) => console.log(err));
    };

    const [publishersList, setPublisersList] = useState([]);
    const getPublishers = () => {
        let req = {}
        axios
        .get(site_ip + '/getPublishers', req, jwtHeader)
        .then((res) => {
            setPublisersList(res.data.Data);
        })
        .catch((err) => console.log(err));
    };

    const [contentTypeList, setContentTypeList] = useState([]);
    const getContentTypeList = () => {
        axios
            .get(site_ip + '/getContentType', jwtHeader)
            .then((res) => {
                setContentTypeList(res.data.Contents);
            })
            .catch((err) => console.log(err));
    };

    const [categoriesList, setCategoriesList] = useState([]);
    const getCategoriesList = () => {
        axios
            .get(site_ip + '/getCategories', jwtHeader)
            .then((res) => {
                setCategoriesList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const [languageListing, setLanguageListing] = useState([]);
    const getLanguageListing = () => {
        axios
            .get(site_ip + '/getLanguages', jwtHeader)
            .then((res) => {
                setLanguageListing(res.data.Contents);

            })
            .catch((err) => console.log(err));
    };

    const [subjectListing, setSubjectListing] = useState([]);
    const getSubjectListing = () => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectListing(res.data.content);

            })
            .catch((err) => console.log(err));
    };

    const validateYear = (mobile) => {
        var phoneno = /^\d{4}$/;
        if (String(mobile).match(phoneno)) {
          return true;
        } else {
          return false;
        }
      };

    const onSubmit = async() => {
        let req = { ...values, book_overview: overview, copyright_details: copyright, book_suppl_details: supplyinfo };

        if (req.access_type_id === "") {
            setErrorMessage("Please select access type.")
            return;
        } else if (req.content_type_id === "") {
            setErrorMessage("Please select content type.")
            return;
        } else if (req.category_id === "") {
            setErrorMessage("Please select category.")
            return;
        } else if (req.subject_id === "") {
            setErrorMessage("Please select subject.")
            return;
        } else if (req.publisher_id === "") {
            setErrorMessage("Please select publisher.")
            return;
        } else if (req.isbn.trim() === "") {
            setErrorMessage("Please add Book ISBN.")
            return;
        } else if (req.book_title.trim() === "") {
            setErrorMessage("Please add Book title.")
            return;
        }else if (!validateYear(req.publishing_year)) {
            setErrorMessage("Please add valid publishing year.")
            return;
        }else if (req.book_type === "") {
            setErrorMessage("Please select Book type.")
            return;
        }else if (req.bisac.trim() === "") {
            setErrorMessage("Please add bisac.")
            return;
        }

        if(bookLogo !== null){
            let fileUrl = await fileUpload(bookLogo[0])
            req = { ...req, book_logo: fileUrl }
        }
        // else if (req.license_type_id === "") {
        //     setErrorMessage("Please select license type.")
        //     return;
        // } 

        if (req.book_id === undefined) {
            axios
                .post(site_ip + '/createBook', req, jwtHeader)
                .then((res) => {
                    setNewBookId(res.data.Content.book_id)
                    setSuccessMessage("New book added successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setBook', req, jwtHeader)
                .then((res) => {
                    setSuccessMessage("Book details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        if (values.book_id === undefined) {
            props.moveToNextStep(newBookId);
        }
        // navigate("/view-issue")
    }


    return (
        <Aux>
            <div className='new-content-section'>
                <div>
                    <Row>
                        <Col>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                Access Type <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.access_type_id}
                                                    as="select"
                                                    name="access_type_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="Free">Free</option>
                                                    <option value="Paid">Paid</option>
                                                    {/* {accessListing.map((item)=>{
                                                        return(
                                                            <option value={item.access_type_id} key={item.access_type_id}>{item.access_type}</option>
                                                        )
                                                    })} */}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Book Content Type <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.content_type_id}
                                                    as="select"
                                                    name="content_type_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {contentTypeList?.map((value, index) => (
                                                        <option value={value.content_type_id} key={index}>
                                                            {value.content_type_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Category <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.category_id}
                                                    as="select"
                                                    name="category_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {categoriesList?.map((value, index) => (
                                                        <option value={value.category_id} key={index}>
                                                            {value.category_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Subject <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.subject_id}
                                                    as="select"
                                                    name="subject_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {subjectListing?.map((value, index) => (
                                                        <option value={value.subject_id} key={index}>
                                                            {value.subject_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Publisher <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.publisher_id}
                                                    as="select"
                                                    name="publisher_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {publishersList?.map((value, index) => (
                                                        <option value={value.publisher_id} key={index}>
                                                            {value.publisher_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Language
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.language_id}
                                                    as="select"
                                                    name="language_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {languageListing?.map((value, index) => (
                                                        <option value={value.language_id} key={index}>
                                                            {value.language_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>ISBN <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.isbn}
                                                    name="isbn"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Book Title <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.book_title}
                                                    name="book_title"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Book Subtitle</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.book_sub_title}
                                                    name="book_sub_title"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>DOI</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.doi}
                                                    name="doi"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Book Cover Image</div></td>
                                        <td >
                                            <div className="row" style={{margin:0}}>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                        type="file"
                                                        accept="image/png, image/jpg, image/jpeg"
                                                        name="cover_image1"
                                                        onChange={(e) => setBookLogo(e.target.files)}
                                                    />
                                                    (Allowed formats: .jpg, .jpeg or .png; Recommend pixel size:[370,50])
                                                </div>
                                                <div className="col-md-5">
                                                    {values?.book_logo === null || values?.book_logo === "" ? null :
                                                        <Image src={values?.book_logo} style={{ height: "50px", width: "50px" }} />}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><div>Edition</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.edition}
                                                    name="edition"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Featured
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={values?.featured === 'Y' ? true : false}
                                                    onChange={() => handleChangeByName("featured", values?.featured === 'Y' ? "N" : "Y")}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>No Of Chapters</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="number"
                                                    value={values?.no_of_chapters}
                                                    name="no_of_chapters"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Publishing Year <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="number"
                                                    value={values?.publishing_year}
                                                    name="publishing_year"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>No of Pages</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="number"
                                                    value={values?.no_of_pages}
                                                    name="no_of_pages"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>No of Volumes</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="number"
                                                    value={values?.no_of_volumes}
                                                    name="no_of_volumes"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Book Type <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.book_type}
                                                    as="select"
                                                    name="book_type"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="Reference">Reference</option>
                                                    <option value="Professional">Professional</option>
                                                    <option value="Textbook">Textbook</option>
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Book Bisac <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.bisac}
                                                    name="bisac"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Publish Status <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.publish_status}
                                                    as="select"
                                                    name="publish_status"
                                                    onChange={handleChange}
                                                >
                                                    <option value="Staging">Staging</option>
                                                    <option value="Live">Live</option>
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Book Description
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <Form.Control
                                                    as={"textarea"}
                                                    value={values?.book_desc}
                                                    name="book_desc"
                                                    row={4}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Preface
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <Form.Control
                                                    as={"textarea"}
                                                    value={values?.preface}
                                                    name="preface"
                                                    row={4}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Dedication
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <Form.Control
                                                    as={"textarea"}
                                                    value={values?.dedication}
                                                    name="dedication"
                                                    row={4}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Forward
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <Form.Control
                                                    as={"textarea"}
                                                    value={values?.foreward}
                                                    name="foreward"
                                                    row={4}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Rating
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.rating}
                                                    as="select"
                                                    name="rating"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Download Enable
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={values?.downloadable === 'Y' ? true : false}
                                                    onChange={() => handleChangeByName("downloadable", values?.downloadable === 'Y' ? "N" : "Y")}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Show Chapters
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={values?.show_chapters === 'Y' ? true : false}
                                                    onChange={() => handleChangeByName("show_chapters", values?.show_chapters === 'Y' ? "N" : "Y")}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                New Release
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={values?.new_release_ind === 'Y' ? true : false}
                                                    onChange={() => handleChangeByName("new_release_ind", values?.new_release_ind === 'Y' ? "N" : "Y")}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Book Overview</div></td>
                                        <td >
                                            <div style={{maxWidth:"calc(100% - 5px)"}}>
                                                <CKEditor
                                                    editor={Editor}
                                                    data={overview === null || overview === undefined ? "" : overview}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setOverview(data)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Supplementary information</div></td>
                                        <td >
                                            <div style={{maxWidth:"calc(100% - 5px)"}}>
                                                <CKEditor
                                                    editor={Editor}
                                                    data={supplyinfo === null || supplyinfo === undefined ? "" : supplyinfo}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setSupplyInfo(data)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Copyright Details</div></td>
                                        <td>
                                            <div style={{maxWidth:"calc(100% - 5px)"}}>
                                                <CKEditor
                                                    style={{width:"100%"}}
                                                    editor={Editor}
                                                    data={copyright === null || copyright === undefined ? "" : copyright}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setCopyright(data)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Print Publish Date</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="date"
                                                    value={values?.print_publish_date}
                                                    name="print_publish_date"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Online Publish Date</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="date"
                                                    value={values?.online_publish_date}
                                                    name="online_publish_date"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "8px" }}>

                        <div style={{ display: "flex" }}>
                            <button onClick={() => onSubmit()} className="primary-plus-button">{values.book_id === undefined ? "Add" : "Update"} Book</button>
                            <div className='spacer' />
                            <button onClick={() => navigate('/manage-book')} className="secondary-button">Cancel</button>
                        </div>
                    </div>
                    <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                    <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
                </div>
            </div>
        </Aux>
    );
}
