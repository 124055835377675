import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
import { fileUpload } from '../../Common/fileUploads';
import SuccessModal from '../../Common/SuccessModal';
import ErrorModal from '../../Common/ErrorModal';
export default function ManageVideo(props) {
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        video_desc: "",
        video_title: "",
        book_id: "",
        chapter_id: "",
        thumbnail_name: "",
        file_name: "",
        video_number: "",
        video_url: "",
        file_size: "",
        video_desc: "",
        duration: "",
        access_type_id: "Paid",
        video_for: "Book",
        subject_id: "",
        journal_id: "",
        issue_id: "",
        proxy_url: "",
        volume_id: "",
        downloadable: "Y",
        created_by: ""
    }
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailFile, setThumbnail] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);
    const [newId, setNewId] = useState(0);
    const handleChange = (e) => {
        let { name, value } = e.target;
        let data = { ...values }
        if (name === "book_id") {
            data[name] = value;
            data["chapter_id"] = "";
            setValues(data);
            getBookChapters(value)
        } else {
            data[name] = value;
            setValues(data)
        }
    }

    const handleChangeByName = (name, value) => {
        let data = { ...values }
        data[name] = value;
        setValues(data)
    }

    useEffect(() => {
        if (props.videoId !== 0) {
            getVideoById(props.videoId)
        }
        getBooks();
    }, [])
    
    const getVideoById = (id) => {
        axios
            .get(site_ip + '/getVideoById/' + id, jwtHeader)
            .then((res) => {
                setValues(res.data.Content);
                getBookChapters(res.data.Content.book_id)
            })
            .catch((err) => console.log(err));
    };

    const [bookList, setBooksList] = useState([]);
    const getBooks = () => {
        let new_filter = {}
        axios
            .post(site_ip + '/getBookSearch', new_filter, jwtHeader)
            .then((res) => {
                setBooksList(res.data.Books);
            })
            .catch((err) => console.log(err));
    };
    const [chapterList, setChapterList] = useState([]);
    const getBookChapters = (id) => {
        axios
            .get(site_ip + '/getBookChapter/' + id, jwtHeader)
            .then((res) => {
                setChapterList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const calculateMBs = (size) =>{
        return parseFloat(Math.round(size / (1024 * 1024), 2)).toFixed(2);
    }

    const onSubmit = async() => {
        
        let req = { ...values };
        if (req.video_title === "") {
            setErrorMessage("Please add video title.")
            return;
        } else if (req.book_id === "") {
            setErrorMessage("Please select book.")
            return;
        } else if (req.chapter_id === "") {
            setErrorMessage("Please select chapter.")
            return;
        } else if(req.video_id === undefined && videoFile === null){
            setErrorMessage("Please add video file.")
            return;
        }

        if (videoFile !== null) {
            let fileUrl = await fileUpload(videoFile[0])
            req = { 
                ...req, 
                video_url: fileUrl,
                file_size: calculateMBs(videoFile[0].size),
                file_name:videoFile[0].name
            }
        }

        if (thumbnailFile !== null) {
            let fileUrl = await fileUpload(thumbnailFile[0])
            req = { ...req, thumbnail_name: fileUrl }
        }


        

        if (req.video_id === undefined) {
            axios
                .post(site_ip + '/createVideoDatabase', req, jwtHeader)
                .then((res) => {
                    setNewId(res.data.Content.video_id)
                    setSuccessMessage("New video added successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setVideoDatabase', req, jwtHeader)
                .then((res) => {
                    setSuccessMessage("Video details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        if(values.video_id === undefined){
            props.moveToNextStep(newId)
        }
    }


    return (
        <Aux>
            <div className='new-content-section' style={{backgroundColor:"#f5f5f5"}}>
                <Row className="underline">
                    <Col className="page-title-container">
                    </Col>
                    <Col className="page-title-right">
                        <button onClick={() => navigate('/admin/book/video')} className="primary-plus-button">Video List</button>
                    </Col>
                </Row>
                <div>
                    <div style={{ paddingTop: "8px" }}>
                        <table className='table'>
                            <tbody>
                                <tr>
                                        <td>
                                            <div>
                                                Book
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.book_id}
                                                    as="select"
                                                    name="book_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {bookList.map((value, index) => (
                                                        <option value={value.book_id} key={index}>
                                                            {value.book_title}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Chapter
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.chapter_id}
                                                    as="select"
                                                    name="chapter_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {chapterList.map((value, index) => (
                                                        <option value={value.chapter_id} key={index}>
                                                            {value.chapter_title}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                <tr>
                                    <td><div>Video Title <span className="mandatory">*</span></div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.video_title}
                                                name="video_title"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Video Number</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.video_number}
                                                name="video_number"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Video File <span className="mandatory">* (or video URL)</span></div></td>
                                    <td >
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="file"
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    // accept="image/png, image/jpg, image/jpeg"
                                                    name="cover_image1"
                                                    onChange={(e) => setVideoFile(e.target.files)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Video URL</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                // readOnly
                                                type="text"
                                                value={values?.video_url}
                                                name="video_url"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Duration</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.duration}
                                                name="duration"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Proxy URL</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.proxy_url}
                                                name="proxy_url"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Video Thumbnail</div></td>
                                    <td >
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    name="cover_image1"
                                                    onChange={(e) => setThumbnail(e.target.files)}
                                                />
                                                (Allowed formats: .jpg, .jpeg or .png)
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>Access Type</div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.access_type_id}
                                                as="select"
                                                name="access_type_id"
                                                onChange={handleChange}
                                            >
                                                <option value="Free">Free</option>
                                                <option value="Paid">Paid</option>
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Downloadable
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5" style={{ display: "flex" }}>
                                            <Form.Check
                                                type="checkbox"
                                                checked={values?.downloadable === 'Y' ? true : false}
                                                onChange={() => handleChangeByName("downloadable", values?.downloadable === 'Y' ? "N" : "Y")}
                                            />
                                            Yes
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Description
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                as="textarea"
                                                placeholder=""
                                                name='description'
                                                value={values?.description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div style={{ display: "flex" }}>
                            <button onClick={() => onSubmit()} className="primary-plus-button">{values.video_id === undefined ? "Add" : "Update"} Video</button>
                            <div className='spacer' />
                            <button onClick={() => navigate('/admin/book/video')} className="secondary-button">Cancel</button>
                        </div>
                    </div>
                    <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                    <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
                </div>
            </div>
        </Aux>
    );
}
