import React, { useState, useEffect } from 'react';
import { Image, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import { useNavigate, useParams } from 'react-router-dom';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';
import { fileUpload } from '../../../Common/fileUploads';
export default function AddAuthor(props) {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        salutation: "Dr.",
        first_name: "",
        middle_name: "",
        last_name: "",
        author_phone: "",
        country_id: "",
        state_id: "",
        city_id: "",
        email: "",
        url: "",
        orcid: "",
        role_id: "",
        affiliations: "",
        display_status: "0",
        profile_details: "",
        profile_pic: "",
        created_by: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);

    const handleChange = (e) => {
        let { name, value } = e.target;
        let data = { ...values }
        if (name === "country_id") {
            data['country_id'] = value;
            data['state_id'] = "";
            data['city_id'] = ""
            getStateList(value)
        } else if (name === "state_id") {
            data['state_id'] = value;
            data['city_id'] = "";
            getCities(value);
        }
        else {
            data[name] = value;
        }
        setValues(data)
    }

    const [profilePic, setProfilePic] = useState(null);

    useEffect(() => {
        getCountryList();
    }, [])

    const [countryList, setCountryList] = useState([]);
    const getCountryList = () => {
        axios
            .get(site_ip + '/getCountries', jwtHeader)
            .then((res) => {
                setCountryList(res.data.Data);
            })
            .catch((err) => console.log(err));
    };

    const [stateList, setStateList] = useState([]);
    const getStateList = (id) => {
        axios
            .get(site_ip + '/getStateByCountry/' + id, jwtHeader)
            .then((res) => {
                setStateList(res.data.Data);
            })
            .catch((err) => console.log(err));
    };

    const [cityList, setCityList] = useState([]);
    const getCities = (id) => {
        axios
            .get(site_ip + '/getCities/' + id, jwtHeader)
            .then((res) => {
                setCityList(res.data.States);
            })
            .catch((err) => console.log(err));
    };

    const validateEmail = (mail) => {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                mail
            )
        ) {
            return true;
        }
        return false;
    };

    const phonenumber = (mobile) => {
        var phoneno = /^\d{10}$/;
        if (String(mobile).match(phoneno)) {
            return true;
        } else {
            return false;
        }
    };

    const onSubmit = async () => {
        let req = { ...values };
        if (req.first_name.trim() === "") {
            setErrorMessage("Please add first name.")
            return;
        } else if (req.last_name.trim() === "") {
            setErrorMessage("Please add last name.")
            return;
        } else if (!validateEmail(req.email)) {
            setErrorMessage("Please add a valid email.")
            return;
        } else if (!phonenumber(req.author_phone)) {
            setErrorMessage("Please add a valid contact number.")
            return;
        } else if (req.display_status === "") {
            setErrorMessage("Please select display status.")
            return;
        }
        
        if (profilePic !== null) {
            let fileUrl = await fileUpload(profilePic[0])
            req = { ...req, profile_pic: fileUrl }
        }
        
        axios
            .post(site_ip + '/createAuthor', req, jwtHeader)
            .then((res) => {
                props.onSuccess(res.data?.Content?.author_id)
            })
            .catch((err) => console.log(err));
    }

    return (
        <Aux>
            <div className='new-content-section'>
                <Row className="underline">
                    <Col className="page-title-container">
                        <p>{values.author_id === undefined ? "Add Author" : "Update Author"}</p>
                    </Col>
                    <Col className="page-title-right">
                        <button onClick={() => props.onClose()} className="primary-plus-button">Cancel</button>
                    </Col>
                </Row>
                <div>
                    <div style={{ paddingTop: "8px" }}>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td><div>First Name <span className="mandatory">*</span></div></td>
                                    <td>
                                        <div className='row' style={{ margin: "0px" }}>
                                            <div className="col-md-1" style={{padding:0}}>
                                                <Form.Control
                                                    value={values?.salutation}
                                                    as="select"
                                                    name="salutation"
                                                    onChange={handleChange}
                                                >
                                                    <option value="Dr.">Dr</option>
                                                    <option value="Mr.">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms.">Ms</option>
                                                </Form.Control>
                                            </div>
                                            <div className="col-md-4" style={{paddingRight:0}}>
                                                <Form.Control
                                                    type="text"
                                                    value={values?.first_name}
                                                    name="first_name"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Middle Name</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.middle_name}
                                                name="middle_name"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Last Name <span className="mandatory">*</span></div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.last_name}
                                                name="last_name"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Email <span className="mandatory">*</span></div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.email}
                                                name="email"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Phone <span className="mandatory">*</span></div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                maxLength={10}
                                                value={values?.author_phone}
                                                name="author_phone"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>ORCID</div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.orcid}
                                                name="orcid"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        <div>
                                            Role <span className="mandatory">*</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.role_id}
                                                as="select"
                                                name="role_id"
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {roleList.map((value, index) => (
                                                    <option value={value.role_id} key={index}>
                                                        {value.role_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr> */}
                                <tr>
                                    <td>
                                        <div>URL</div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                type="text"
                                                value={values?.url}
                                                name="url"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Country
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.country_id}
                                                as="select"
                                                name="country_id"
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {countryList.map((value, index) => (
                                                    <option value={value.country_id} key={index}>
                                                        {value.country_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            State
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.state_id}
                                                as="select"
                                                name="state_id"
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {stateList.map((value, index) => (
                                                    <option value={value.state_id} key={index}>
                                                        {value.state_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            City
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                value={values?.city_id}
                                                as="select"
                                                name="city_id"
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {cityList.map((value, index) => (
                                                    <option value={value.city_id} key={index}>
                                                        {value.city_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Profile Pic</div></td>
                                    <td>
                                        <div className='row' style={{margin:0}}>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    name="cover_image1"
                                                    onChange={(e) => setProfilePic(e.target.files)}
                                                />
                                                (Allowed formats: .jpg, .jpeg or .png; Recommend pixel size:[450px width x 320px height])
                                            </div>
                                            <div className="col-md-5">
                                                {values?.profile_pic === null || values?.profile_pic === "" ? null :
                                                <Image src={values?.profile_pic} style={{height:"100px", width:"100px"}} /> }
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Profile Details
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                style={{fontSize:"12px"}}
                                                as="textarea"
                                                placeholder=""
                                                name='profile_details'
                                                value={values?.profile_details}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            Affiliations
                                        </div>
                                    </td>
                                    <td>
                                        <div className="col-md-5">
                                            <Form.Control
                                                style={{fontSize:"12px"}}
                                                as="textarea"
                                                placeholder=""
                                                name='affiliations'
                                                value={values?.affiliations}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div>Display Status<span className="mandatory">*</span></div></td>
                                    <td >
                                        <div className="col-md-5">
                                            <Form.Control
                                                as="select"
                                                name="display_status"
                                                onChange={handleChange}
                                                value={values?.display_status}
                                            >
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </Form.Control>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div style={{ display: "flex" }}>
                            <button onClick={() => onSubmit()} className="primary-plus-button">{values.author_id === undefined ? "Add" : "Update"} Author</button>
                            <div className='spacer' />
                            <button onClick={() => props.onClose()} className="secondary-button">Cancel</button>
                        </div>
                    </div>
                    <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                </div>
            </div>
        </Aux>
    );
}
