import React, { useEffect, useState } from "react"
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Form, Col, Row, Button } from "react-bootstrap";
import "../Books/book.css";
import "./course.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
const CourseDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [courseDetails, setCourseDetails] = useState({})
    const [sectionList, setSectionList] = useState([])
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    // const [lessonList, setLessonList] = useState([]);
    const [activeLesson, setActiveLesson] = useState(null);
    // const [activeSectionId, setActiveSectionId] = useState(0);
    useEffect(() => {
        getCourseById(params?.id);
        getSections(params?.id);
    }, [])

    const getCourseById = (id) => {
        axios
            .get(site_ip + '/getCourseById/' + id, jwtHeader)
            .then((res) => {
                setCourseDetails(res.data.Content[0]);

            })
            .catch((err) => console.log(err));
    };


    const findActiveLessons = (allLessons, activeSection) =>{
        let filtered_lessons = allLessons.filter((item) => item.section_id === activeSection);
        return filtered_lessons;
    }

    const getSections = (id) => {
        axios
            .get(site_ip + '/getCourseSectionLessons/' + id, jwtHeader)
            .then((res) => {
                let section_list = res.data.Section;
                section_list.sort((a, b) => a.section_seq > b.section_seq ? 1 : -1)

                section_list.map((item, index)=>{
                    let lessons = findActiveLessons(res.data.Lesson, item?.section_id)
                    section_list[index] = {...item, lessons: lessons}
                })
                setSectionList(section_list)
            })
            .catch((err) => console.log(err));
    };

    // const activeSectionChanged = (new_section_id) =>{
    //     setActiveSectionId(new_section_id);
    //     setActiveLessons(findActiveLessons(lessonList, new_section_id))
    // }
    

    return (
        <>
            <div style={{padding:"25px"}}>
                <Row style={{marginBottom:"15px"}}>
                    <Col>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/home") }}>Home</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/courses")}}>Courses</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault();}}>{courseDetails?.course_title}</a>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <div>
                            <img src={courseDetails?.course_image} className="book-detail-logo" style={{marginTop:"0px", marginBottom:"0px"}} />
                            <p className="black label-2 mb-5">{courseDetails?.course_title}</p>
                        </div>
                        <div className="book-detail-menu">
                            {sectionList.map((item, index)=>{
                                return(
                                    <SectionItem item={item} key={index} setActiveLesson={(val) => setActiveLesson(val)} />
                                )
                            })}
                        </div>
                    </Col>
                    <Col md={9} style={{border:"1px solid #ccc"}}>
                        {activeLesson !== null ? 
                        <Lesson item={activeLesson} /> : null }
                        {/* {activeLessons.map((item, index)=>{
                            return(
                                
                            )
                        })} */}
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CourseDetails

const SectionItem = (props) =>{
    const item = props.item;
    const [toggleSection, setToggleSection] = useState(false);

    return(
        <div>
            <div className={toggleSection ? "section-btn-div active" : "section-btn-div"}>
                <button onClick={() => setToggleSection(!toggleSection)}>
                    {item?.section_name} 
                </button>
            </div>
            {toggleSection ?
            <div className="lesson-listing">
                {item?.lessons?.map((lesson, index)=>{
                    return(
                        <div style={{border:"1px solid #ccc"}}>
                            <button key={index} onClick={() => props.setActiveLesson(lesson)}>
                                {lesson?.lesson_name} 
                            </button>
                        </div>
                    )
                })}
            </div> : null }
        </div>
    )
}


const Lesson = ({ item }) => {
    const navigate = useNavigate();
    const [playedTiming, setPlayedTiming] = useState(0)
    const [notesVisible, setNotesVisible] = useState(false);

    return (
        <div style={{ borderBottom: "1px solid #ccc" }}>
            {item.section !== null ?
                <div className="content-row">
                    <div className="section-btn" style={{padding:"15px 0px"}}>
                        <p style={{ paddingRight: "15px" }}>{item?.lesson_name} <span style={{fontSize:"12px", color:"#ccc"}}>({item?.lesson_duration+" "+item?.lesson_duration_unit})</span></p>
                        {/* <p style={{ fontSize: "16px", position: "absolute", right: 0 }}> {expended ? "-" : "+"}</p> */}
                    </div>
                </div> : null}
            <div>
                <div>
                    <button className="plain-button" onClick={() => setNotesVisible(!notesVisible)} ><i className="far fa-clipboard" style={{marginRight:"5px"}}></i>Notes</button>
                    <button className="plain-button" onClick={() => navigate("/user/forums/"+item?.lesson_id)}><i className="fas fa-users" style={{marginRight:"5px"}}></i>Discuss</button>
                </div>
                <div style={{display:"flex"}}>
                    <div style={{flex: 2, border:"1px solid #cccccc", padding:"15px"}}>
                        <p style={{fontSize:"12px"}}>{item?.lesson_desc}</p>
                        <div className="video-box-shadow">
                            <ReactPlayer
                                controls={true}
                                url='https://icp-videos.s3.amazonaws.com/Acid-Base-Disorder-Dr-Subhash-Todi.mp4'
                                className='react-player'
                                playing={false}
                                width='100%'
                                height='100%'
                                progressInterval={5000}
                                onProgress={(state) => {
                                    // console.log(state);
                                    setPlayedTiming(state?.playedSeconds)
                                }}
                            />
                        </div>
                    </div>
                    {notesVisible ? 
                    <div className="notes-section">
                        <Row>
                            <Col xs={8}>
                                <p className="notes-title">Notes (2)</p>
                            </Col>
                            <Col xs={4} style={{display:"flex", justifyContent:"flex-end"}}>
                                <button onClick={() => setNotesVisible(false)} className="plain-button" style={{marginRight:0}}>Close <i className="fas fa-times" style={{marginLeft:"5px"}}></i></button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control as="textarea" rows={3} />
                                <div style={{height:"5px"}}></div>
                                <Button variant="success">Save</Button>{' '}
                                <Button variant="outline-secondary">Cancel</Button>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:"15px"}}>
                            <Col xs={12}>
                                <p className="notes-text">Welcome to the videos of the next lesson. In this lesson, we will try to understand what is streaming and how to multimedia content.</p>
                            </Col>
                            <Col xs={3}>
                                <button className="plain-button" style={{marginRight:0, fontSize:"12px"}}>Delete</button>
                            </Col>
                        </Row>
                    </div> : null }   
                </div>
            </div>
        </div>
    )
}