import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
export default function BookChapter(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'All']
  const [activeAlpha, setActiveAlpha] = useState("All")
  const [show, setShow] = useState(false);

  const blankValues = {
    book_id:0,
  }
  const [values, setValues] = useState(blankValues);
  const [confirmationModal, setConfirmationModal] = useState(0);
  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    getBooks();
    
  }, [])

  const [chapterList, setBookChapters] = useState([]);
  const getChapters = (id) => {
    if (id === 0) {
      setBookChapters([]);
    } else {
      axios
        .get(site_ip + '/getBookChapter/' + id, jwtHeader)
        .then((res) => {
          setBookChapters(res.data.Content);
        })
        .catch((err) => console.log(err));
    }
  };

  const [bookList, setBookList] = useState([]);
  const getBooks = () => {
    axios
      .post(site_ip + '/getBookSearch', {}, jwtHeader)
      .then((res) => {
        setBookList(res.data.Books);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setConfirmationModal(0)
  }

  const deleteRecord = () => {
    let req = { chapter_id: confirmationModal };
    axios.post(site_ip + "/deleteBookChapter", req, jwtHeader)
        .then((res) => {
            getChapters(values.book_id)
            setConfirmationModal(0)
        }).catch((err) =>
            console.log(err)
        )
}

  const columns = [
    {
      name: 'Seq No',
      selector: row => row.seq_no,
      width:"120px"
    },
    {
      name: 'Chapter No.',
      selector: row => row.chapter_no,
    },
    {
      name: 'Chapter Title',
      selector: row => row.chapter_title,
    },
    {
      name: 'Access Type',
      selector: row => row.access_type,
    },
    {
      name: 'DOI',
      selector: row => row.doi,
    },
    {
      name: 'Downloadable',
      selector: row => row.downloadable === "Y" ? "Yes" : "No",
    },
    {
      name: 'Action',
      width:"150px",
      cell: (row) => (
        <>
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <button style={{ padding: "5px" }} onClick={() => clickHandler(row.chapter_id)} className="primary-plus-button">
            <EditIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
        <div style={{ paddingTop: "5px", paddingBottom: "5px", marginLeft:"10px" }}>
          <button style={{ padding: "5px", backgroundColor: "#ff4d4d" }} onClick={() => setConfirmationModal(row.chapter_id)} className="primary-plus-button">
            <DeleteOutlineIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/book/chapter/edit/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Chapters Lists</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/book/chapter/add')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Create Chapter</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col className="search-row">
            <Form.Control
              as="select"
              className="search-form-element"
              name="book_id"
              onChange={handleChange}
              value={values.book_id}
            >
              <option value={""}>Select Book</option>
              {bookList.map((item, index) => {
                return (
                  <option key={index} value={item.book_id}>{item.book_title}</option>
                )
              })}
            </Form.Control>
            <button onClick={() => getChapters(values.book_id)} className="primary-plus-button">Search</button>
            <div className='spacer' />
            <button onClick={() => { getChapters(0); setValues(blankValues) }} className="secondary-button">Reset</button>
          </Col>
        </Row>
        {/* <Row className="underline-dashed">
          <Col>
            <div className="alphabet-filter">
              {alphabets.map((item, index) => {
                return (
                  <button key={index} onClick={() => setActiveAlpha(item)} className={item === activeAlpha ? "alpha-filter active" : "alpha-filter"}>{item}</button>
                )
              })}
            </div>
          </Col>
        </Row> */}
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={chapterList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>Remove Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to remove this record?</Modal.Body>
          <Modal.Footer>
              <button
                  style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                  className="btn btn-sm" variant="secondary" onClick={handleClose}>
                  Close
              </button>
              <button
                  style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                  className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                  Confirm
              </button>
          </Modal.Footer>
      </Modal>
    </Aux>

  );
}