import React, { useState } from 'react';
import { Form, Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import jsPDF from 'jspdf';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

export default function AddCommentaryFiles(props) {
  const [key, setKey] = useState('create');
  const [caseFile, setCaseFile] = useState("");
  const [values, setValues] = useState({
    commentary_id: props.location.state,
    case_video_names: '',
    case_html_file_link: '',
    case_pdf_file_link: '',
  });
  function handleChange(e) {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const [file, setFile] = useState();

  function fileUpload() {
    const target = { Bucket: 'derma', Key: file.name, Body: file };
    const creds = {
      accessKeyId: 'AKIAWUYVKQ2U4TARTTST',
      secretAccessKey: '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',
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'ap-south-1', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress);
      });

      parallelUploads3.done();
    } catch (e) {
      console.log(e);
    }
  }
  const html =
    `<html><head><title>Commentary Files</title></head><body><div>` +
    caseFile +
    `</div></body></html>`;

  const exportPdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    const html =
      `<html><head><title>Commentary Files</title></head><body><div>` +
        caseFile +
      `</div></body></html>`;
    doc.fromHTML(html, marginLeft, marginLeft, { width: 500 });
    doc.save('document.pdf');
    // window.open(doc.output('bloburl')); // For Testing Purposes
  };
  function exportHtml() {
    var textToSave = html;
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/html,' + encodeURI(textToSave);
    hiddenElement.download = 'document.html';
    hiddenElement.click();
  }
  return (
    <>
      <Container fluid>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
          variant="pills"
        >
          <Tab eventKey="create" title="Create ">
            <br />
            <div align="right">
              <Button onClick={exportPdf} variant="warning">
                Download PDF
              </Button>

              <Button
                className="m-3 mt-0 mb-0"
                onClick={exportHtml}
                variant="primary"
              >
                Download HTML
              </Button>
            </div>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Case File Details</Form.Label>
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '2px',
                      minHeight: '300px',
                    }}
                  >
                    <Editor
                      editorState={caseFile}
                      onEditorStateChange={setCaseFile}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="upload" title="Upload Case Files">
            <br />
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Course Video</Form.Label>
                  <Form.Control
                    name="case_video_names"
                    value={values.case_video_names}
                    onChange={handleChange}
                    className="mb-3"
                    type="file"
                    accept="video/*"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Case HTML File</Form.Label>
                  <Form.Control
                    name="case_html_file_link"
                    value={values.case_html_file_link}
                    onChange={handleChange}
                    className="mb-3"
                    type="file"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Case PDF File</Form.Label>
                  <Form.Control
                    name="case_pdf_file_link"
                    value={values.case_pdf_file_link}
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      handleChange(e);
                    }}
                    className="mb-3"
                    type="file"
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Button onClick={fileUpload} variant="dark">
              Upload Files
            </Button>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}
