import React, {Component} from 'react';
import Aux from "../../hoc/_Aux";
import {Button, Card, Col, Dropdown, Form, Row, Table} from "react-bootstrap";


const StudentScore = () => {

    return (
        <Aux>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Students Exam Score List</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam </Form.Label>
                                            <Form.Control as="select">
                                                <option>Select Exam</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam Type</Form.Label>
                                            <Form.Control as="select">
                                                <option>Select Exam Type</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Student Name</Form.Label>
                                            <Form.Control type="text" placeholder="Student Name"/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Date From</Form.Label>
                                            <Form.Control type="date" placeholder="Date From"/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Date To</Form.Label>
                                            <Form.Control type="date" placeholder="Date To"/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as="select">
                                                <option>Select State</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control as="select">
                                                <option>Select City</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={2} className="mt-4">
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Button variant="primary">
                                                Search
                                            </Button>
                                        </Form.Group>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exam</th>
                                    <th>Student Name</th>
                                    <th>Student Id</th>
                                    <th>Exam</th>
                                    <th>Total Question</th>
                                    <th>Exam Date</th>
                                    <th>Correct</th>
                                    <th>Incorrect</th>
                                    <th>Exam Marks</th>
                                    <th>Exam Ranks</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>CLAT</td>
                                    <td>Naveen Kumar Singh</td>
                                    <td>SG250730503</td>
                                    <td>CLAT</td>
                                    <td>200</td>
                                    <td>22/11/2020</td>
                                    <td>120</td>
                                    <td>80</td>
                                    <td>200</td>
                                    <td>1500</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Aux>
    );
}

export default StudentScore;