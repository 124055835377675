import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import "../commoncss.css"
import Upload from "../../../Components/Upload";
const ProductSection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  const [subjectList, setSubjectList] = useState([]);
  const [activeSubject, setActiveSubject] = useState(null);
  const [searchedString, setSearchString] = useState("");
  const [queryString, setQueryString] = useState("");
  const [bookList, setBookList] = useState([])

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [qualification, setQualification] = useState('');
  const [designation, setDesignation] = useState('');
  const [institute, setInstitute] = useState('');

  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');

  const [email, setEmail] = useState('');
  const [disclaimer, setDisclaimer] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formErrors, setFormErrors] = useState([]);

  const [contact, setContact] = useState('');

  const blankSearch = {
    first_name: "",
    last_name: "",
    country_id: "",
    state_id: "",
    city_id: "",
  }
  const [search, setSearch] = useState(blankSearch)

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "country_id") {
      setSearch({ ...search, [name]: value, state_id: "", city_id: "" })
      getStateList(value)
    } else if (name === "state_id") {
      setSearch({ ...search, [name]: value, city_id: "" })
      getCities(value)
    } else {
      setSearch({ ...search, [name]: value })
    }
  }



  useEffect(() => {
    getSubjects();
    getCountryList();
  }, [])

  const [countryList, setCountryList] = useState([]);
  const getCountryList = () => {
    axios
      .get(site_ip + '/getCountries', jwtHeader)
      .then((res) => {
        setCountryList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const [stateList, setStateList] = useState([]);
  const getStateList = (id) => {
    axios
      .get(site_ip + '/getStateByCountry/' + id, jwtHeader)
      .then((res) => {
        setStateList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const [cityList, setCityList] = useState([]);
  const getCities = (id) => {
    axios
      .get(site_ip + '/getCities/' + id, jwtHeader)
      .then((res) => {
        setCityList(res.data.States);
      })
      .catch((err) => console.log(err));
  };

  const getSubjects = () => {
    axios
      .get(site_ip + '/getSubjects', jwtHeader)
      .then((res) => {
        console.log(res);
        setSubjectList(res.data.content);
      })
      .catch((err) => console.log(err));
  };


  const selectSection = (id) => {

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation
    const errors = [];
    if (!firstName) {
      errors.push('First name is required');
    }
    if (!lastName) {
      errors.push('Last name is required');
    }
    if (!email) {
      errors.push('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.push('Email is invalid');
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    // Form submission logic
    // You can perform additional actions here, such as making an API request



    // Reset the form after submission
    setFirstName('');
    setLastName('');
    setEmail('');
    setFormErrors([]);

    // Display a success message or perform other actions as needed
  };

  const getAuthors = (req) => {
    axios
      .post(site_ip + '/searchAuthor', req, jwtHeader)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div style={{ padding: "15px 0px" }}>
        <Row style={{ margin: "0px" }}>
          <Col md={1}></Col>
          <Col md={3}>
            <div className="section-box">
              <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000", padding: "15px", marginBottom: "0px" }}>SECTION</p>
              {subjectList.map((item, index) => {
                return (
                  <button key={index} className="section-item" onClick={() => { selectSection(item.subject_id); setActiveSubject(item) }} >
                    <p style={{ padding: "15px", marginBottom: "0px" }}>{item.subject_name}</p>
                  </button>
                )
              })}
            </div>
          </Col>
          <Col md={7}>
            <Row style={{ margin: 0, marginBottom: "15px" }}>
              <Col style={{ display: "flex", alignItems: "center", }}>
                <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000", marginBottom:"0px" }}>Digital Atlas</p>
              </Col>
              <Col style={{ display: "flex", alignItems: "center", justifyContent:'flex-end' }}>
                <button onClick={() => navigate("/digital_atlas/submit")} className="primary-plus-button" style={{ padding: "10px 15px" }}>
                  Submit Images for Digital Atlas
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
      </div>
    </div>
  )
}

export default ProductSection;

