import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "../commoncss.css"
import "./news.css"
const CommentrySection = () => {
    const params = useParams();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    useEffect(() => {
        getSubjects();
        getExpertCommentaries();
    }, [])

    const [subjectList, setSubjectList] = useState([]);
    const [activeSubject, setActiveSubject] = useState(null);
    const [searchedString, setSearchString] = useState("");
    const [queryString, setQueryString] = useState("");
    const getSubjects = (id) => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectList(res.data.content);
            })
            .catch((err) => console.log(err));
    };

    const [expertCommentries, setExpertCommentaries] = useState([]);
    const getExpertCommentaries = () => {
        axios
            .get(site_ip + '/getExpertCommentaries', jwtHeader)
            .then((res) => {
                setExpertCommentaries(res.data.Data);
            })
            .catch((err) => console.log(err));
    };

    const searchExpertCommentries = (subject_id) => {
        let req;
        if (subject_id !== null) {
            setSearchString("");
            req = {
                subject_id: subject_id
            }
        } else {
            setActiveSubject(null)
            if (queryString.trim() === "") {
                return
            }
            req = {
                case_name: queryString.trim()
            }
            setSearchString(queryString.trim())
        }
        axios
            .post(site_ip + '/searchExpertCommentaries', req, jwtHeader)
            .then((res) => {
                setExpertCommentaries(res.data.Data);
            })
            .catch((err) => console.log(err));
    };



    return (
        <div style={{ padding: "15px 0px" }}>
            <Row style={{ margin: "0px" }}>
                <Col md={1}></Col>
                <Col md={3}>
                    <div className="section-box">
                        <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000", padding: "15px", marginBottom: "0px" }}>SECTION</p>
                        {subjectList.map((item, index) => {
                            return (
                                <button key={index} className="section-item" onClick={() => { searchExpertCommentries(item.subject_id); setActiveSubject(item) }} >
                                    <p style={{ padding: "15px", marginBottom: "0px" }}>{item.subject_name}</p>
                                </button>
                            )
                        })}
                    </div>
                </Col>
                <Col md={7}>
                    <Row style={{ margin: 0, marginBottom: "15px" }}>
                        <Col style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Expert Commentary</p>
                        </Col>
                        <Col>
                            <div className="search-round-div">
                                <input placeholder='Search expert commentries...' value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                                <button style={{ cursor: "pointer" }} onClick={() => searchExpertCommentries(null)}>Search</button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ margin: 0 }}>
                        <Row>
                            <Col xs={10}>
                                {activeSubject !== null ? <p className="related-news-title">Showing result for <b>{activeSubject?.subject_name}</b></p> : null}
                                {searchedString !== "" ? <p className="related-news-title">Showing result for <b>{searchedString}</b></p> : null}
                            </Col>
                            <Col xs={2}>
                                {activeSubject !== null || searchedString !== "" ? <button style={{ borderBottom: "0px" }} className="news-subject-item" onClick={() => { getExpertCommentaries(); setActiveSubject(null); setSearchString("") }} >Clear</button> : null}
                            </Col>
                        </Row>
                        {expertCommentries.map((item, index) => {
                            return (
                                <Col key={index} md={12}>
                                    <CaseSubjectThumbnail item={item} />
                                </Col>
                            )
                        })}
                        {expertCommentries.length === 0 ?
                            <div style={{ height: "150px", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                <p>No result found...</p>
                            </div> : null}
                    </Row>
                </Col>
                <Col md={1}></Col>
            </Row>
        </div>
    )
}

export default CommentrySection;

const CaseSubjectThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    return (
        <div className='news-thumbnail-div'>
            <Row>
                <Col md={3}>
                    <div className='news-thumbnail-img-div'>
                        {item?.case_images_names !== null && item?.case_images_names !== undefined ?
                            <img src={item?.case_images_names} alt="news_image" /> : null}
                    </div>
                </Col>
                <Col md={9}>
                    <p className="news-thumbnail-category">{item.subject_name}</p>
                    <p className="news-thumbnail-name"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/expert_commentry/" + item.commentary_id + "/" + item.case_name?.split(' ').join('_')) }}>{item.case_name}</a></p>
                    <Row>
                        <Col md={8}>
                            <div>
                                {item?.expert_name !== null && item?.expert_name !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                        <i className="fas fa-user-edit"></i>
                                        <p className='author-name'>{item?.expert_name}</p>
                                    </div> : null}
                                {/* {item?.publish_date !== null && item?.publish_date !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-calendar-alt"></i>
                                        <p className='author-name'>{moment(item?.publish_date).format("DD, MMM YYYY")}</p>
                                    </div> : null} */}
                            </div>
                        </Col>
                        {/* <Col md={4}>
                            <div style={{ display: "flex" }}>
                                <div className="count-div">
                                    <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                    <p>{item?.likes_cnt}</p>
                                </div>
                                <div className="count-div" style={{ marginLeft: "25px" }}>
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    <p>{item?.views_cnt}</p>
                                </div>
                            </div>
                        </Col> */}

                    </Row>
                </Col>
            </Row>
        </div>
    )
}