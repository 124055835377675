import React, {useEffect, useState} from "react";
import Aux from "../../hoc/_Aux";
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Table
} from "react-bootstrap";
import axios from "axios";

const ExamPatternSetup = (props) => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };

    let initialFormData = Object.freeze({
        exam_id: "",
        exam_type: "",
    });
    const [data, setData] = useState(initialFormData);
    const [exam, setExam] = useState([]);
    const [patdata, setPatData] = useState([]);

    const fetchExam = () => {
        axios.get(`https://nodeapi.uniqtoday.com/api/getGrades`, jwtHeader)
            .then((response) => {
                setExam(response.data.content)
            }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        fetchExam();

    }, []);
    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const [marksCount, setMarkCount] = useState(null);
    const [weightagePer, setWeightagePer] = useState(null);
    const [timeInMin, setTimeInMin] = useState(null);
    const [questionCount, setQuestionCount] = useState(null);
    const [simpleQuesCount, setSimpleQuesCount] = useState(null);
    const [mediumQuesCount, setMediumQuesCount] = useState(null);
    const [complexQuesCount, setComplexQuesCount] = useState(null);

    /**
     *
     * @param id - The id of the product
     * @param currentUnitPrice - The current unit price of the product
     */
    const onEdit = ({
                        id, currentMarkCount,
                        currentWeightagePer,
                        currentTimeInMin,
                        currentQuesCount,
                        currentSimpleQuesCount,
                        currentMediumQuesCount,
                        currentComplexQuesCount
                    }) => {
        setInEditMode({
            status: true,
            rowKey: id
        })
        setMarkCount(currentMarkCount);
        setWeightagePer(currentWeightagePer);
        setTimeInMin(currentTimeInMin);
        setQuestionCount(currentQuesCount);
        setSimpleQuesCount(currentSimpleQuesCount);
        setMediumQuesCount(currentMediumQuesCount);
        setComplexQuesCount(currentComplexQuesCount);
    }

    const onSave = ({
                        id, newMarkCount,
                        newWeightagePer,
                        newTimeInMin,
                        newQuestionCount,
                        newSimpleQuesCount,
                        newMediumQuesCount,
                        newComplexQuesCount
                    }) => {
        handleSubmit({
            id, newMarkCount,
            newWeightagePer,
            newTimeInMin,
            newQuestionCount,
            newSimpleQuesCount,
            newMediumQuesCount,
            newComplexQuesCount
        });
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value

        setMarkCount(null);
        setWeightagePer(null);
        setTimeInMin(null);
        setQuestionCount(null);
        setSimpleQuesCount(null);
        setMediumQuesCount(null);
        setComplexQuesCount(null);
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (id, newMarkCount,
                                newWeightagePer,
                                newTimeInMin,
                                newQuestionCount,
                                newSimpleQuesCount,
                                newMediumQuesCount,
                                newComplexQuesCount) => {

        await fetch('https://nodeapi.uniqtoday.com/api/addTestPattern', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem('admin_token')
            },
            body: JSON.stringify({
                "exam_id ": data.exam_id,
                "exam_type ": data.exam_type,
                "ct_exam_pattern_id ": id,
                "marks_cnt ": newMarkCount,
                "weightage_pct": newWeightagePer,
                "time_in_min ": newTimeInMin,
                "questions_cnt": newQuestionCount,
                "simple_ques_cnt": newSimpleQuesCount,
                "medium_ques_cnt ": newMediumQuesCount,
                "complex_ques_cnt": newComplexQuesCount
            }),
        }).then(response => response.json()).then(json => {

            // reset inEditMode and unit price state values
            onCancel();

            // fetch the updated data
            // fetchInventory();
        })
    }

    const addPattern = () => {
        fetch(`https://nodeapi.uniqtoday.com/api/getTestPattern`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                'access-token': localStorage.getItem('admin_token')
            }
        })
            .then(response => response.json())
            .then(response => {
                setPatData(response.Content)
            })
    }
    return (
        <Aux>
            <Row>
                <Col>

                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Subject List</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={5}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam </Form.Label>
                                            <Form.Control name="exam_id" as="select" className="mb-3"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}>
                                                <option>Select Exam</option>
                                                {exam.map((res, index) => (
                                                    <option value={res.id}>{res.class_exam_cd}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={5}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam Type</Form.Label>
                                            <Form.Control as="select" name="exam_type" className="mb-3"
                                                          onChange={e => {
                                                              handleChange(e)
                                                          }}>
                                                <option>Select Exam Type</option>
                                                <option value="Scholarship">Scholarship</option>
                                                <option value="Profiling">Profiling</option>
                                                <option value="Live">Live</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={2} className="mt-4">
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Button onClick={addPattern} className="btn-align-center"
                                                    variant="primary">Submit</Button>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Table striped responsive>
                                        <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Subject</th>
                                            <th>Marks Count</th>
                                            <th>Weightage Percentage</th>
                                            <th>Time in Min</th>
                                            <th>Question Count</th>
                                            <th>Simple Question Count</th>
                                            <th>Medium Question Count</th>
                                            <th>Complex Question Count</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            patdata.map((item) => (
                                                <tr key={item.ct_exam_pattern_id}>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <React.Fragment>
                                                                    <button

                                                                        className={"btn-success"}
                                                                        onClick={() => onSave({
                                                                            id: item.id,
                                                                            newMarkCount: marksCount,
                                                                            newWeightagePer: weightagePer,
                                                                            newTimeInMin: timeInMin,
                                                                            newQuestionCount: questionCount,
                                                                            newSimpleQuesCount: simpleQuesCount,
                                                                            newMediumQuesCount: mediumQuesCount,
                                                                            newComplexQuesCount: complexQuesCount,
                                                                        })}
                                                                    >
                                                                        Save
                                                                    </button>

                                                                    <button
                                                                        className={"btn-secondary"}
                                                                        style={{marginLeft: 8}}
                                                                        onClick={() => onCancel()}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </React.Fragment>
                                                            ) : (
                                                                <button
                                                                    className={"btn-primary"}
                                                                    onClick={() => onEdit({
                                                                        id: item.id,
                                                                        currentMarkCount: item.marks_cnt,
                                                                        currentWeightagePer: item.weightage_pct,
                                                                        currentTimeInMin: item.time_in_min,
                                                                        currentQuesCount: item.questions_cnt,
                                                                        currentSimpleQuesCount: item.simple_ques_cnt,
                                                                        currentMediumQuesCount: item.medium_ques_cnt,
                                                                        currentComplexQuesCount: item.complex_ques_cnt,
                                                                    })}
                                                                >
                                                                    Edit
                                                                </button>
                                                            )
                                                        }
                                                    </td>
                                                    <td>{item.subject_name}</td>

                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={marksCount}
                                                                       onChange={(event) => setMarkCount(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.marks_cnt
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={weightagePer}
                                                                       onChange={(event) => setWeightagePer(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.weightage_pct
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={timeInMin}
                                                                       onChange={(event) => setTimeInMin(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.time_in_min
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={questionCount}
                                                                       onChange={(event) => setQuestionCount(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.questions_cnt
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={simpleQuesCount}
                                                                       onChange={(event) => setSimpleQuesCount(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.simple_ques_cnt
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={mediumQuesCount}
                                                                       onChange={(event) => setMediumQuesCount(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.medium_ques_cnt
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            inEditMode.status && inEditMode.rowKey === item.id ? (
                                                                <input value={complexQuesCount}
                                                                       onChange={(event) => setComplexQuesCount(event.target.value)}
                                                                />
                                                            ) : (
                                                                item.complex_ques_cnt
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Aux>
    );
}

export default ExamPatternSetup;
