import React, { useState, useEffect } from 'react';
import { Button, Container, Col, Form, Row, Table, Card, Modal} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import Aux from '../../../hoc/_Aux';
import API from '../../API';
import '../../Master/master.css';

export default function SiteUsersList(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [activePublisher, setActivePublisher] = useState(0);
  const initialSearch = {
    first_name: '',
    // last_name: '',
    institution_id: '',
    user_email: '',
    user_mobile: '',
  };

  const [search, setSearch] = useState(initialSearch);
  const [publisher, setPublisher] = useState([]);
  const [publisherUsers, setPublisherUsers] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchInstitutionData();
    fetchAdminRoles();
    fetchUsers();
    // eslint-disable-next-line
  }, []);
  const fetchInstitutionData = async () => {
    try {
      setPublisher(await API.fetchInstitutions());
    } catch (error) {
      console.log(error);
    }
  };
  
  const [roles, setRoles] = useState([]);
  const fetchAdminRoles = async () => {
    try {
      setRoles(await API.fetchRoles());
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchUsers = async () => {
    await axios
      .post(site_ip + '/auth/searchSiteUsers', search, jwtHeader)
      .then(function (response) {
        setPublisherUsers(response.data.Admin);
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editButton = (id) =>{
    navigate('/admin/edit-site-user/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        user_id: selectedRecord,
        institution_id: activePublisher
      }
      handleClose();
        await axios
        .post(site_ip + '/auth/deleteSiteUser/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            fetchUsers();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id, institution_id) =>{
        setActivePublisher(institution_id)
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Aux>
      <Container fluid>
        <Row style={{marginTop:"15px"}}>
          <Col>
          <Card>
              <Card.Header as="h4">Site Users List</Card.Header>
                <Card.Body>
                <div>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Institution</Form.Label>
                        <Form.Control
                          as="select"
                          className="mb-3"
                          name="institution_id"
                          value={search.institution_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Institution</option>
                          {publisher?.map((value) => (
                            <option key={value.institution_id} value={value.institution_id}>
                              {value.institute_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          name="first_name"
                          value={search.first_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col md={3}>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          name="last_name"
                          value={search.last_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col> */}
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          name="user_email"
                          value={search.user_email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          name="user_mobile"
                          value={search.user_mobile}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4} className="mt-4">
                      <Button
                        variant="primary"
                        className="mt-1 mb-0"
                        onClick={fetchUsers}
                      >
                        Search
                      </Button>
                      <Button
                        variant="warning"
                        onClick={() => setSearch(initialSearch)}
                        className="mt-1 mb-0 m-3"
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </div>
          </Card.Body>
          </Card>
          </Col>
        </Row>  
        <Row style={{marginTop:"15px"}}>
          <Col md={6}>
            <h4></h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/add-site-user')}
            >
              Add Site Users
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <div>
              <table className="customTable">
                <thead className="lt-head">
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Name </th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {publisherUsers?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{user.first_name + ' ' + ' ' + user.last_name}</td>
                        <td>{user.user_email}</td>
                        <td>{user.user_mobile}</td>
                        <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(user.user_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(user.user_id, user.institution_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
