import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import API from '../API';
import { useNavigate } from 'react-router-dom';

export default function AddFacultyMembers(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    member_first_name: '',
    member_last_name: '',
    member_pic: '',
    gender: '',
    member_email: '',
    mobile_number: '',
    role_id: '',
    publisher_id: '',
    subject_id: '',
    speciality_id: '',
    password: '',
    status: '',
  });

  const [publisher, setPublisher] = useState([]);
  const [roles, setRoles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchRoles = async () => {
    try {
      setRoles(await API.fetchRoles());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPublishers = async () => {
    try {
      setPublisher(await API.fetchPublishers());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPublishers();
    fetchRoles();
    fetchSubjects();
    fetchCategories();
  }, []);

  const [editorQualificationState, setEditorQualificationState] = useState("");
  const [editorBioState, setEditorBioState] = useState("");
  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (pass) => {
    if (pass.trim().length < 8) {
      return false;
    }
    return true;
  };
  const proceed = async () => {
    const editors = {
      member_qualifications: editorQualificationState,
      member_bio: editorBioState,
    };
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (
      values.member_first_name === '' ||
      values.member_last_name === '' ||
      values.role_id === '' ||
      values.member_email === '' ||
      values.mobile_number === '' ||
      values.status === '' ||
      values.gender === '' ||
      values.publisher_id === '' ||
      values.password === ''
    )
      alert('Please fill all the fields');
    else if (!validateEmail(values.member_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.mobile_number)) {
      alert('Please enter valid mobile number');
    } else if (!validatePassword(values.password)) {
      alert('Password must be minimum 8 character long');
    } else {
      await axios
        .post(
          site_ip + '/addFacultyMember',
          { ...values, ...editors },
          jwtHeader
        )
        .then(function (response) {
          //   console.log(response);
          if (response.data.Status) {
            Swal.fire({
              title: 'Success',
              text: response.data.message,
              icon: 'success',
            });
            navigate('/admin/faculty-listing');
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  return (
    <Aux>
      <Container>
        <br />
        <h4>Add Faculty Member</h4>
        <Form className="p-3 border bg-white">
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  name="member_first_name"
                  value={values.member_first_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  name="member_last_name"
                  value={values.member_last_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Profile Picture</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="file"
                  name="member_pic"
                  value={values.member_pic}
                  onChange={handleChange}
                  accept="image/*"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  className="mb-3"
                  as="select"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                >
                  <option>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="email"
                  name="member_email"
                  value={values.member_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="number"
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  className="mb-3"
                  as="select"
                  name="role_id"
                  value={values.role_id}
                  onChange={handleChange}
                >
                  <option>Select Role</option>
                  {roles.map((value) => (
                    <option key={value.role_id} value={value.role_id}>
                      {value.role_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Form.Label>Publisher</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="publisher_id"
                  value={values.publisher_id}
                  onChange={handleChange}
                >
                  <option>Select Publisher</option>
                  {publisher?.map((option) => (
                    <option value={option.publisher_id}>
                      {option.publisher_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="speciality_id"
                  value={values.speciality_id}
                  onChange={handleChange}
                >
                  <option>Select Category</option>
                  {categories?.map((option) => (
                    <option value={option.category_id}>
                      {option.category_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="subject_id"
                  value={values.subject_id}
                  onChange={handleChange}
                >
                  <option>Select Subject</option>
                  {subjects?.map((option) => (
                    <option value={option.subject_id}>
                      {option.subject_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  className="mb-3"
                  as="select"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Qualification</Form.Label>
                <div
                  style={{
                    border: '1px solid #ced4da',
                    padding: '2px',
                    minHeight: '300px',
                  }}
                >
                  <CKEditor
                    editor={Editor}
                    data={editorQualificationState}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorQualificationState(data)
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Bio</Form.Label>
                <div
                  style={{
                    border: '1px solid #ced4da',
                    padding: '2px',
                    minHeight: '300px',
                  }}
                >
                  <CKEditor
                    editor={Editor}
                    data={editorBioState}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorBioState(data)
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Button className="mt-1" onClick={proceed}>
            Submit
          </Button>
        </Form>
      </Container>
    </Aux>
  );
}
