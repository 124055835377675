import React, { useState } from 'react';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, S3 } from '@aws-sdk/client-s3';

const FileUpload = () => {
  const [file, setFile] = useState();

  function fileUpload() {
    const target = { Bucket: 'derma', Key: file.name, Body: file };
    const creds = {
      accessKeyId: 'AKIAWUYVKQ2U4TARTTST',
      secretAccessKey: '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',
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'ap-south-1', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress);
      });

      parallelUploads3.done();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <input
        type="file"
        id="file"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <button onClick={fileUpload}>Submit</button>
    </div>
  );
};

export default FileUpload;
