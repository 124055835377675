import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button, Badge, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../config';

// import { Link } from "react-router-dom";
// import { convertFromRaw } from 'draft-js';
// import { stateToHTML } from 'draft-js-export-html';
// import {
//   GETQUESTIONLIST,
//   GETINSTRUCTLIST,
//   GETATTEMPTLIST,
// } from '../../../../action/PostAction';
// import { connect } from 'react-redux';
// import Stopwatch from './Stopwatch';
import { useNavigate, useLocation } from 'react-router-dom';
// import moment from 'moment';
import './Exam.css';
// import { CountdownCircleTimer } from 'react-countdown-circle-timer';
const ExamPage = (props) => {
  // const [questionList, setQuestionList] = useState([]);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  const location = useLocation();
  let navigate = useNavigate();
  const testType = location.state.test_type;
  // console.log(testType);
  // const [test_id, setTest_id] = useState(1);
  const [quest_id, setQuest_Id] = useState(0);
  // const [buttonStatus, setButtonStatus] = useState([]);
  // console.log(buttonStatus);
  const [optionStatus, setOptionStatus] = useState([]);
  const [option, setOption] = useState(0);
  // const [correct] = useState(0);
  const [attempted, setAttempted] = useState(0);
  // const [unattempted] = useState(location.state.data.length);
  // const [other, setOther] = useState([]);
  // const [candidateResponse, setCandidateResponse] = useState({});
  const [toggle, setToggle] = useState(false);

  const [submittedAnswers, setSubmittedAnswers] = useState([]);

  // create a blank arrays of option for attempted/unattempted
  const responseStatusRef = useRef(null);
  const [responsestatus, setResponseStatus] = useState([]);
  responseStatusRef.current = responsestatus;

  const userAnswerRef = useRef(null);
  const [useranswers, setUserAnswer] = useState([]);
  userAnswerRef.current = useranswers;

  const activeSectionRef = useRef(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  activeSectionRef.current = activeSectionIndex;

  const [selected_section, setSelectedSection] = useState([]);
  const progressRef = useRef(null);
  const [testProgress, setTestProgress] = useState([]);
  progressRef.current = testProgress;

  useEffect(() => {
    let testProgress = [];
    // for (let j = 0; j < location.state.data.length; j++) {
    let blank_array = [];
    let blank_answers = [];
    for (let i = 0; i < location.state.data.length; i++) {
      blank_array.push(-1);
      if (selected_section[i]?.question_type_id === 1) {
        blank_answers.push([]);
      } else if (selected_section[i]?.question_type_id === 2) {
        blank_answers.push(0);
      } else if (selected_section[i]?.question_type_id === 3) {
        blank_answers.push(0);
      } else if (selected_section[i]?.question_type_id === 4) {
        blank_answers.push('');
      }
    }

    let element = {
      useranswer: blank_answers,
      responsestatus: blank_array
    }
    // console.log(blank_answers);
    testProgress.push(element)
    // }
    setTestProgress(testProgress)
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(location.state.data)
    setSelectedSection(location.state.data)
    let blank_array = [];
    let blank_answers = [];
    for (let i = 0; i < location.state.data.length; i++) {
      blank_array.push(-1);
      if (location.state.data[i]?.question_type_id === 1) {
        blank_answers.push([]);
      } else if (location.state.data[i]?.question_type_id === 2) {
        blank_answers.push(0);
      } else if (location.state.data[i]?.question_type_id === 3) {
        blank_answers.push(0);
      } else if (location.state.data[i]?.question_type_id === 4) {
        blank_answers.push('');
      }
    }
    setUserAnswer(blank_answers);
    setResponseStatus(blank_array);
    //eslint-disable-next-line
  }, []);


  const updateAnswers = (e) => {
    if (!submittedAnswers.includes(quest_id)) {
      setToggle(!toggle);
      if (selected_section[quest_id]?.question_type_id === 1) {
        let new_ans = useranswers;
        if (new_ans[quest_id].includes(parseInt(e))) {
          new_ans[quest_id] = new_ans[quest_id].filter(
            (item) => item !== parseInt(e)
          );

          if (new_ans[quest_id].length === 0) {
            let new_status = responsestatus;
            new_status[quest_id] = 1;
            setResponseStatus(new_status);
          } else {
            let new_status = responsestatus;
            new_status[quest_id] = 2;
            setResponseStatus(new_status);
          }
          setUserAnswer(new_ans);
        } else {
          new_ans[quest_id].push(parseInt(e));
          setUserAnswer(new_ans);
          let new_status = responsestatus;
          new_status[quest_id] = 2;
          setResponseStatus(new_status);
        }
      } else if (selected_section[quest_id].question_type_id === 2) {
        let new_ans = useranswers;
        new_ans[quest_id] = e;
        setUserAnswer(new_ans);

        let options = responsestatus;
        options[quest_id] = 2;
        setResponseStatus(options);
      } else if (selected_section[quest_id].question_type_id === 3) {
        let new_ans = useranswers;
        new_ans[quest_id] = e;
        setUserAnswer(new_ans);

        let options = responsestatus;
        options[quest_id] = 2;
        setResponseStatus(options);

      } else if (
        selected_section[quest_id].question_type_id === 4 ||
        selected_section[quest_id].question_type_id === 5
      ) {
        let new_ans = useranswers;
        new_ans[quest_id] = e.target.value;
        setUserAnswer(new_ans);
        // Change answer status
        if (e.target.value === '') {
          let new_status = responsestatus;
          new_status[quest_id] = false;
          setResponseStatus(new_status);
        } else {
          let new_status = responsestatus;
          new_status[quest_id] = true;
          setResponseStatus(new_status);
        }
      }
    }
  };


  


  const saveStudentTestResult = () => {



    let current_progress = progressRef.current;
    let element = {
      useranswer: userAnswerRef.current,
      responsestatus: responseStatusRef.current
    }
    current_progress[activeSectionRef.current] = element;

    // let user_data = localStorage.getItem('userdata');
    // let user_data_obj = JSON.parse(user_data)

    let total_questions = location.state.data.length;

    if(localStorage.getItem("user_id") !== null){
      // User is logged in
      loggedInUserResponses(current_progress)
    }else{
      submitUserSelectedOptions(current_progress)
    }
  }

  const loggedInUserResponses = (current_progress) => {
    let options = [];
    for (let j = 0; j < current_progress.length; j++) {
      for (let i = 0; i < current_progress[j]?.useranswer.length; i++) {
        // Correct option
        // let option_text = location.state.data[j].questions[i].question_options
        // let correct_obj = JSON.parse(option_text);
        let resp_obj = "[]";
        if (current_progress[j]?.useranswer[i].length !== undefined) {
          resp_obj = current_progress[j]?.useranswer[i]?.sort().toString();
        } else if (current_progress[j]?.useranswer[i] === 1) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else if (current_progress[j]?.useranswer[i] === 2) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else if (current_progress[j]?.useranswer[i] === 3) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else if (current_progress[j]?.useranswer[i] === 4) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else {
          resp_obj = current_progress[j]?.useranswer[i];
        }

        let element = {
          question_id: location.state.data[i].question_id,
          user_test_id: 1,   //resp?.data?.Content?.user_test_id,
          user_id: 1,
          attempt_status: "",
          time_taken: "",
          negative_marks_cnt: "",
          gain_marks: "",
          ques_responses: resp_obj
        }
        options.push(element)
      }
    }

    let other_information = {
      user_id: localStorage.getItem("user_id"),
      exam_id: "",
      exam_mode: "",
      questions_count: location.state.data.length,
      correct_count: 0,
      incorrect_count: 0,
      test_type_id: 0,
      unattempted_count: 0,
      marks_gain: 0,
      test_time: 0,
      result_percentage: 0,
      test_series_id: 0
    }

    other_information = {...other_information, questions: options}


    axios
      .post(site_ip + '/saveUserTestsResponses', other_information, jwtHeader)
      .then((response) => {
        if (response.data.Status === 200)
          navigate('/user/assessment/review',
            {
              state: { user_test_id: response.data?.Result[0]?.user_test_id, user_result: response.data }
            })
      })
      .catch((err) => console.log(err));

  }

  const submitUserSelectedOptions = (current_progress) => {
    let options = [];
    for (let j = 0; j < current_progress.length; j++) {
      for (let i = 0; i < current_progress[j]?.useranswer.length; i++) {
        // Correct option
        // let option_text = location.state.data[j].questions[i].question_options
        // let correct_obj = JSON.parse(option_text);
        let resp_obj = "[]";
        if (current_progress[j]?.useranswer[i].length !== undefined) {
          resp_obj = current_progress[j]?.useranswer[i]?.sort().toString();
        } else if (current_progress[j]?.useranswer[i] === 1) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else if (current_progress[j]?.useranswer[i] === 2) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else if (current_progress[j]?.useranswer[i] === 3) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else if (current_progress[j]?.useranswer[i] === 4) {
          resp_obj = current_progress[j]?.useranswer[i]?.toString();
        } else {
          resp_obj = current_progress[j]?.useranswer[i];
        }

        let element = {
          question_id: location.state.data[i].question_id,
          user_test_id: 1,   //resp?.data?.Content?.user_test_id,
          user_id: 1,
          attempt_status: "",
          time_taken: "",
          negative_marks_cnt: "",
          gain_marks: "",
          ques_responses: resp_obj
        }
        options.push(element)
      }
    }

    axios
      .post(site_ip + '/showTestsResponses', options, jwtHeader)
      .then((response) => {
        if (response.data.Status === 200)
          navigate('/user/assessment/result',
            {
              // state: { user_test_id: resp?.data?.Content?.user_test_id, user_result: response.data?.Result[0] }
              state: { user_result: response.data }
            })
      })
      .catch((err) => console.log(err));

  }

  const MailSentExam = () => {
    axios({
      method: 'post',
      url: site_ip + '/saveTestsResult',
      data: {
        candidate_id: localStorage.getItem('quantam_learner_id'),
        site_learner_id: localStorage.getItem('quantam_learner_id'),
        // job_id: null,
        test_type: testType,
        test_series_id: null,
        company_id: null,
        test_questions_count: selected_section.length,
        test_date: new Date()
          .toISOString()
          .split('T')[0]
          .split('-')
          .reverse()
          .join('-'),
        test_time: null,
        co_test_id: null,
        time_taken: null,
      },
      dataType: 'json',
    }).then((response) => {
      if (response.status === 200) navigate('/student/thankyou');
    });
  };

  const hChange1 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(selected_section[quest_id].ques_option1);
    //console.log(selected_section?[quest_id].ques_option1);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };
  const hChange2 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(selected_section[quest_id].ques_option2);
    // console.log(selected_section?[quest_id].ques_option2);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }

    options[quest_id] = true;
    setResponseStatus(options);
  };

  const hChange3 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(selected_section[quest_id].ques_option3);
    // console.log(selected_section?[quest_id].option3);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };
  const hChange4 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(selected_section[quest_id].ques_option4);
    // console.log(selected_section?[quest_id].ques_option4);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };

  useEffect(() => {
    // console.log(optionStatus);
    setOptionStatus(optionStatus);
    //eslint-disable-next-line
  }, []);


  let buttonList = responsestatus.map((item, index) => {

    if (item === -1) {
      return (
        <button className='legend-btn' onClick={() => changeQuestion(index)}>
          <div key={index} className={"not-visited"}>
            {index + 1}
          </div>
        </button>
      );
    }
    if (item === 0) {
      return (
        <button className='legend-btn' onClick={() => changeQuestion(index)}>
        <div key={index} className={"not-answered"}>
          {index + 1}
        </div>
        </button>
      );
    }
    if (item === 1) {
      return (
        <button className='legend-btn' onClick={() => changeQuestion(index)}>
          <div key={index} className={"marked"}>
            {index + 1}
          </div>
        </button>
      );
    }
    if (item === 2) {
      return (
        <button className='legend-btn' onClick={() => changeQuestion(index)}>
          <div key={index} className={"answered"}>
            {index + 1}
          </div>
        </button>
      );
    }
  });

  const submitUserAnswer = () => {
    let current_ques = parseInt(quest_id) + 1
    if (current_ques < selected_section.length) {
      setQuest_Id(parseInt(quest_id) + 1);
      let submitted_answers = submittedAnswers;
      submitted_answers.push(quest_id);
      setSubmittedAnswers(submitted_answers);
    } else {
      saveStudentTestResult();
    }
  }


  const submitUserAnswer1 = () => {
    // console.log(useranswers);
    let submitted_answers = submittedAnswers;
    submitted_answers.push(quest_id);
    setSubmittedAnswers(submitted_answers);

    let user_response = null;
    let desc_ans = null;
    if (selected_section[quest_id].question_type_id === 4) {
      desc_ans = useranswers[quest_id];
    } else if (selected_section[quest_id].question_type_id === 3) {
      user_response = {
        ans: useranswers[quest_id].toString(),
      };
    } else if (selected_section[quest_id].question_type_id === 2) {
      user_response = {
        ans: useranswers[quest_id].toString(),
      };
    } else if (selected_section[quest_id].question_type_id === 1) {
      user_response = {
        ans: useranswers[quest_id].sort().toString(),
      };
    }
    axios({
      method: 'post',
      url: site_ip + '/saveTestsResponses',
      data: {
        candidate_id: localStorage.getItem('quantam_learner_id'),
        site_learner_id: localStorage.getItem('quantam_learner_id'),
        test_attempt_id: null,
        subject_id: selected_section[quest_id].subject_id,
        topic_id: selected_section[quest_id].topic_id,
        test_type: testType,
        test_series_id: null,
        ques_type: null,
        question_id: null,
        co_question_id: null,
        attempt_status: 3,
        gain_marks: selected_section[quest_id].marks,
        time_taken: null,
        cand_responses: user_response,
        cand_resp_desc_ans: desc_ans,
        correct_yn: null,
        // question_id: selected_section[quest_id].question_id,
        // attempt_status: 3,
        // gain_marks: selected_section[quest_id].marks,
        // time_taken: selected_section[quest_id].time_allowed,
        // subject_id: selected_section[quest_id].subject_id,
        // topic_id: selected_section[quest_id].topic_id,
        // cand_responses: user_response,
        // can_resp_desc_ans: desc_ans,
        // candidate_id: selected_section.otptoken_list?.data.Candidate,
        //option_id:option,
        // co_question_id: selected_section[quest_id].question_id,
        // job_id: selected_section.instruct_list?.Tests[test_type].job_id,
        // test_attempt_id: selected_section.attempt_list?.data.Test,
        // exam_type: selected_section.instruct_list?.Tests[test_type].test_type,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        let new_question = quest_id + 1;
        if (quest_id < selected_section.length - 1) {
          setQuest_Id(new_question);
          setOption(0);
        } else {
          // alert('Quiz completed.');
          MailSentExam();
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const Next = () => {
    if (!submittedAnswers.includes(quest_id)) submitUserAnswer();
    else saveStudentTestResult();
  };

  const renderQuestion = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  };

  const renderOptionOne = (text) => {
    let correct_obj = "";
    try {
      correct_obj = JSON.parse(text);

      if (correct_obj[0] !== undefined) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: correct_obj[0],
            }}
          />
        );
      }
      // else{
      //   return (
      //     <div
      //       dangerouslySetInnerHTML={{
      //         __html: stateToHTML(convertFromRaw(correct_obj)),
      //       }}
      //     />
      //   );
      // }

    } catch (error) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    }
    return '';
  };

  const renderOptionTwo = (text) => {
    let correct_obj = "";
    try {
      correct_obj = JSON.parse(text);
      if (correct_obj[1] !== undefined) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: correct_obj[1],
            }}
          />
        );
      }
      // else{
      //   return (
      //     <div
      //       dangerouslySetInnerHTML={{
      //         __html: stateToHTML(convertFromRaw(correct_obj)),
      //       }}
      //     />
      //   );
      // }

    } catch (error) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    }
    return '';
  };

  const renderOptionThree = (text) => {
    let correct_obj = "";
    try {
      correct_obj = JSON.parse(text);
      if (correct_obj[2] !== undefined) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: correct_obj[2],
            }}
          />
        );
      }
      // else{
      //   return (
      //     <div
      //       dangerouslySetInnerHTML={{
      //         __html: stateToHTML(convertFromRaw(correct_obj)),
      //       }}
      //     />
      //   );
      // }

    } catch (error) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    }
    return '';
  };

  const renderOptionFour = (text) => {
    let correct_obj = "";
    try {
      correct_obj = JSON.parse(text);
      if (correct_obj[3] !== undefined) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: correct_obj[3],
            }}
          />
        );
      }
      // else{
      //   return (
      //     <div
      //       dangerouslySetInnerHTML={{
      //         __html: stateToHTML(convertFromRaw(correct_obj)),
      //       }}
      //     />
      //   );
      // }

    } catch (error) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    }
    return '';
  };

  const children = ({ remainingTime }) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    return `${hours}:${minutes}:${seconds}`;
  };

  const markQuestion = () => {
    markThisQuestionInDB(selected_section[quest_id])
    let new_question = quest_id + 1;
    if (selected_section.length < (new_question + 1)) {
      setQuest_Id(0);
    } else {
      setQuest_Id(new_question);
    }

    let options = responsestatus;
    options[quest_id] = 1;
    setResponseStatus(options);
  }

  const markThisQuestionInDB = (question_set) => {

    let user_data = localStorage.getItem('userdata');
    let user_data_obj = JSON.parse(user_data)

    const data = {
      student_id: localStorage.getItem('quantam_learner_id'),
      exam_id: user_data_obj?.exam_id,
      subject_id: question_set?.subject_id,
      question_id: question_set?.question_id
    }
    axios({
      method: 'post',
      url: site_ip + '/bookmarkQuestions',
      data: data,
      dataType: 'json',
    }).then((response) => {
      // submitUserSelectedOptions(response, current_progress);
    });
  }

  const clearQuestion = () => {
    if (selected_section[quest_id]?.question_type_id === 1) {
      let new_ans = useranswers;
      new_ans[quest_id] = [];
      setUserAnswer(new_ans);
    } else if (selected_section[quest_id]?.question_type_id === 2 || selected_section[quest_id]?.question_type_id === 3) {
      let new_ans = useranswers;
      new_ans[quest_id] = 0;
      setUserAnswer(new_ans);
    } else {
      let new_ans = useranswers;
      new_ans[quest_id] = '';
      setUserAnswer(new_ans);
    }

    let options = responsestatus;
    options[quest_id] = 0;
    setResponseStatus(options);
    setToggle(!toggle)
  }

  const nextQuestion = () => {
    let new_question = quest_id + 1;
    if (selected_section.length < (new_question + 1)) {
      setQuest_Id(0);
    } else {
      setQuest_Id(new_question);
    }
    let options = responsestatus;
    options[quest_id] = 0;
    setResponseStatus(options);
  }

  const changeQuestion = (index) =>{
    setQuest_Id(index);
  }

  const prevQuestion = () => {
    let new_question = quest_id - 1;
    // if (selected_section.length < (new_question + 1)) {
    //   setQuest_Id(0);
    // } else {
      setQuest_Id(new_question);
    // }
    // let options = responsestatus;
    // options[quest_id] = 0;
    // setResponseStatus(options);
  }

  const savenextQuestion = () => {
    let new_question = quest_id + 1;
    if (selected_section.length < (new_question + 1)) {
      setQuest_Id(0);
    } else {
      setQuest_Id(new_question);
    }

    let options = responsestatus;
    options[quest_id] = 2;
    setResponseStatus(options);
  }



  
  return (
    <>
      <div className="exam-page">
        <Row style={{ margin: 0 }}>
          <Col md={10} sm={9}>
            <p className='testname-heading'>{location?.state?.data[activeSectionIndex].subject_name.toUpperCase()}</p>
          </Col>
          <Col md={2} sm={3}>
            <TimeClock totalTimeInSec={parseInt(location.state.data.length)*60} submitAssessment={() => saveStudentTestResult()} />
          </Col>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col md={10} sm={9}>
            <div className="test-content-section">
              {/* <div className="test-navigation-div">
                {location?.state?.data?.map((item, index)=>{
                  return(
                    <button onClick={()=> sectionChanged(index)} key={index} className={activeSectionIndex === index ? "exam-section active" : "exam-section"} >
                      {item.subject_name.toUpperCase()}
                    </button>
                  )
                })}
              </div>   */}
              <div className="test-content-div">
                <div className="question-bar">
                  <p className="question" style={{ fontWeight: 'bold' }}>
                    Question No. {parseInt(quest_id) + 1}
                  </p>
                </div>
                <div style={{ height: '62vh', overflowY: 'auto' }}>
                  <div className="p-3">
                    {/* <p className="question"> */}
                    {renderQuestion(
                      selected_section[quest_id]?.question
                    )}
                    {/* </p> */}
                    {selected_section[quest_id]?.question_type_id === 1 ? (
                      <Row>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name="radio"
                              type="checkbox"
                              id={selected_section[quest_id].ques_option1}
                              value={option}
                              label={renderOptionOne(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id]?.includes(1)}
                              onChange={(e) => {
                                // hChange1('ques_option1');
                                updateAnswers(1);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers ">
                            <Form.Check
                              name={quest_id}
                              type="checkbox"
                              id={option}
                              label={renderOptionTwo(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id]?.includes(2)}
                              onChange={(e) => {
                                // hChange2('ques_option2');
                                updateAnswers(2);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name={quest_id}
                              type="checkbox"
                              id={option}
                              label={renderOptionThree(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id]?.includes(3)}
                              onChange={(e) => {
                                // hChange3('ques_option3');
                                updateAnswers(3);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name={quest_id}
                              type="checkbox"
                              id={option}
                              label={renderOptionFour(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id]?.includes(4)}
                              onChange={(e) => {
                                // hChange4('ques_option4');
                                updateAnswers(4);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    {selected_section[quest_id]?.question_type_id === 2 ? (
                      <Row>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name="radio"
                              type="radio"
                              id={selected_section[quest_id].ques_option1}
                              value={option}
                              label={renderOptionOne(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id] === 1}
                              onChange={(e) => {
                                // hChange1('ques_option1');
                                updateAnswers(1);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers ">
                            <Form.Check
                              name={quest_id}
                              type="radio"
                              id={option}
                              label={renderOptionTwo(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id] === 2}
                              onChange={(e) => {
                                // hChange2('ques_option2');
                                updateAnswers(2);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name={quest_id}
                              type="radio"
                              id={option}
                              label={renderOptionThree(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id] === 3}
                              onChange={(e) => {
                                // hChange3('ques_option3');
                                updateAnswers(3);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name={quest_id}
                              type="radio"
                              id={option}
                              label={renderOptionFour(
                                selected_section[quest_id].question_options
                              )}
                              checked={useranswers[quest_id] === 4}
                              onChange={(e) => {
                                // hChange4('ques_option4');
                                updateAnswers(4);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    {selected_section[quest_id]?.question_type_id === 3 ? (
                      <Row>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name="radio"
                              type="radio"
                              id={'True'}
                              value={option}
                              label={'True'}
                              checked={useranswers[quest_id] === 1}
                              onChange={(e) => {
                                // hChange1('True');
                                updateAnswers(1);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div className="border-bottom p-2 w-100 h-100 answers ">
                            <Form.Check
                              name="radio"
                              type="radio"
                              id={'False'}
                              value={option}
                              label={'False'}
                              checked={useranswers[quest_id] === 2}
                              onChange={(e) => {
                                // hChange2('False');
                                updateAnswers(2);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    {selected_section[quest_id]?.question_type_id === 4 ||
                      selected_section[quest_id]?.question_type_id === 5 ? (
                      <Row>
                        <Col md={12} className="mb-4">
                          <div className="p-2 w-100 h-100 answers">
                            <Form.Control
                              as="textarea"
                              onChange={(e) => updateAnswers(e)}
                              value={useranswers[quest_id]}
                              placeholder="Answer here..."
                              style={{ height: '40vh' }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "25px" }}>
                <Row>
                  <Col>
                    <button className="mark-for-review" onClick={() => markQuestion()}>Mark for Review & Next</button>
                    {quest_id > 0 ? <button className="mark-for-review" onClick={() => prevQuestion()}>Previous</button> : null }
                    {responsestatus[quest_id] === 2 ?
                      <button className="mark-for-review" onClick={() => clearQuestion()} >Clear Response</button> : null}
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    {responsestatus[quest_id] !== 2 ?
                      <button className="go-next" onClick={() => nextQuestion()}>Next</button> : null}
                    {responsestatus[quest_id] === 2 && (quest_id < selected_section.length - 1) ?
                      <button className="save-next" onClick={() => savenextQuestion()}>Save & Next</button> : null}
                    {responsestatus[quest_id] === 2 && (quest_id === selected_section.length - 1) ?
                      <button className="save-next" onClick={() => saveStudentTestResult()}>Save & Final Submit</button> : null}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={2} sm={3} className="exam-palette">
            {/* <p>You are viewing <b>{location?.state?.data[activeSectionIndex].subject_name.toUpperCase()}</b> section.</p> */}
            <div
              className="numbering-block d-flex flex-wrap align-items-center"
              style={{ display: 'flex' }}
            >
              {buttonList}
            </div>
            <p style={{ marginTop: "30px", marginBottom: "15px" }}><b>LEGEND:</b></p>
            <div>
              <div style={{ display: "flex", alignItems: 'center', marginTop: "15px" }}>
                <div className={"not-visited"} style={{ marginTop: "0px" }}>
                  {responsestatus.filter(function (value) { return value === -1; }).length}
                </div>
                Not Visited
              </div>
              <div style={{ display: "flex", alignItems: 'center', marginTop: "15px" }}>
                <div className={"not-answered"} style={{ marginTop: "0px" }}>
                  {responsestatus.filter(function (value) { return value === 0; }).length}
                </div>
                Not Answered
              </div>
              <div style={{ display: "flex", alignItems: 'center', marginTop: "15px" }}>
                <div className={"marked"} style={{ marginTop: "0px" }}>
                  {responsestatus.filter(function (value) { return value === 1; }).length}
                </div>
                Marked
              </div>
              <div style={{ display: "flex", alignItems: 'center', marginTop: "15px" }}>
                <div className={"answered"} style={{ marginTop: "0px" }}>
                  {responsestatus.filter(function (value) { return value === 2; }).length}
                </div>
                Answered
              </div>
            </div>
            {/* <div style={{ marginTop: "20px", textAlign: 'center', position: 'absolute', bottom: "10px", width: "80%" }}>
              <button className="save-next" onClick={() => saveStudentTestResult()}>FINAL SUBMIT</button>
            </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ExamPage
// function mapStateToProps(state) {
//   const { question_list, instruct_list, otptoken_list, attempt_list } =
//     state.auth;
//   return {
//     question_list: question_list,
//     instruct_list: instruct_list,
//     otptoken_list: otptoken_list,
//     attempt_list: attempt_list,
//   };
// }

// export default connect(mapStateToProps, {
//   GETQUESTIONLIST,
//   GETINSTRUCTLIST,
//   GETATTEMPTLIST,
// })(ExamPage);


const TimeClock = (props) =>{
  
  const [clock, setClock] = useState("00:00:00");
  let clockDesign = (total_time_in_sec) =>{
    let hour = Math.floor(total_time_in_sec / 3600); 
    let min = Math.floor((total_time_in_sec % 3600)/60);
    let sec = Math.floor((total_time_in_sec % 3600)%60);
    setClock(addZero(hour)+":"+addZero(min)+":"+addZero(sec));  
  }

  useEffect(()=>{
    let total_available_time = props.totalTimeInSec;
    let clockInterval = setInterval(()=>{
      clockDesign(total_available_time)
      if(total_available_time < 1){
        clearInterval(clockInterval)
        props.submitAssessment()
      }else{
        total_available_time--;
      }
      
    }, 1000)
    return () =>{
      clearInterval(clockInterval)
    }
  }, [])

  const addZero = (time) =>{
    return time < 10 ? '0'+time : time; 
  }

  return(
    <p className='testname-heading'>{clock}</p>
  )
}