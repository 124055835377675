import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Plus from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import API from '../API';

const Sections = (props) => {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const [show, setShow] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(0);
    const blankSection = {
        course_id: props.id,
        section_name: '',
        section_seq: '',
        no_of_lessons: ''
    }


    const [sectionData, setSectionData] = useState([blankSection]);
    const addNewRange = () => {
        setSectionData([...sectionData, blankSection])
    }

    useEffect(() => {
        getEntries()
    }, [])

    const getEntries = async () => {
        await axios
            .get(site_ip + '/getCourseSections/' + props.id, jwtHeader)
            .then(function (response) {
                if (response.data.Sections.length !== 0) {
                    setSectionData(response.data.Sections)
                } else {
                    setSectionData([blankSection])
                }
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    }

    const saveEntry = async (item) => {

        //Check it is a new entry
        if (item.section_id === undefined) {
            // New Entry
            await axios
                .post(site_ip + '/createCourseSections', item, jwtHeader)
                .then(function (response) {
                    Swal.fire({
                        title: 'Success',
                        text: 'New Section added successfully',
                        icon: 'success',
                    });
                })
                .catch(function (error) {
                    console.log('error' + error);
                });
        } else {
            // Update Entry
            await axios
                .post(site_ip + '/setCourseSections', item, jwtHeader)
                .then(function (response) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Section data updated successfully',
                        icon: 'success',
                    });
                })
                .catch(function (error) {
                    console.log('error' + error);
                });
        }
    }


    const handleClose = () => setShow(false);

    const removeRecord = async () => {

        let item = {
            section_id: selectedRecord
        }
        handleClose();
        await axios
            .get(site_ip + '/deleteCourseSections/' + selectedRecord, jwtHeader)
            .then(function (response) {
                Swal.fire({
                    title: 'Success',
                    text: 'Section Details removed successfully',
                    icon: 'success',
                });
                getEntries();
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    }

    const deleteEntry = (section_id, index) => {
        if (section_id === undefined) {
            let all_ips = [...sectionData];
            all_ips.splice(index, 1);
            setSectionData(all_ips);
        } else {
            // Need to hit API
            setSelectedRecord(section_id);
            setShow(true);
        }
    }

    return (
        <Aux>
            <Container fluid>
                <Row>
                    <Col className="text-end">
                        <Button variant="dark" onClick={() => addNewRange()}>
                            <Plus /> New Section
                        </Button>
                    </Col>
                </Row>
                <Row className="rangeBox">
                    <Col>
                        <TableHeader />
                        {sectionData.map((item, index) => {
                            return (
                                <SectionItem key={index} item={item} saveEntry={(item) => saveEntry(item)} deleteEntry={(section_id) => deleteEntry(section_id, index)} />
                            )
                        })}
                    </Col>
                </Row>
            </Container>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    Are you sure to remove this record?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={removeRecord}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleClose} >No</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}

const TableHeader = () => {

    return (
        <Row className="tableRow" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            {/* <Col xs={2} style={{fontWeight:'bold', paddingLeft:"20px"}}>
                Course
            </Col> */}
            <Col xs={4} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                Section Name
            </Col>
            <Col xs={3} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                Section Sequence
            </Col>
            <Col xs={3} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                Number of Lessons
            </Col>
            <Col xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', }}>
                Actions
            </Col>
        </Row>
    )
}

const SectionItem = (props) => {
    const [item, setItem] = useState(props.item);

    useEffect(() => {
        setItem(props.item)
    }, [props.item])

    function validateSection() {
        let status = true;
        if (item.section_name.trim() === "") {
            status = false;
        } else if (item.section_seq === '' || item.section_seq < 1) {
            status = false;
        } else if (item.no_of_lessons === '' || item.no_of_lessons < 1) {
            status = false;
        }
        return status;
    }

    const onChange = (e) => {
        let prev_item = { ...item };
        prev_item[e.target.name] = e.target.value;
        setItem(prev_item)
    }

    const addNewRange = () => {
        if (!validateSection()) {
            alert("Invalid Enteries")
        } else {
            props.saveEntry(item)
        }
    }

    return (
        <Row className="tableRow">
            <Col xs={4}>
                <input className="form-input" onChange={onChange} name="section_name" value={item.section_name}></input>
            </Col>
            <Col xs={3}>
                <input className="form-input" onChange={onChange} type={"number"} min={1} name="section_seq" value={item.section_seq}></input>
            </Col>
            <Col xs={3}>
                <input className="form-input" onChange={onChange} type={"number"} min={1} name="no_of_lessons" value={item.no_of_lessons}></input>
            </Col>
            <Col xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button variant="success" className="rowActionButton" onClick={() => addNewRange()}>
                    <AddBoxIcon />
                </Button>
                <Button variant="danger" className="rowActionButton" onClick={() => props.deleteEntry(item?.section_id)}>
                    <DeleteForeverIcon />
                </Button>
            </Col>
        </Row>
    )
}

export default Sections 
