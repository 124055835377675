import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './common.css';
export default function TopBar() {
  return (
    <div className="d-flex align-items-center top-bar">
      <Col md={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <img src="/img/IADVAL.png" className="left_logo" alt="Logo" />
      </Col>
      <Col md={8} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <div className="headerTxt">
          <h5 style={{ color: '#FFF', marginBottom:0 }}>Dermatology Knowledge Network</h5>
        </div>
      </Col>
      <Col md={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <div style={{ backgroundColor: '#fff', padding: '15px 20px' }}>
          <img src={'/img/Knowledge.png'} className="right_logo" alt="Logo2" />
        </div>
      </Col>
    </div>
  );
}
