import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import Aux from '../../../hoc/_Aux';
import API from '../../API';
import { site_ip } from '../../../globalSetting';

export default function UnitEdit(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({
    unit_id: '',
    exam_id: '',
    subject_id: '',
    unit_name: '',
    book_id: '',
    chapter_id: '',
  });
  const fetchUnitById = async (unit_id) => {
    try {
      let data = await API.fetchUnitById(unit_id);
      fetchSubjects(data.exam_id);
      fetchSubjectBooks(data.subject_id);
      fetchChapters(data.book_id)  
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [subjects, setSubjects] = useState([]);

  const fetchSubjects = async (id) => {
    try {
      setSubjects(await API.fetchSubjectByExamId(id));
    } catch (error) {
      console.log(error);
    }
  };

  const [exams, setExams] = useState([]);
  const fetchExams = async () => {
    try {
      setExams(await API.fetchExams());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUnitById(params.unit_id);
    fetchExams();
  }, []);

  const [books, setBooks] = useState([]);
  const fetchSubjectBooks = async (subject_id) => {
    try {
      setBooks(await API.fetchSubjectBooks(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [chapters, setChapters] = useState([]);
  const fetchChapters = async (book_id) => {
    try {
      setChapters(await API.fetchChapters(book_id));
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChange = (e) => {
  //   setData({ ...data, [e.target.name]: e.target.value });
  //   if (e.target.name === 'subject_id') fetchSubjectBooks(e.target.value);
  //   if (e.target.name === 'book_id') fetchChapters(e.target.value);
  // };

  const handleChange = (e) => {
    if(e.target.name === "exam_id"){
      setData({ ...data, [e.target.name]: e.target.value, ['subject_id'] : "", ["book_id"]:"", ["chapter_id"]:""});  
      fetchSubjects(e.target.value)
    }else if(e.target.name === "subject_id"){
      setData({ ...data, [e.target.name]: e.target.value, ["book_id"]:"", ["chapter_id"]:""});  
      fetchSubjectBooks(e.target.value);
    }else if(e.target.name === "book_id"){
      setData({ ...data, [e.target.name]: e.target.value, ["chapter_id"]:""});  
      fetchChapters(e.target.value);
    }else{
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (
      data.exam_id !== '' &&
      data.subject_id !== '' && data.unit_name
    ) {
      await axios
        .post(`${site_ip}/setExamSubjectUnits`, data, jwtHeader)
        .then((response) => {
          // console.log(response);
          if (response.data.Status === 200) {
            Swal.fire('Successfully Updated');
            navigate('/admin/unit-listing');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('Please fill required fields');
    }
  };

  return (
    <Aux>
      <br />
      <Container fluid>
        <Row>
          <Col>
            <div align="right" className="mb-3">
              <Button onClick={() => navigate('/admin/unit-listing')}>
                Units List
              </Button>
            </div>
            <Card>
              <Card.Header>
                <Card.Title as="h5" style={{color:"#ffffff"}}>Edit Unit</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                  <Col md={3}>
                      <Form.Group>
                        <Form.Label>Exam</Form.Label>
                        <Form.Control
                          as="select"
                          name="exam_id"
                          className="mb-3"
                          value={data.exam_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Exam <span style={{color:"#ff0000"}}>*</span></option>
                          {exams?.map((val) => (
                            <option key={val.exam_id} value={val.exam_id}>
                              {val.exam_cd}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Subject </Form.Label>
                        <Form.Control
                          as="select"
                          name="subject_id"
                          className="mb-3"
                          value={data.subject_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Subject <span style={{color:"#ff0000"}}>*</span></option>
                          {subjects.map((val) => (
                            <option key={val.subject_id} value={val.subject_id}>
                              {val.subject_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Book</Form.Label>
                        <Form.Control
                          as="select"
                          name="book_id"
                          className="mb-3"
                          value={data.book_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Book </option>
                          {books?.map((val) => (
                            <option key={val.book_id} value={val.book_id}>
                              {val.book_title}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Chapter</Form.Label>
                        <Form.Control
                          as="select"
                          name="chapter_id"
                          className="mb-3"
                          value={data.chapter_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Chapter</option>
                          {chapters?.map((val) => (
                            <option key={val.chapter_id} value={val.chapter_id}>
                              {val.chapter_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Unit Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          name="unit_name"
                          value={data.unit_name}
                          type="text"
                          className="mb-3"
                          placeholder="Enter Unit Name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button onClick={handleSubmit} variant="primary">
                    Update Unit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
