import React, { useState } from 'react';
import '../../admin.css';

import { useParams, useNavigate } from 'react-router-dom';
import AddVideo  from './Add';
import Authors from './Authors';

const JournalAdd = () => {
    const params = useParams();
    const navigate = useNavigate();
    const videoId = params.id === undefined ? 0 : params.id;
    const [activeTab, setActiveTab] = useState(1);

    const openInEditMode = (id) =>{
        navigate('/admin/book/video/edit/' + id)
    }
    
    return (
        <>
            <div className="tab-div">
                <button onClick={() => setActiveTab(1)} className={activeTab === 1 ? "tab-div-btn active" : "tab-div-btn"}>{videoId === 0 ? "Add Video" : "Update Video"}</button>
                {videoId !== 0 ? <button onClick={() => setActiveTab(2)} className={activeTab === 2 ? "tab-div-btn active" : "tab-div-btn"}>Author Info</button> : null }
            </div>
            <div style={{backgroundColor:"#ff9900"}}>
                {activeTab === 1 ? <AddVideo videoId={videoId} moveToNextStep={(val) => openInEditMode(val)}  /> : null }
                {activeTab === 2 ? <Authors videoId={videoId} /> : null }
            </div>
        </>
    )
}

export default JournalAdd;