import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Button } from 'react-bootstrap';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from "./Components/Home";
import ContactUs from './Components/ContactUs';
import WebinarList from './Components/WebinarList';
import PatientEducation from './Components/PatientEducation';
import Case from './Components/Case';
import Commentary from './Components/Commentary';

// User Header
import UserHeader from "./user/common/Header";
import UserMenu from "./user/common/Menu";
import UserFooter from "./user/common/Footer";

import Login from "./user/Login";
import UserHome from "./user/screens/Home";
import News from './user/screens/News/News';
import NewsSubjects from "./user/screens/News/NewsSubjects";

// Medical Cases
import Cases from './user/screens/MedicalCases/Cases';
import CasesSubjects from "./user/screens/MedicalCases/CasesSubjects";

// Videos
import Videos from './user/screens/ExpertVideos/Videos';
import VideosSection from "./user/screens/ExpertVideos/VideosSection";

// Podcasts
import Podcasts from './user/screens/Podcasts/Podcasts';
import PodcastsSection from "./user/screens/Podcasts/PodcastsSection";

// Expert Commentry
import Commentry from './user/screens/ExpertCommentry/Commentry';
import CommentrySection from "./user/screens/ExpertCommentry/CommentrySection";

// Books
import BookSection from "./user/screens/Books/BookSection";
import BookDetails from "./user/screens/Books/BookDetails";
import BookSubDetails from "./user/screens/Books/BookSubDetails";
import ChapterDetails from "./user/screens/Books/BookChapterDetails";

// Assessment
import AssessmentSection from './user/screens/Assessment/AssessmentSection';
import Assessment from "./user/screens/Assessment/AssessmentSetup";

// CaseVignetts 
import CaseVignettsSection from "./user/screens/CaseVignetts/Section";

// Digital Atlas
import DigitalAtlasSection from "./user/screens/DigitalAtlas/Section";
import SubmitDigitalAtlas from "./user/screens/DigitalAtlas/SubmitDigitalAtlas";
// ProcedureGuide
import ProcedureGuideSection from "./user/screens/ProcedureGuide/Section";

// Courses
import CourseListing from "./user/screens/Courses/CourseListing";
import CourseDetails from './user/screens/Courses/CourseDetails';

// Forums
import ForumsListing from "./user/screens/Forums/Listing";
import ForumsDetails from "./user/screens/Forums/Details";

// Admin
import Admin from "./admin"

import ExamPage from './user/tests/ExamPage';
import ExamReview from './user/tests/ExamReview';
import NotLoggedInExamReview from './user/tests/NotLoggedInExamReview';
import InstructionPage from './user/tests/CustomTestInstructions';
// import ExamReview from './user/tests/ExamReview';
// import CustomTest from './user/tests/CustomTest';
// import TestSeries from './user/tests/TestSeries';
// import ThankyouPage from './user/tests/ThankyouPage';
// import TestSeriesExam from './user/tests/TestSeriesExam';
// import InstructionPage from './user/tests/InstructionPage';
// import PracticeTest from './user/tests/PracticeTest';

// import CustomTestInstructions from './user/tests/CustomTestInstructions';



import Temp from './temp';

function App() {

  const mainDivRef = useRef(null)

  const executeScroll = () => {
    mainDivRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    console.log("here");
  };

  const onScroll = () => {
    if (mainDivRef.current.scrollTop > 400) {
      document.getElementById("scrollTop").style.display = "block";
    } else if (mainDivRef.current.scrollTop < 400) {
      document.getElementById("scrollTop").style.display = "none";
    }
  };

  const LayoutOfHeader = (children) => (
    <>
      <div style={{ height: '80vh' }}>
        <div className="fixHeader">
          <Header />
        </div>
        <div className="fixBody" ref={mainDivRef} onScroll={onScroll}>{children}</div>
        <Button className="topArrowBtn" id={"scrollTop"} style={{ display: "none" }} onClick={() => executeScroll()}>
          <ArrowCircleUpIcon />
        </Button>
      </div>
    </>
  );

  const LayoutOfEndUser = (children) => (
    <>
      <div>
        <UserHeader />
        <UserMenu />
        <div style={{minHeight:"70vh"}}>{children}</div>
        <UserFooter />
      </div>
    </>
  );

  const LayoutWithHeaderFooter = (children) => (
    <>
      <div style={{ height: '80vh' }}>
        <div className="fixHeader">
          <Header />
        </div>
        <div className="fixBody" ref={mainDivRef} onScroll={onScroll}>
          {children}
          <div style={{position:"absolute", bottom:0, width:"100vw"}}>
            <Footer />
          </div>
        </div>
        <Button className="topArrowBtn" id={"scrollTop"} style={{ display: "none" }} onClick={() => executeScroll()}>
          <ArrowCircleUpIcon />
        </Button>
      </div>
    </>
  );

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/product/1/assessment"
          element={LayoutOfEndUser(<AssessmentSection />)}
        />
        <Route
          exact
          path="/section/:section/:name"
          element={LayoutOfEndUser(<AssessmentSection />)}
        />
        <Route
          exact
          path="/product/2/case_vignetts"
          element={LayoutOfEndUser(<CaseVignettsSection />)}
        />
        <Route
          exact
          path="/product/3/digital_atlas"
          element={LayoutOfEndUser(<DigitalAtlasSection />)}
        />
        <Route
          exact
          path="/digital_atlas/submit"
          element={<SubmitDigitalAtlas />}
        />
        <Route
          exact
          path="/product/4/expert_commentaries"
          element={LayoutOfEndUser(<CommentrySection />)}
        />
        <Route
          exact
          path="/product/5/expert_panel_videos"
          element={LayoutOfEndUser(<VideosSection />)}
        />
        <Route
          exact
          path="/product/6/eBooks"
          element={LayoutOfEndUser(<BookSection />)}
        />
        {/* <Route
          exact
          path="/product/7/news"
          element={LayoutOfEndUser(<Assessment />)}
        /> */}
        <Route
          exact
          path="/product/8/podcasts"
          element={LayoutOfEndUser(<PodcastsSection />)}
        />
        <Route
          exact
          path="/product/9/procedure_guide"
          element={LayoutOfEndUser(<ProcedureGuideSection />)}
        />
        <Route
          exact
          path="/"
          element={LayoutOfEndUser(<UserHome />)}
        />
        <Route
          exact
          path="/user/auth/:type"
          element={<Login />}
        />
        <Route
          exact
          path="/user/home"
          element={LayoutOfEndUser(<UserHome />)}
        />
        {/* <Route
          exact
          path="/user/books/list"
          element={LayoutOfEndUser(<BookListing />)}
        /> */}
        <Route
          exact
          path="/user/books/:id"
          element={LayoutOfEndUser(<BookDetails />)}
        />
        <Route
          exact
          path="/user/books/:id/section/:section_name"
          element={LayoutOfEndUser(<BookSubDetails />)}
        />
        <Route
          exact
          path="/user/books/:book_id/chapter/:chapter_id"
          element={LayoutOfEndUser(<ChapterDetails />)}
        />
        <Route
          exact
          path="/user/courses"
          element={LayoutOfEndUser(<CourseListing />)}
        />
        <Route
          exact
          path="/user/course/:id"
          element={LayoutOfEndUser(<CourseDetails />)}
        />
        <Route
          exact
          path="/user/forums"
          element={LayoutOfEndUser(<ForumsListing />)}
        />
        <Route
          exact
          path="/user/forums/:id"
          element={LayoutOfEndUser(<ForumsDetails />)}
        />
        {/* <Route
          exact
          path="/user/decision_point"
          element={LayoutOfEndUser(<UserHome />)}
        />
        <Route
          exact
          path="/user/cme"
          element={LayoutOfEndUser(<UserHome />)}
        />
        <Route
          exact
          path="/user/drugsanddiseases"
          element={LayoutOfEndUser(<UserHome />)}
        /> */}
        
        {/* <Route
          exact
          path="/user/news"
          element={LayoutOfEndUser(<NewsSubjects />)}
        />
        <Route
          exact
          path="/user/news/:id/:title"
          element={LayoutOfEndUser(<News />)}
        /> */}
        {/* <Route
          exact
          path="/user/medical_cases"
          element={LayoutOfEndUser(<CasesSubjects />)}
        />
        <Route
          exact
          path="/user/medical_cases/:id/:title"
          element={LayoutOfEndUser(<Cases />)}
        /> */}
        {/* <Route
          exact
          path="/user/expert_commentry"
          element={LayoutOfEndUser(<CommentrySubjects />)}
        /> */}
        <Route
          exact
          path="/user/expert_commentry/:id/:title"
          element={LayoutOfEndUser(<Commentry />)}
        />
        {/* <Route
          exact
          path="/user/videos"
          element={LayoutOfEndUser(<VideosSubjects />)}
        /> */}
        <Route
          exact
          path="/user/videos/:id/:title"
          element={LayoutOfEndUser(<Videos />)}
        />
        {/* <Route
          exact
          path="/user/podcasts"
          element={LayoutOfEndUser(<PodcastsSection />)}
        /> */}
        <Route
          exact
          path="/user/podcasts/:id/:title"
          element={LayoutOfEndUser(<Podcasts />)}
        />
        <Route
          exact
          path="/user/assessment/test"
          element={<ExamPage />}
        />
        <Route
          exact
          path="/user/assessment/instruction"
          element={<InstructionPage />}
        />
        <Route
          exact
          path="/user/assessment/review"
          element={<ExamReview />}
        />
        <Route
          exact
          path="/user/assessment/result"
          element={<NotLoggedInExamReview />}
        />
        <Route
          path="/admin/*"
          element={<Admin />}
        />
        

      </Routes>
    </Router>
  );
}

export default App;
