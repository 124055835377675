import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

export default function CreateBook(props) {
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        book_id: "",
        chapter_title: "",
        chapter_subtitle: "",
        section_id: "",
        chapter_no: "",
        seq_no: "",
        access_type_id: "",
        doi: "",
        first_page: "",
        last_page: "",
        description: "",
        chapter_file_name: "",
        downloadable: "Y",
        content_type_id: "",
        display_status: 0,
        created_by: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(blankValues);
    const [newChapterId, setNewChapterId] = useState(0);
    // Fields
    const [description, setDescription] = useState("");
    const [contentFile, setContentFile] = useState(null);
    const handleChange = (e) => {
        let { name, value } = e.target;
        let data = { ...values }
        if (name === "book_id") {
            data[name] = value;
            data["section_id"] = "";
            setValues(data);
            getSectionById(value);
        } else {
            data[name] = value;
            setValues(data)
        }
    }

    const handleChangeByName = (name, value) => {
        let data = { ...values }
        data[name] = value;
        setValues(data)
    }

    useEffect(() => {
        getBooks();
        getAccessTypeList();
        getContentTypeList();
        if (props.chapterId !== 0) {
            getChapterById(props.chapterId)
        }
    }, [])

    const getChapterById = (id) => {
        axios
            .get(site_ip + '/getBookChapterById/'+id, jwtHeader)
            .then((res) => {
                setValues(res.data.Content[0]);
                setDescription(res.data.Content[0].description);
                getSectionById(res.data.Content[0].book_id);
            })
            .catch((err) => console.log(err));
    };

    const [accessTypeList, setAccessTypeList] = useState([]);
    const getAccessTypeList = () => {
        axios
            .get(site_ip + '/getAccessType', jwtHeader)
            .then((res) => {
                setAccessTypeList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const [contentTypeList, setContentTypeList] = useState([]);
    const getContentTypeList = () => {
        axios
            .get(site_ip + '/getContentType', jwtHeader)
            .then((res) => {
                setContentTypeList(res.data.Contents);
            })
            .catch((err) => console.log(err));
    };

    const [bookList, setBookList] = useState([]);
    const getBooks = () => {
        let req = {}
        axios
        .post(site_ip + '/getBookSearch', req, jwtHeader)
        .then((res) => {
            setBookList(res.data.Books);
        })
        .catch((err) => console.log(err));
    };

    const [sectionList, setSectionList] = useState([]);
    const getSectionById = (id) => {
        axios
            .get(site_ip + '/getBookSection/' + id, jwtHeader)
            .then((res) => {
                setSectionList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };


    const onSubmit = async() => {
        let req = { ...values, description: description};

        if (req.book_id === "") {
            setErrorMessage("Please select book.")
            return;
        } else if (req.access_type_id === "") {
            setErrorMessage("Please select access type.")
            return;
        } else if (req.seq_no === "") {
            setErrorMessage("Please add sequance number.")
            return;
        } else if (req.chapter_title.trim() === "") {
            setErrorMessage("Please add chapter title.")
            return;
        } else if (req.chapter_no.trim() === "") {
            setErrorMessage("Please add chapter number.")
            return;
        }else if (req.content_type_id === "") {
            setErrorMessage("Please select content type.")
            return;
        }else if (req.first_page.toString().trim() === "") {
            setErrorMessage("Please add first page.")
            return;
        }else if (req.last_page.toString().trim() === "") {
            setErrorMessage("Please add last page.")
            return;
        }

        if (req.chapter_id === undefined) {
            axios
                .post(site_ip + '/createBookChapters', [req], jwtHeader)
                .then((res) => {
                    setNewChapterId(res.data.Content[0].chapter_id)
                    setSuccessMessage("New chapter added successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setBookChapters', req, jwtHeader)
                .then((res) => {
                    setSuccessMessage("Chapter details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        if (values.chapter_id === undefined) {
            props.moveToNextStep(newChapterId);
        }
    }


    return (
        <Aux>
            <div className='new-content-section' style={{backgroundColor:"#f5f5f5"}}>
                <div>
                    <Row>
                        <Col>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                Book <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.book_id}
                                                    as="select"
                                                    name="book_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {bookList.map((value, index) => (
                                                        <option value={value.book_id} key={index}>
                                                            {value.book_title}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Section
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.section_id}
                                                    as="select"
                                                    name="section_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {sectionList.map((value, index) => (
                                                        <option value={value.section_id} key={index}>
                                                            {value.section_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Access Type <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.access_type_id}
                                                    as="select"
                                                    name="access_type_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {accessTypeList.map((item, index)=>{
                                                        return(
                                                            <option value={item.access_type_id} key={index} >{item.access_type}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>DOI</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.doi}
                                                    name="doi"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Sequence Number <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="number"
                                                    value={values?.seq_no}
                                                    min={0}
                                                    name="seq_no"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Chapter Title <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.chapter_title}
                                                    name="chapter_title"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Chapter Subtitle</div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.chapter_subtitle}
                                                    name="chapter_subtitle"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Book Content Type <span className="mandatory"> *</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.content_type_id}
                                                    as="select"
                                                    name="content_type_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--Select--</option>
                                                    {contentTypeList.map((value, index) => (
                                                        <option value={value.content_type_id} key={index}>
                                                            {value.content_type_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Chapter No <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.chapter_no}
                                                    name="chapter_no"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>First Page <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.first_page}
                                                    name="first_page"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Last Page <span className="mandatory"> *</span></div></td>
                                        <td >
                                            <div className="col-md-5">
                                                <Form.Control
                                                    type="text"
                                                    value={values?.last_page}
                                                    name="last_page"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Downloadable
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={values?.downloadable === 'Y' ? true : false}
                                                    onChange={() => handleChangeByName("downloadable", values?.downloadable === 'Y' ? "N" : "Y")}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><div>Description</div></td>
                                        <td >
                                            <div className="col-md-12">
                                                <CKEditor
                                                    editor={Editor}
                                                    data={description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setDescription(data)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                Display Status
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-5">
                                                <Form.Control
                                                    value={values?.display_status}
                                                    as="select"
                                                    name="display_status"
                                                    onChange={handleChange}
                                                >
                                                    <option value={0}>In Active</option>
                                                    <option value={1}>Active</option>
                                                </Form.Control>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "8px" }}>

                        <div style={{ display: "flex" }}>
                            <button onClick={() => onSubmit()} className="primary-plus-button">{values?.chapter_id === undefined ? "Add" : "Update"} Chapter</button>
                            <div className='spacer' />
                            <button onClick={() => navigate('/admin/book/chapters')} className="secondary-button">Cancel</button>
                        </div>
                    </div>
                    <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                    <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
                </div>
            </div>
        </Aux>
    );
}
