import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import API from '../API';
import { useParams } from 'react-router-dom';
export default function WebinarDetails(props) {
  const params = useParams();
  const [video, setVideo] = useState([]);
  const handleVideo = async (webinar_id) => {
    try {
      setVideo(await API.fetchWebinarById(webinar_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVideo(params.webinar_id); /* eslint-disable-next-line */
  }, []);
  const renderAnything = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  };

  return (
    <Container>
      <h2 style={{ textAlign: 'center' }}>{video && video.webinar_title}</h2>
      <br />
      <Row>
        <Col>{renderAnything(video.webinar_desc)}</Col>
      </Row>
      <br />
      <Row>
        <Col>
          <video
            width="100%"
            controls
            poster="https://cf-images.ap-southeast-1.prod.boltdns.net/v1/static/6197335189001/d75d3def-1f2f-47b7-94ab-4114726ec7ab/3d3ba397-f3f7-4a5d-8713-1a03af7ff754/1280x720/match/image.jpg
"
          >
            <source src="movie.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Col>
      </Row>
      <br />
    </Container>
  );
}
