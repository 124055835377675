import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Table,
  Container,
  Modal
} from 'react-bootstrap';
import axios from 'axios';
import API from '../API';
import { site_ip } from '../../globalSetting';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';


export default function News() {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);


  const [news, setNews] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [category, setCategory] = useState([]);
  
  const fetchSubjects = async () => {
    try {
      setSubjects(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategories = async () => {
    try {
      setCategory(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  
  const blankFitlers = {
    category_id: '',
    subject_id:'',
    author_name:'',
    keywords:'',
    publish_date:''
  };

  // const handleSubject = async (subject_id) => {
  //   try {
  //     setCases(await API.fetchMedicalCaseBySubject(subject_id));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    handleSearch(blankFitlers);
    fetchSubjects();
    fetchCategories();
  }, []);
  
  const [search, setSearch] = useState(blankFitlers);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearch = async (filter) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    await axios
      .post(`${site_ip}/searchNews`, filter, jwtHeader)
      .then((res) => {
        setNews(res.data.Data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) =>{
    navigate('/admin/edit-news/'+id)
  }

  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        news_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deleteNews/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            handleSearch();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }


  return (
    <Container fluid>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Header as="h4">News</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        name="category_id"
                        value={search.category_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Category</option>
                        {category?.map((item, key) => (
                          <option key={key} value={item.category_id}>
                            {item.category_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        name="subject_id"
                        value={search.subject_id}
                        onChange={handleChange}
                        className="mb-3"
                        as="select"
                      >
                        <option value="">Select Subject</option>
                        {subjects?.map((item, key) => (
                          <option key={key} value={item.subject_id}>
                            {item.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Publish Date</Form.Label>
                      <Form.Control
                        name="publish_date"
                        value={search.publish_date}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Author Name</Form.Label>
                      <Form.Control
                        name="author_name"
                        value={search.author_name}
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Keywords</Form.Label>
                      <Form.Control
                        name="keywords"
                        value={search.keywords}
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                      />
                    </Form.Group>
                  </Col>

                  <Col className="mt-1">
                    <Button
                      variant="primary"
                      className="mt-4"
                      onClick={() => handleSearch(search)}
                    >
                      Search
                    </Button>
                    <Button
                      variant="warning"
                      className="mt-4 mb-0 m-2"
                      onClick={() =>{
                        setSearch(blankFitlers)
                        handleSearch(blankFitlers)
                      }
                      }
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        {/* <Col md={3}>
          <Card>
            <Card.Header as="h4">All Subjects</Card.Header>
            <Card.Body>
              {subjects?.map((item) => (
                <ul>
                  <li>
                    <a
                      onClick={() => handleSubject(item.subject_id)}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.subject_name}
                    </a>
                  </li>
                </ul>
              ))}
            </Card.Body>
          </Card>
        </Col> */}
        <Col md={12}>
          {/* <Card>
            <Card.Header as="h5">News List</Card.Header>
            <Card.Body> */}
              <table className="customTable">
                <thead>
                  <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Subject</th>
                    <th>Author</th>
                    <th>Publish Date</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {news?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1)}</td>
                      <td>{item.news_title}</td>
                      <td>{item.category_name}</td>
                      <td>{item.subject_name}</td>
                      <td>{item.author_name}</td>
                      <td>{item.publish_date}</td>
                      <td>
                        <div style={{display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(item.news_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(item.news_id)}>
                            <DeleteForeverIcon />
                          </Button>
                        </div> 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            {/* </Card.Body>
          </Card> */}
        </Col>
      </Row>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Container>
  );
}
