import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import SuccessModal from '../../Common/SuccessModal';
import ErrorModal from '../../Common/ErrorModal';

export default function Authors(props) {
    
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    
    const blankValues = {
        video_id: parseInt(props.videoId),
        author_id: "",
        author_seq: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
     
    const [values, setValues] = useState(blankValues);
    const [authorList, setAuthorList] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(0);
    useEffect(() => {
        getAuthorsByvideoId(props.videoId)
    }, [])

    const getAuthorsByvideoId = (id) => {
        axios
            .get(site_ip + '/getVideoAuthorByVideo/' + id, jwtHeader)
            .then((res) => {
                setAuthorList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const addOneRow = () => {
        let lists = [...authorList];
        lists.push(values);
        setAuthorList(lists);
    }

    const removeOneRow = (id, index) => {
        if (id === 0) {
            let lists = [...authorList];
            lists.splice(index, 1);
            setAuthorList(lists);
        } else {
            setConfirmationModal(id);
        }
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        getAuthorsByvideoId(props.videoId)
    }

    const handleClose = () => {
        setConfirmationModal(0);
    }

    const deleteRecord = () => {
        let req = { vauth_id: confirmationModal };
        axios.post(site_ip + "/deleteVideoAuthor", req, jwtHeader)
            .then((res) => {
                getAuthorsByvideoId(props.videoId)
                setConfirmationModal(0)
            }).catch((err) =>
                console.log(err)
            )
    }

    return (
        <Aux>
            <div className='new-content-section' style={{backgroundColor:"#f5f5f5"}}>
                <div>
                    <Row>
                        <Col>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>
                                            <p>Seq No</p>
                                        </th>
                                        <th>
                                            <p>Author</p>
                                        </th>
                                        <th>
                                            <p>First Name</p>
                                        </th>
                                        <th>
                                            <p>Middle Name</p>
                                        </th>
                                        <th>
                                            <p>Last Name</p>
                                        </th>
                                        <th>
                                            <p>Email</p>
                                        </th>
                                        <th>
                                            <p>Role</p>
                                        </th>
                                        <th>
                                            <p>Action</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {authorList.map((item, index) => {
                                        return (
                                            <Author videoId={props.videoId} item={item} key={index} removeOneRow={() => removeOneRow(item.vauth_id === undefined ? 0 : item.vauth_id, index)} setSuccessMessage={(val) => setSuccessMessage(val)} />
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: "100%" }}>
                                <button onClick={() => addOneRow()} className="primary-plus-button">Add More</button>
                            </div>
                        </Col>
                    </Row>
                    <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
                </div>
            </div>
            <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to remove this record?</Modal.Body>
                <Modal.Footer>
                    <button
                        style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                        className="btn btn-sm" variant="secondary" onClick={handleClose}>
                        Close
                    </button>
                    <button
                        style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                        className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


const Author = (props) => {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(props.item);
    const [searchStr, setSearchStr] = useState("");
    const [authorList, setAuthorList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        setValues(props.item)
        if (props.item.author_id !== "") {
            searchAuthorById(props.item.author_id)
        }
    }, [props.item])

    useEffect(() => {
        if (searchStr === "") {
            setAuthorList([])
        } else {
            searchAuthor(searchStr)
        }
    }, [searchStr])



    const selectThisOption = (row) => {
        setSelectedOption(row);
        setSearchStr(row?.first_name + " " + row?.last_name)
        handleChange("author_id", row?.author_id)
    }

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const searchAuthorById = (id) => {
        axios
            .get(site_ip + '/getAuthorById/' + id, jwtHeader)
            .then((res) => {
                setSelectedOption(res.data.Content[0])
                setSearchStr(res.data.Content[0]?.first_name + " " + res.data.Content[0]?.last_name)
            })
            .catch((err) => console.log(err));
    }

    const searchAuthor = (str) => {
        axios
            .get(site_ip + '/getAuthorsByName/' + str, jwtHeader)
            .then((res) => {
                setAuthorList(res.data.Content)
            })
            .catch((err) => console.log(err));
    }

    const addAuthor = () => {
        let req = { ...values };
        if (req.author_seq === "" || req.author_seq < 0) {
            setErrorMessage("Please add display sequence.")
            return;
        } else if (req.author_id === "") {
            setErrorMessage("Please select author from list.")
            return;
        }
        

        if (values.id === undefined) {
            axios
                .post(site_ip + '/createVideoAuthors', req, jwtHeader)
                .then((res) => {
                    props.setSuccessMessage("New author added to video successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setVideoAuthors', req, jwtHeader)
                .then((res) => {
                    props.setSuccessMessage("Author details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }


    return (
        <tr>
            <td style={{ width: "10%" }}>
                <div style={{ paddingTop: "10px" }}>
                    <Form.Control
                        type="number"
                        value={values?.author_seq}
                        name="author_seq"
                        min={0}
                        onChange={(e) => handleChange("author_seq", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "20%" }}>
                <div style={{ paddingTop: "5px", position: "relative" }}>
                    <Form.Control
                        type="text"
                        value={searchStr}
                        name="author_seq"
                        onChange={(e) => setSearchStr(e.target.value)}
                    />
                    <div className="fixed-author-list">
                        {authorList.map((item, index) => {
                            return (
                                <button key={index} className='option-btn' onClick={() => selectThisOption(item)} >{item.first_name + " " + item.last_name}</button>
                            )
                        })}
                    </div>
                </div>
            </td>
            <td style={{ width: "12%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        readOnly
                        value={selectedOption?.first_name}
                        name="author_seq"
                    />
                </div>
            </td>
            <td style={{ width: "12%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        readOnly
                        value={selectedOption?.middle_name}
                        name="author_seq"
                    />
                </div>
            </td>
            <td style={{ width: "12%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        readOnly
                        value={selectedOption?.last_name}
                        name="author_seq"
                    />
                </div>
            </td>
            <td style={{ width: "12%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        readOnly
                        value={selectedOption?.email}
                        name="author_seq"
                    />
                </div>
            </td>
            <td style={{ width: "12%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        readOnly
                        value={""}
                        name="author_seq"
                    />
                </div>
            </td>
            <td style={{ textAlign: 'center', width: "20px" }}>
                <div style={{ display: "flex" }}>
                    <button style={{ padding: "5px", marginTop: "5px", marginRight: "10px" }} onClick={() => addAuthor()} className="primary-plus-button">
                        {/* <DeleteOutlineIcon style={{ fontSize: "20px" }} onClick={() => props.removeOneRow()} /> */}
                        {values.vauth_id === undefined ? "Add" : "Update"}
                    </button>
                    <button style={{ padding: "5px", marginTop: "5px", backgroundColor: "#ff4d4d" }} onClick={() => props.removeOneRow()} className="primary-plus-button">
                        Remove
                        {/* <DeleteOutlineIcon style={{ fontSize: "20px" }} onClick={() => props.removeOneRow()} /> */}
                    </button>
                </div>
            </td>
            <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
        </tr>
    )
} 