import React, {Component} from 'react';
import Aux from "../../hoc/_Aux";
import {Button, Card, Col, Tab, Tabs, Form, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";


const ExamsListing = () => {

    return (
        <Aux>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5"></Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam </Form.Label>
                                            <Form.Control as="select">
                                                <option>Select Exam</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Exam Type</Form.Label>
                                            <Form.Control as="select">
                                                <option>Select Exam Type</option>
                                            </Form.Control>
                                        </Form.Group>

                                    </Form>

                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Date From</Form.Label>
                                            <Form.Control type="date" placeholder="Date From"/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Date To</Form.Label>
                                            <Form.Control type="date" placeholder="Date To"/>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={2} className="mt-4">
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Button variant="primary">
                                                Search
                                            </Button>
                                        </Form.Group>

                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Tabs variant="pills" defaultActiveKey="upcoming" className="mb-3">
                        <Tab eventKey="upcoming" title="UPCOMING">
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Exam</th>
                                    <th>Exam Type</th>
                                    <th>Exam Date</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Result Date</th>
                                    <th>Exam Url</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>JEE</td>
                                    <td>Scholarship</td>
                                    <td>05-06-2021</td>
                                    <td>12:44 PM</td>
                                    <td>01:44 PM</td>
                                    <td>06-06-2021</td>
                                    <td>http://localhost/uniq_dev%28backup%29/common_exam/NDY%3D</td>
                                    <td><Link to={{pathname: '/exam-setup-level1'}}><i
                                        className="feather icon-edit"/></Link></td>
                                </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="completed" title="COMPLETED">
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Aux>
    );
}


export default ExamsListing;