import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Container,
  Modal
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';
import DataTable from 'react-data-table-component';
import '../master.css';
import Aux from '../../../hoc/_Aux';
import API from '../../API';
import {tableCustomStyles} from "../../../customTableStyle";
export default function TopicsList(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);


  const [topics, setTopics] = useState([]);
  const fetchTopics = async () => {
    try {
      setTopics(await API.fetchTopics());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const editButton = (id) => {
    navigate('/admin/topic-edit/' + id)
  }


  const handleClose = () => setShow(false);

  const removeRecord = async () => {
    let data = {
      topic_id: selectedRecord
    }
    handleClose();
    await axios
      .post(site_ip + '/deleteTopic', data, jwtHeader)
      .then(function (response) {
        Swal.fire({
          title: 'Success',
          text: 'Record removed successfully',
          icon: 'success',
        });
        fetchTopics();
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  }

  const deleteEntry = (id) => {
    setSelectedRecord(id);
    setShow(true);
  }

  const columns = [
    // {
    //   name: 'First Name',
    //   selector: row => row.first_name,
    // },
    {
      name: 'Subject',
      selector: row => row.subject_name,
    },
    {
      name: 'Topic',
      selector: row => row.topic_name,
    },
    {
      name: 'Topic Description',
      selector: row => row.topic_desc,
    },
    {
      name: 'Action',
      width: "120px",
      cell: (row) => (
        // <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        //   <button style={{ padding: "5px" }} onClick={() => clickHandler(row.author_id)} className="primary-plus-button">
        //     <EditIcon style={{ fontSize: "20px" }} />
        //   </button>
        // </div>
        <div style={{ paddingTop: "5px", paddingBottom: "5px", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Button variant="success" className="rowActionButton" onClick={()=> editButton(row.topic_id)}>
            <EditIcon />
          </Button>
          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(row.topic_id)}>
            <DeleteForeverIcon />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];

  return (
    <Aux>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <h4>Topic List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/topic-add')}
            >
              Add Topic
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{height:"15px"}} />
            <DataTable
              customStyles={tableCustomStyles}
              columns={columns}
              data={topics}
              pagination
              dense
            />
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Aux>
  );
}
