import React, { useState } from 'react';
import '../../admin.css';

import { useParams, useNavigate } from 'react-router-dom';
import CreateBook  from './Components/CreateBook';
import Volumes  from './Components/Volumes';
import Section  from './Components/Section';
import Chapters  from './Components/Chapters';
import Authors from './Components/Authors';
import Editors from './Components/Editors';
import Prices from './Components/Prices';
import SupplymentryFiles from './Components/SupplementaryFiles';
const JournalAdd = () => {
    const params = useParams();
    const navigate = useNavigate();
    const bookId = params.book_id === undefined ? 0 : params.book_id;
    const [activeTab, setActiveTab] = useState(1);

    const openInEditMode = (id) =>{
        navigate('/admin/book/edit/' + id)
    }


    return (
        <>
            <div className="tab-div">
                <button onClick={() => setActiveTab(1)} className={activeTab === 1 ? "tab-div-btn active" : "tab-div-btn"}>Create Book</button>
                {bookId !== 0 ? <button onClick={() => setActiveTab(2)} className={activeTab === 2 ? "tab-div-btn active" : "tab-div-btn"}>Volume</button> : null }
                {bookId !== 0 ? <button onClick={() => setActiveTab(3)} className={activeTab === 3 ? "tab-div-btn active" : "tab-div-btn"}>Section</button> : null }
                {bookId !== 0 ? <button onClick={() => setActiveTab(4)} className={activeTab === 4 ? "tab-div-btn active" : "tab-div-btn"}>Chapters</button> : null }
                {bookId !== 0 ? <button onClick={() => setActiveTab(5)} className={activeTab === 5 ? "tab-div-btn active" : "tab-div-btn"}>Author</button> : null }
                {bookId !== 0 ? <button onClick={() => setActiveTab(6)} className={activeTab === 6 ? "tab-div-btn active" : "tab-div-btn"}>Editor</button> : null }
                {bookId !== 0 ? <button onClick={() => setActiveTab(7)} className={activeTab === 7 ? "tab-div-btn active" : "tab-div-btn"}>Supplementry File</button> : null }
                {bookId !== 0 ? <button onClick={() => setActiveTab(8)} className={activeTab === 8 ? "tab-div-btn active" : "tab-div-btn"}>Book Price</button> : null }
            </div>
            <div>
                {activeTab === 1 ? <CreateBook bookId={bookId} moveToNextStep={(val) => openInEditMode(val)}  /> : null }
                {activeTab === 2 ? <Volumes bookId={bookId} /> : null }
                {activeTab === 3 ? <Section bookId={bookId} /> : null }
                {activeTab === 4 ? <Chapters bookId={bookId} /> : null }
                {activeTab === 5 ? <Authors bookId={bookId} /> : null }
                {activeTab === 6 ? <Editors bookId={bookId} /> : null }
                {activeTab === 7 ? <SupplymentryFiles bookId={bookId} /> : null }
                {activeTab === 8 ? <Prices bookId={bookId} /> : null }
               
            </div>
        </>
    )
}

export default JournalAdd;