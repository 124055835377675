import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const AssessmentInstruction = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const startTest = () => {
    navigate('/user/assessment/test', {
      state: { data: location.state.data, test_type: location.state.test_type },
    });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12} className="mx-auto">
            <div className="p-4 px-5">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="text-danger">{location.state.data[0]?.subject_name}</h3>
                  <div class="d-flex border-top border-bottom py-4">
                    <div class="d-flex flex-column">
                      <small>No of Question</small>
                      <span>
                        <span class="text-danger">{location?.state?.data?.length}</span> Question
                      </span>
                    </div>
                    <div class="ms-auto">
                      <div class="d-flex flex-column">
                        <small>Duration</small>
                        <span class="fw-bold">
                          <span class="text-danger font-weight-bold">{location?.state?.data?.length} minutes</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '75vh', overflowY:'auto' }}>
                    <div>
                      <h2>Instructions</h2>

                      <ol start="1">
                        <li>
                          <p>
                            The clock will be set at the server. The countdown
                            timer at the top right corner of screen will display
                            the remaining time available for you to complete the
                            examination. When the timer reaches zero, the
                            examination will end by itself. You need not
                            terminate the examination or submit your paper.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Question Palette displayed on the right side of
                            screen will show the status of each question using
                            one of the following symbols:
                          </p>
                          <ul class="que-ans-states hide-on-railway">
                            <li>
                              <div style={{display:"flex", alignItems:'center'}}>
                                You have not visited the question yet.
                                <div className='not-visited' style={{marginTop:"0px", marginLeft:"15px", border:"1px solid #0000ff"}}>

                                </div>
                              </div>
                            </li>
                            <li>
                              <div style={{display:"flex", alignItems:'center', marginTop:"10px"}}>
                                You have not answered the question.
                                <div className='not-answered' style={{marginTop:"0px", marginLeft:"15px", border:"1px solid #0000ff"}}>

                                </div>
                              </div>
                            </li>
                            <li>
                              <div style={{display:"flex", alignItems:'center', marginTop:"10px"}}>
                                You have answered the question.
                                <div className='answered' style={{marginTop:"0px", marginLeft:"15px", border:"1px solid #0000ff"}}>

                                </div>
                              </div>
                            </li>
                            <li>
                              <div style={{display:"flex", alignItems:'center', marginTop:"10px"}}>
                                  You have NOT answered the question, but have marked the question for review.
                                <div className='marked' style={{marginTop:"0px", marginLeft:"15px", border:"1px solid #0000ff"}}>

                                </div>
                              </div>
                            </li>
                            <li>
                              You have answered the question, but marked it for review.
                            </li>
                          </ul>
                        </li>
                      </ol>
                      <p>
                        The <b>Mark For Review</b> status for a question simply
                        indicates that you would like to look at that question
                        again. If a question is answered, but marked for review,
                        then the answer will be considered for evaluation unless
                        the status is modified by the candidate.
                      </p>
                      <b>Navigating to a Question :</b>
                      <ol start="3">
                        <li>
                          <p>To answer a question, do the following:</p>
                          <ol>
                            <li>
                              Click on the question number in the Question
                              Palette at the right of your screen to go to that
                              numbered question directly. Note that using this
                              option does NOT save your answer to the current
                              question.
                            </li>
                            <li>
                              Click on <b>Save &amp; Next</b> to save your
                              answer for the current question and then go to the
                              next question.
                            </li>
                            <li>
                              Click on <b>Mark for Review <span class="hide-on-railway">&amp; Next</span></b> to save your answer for the current question and also mark it for review <span class="hide-on-railway">
                                , and then go to the next question.
                              </span>
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <p>
                        Note that your answer for the current question will not
                        be saved, if you navigate to another question directly
                        by clicking on a question number{' '}
                        <span>without saving</span>
                        the answer to the previous question.
                      </p>
                      <p>
                        You can view all the questions by clicking on the <b>Question Paper</b> button.{' '}
                        <span class="text-danger">
                          This feature is provided, so that if you want you can
                          just see the entire question paper at a glance.
                        </span>
                      </p>
                      <h4>Answering a Question :</h4>
                      <ol start="4">
                        <li>
                          <p>
                            Procedure for answering a multiple choice (MCQ) type
                            question:
                          </p>
                          <ol>
                            <li>
                              Choose one answer from the 4 options (A,B,C,D)
                              given below the question
                              <span class="hide-on-railway">
                                , click on the bubble placed before the chosen
                                option.
                              </span>
                            </li>
                            <li class="hide-on-railway">
                              To deselect your chosen answer, click on the
                              bubble of the chosen option again or click on the
                              {' '}<b>
                                <span class="hide-on-railway">
                                  Clear Response
                                </span>
                              </b>{' '}
                              button
                            </li>
                            <li>
                              To change your chosen answer, click on the bubble
                              of another option.
                            </li>
                            <li>
                              To save your answer, you MUST click on the{' '}
                              <b>Save &amp; Next</b>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <p>
                            To mark a question for review, click on the <b>Mark for Review & Next</b> button. If an answer is selected for a question that <b>Marked for Review</b>, that answer will be considered in the evaluation unless the status is modified by the candidate.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btnbox d-flex align-items-center mt-3">
                <Button variant="outline-danger">Cancel</Button>
                <Button className="btn btn-danger ms-auto" onClick={startTest}>
                  Start Test
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AssessmentInstruction;
// function mapStateToProps(state) {
//   const { instruct_list, otptoken_list } = state.auth;
//   return {
//     instruct_list: instruct_list,
//     otptoken_list: otptoken_list,
//   };
// }

// export default connect(mapStateToProps, { GETINSTRUCTLIST, GETQUESTIONLIST })(
//   CustomTestInstructions
// );
