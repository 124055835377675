import React, { useEffect, useState } from "react"
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Col, Row } from "react-bootstrap";
import "./book.css";
import { useParams, useNavigate } from "react-router-dom";
// import Iframe from 'react-iframe'
// import IframeResizer from 'iframe-resizer-react'
import IframeResizer from 'iframe-resizer-react'
const BookDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const [bookDetails, setBookDetails] = useState({})
    const [chapterDetails, setChapterDetails] = useState({})
    const [chapterList, setChapterList] = useState([])
    const [activeChapterPos, setActiveChapterPos] = useState(0);
    const [authors, setAuthors] = useState([]);
    useEffect(() => {
        getBookById(params?.book_id);
        getChapters(params?.book_id);
        getAuthorsByChapterId(params?.chapter_id)
    }, [])

    const getBookById = (id) => {
        axios
            .get(site_ip + '/getBookById/' + id, jwtHeader)
            .then((res) => {
                setBookDetails(res.data.Books[0]);

            })
            .catch((err) => console.log(err));
    };

    const getAuthorsByChapterId = (id) => {
        axios
            .get(site_ip + '/getChapterAuthorByChapter/' + id, jwtHeader)
            .then((res) => {
                // setAuthorList(res.data.Content);
                let authors_list = res.data.Content;
                authors_list.sort((a, b) => b.author_disp_seq - a.author_disp_seq);
                let authors = [];
                authors_list.map((item) => {
                    let auth = item?.salutation + " " + item?.first_name + " " + item?.last_name
                    authors.push(auth)
                })
                setAuthors(authors);
            })
            .catch((err) => console.log(err));
    };

    const getChapters = (id) => {
        axios
            .get(site_ip + '/getBookChapter/' + id, jwtHeader)
            .then((res) => {
                setChapterList(res.data.Content);
                let chapterPos = res.data.Content.findIndex((item) => item.chapter_id === parseInt(params?.chapter_id))
                // let chapter = res.data.Content.filter((item)=> item.chapter_id === parseInt(params?.chapter_id))
                if (chapterPos !== -1) {
                    setChapterDetails(res.data.Content[chapterPos])
                    setActiveChapterPos(chapterPos);
                }

            })
            .catch((err) => console.log(err));
    };

    const changeChapter = (n) => {
        let chapter_list = [...chapterList];
        let new_chapter = chapter_list[activeChapterPos + n];
        setChapterDetails(new_chapter)
        setActiveChapterPos(activeChapterPos + n);
        navigate("/user/books/" + params?.book_id + "/chapter/" + new_chapter?.chapter_id);
    }

    return (
        <>
            <Container style={{ marginTop: "25px" }}>
                <Row>
                    <Col>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/home") }}>Home</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/books/list") }}>Books</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/books/" + params?.book_id) }}>{bookDetails?.book_title}</a>
                    </Col>
                    <Col style={{ display: "flex", alignItems: "flex-end", justifyContent: 'flex-end' }}>
                        {activeChapterPos > 0 ?
                            <a className="burder-link" style={{ fontWeight: "bold" }} href="/" onClick={(e) => { e.preventDefault(); changeChapter(-1) }}>{"< Previous Chapter"}</a> : null}
                        {activeChapterPos > 0 && (activeChapterPos < (chapterList.length - 1)) ? <span style={{ padding: "0px 5px" }}>|</span> : null}
                        {activeChapterPos < (chapterList.length - 1) ? <a className="burder-link" style={{ fontWeight: "bold" }} href="/" onClick={(e) => { e.preventDefault(); changeChapter(1) }}>{"Next Chapter >"}</a> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <div>
                            <img src={bookDetails?.book_logo} className="book-detail-logo" style={{ maxWidth: "75%" }} />
                        </div>
                    </Col>
                    <Col md={10}>
                        <div style={{ paddingTop: "10px" }}>
                            <p className="black label-2 mb-5 book-main-title">{chapterDetails?.chapter_title}</p>
                            <p className="authors-name">
                                {authors.join(", ")}
                            </p>
                        </div>
                        <Row style={{ paddingTop: "20px" }}>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="far fa-file-pdf"></i> Download Chapter PDF</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-share-alt"></i> Share</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-book"></i> Get Citation</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-search"></i> Search Book</button>
                            </Col>
                            <Col>
                                <button className="booksubdetails-action-btn"><i className="fas fa-pencil-alt"></i> Annotate</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ margin: 0 }}>
                    <Col style={{ padding: "15px 0px", borderTop: "1px solid #ccc" }}>
                        {chapterDetails?.chapter_url !== undefined ?
                            <div>
                                <IframeResizer
                                    log
                                    src={chapterDetails?.chapter_url}
                                    style={{ width: '1px', minWidth: '100%'}}
                                    />
                            </div> : null}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BookDetails

const RawHTML = ({ children, className = "" }) => {
    return (
        children !== undefined ?
            <div className={className}
                dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }} /> : <div />
    )
}

