import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useNavigate, useParams } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import API from '../API';
import Aux from '../../hoc/_Aux';
import { fileUpload } from '../Common/fileUploads';
export default function EditCase(props) {
  const navigate = useNavigate();
  const params = useParams(); 
  const [editornavigateState, setEditornavigateState] = useState("");
  const [editorDescriptionState, setEditorDescriptionState] = useState("");

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [uploading, setUploading] = useState(false);

  const initialState = {
    case_id: '',
    category_id: '',
    subject_id: '',
    book_id: '',
    chapter_id: '',
    case_name: '',
    patient_name: '',
    case_file_name: '',
    case_images_names: '',
    case_video_names: '',
  };
  const [values, setValues] = useState(initialState);

  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === 'book_id') {
      fetchChapters(e.target.value);
    }
  };
  const [categories, setCategories] = useState([]);
  const [subject, setSubject] = useState([]);
  const [book, setBook] = useState([]);
  const [chapter, setChapter] = useState([]);
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjects = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBooks = async () => {
    try {
      setBook(await API.fetchBooks());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchChapters = async (book_id) => {
    try {
      setChapter(await API.fetchChapters(book_id));
    } catch (error) {
      console.log(error);
    }
  };
  let data;
  const fetchMedicalCaseById = async (case_id) => {
    try {
      data = await API.fetchMedicalCaseById(case_id);
      // console.log(data);
      try{
        setEditorDescriptionState(data.case_desc);
      }catch(e){}

      try{
        // setEditornavigateState(data.patient_navigate);
      }catch(e){}
      
      setValues((prev) => ({
        ...prev,
        case_id: data.case_id,
        category_id: data.category_id,
        subject_id: data.subject_id,
        book_id: data.book_id,
        chapter_id: data.chapter_id,
        case_name: data.case_name,
        patient_name: data.patient_name,
        case_file_name: data.case_file_name,
        case_images_names: data.case_images_names,
        case_video_names: data.case_video_names,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchMedicalCaseById(params.case_id);
    fetchCategories();
    fetchSubjects();
    fetchBooks();
  }, []);

  const handleSubmit = async (e) => {

    if(values.case_name.trim() === ""){
      alert("Please fill required fields");
      return;
    }else if(values.patient_name.trim() === ""){
      alert("Please fill required fields");
      return;
    }

    setUploading(true)

    var convertednavigateState = editornavigateState;
    var convertedDescriptionState = editorDescriptionState;
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    let fileAttachment = null;
    if(file !== null){
      fileAttachment = await fileUpload(file)
    }

    let imageAttachment = null;
    if(image !== null){
      imageAttachment = await fileUpload(image)
    }

    let videoAttachment = null;
    if(video !== null){
      videoAttachment = await fileUpload(video)
    }

    setUploading(false);

    await axios
      .post(
        site_ip + '/setMedicalCases',
        {
          case_id: values.case_id,
          category_id: values.category_id,
          subject_id: values.subject_id,
          book_id: values.book_id,
          chapter_id: values.chapter_id,
          case_name: values.case_name,
          case_desc: convertedDescriptionState,
          patient_name: values.patient_name,
          patient_navigate: convertednavigateState,
          case_images_names: imageAttachment === null ? values.case_images_names : imageAttachment,
          case_file_name: fileAttachment === null ? values.case_file_name : fileAttachment,
          case_video_names: videoAttachment === null ? values.case_video_names : videoAttachment,
        },
        jwtHeader
      )
      .then((res) => {
        // console.log(res)
        if (res.data.message === 200) {
          Swal.fire('Medical Case updated');
          navigate('/admin/medical-case');
        }
      })
      .catch((err) => console.log(err));
  };

  
  return (
    <Aux>
      <Container fluid>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h5">Edit Medical Case</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        name="category_id"
                        className="mb-3"
                        value={values.category_id}
                        onChange={handleChange}
                      >
                        <option>Select Category</option>
                        {categories.map((item, index) => (
                          <option key={index} value={item.category_id}>
                            {item.category_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="subject_id"
                        value={values.subject_id}
                        onChange={handleChange}
                      >
                        <option>Select Subject</option>
                        {subject.map((item, index) => (
                          <option key={index} value={item.subject_id}>
                            {item.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Book</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="book_id"
                        value={values.book_id}
                        onChange={handleChange}
                      >
                        <option>Select Book</option>
                        {book.map((item, index) => (
                          <option key={index} value={item.book_id}>
                            {item.book_title}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Chapter</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="chapter_id"
                        value={values.chapter_id === null ? '' : values.chapter_id}
                        onChange={handleChange}
                      >
                        <option value={null} >Select Chapter</option>
                        {chapter.map((item, index) => (
                          <option key={index} value={item.chapter_id}>
                            {item.chapter_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Case Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="case_name"
                        className="mb-3"
                        value={values.case_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Patient Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="patient_name"
                        className="mb-3"
                        value={values.patient_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>Case Description</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                            editor={Editor}
                            data={editorDescriptionState}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setEditorDescriptionState(data)
                            }}
                          />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>Patient navigate</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                            editor={Editor}
                            data={editornavigateState}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setEditornavigateState(data)
                            }}
                          />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Case File</Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                        name="case_file_name"
                        className="mb-3"
                        // value={values.case_file_name}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          handleChange(e);
                        }}
                      />
                    </Form.Group>
                    {values?.case_file_name !== undefined && values?.case_file_name !== null && values?.case_file_name !== "" ? 
                    <a href={values.case_file_name} style={{fontSize:"12px", color:"#0000ff"}} >{values.case_file_name}</a> : null }
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Case Image</Form.Label>
                      <Form.Control
                        type="file"
                        className="mb-3"
                        accept="image/*"
                        name="case_images_names"
                        // value={values.case_images_names}
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          handleChange(e);
                        }}
                      />
                    </Form.Group>
                    {values?.case_images_names !== undefined && values?.case_images_names !== null && values?.case_images_names !== "" ? 
                    <a href={values.case_images_names} style={{fontSize:"12px", color:"#0000ff"}} >{values.case_images_names}</a> : null }
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Case Video</Form.Label>
                      <Form.Control
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        className="mb-3"
                        name="case_video_names"
                        // value={values.case_video_names}
                        onChange={(e) => {
                          setVideo(e.target.files[0]);
                          handleChange(e);
                        }}
                      />
                    </Form.Group>
                    {values?.case_video_names !== undefined && values?.case_video_names !== null && values?.case_video_names !== "" ? 
                    <a href={values.case_video_names} style={{fontSize:"12px", color:"#0000ff"}} >{values.case_video_names}</a> : null }
                  </Col>
                </Row>
                <div style={{height:"30px"}}></div>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={uploading}>
          <Modal.Body style={{textAlign:'center'}}>Please wait, while we are uploading.</Modal.Body>
        </Modal>
      </Container>
    </Aux>
  );
}
