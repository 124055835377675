import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';
import { useNavigate } from 'react-router-dom';
export default function Sections(props) {
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    const blankValues = {
        book_id: props.bookId,
        volume_id:"",
        section_no: "",
        section_name: "",
        no_of_chapters: "",
        no_of_pages: ""
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [chapterList, setChapterList] = useState([blankValues]);
    const [confirmationModal, setConfirmationModal] = useState(0);
    useEffect(() => {
        getBookChapters(props.bookId)
    }, [])

    const getBookChapters = (id) => {
        axios
            .get(site_ip + '/getBookChapter/' + id, jwtHeader)
            .then((res) => {
                setChapterList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const onStepComplete = () => {
        setSuccessMessage(null);
        getBookChapters(props.bookId)
    }

    const handleClose = () => {
        setConfirmationModal(0);
    }

    const onDeleteRequest = (id, index) => {
        setConfirmationModal(id)
    }

    const deleteRecord = () => {
        let req = { chapter_id: confirmationModal };
        axios.post(site_ip + "/deleteBookChapter", req, jwtHeader)
            .then((res) => {
                getBookChapters(props.bookId)
                setConfirmationModal(0)
            }).catch((err) =>
                console.log(err)
            )
    }

return (
    <Aux>
        <div className='new-content-section'>
            <div>
                <Row>
                    <Col>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <p>Seq No</p>
                                    </th>
                                    <th>
                                        <p>DOI</p>
                                    </th>
                                    <th>
                                        <p>Title</p>
                                    </th>
                                    <th>
                                        <p>Chapter No</p>
                                    </th>
                                    <th>
                                        <p>Action</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {chapterList.map((item, index) => {
                                    return (
                                        <Chapter navigate={navigate} item={item} key={index} setSuccessMessage={(val) => setSuccessMessage(val)} onDeleteRequest={(id) => onDeleteRequest(id, index)} />
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>

                </Row>
                <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure to remove this record?</Modal.Body>
                    <Modal.Footer>
                        <button
                            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button
                            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
                <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
            </div>
        </div>
    </Aux>
);
}

const Chapter = (props) => {

    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(props.item);

    useEffect(() => {
        setValues(props.item)
    }, [props.item])
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const updateChapterDetails = () => {
        let req = { ...values };
        if (req.seq_no === "") {
            setErrorMessage("Please add sequance no.")
            return;
        }
        const jwtHeader = {
            headers: { 'access-token': localStorage.getItem('admin_token') },
        };
        axios
        .post(site_ip + '/setBookChapters', req, jwtHeader)
        .then((res) => {
            props.setSuccessMessage("Chapter details updated successfully.")
        })
        .catch((err) => console.log(err));
        
    }

    return (
        <tr>
            {/* volumeList */}
            <td style={{ width: "10%" }}>
                <div style={{ paddingTop: "10px" }}>
                    <Form.Control
                        type="number"
                        value={values?.seq_no}
                        name="seq_no"
                        onChange={(e) => handleChange("seq_no", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "20%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        type="text"
                        value={values?.doi}
                        name="doi"
                        onChange={(e) => handleChange("doi", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "30%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        readOnly
                        type="text"
                        value={values?.chapter_title}
                        name="chapter_title"
                        onChange={(e) => handleChange("chapter_title", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "25%" }}>
                <div style={{ paddingTop: "5px" }}>
                    <Form.Control
                        readOnly
                        type="text"
                        value={values?.chapter_no}
                        name="chapter_no"
                        onChange={(e) => handleChange("chapter_no", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ textAlign: 'center', width: "15px" }}>
                <div style={{ display: "flex" }}>
                    <button style={{ padding: "5px", marginTop: "5px", marginRight: "10px", width: "43px", display: "flex", justifyContent: "center" }} onClick={() => updateChapterDetails()} className="primary-plus-button">
                        {values.chapter_id === undefined ? "Add" : "Update"}
                    </button>
                    <button style={{ padding: "5px", marginTop: "5px", marginRight: "10px", display: "flex", justifyContent: "center" }} onClick={() => props.navigate("/admin/book/chapter/edit/"+values.chapter_id)} className="primary-plus-button">
                        <EditIcon style={{ fontSize: "20px" }} />
                    </button>
                    <button style={{ padding: "5px", marginTop: "5px", backgroundColor: "#ff4d4d" }} onClick={() => props.onDeleteRequest(values.chapter_id === undefined ? 0 : values.chapter_id)} className="primary-plus-button">
                        <DeleteOutlineIcon style={{ fontSize: "20px" }} />
                    </button>
                </div>
            </td>
            <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
        </tr>
    )
}