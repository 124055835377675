import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
export default function BookChapter(props) {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const navigate = useNavigate();
  const blankValues = {
    publisher_id: '',
    subject_id: '',
    book_id: '',
    chapter_id: '',
  }
  const [values, setValues] = useState(blankValues);
  const [confirmationModal, setConfirmationModal] = useState(0);
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "book_id") {
      setValues({ ...values, [name]: value, chapter_id: "" })
      getChapters(value)
    } else {
      setValues({ ...values, [name]: value })
    }

  }

  useEffect(() => {
    getBooks();
    getPublishers();
    getSubjects();
    getPodcasetList(blankValues);
  }, [])

  const [subjectList, setSubjectList] = useState([]);
  const getSubjects = () => {
    axios
      .get(site_ip + '/getSubjects', jwtHeader)
      .then((res) => {
        setSubjectList(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  const [publishersList, setPublisersList] = useState([]);
  const getPublishers = () => {
    let req = {};
    axios
      .get(site_ip + '/getPublishers', req, jwtHeader)
      .then((res) => {
        setPublisersList(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const [chapterList, setBookChapters] = useState([]);
  const getChapters = (id) => {
    if (id === 0) {
      setBookChapters([]);
    } else {
      axios
        .get(site_ip + '/getBookChapter/' + id, jwtHeader)
        .then((res) => {
          setBookChapters(res.data.Content);
        })
        .catch((err) => console.log(err));
    }
  };

  const [podcastList, setPodcastList] = useState([]);
  const getPodcasetList = (req) => {
      axios
        .post(site_ip + '/searchBookPodcast', req, jwtHeader)
        .then((res) => {
          setPodcastList(res.data.Content);
        })
        .catch((err) => console.log(err));
  };

  

  const [bookList, setBookList] = useState([]);
  const getBooks = () => {
    axios
      .post(site_ip + '/getBookSearch', {}, jwtHeader)
      .then((res) => {
        setBookList(res.data.Books);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setConfirmationModal(0)
  }

  const deleteRecord = () => {
    let req = { podcast_id: confirmationModal };
    axios.post(site_ip + "/deleteBookPodcast", req, jwtHeader)
      .then((res) => {
        getPodcasetList(values)
        setConfirmationModal(0)
      }).catch((err) =>
        console.log(err)
      )
  }

  const columns = [
    {
      name: 'Podcast Number',
      selector: row => row.podcast_number,
    },
    {
      name: 'Title',
      selector: row => row.podcast_title,
    },
    {
      name: 'URL',
      selector: row => <a href={row.podcast_url}>Click to Download</a>,
    },
    {
      name: 'Book',
      selector: row => row.book_title,
    },
    {
      name: 'Subject',
      selector: row => row.subject_name,
    },
    {
      name: 'Publisher',
      selector: row => row.publisher_name,
    },
    {
      name: 'Action',
      width:"150px",
      cell: (row) => (
        <>
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <button style={{ padding: "5px" }} onClick={() => clickHandler(row.podcast_id)} className="primary-plus-button">
              <EditIcon style={{ fontSize: "20px" }} />
            </button>
          </div>
          <div style={{ paddingTop: "5px", paddingBottom: "5px", marginLeft: "10px" }}>
            <button style={{ padding: "5px", backgroundColor: "#ff4d4d" }} onClick={() => setConfirmationModal(row.podcast_id)} className="primary-plus-button">
              <DeleteOutlineIcon style={{ fontSize: "20px" }} />
            </button>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ];
  const clickHandler = (id) => {
    navigate('/admin/book/podcast/edit/' + id);
  }
  return (
    <Aux>
      <div className='new-content-section'>
        <Row className="underline">
          <Col className="page-title-container">
            <p>Podcasts</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/book/podcast/add')} className="primary-plus-button"><AddIcon className="primary-btn-icon" /> Add Book Podcast</button>
          </Col>
        </Row>
        <Row className="underline-dashed">
          <Col className="search-row">
            <Form.Control
              as="select"
              className="search-form-element"
              name="publisher_id"
              onChange={handleChange}
              value={values.publisher_id}
            >
              <option value={""}>Select Publisher</option>
              {publishersList.map((item, index) => {
                return (
                  <option key={index} value={item.publisher_id}>{item.publisher_name}</option>
                )
              })}
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="subject_id"
              onChange={handleChange}
              value={values.subject_id}
            >
              <option value={""}>Select Subject</option>
              {subjectList.map((item, index) => {
                return (
                  <option key={index} value={item.subject_id}>{item.subject_name}</option>
                )
              })}
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="book_id"
              onChange={handleChange}
              value={values.book_id}
            >
              <option value={""}>Select Book</option>
              {bookList.map((item, index) => {
                return (
                  <option key={index} value={item.book_id}>{item.book_title}</option>
                )
              })}
            </Form.Control>
            <Form.Control
              as="select"
              className="search-form-element"
              name="chapter_id"
              onChange={handleChange}
              value={values.chapter_id}
            >
              <option value={""}>Select Chapter</option>
              {chapterList.map((item, index) => {
                return (
                  <option key={index} value={item.chapter_id}>{item.chapter_title}</option>
                )
              })}
            </Form.Control>
            <button onClick={() => getPodcasetList(values)} className="primary-plus-button">Search</button>
            <div className='spacer' />
            <button onClick={() => { getPodcasetList(blankValues); setValues(blankValues) }} className="secondary-button">Reset</button>
          </Col>
        </Row>
        {/* <Row className="underline-dashed">
          <Col>
            <div className="alphabet-filter">
              {alphabets.map((item, index) => {
                return (
                  <button key={index} onClick={() => setActiveAlpha(item)} className={item === activeAlpha ? "alpha-filter active" : "alpha-filter"}>{item}</button>
                )
              })}
            </div>
          </Col>
        </Row> */}
        <div style={{ paddingTop: "8px" }}>
          <DataTable
            className="table table-bordered"
            columns={columns}
            data={podcastList}
            pagination
            dense
          />
        </div>
      </div>
      <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Aux>

  );
}