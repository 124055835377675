import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import '../../admin.css';
import Aux from '../../../hoc/_Aux';
import { site_ip } from '../../../globalSetting';
import { useNavigate, useParams } from 'react-router-dom';
import { fileUpload } from '../../Common/fileUploads';
import SuccessModal from '../../Common/SuccessModal';
import ErrorModal from '../../Common/ErrorModal';

export default function JournalDetail(props) {
  const navigate = useNavigate();
  const params = useParams();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const blankValues = {
    book_id: "",
    review_type: "",
    review_by: "",
    file_name: "",
    review_comment: "",
    review_rating: "",
    created_by: ""
  }

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [values, setValues] = useState(blankValues);
  const [contentFile, setContentFile] = useState(null);
  const handleChange = (e) => {
    let { name, value } = e.target;
    let data = { ...values }
    data[name] = value;
    setValues(data)
  }

  useEffect(() => {
    getBooks();
    if (params.id !== undefined) {
      getReviewById(params.id)
    }
  }, [])

  const [bookList, setBookList] = useState([]);
  const getBooks = () => {
    let req = {}
    axios
      .post(site_ip + '/getBookSearch', req, jwtHeader)
      .then((res) => {
        setBookList(res.data.Books);
      })
      .catch((err) => console.log(err));
  };


  const getReviewById = (id) => {
    axios
      .get(site_ip + '/getBookReviewById/' + id, jwtHeader)
      .then((res) => {
        setValues(res.data.Content[0]);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = async() => {
    let req = { ...values };
    if (req.book_id === "") {
      setErrorMessage("Please select book.")
      return;
    } else if (req.review_type === "") {
      setErrorMessage("Please select review type.")
      return;
    } else if (req.review_comment.trim() === "") {
      setErrorMessage("Please add comment.")
      return;
    } else if (req.review_rating === "") {
      setErrorMessage("Please select rating.")
      return;
    }

    if(contentFile !== null){
      let fileUrl = await fileUpload(contentFile[0])
      req = { ...req, file_name: fileUrl }
  }

    if (values.book_review_id === undefined) {
      axios
        .post(site_ip + '/createBookReview', req, jwtHeader)
        .then((res) => {
          setSuccessMessage("New review added successfully.")
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(site_ip + '/setBookReview', req, jwtHeader)
        .then((res) => {
          setSuccessMessage("Review details updated successfully.")
        })
        .catch((err) => console.log(err));
    }
  }

  const onStepComplete = () => {
    setSuccessMessage(null);
    navigate("/admin/book/review")
  }

  return (
    <Aux>
      <div className='new-content-section' style={{backgroundColor:"#f5f5f5"}}>
        <Row className="underline">
          <Col className="page-title-container">
            <p>{values.book_review_id === undefined ? "Add Review" : "Update Review"}</p>
          </Col>
          <Col className="page-title-right">
            <button onClick={() => navigate('/admin/book/review')} className="primary-plus-button">Reviews List</button>
          </Col>
        </Row>
        <div>
          <div style={{ paddingTop: "8px" }}>
            <table className='table'>
              <tbody>
                <tr>
                  <td>
                    <div>
                      Book <span className="mandatory"> *</span>
                    </div>
                  </td>
                  <td>
                    <div className="col-md-5">
                      <Form.Control
                        value={values?.book_id}
                        as="select"
                        name="book_id"
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                        {bookList.map((value, index) => (
                          <option value={value.book_id} key={index}>
                            {value.book_title}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Review Type
                    </div>
                  </td>
                  <td>
                    <div className="col-md-5">
                      <Form.Control
                        value={values?.review_type}
                        as="select"
                        name="review_type"
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                        <option value="Doody">Doody</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Review By
                    </div>
                  </td>
                  <td>
                    <div className="col-md-5">
                      <Form.Control
                        value={values?.review_by}
                        as="select"
                        name="review_by"
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                      </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Review Comment
                    </div>
                  </td>
                  <td>
                    <div className="col-md-12">
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        name='review_comment'
                        value={values?.review_comment}
                        onChange={handleChange}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Review Rating</div>
                  </td>
                  <td>
                    <div className="col-md-5">
                      <Form.Control
                        value={values?.review_rating}
                        as="select"
                        name="review_rating"
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><div>Review File</div></td>
                  <td >
                    <div className="col-md-5">
                      <Form.Control
                        type="file"
                        accept="application/pdf, .docx"
                        name="cover_image1"
                        onChange={(e) => setContentFile(e.target.files)}
                      />
                      (Allowed formats: .pdf/.docx)
                      <br/>
                      {values?.file_name === null || values?.file_name === "" ? null :
                        <a href={values.file_name}>{values.file_name}</a>}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div style={{ display: "flex" }}>
              <button onClick={() => onSubmit()} className="primary-plus-button">{values.book_review_id === undefined ? "Add" : "Update"} Review</button>
              <div className='spacer' />
              <button onClick={() => navigate('/admin/book/review')} className="secondary-button">Cancel</button>
            </div>
          </div>
          <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
          <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
        </div>
      </div>
    </Aux>
  );
}
