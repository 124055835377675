import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { site_ip } from '../../../globalSetting';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import custom_config from "../../../ckeditor.config";

const AddStaticPage = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const initialState = {
    publisher_id: "",
    journal_id: "",
    page_name: "",
    page_for: "",
    page_code: "",
    image_name: "",
    page_content: "",
    page_url: "",
    display_status: ""
  };



  const [body, setBody] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  useEffect(() => {
    fetchPublishers();
    if(params.content_id !== undefined){
      fetchPage(params.content_id)
    }
  }, [])

  const fetchPage = (id) => {
    axios.get(
      site_ip + '/getStaticPageById/'+id,
      // filter
      jwtHeader
    ).then((res) => {
      setValues(res.data.Data[0])
      setBody(res.data.Data[0].page_content)
    }).catch((err) =>
      console.log(err)
      //   alert("Check Your Credentials")
    )
  }

  const [publishers, setPublishsers] = useState([])
  const fetchPublishers = () => {
    axios.get(
      site_ip + '/getPublishers',
      // filter
      jwtHeader
    ).then((res) => {
      setPublishsers(res.data.Data)
    }).catch((err) =>
      console.log(err)
      //   alert("Check Your Credentials")
    )
  }

  const [values, setValues] = useState(initialState);

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const validateForm = () => {

    let status = true;
    let entered_values = { ...values };
    if (entered_values.page_name.trim() === "") {
      alert("Template Name cannot be blank.")
      status = false;
    } else if (entered_values.page_code.trim() === "") {
      alert("Please select a user group.")
      status = false;
    } else if (entered_values.page_for.trim() === "") {
      alert("Please add Email From field.")
      status = false;
    } else if (body.trim() === "") {
      alert("Email body cannot be blank.")
      status = false;
    }
    return status;
  }

  const submitForm = () => {

    let req = { ...values };
    if (!validateForm()) {
      return;
    }
    req.page_content = body;
    if(req.static_page_id === undefined){
      axios.post(site_ip + "/createStaticPages", req, jwtHeader)
      .then((res) => {
        if (res.data.Status === 200) {
          alert("New Page added successfully.")
          navigate('/admin/static-content-listing')
        }
      }).catch((err) =>
        console.log(err)
      )
    }else{
      axios.post(site_ip + "/setStaticPages", req, jwtHeader)
      .then((res) => {
        if (res.data.Status === 200) {
          alert("Page updated successfully.")
          navigate('/admin/static-content-listing')
        }
      }).catch((err) =>
        console.log(err)
      )
    }
    
  }

  return (
    <div className="admin-content-section">
      {/* <Header /> */}
      <div className='width-100' style={{margin:"15px"}}>
        <Row>
          <Col style={{ borderBottom: "1px solid #853B85", marginBottom: "10px" }}>
            <p style={{ fontSize: "16px", fontWeight: "bold", color: "#853B85" }}>New Static Page</p>
          </Col>
        </Row>
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Publisher</p>
          </Col>
          <Col md={6}>
            <Form.Select name='publisher_id' value={values.publisher_id} onChange={handleChange} >
              <option value={""}>--Select--</option>
              {publishers.map((item) => {
                return (
                  <option key={item.publisher_id} value={item.publisher_id} >{item.publisher_name}</option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Page Name <span style={{ color: "#ff0000" }}>*</span></p>
          </Col>
          <Col md={6}>
            <Form.Control type="text" placeholder="" name='page_name' value={values.page_name} onChange={handleChange} />
          </Col>
        </Row>
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Page For <span style={{ color: "#ff0000" }}>*</span></p>
          </Col>
          <Col md={6}>
            <Form.Select name='page_for' value={values.page_for} onChange={handleChange} >
              <option value={""}>--Select--</option>
              <option value={"Home"}>Home</option>
              <option value={"Author"}>Author</option>
              <option value={"Editor"}>Editor</option>
              <option value={"Reviewer"}>Reviewer</option>
              <option value={"Admin"}>Admin</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Page Code <span style={{ color: "#ff0000" }}>*</span></p>
          </Col>
          <Col md={6}>
            <Form.Control type="text" placeholder="" name='page_code' value={values.page_code} onChange={handleChange} />
          </Col>
        </Row>
        {/* <Row className="vertical-form-row">
          <Col md={2}>
            <p>Image</p>
          </Col>
          <Col md={6}>
            <Form.Control type="text" placeholder="" name='image_name' value={values.image_name} onChange={handleChange} />
          </Col>
        </Row> */}
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Page URL</p>
          </Col>
          <Col md={6}>
            <Form.Control type="text" placeholder="" name='page_url' value={values.page_url} onChange={handleChange} />
          </Col>
        </Row>
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Page Content <span style={{ color: "#ff0000" }}>*</span></p>
          </Col>
          <Col md={10}>
            <CKEditor
              config={custom_config}
              style={{ height: "400px" }}
              editor={Editor}
              data={body}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setBody(data);
                // console.log( { event, editor, data } );
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>
            <button
              style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
              className="btn btn-sm" variant="secondary" onClick={() => setConfirmationModal(true)}>
              Preview
            </button>
          </Col>
        </Row>
        <Row className="vertical-form-row">
          <Col md={2}>
            <p>Page Status</p>
          </Col>
          <Col md={4}>
            <Form.Select name='is_active' value={values.is_active} onChange={handleChange} >
              <option value={0}>Inactive</option>
              <option value={1}>Active</option>
            </Form.Select>
          </Col>
        </Row>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: "100%", padding: "10px 0px" }}>
          <Button className={"theme-primary-btn mb-15 mr-15"} onClick={() => submitForm()} >Submit</Button>
        </div>
      </div>
      <Modal show={confirmationModal} size={'lg'} onHide={() => setConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: body }} className='editor'></div>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="secondary" onClick={() => setConfirmationModal(false)}>
            Close
          </button>
          {/* <button
            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
            Confirm
          </button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddStaticPage;
