import React, { useState, useEffect } from 'react';
import { Button, Container, Col, Form, Row, Table, Modal } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import '../master.css';
import API from '../../API';
import Aux from '../../../hoc/_Aux';
export default function PublishersList(props) {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const [publisher, setPublisher] = useState([]);

  const fetchPublisherData = async () => {
    try {
      setPublisher(await API.fetchPublishers());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPublisherData();
    // eslint-disable-next-line
  }, []);

  const editButton = (id) =>{
    navigate('/admin/edit-publisher/'+id)
  }


  const handleClose = () => setShow(false);

   const removeRecord = async() => {
      let data = {
        publisher_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/deletePublisher',data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            fetchPublisherData();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Aux>
      <Container fluid>
        <Row>
          <Col md={6}>
            <h4>Publishers List</h4>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="dark"
              onClick={() => navigate('/admin/add-publisher')}
            >
              Add Publishers
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xl={12} className="mt-3">
            {/* <div className="p-3 border bg-white"> */}
              <table className="customTable">
                <thead>
                 <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Publisher Code</th>
                    <th>Publisher Name</th>
                    <th>City</th>
                    <th>Mobile</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>  
                  {publisher?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td style={{textAlign:'center'}}>{index + 1}</td>
                        <td>{user.publisher_code}</td>
                        <td>{user.publisher_name}</td>
                        <td>{user.city_name}</td>
                        <td>{user.contact_no}</td>
                        <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editButton(user.publisher_id)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(user.publisher_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>  
              </table>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}
