import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Container } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../../hoc/_Aux';
import API from '../../API';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
import custom_config from '../../../ckeditor.config';
export default function QuestionAdd(props) {
  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const input = props.input;
  const [exams, setExams] = useState(null);
  const fetchExamData = async () => {
    try {
      setExams(await API.fetchExams());
    } catch (error) {
      console.log(error);
    }
  };

  const [courses, setCourses] = useState(null);
  const fetchCoursesData = async () => {
    try {
      setCourses(await API.fetchCourses());
    } catch (error) {
      console.log(error);
    }
  };
  const [subject, setSubject] = useState(null);
  const fetchSubjectData = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [books, setBooks] = useState([]);
  const fetchSubjectBooks = async (subject_id) => {
    try {
      setBooks(await API.fetchSubjectBooks(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [chapters, setChapters] = useState([]);
  const fetchChapters = async (book_id) => {
    try {
      setChapters(await API.fetchChapters(book_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [topic, setTopic] = useState(null);
  const fetchTopicData = async (subject_id) => {
    try {
      setTopic(await API.fetchSubjectTopics(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [questionType, setQuestionType] = useState(null);
  const fetchQuestionTypeData = async () => {
    await axios.get(`${site_ip}/getQuestionType`, jwtHeader).then((res) => {
      // console.log(res);
      setQuestionType(res.data.content);
    });
  };
  const [taxonomy, setTaxonomy] = useState(null);
  const fetchTaxonomyData = async () => {
    await axios.get(`${site_ip}/getQuestionTaxonomy`, jwtHeader).then((res) => {
      // console.log(res);
      setTaxonomy(res.data.content);
    });
  };
  const [complexity, setComplexity] = useState(null);
  const fetchComplexityData = async () => {
    await axios.get(`${site_ip}/getQuestionComplexity`, jwtHeader).then((res) => {
      // console.log(res);
      setComplexity(res.data.Data);
    });
  };
  const [tags, setTags] = useState(null);
  const fetchTagsData = async () => {
    await axios.get(`${site_ip}/getQuestionTags`, jwtHeader).then((res) => {
      // console.log(res);
      setTags(res.data.Content);
    });
  };

  useEffect(() => {
    fetchExamData();
    fetchSubjectData();
    fetchTaxonomyData();
    fetchComplexityData();
    fetchTagsData();
    fetchCoursesData();
  }, []);

  useEffect(()=>{
    if(input.question !== undefined){
        if(input.subject_id !== ""){
            fetchTopicData(input.subject_id);
            fetchSubjectBooks(input.subject_id);      
        }

        if(input.book_id !== ""){
            fetchChapters(input.book_id);  
        }
    }
  }, [input])

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setInputs((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    props.handleChange(name, value)
    if (name === 'subject_id') {
      fetchTopicData(value);
      fetchSubjectBooks(value);
    }
    if (name === 'book_id') fetchChapters(value);
  };

  const handleSubmit = () =>{
    props.changeStep(3)
  }


  return (
    <Aux>
      <Row style={{margin:0}}>
        <Col>
          <Card>
            <Card.Body>
                <Row>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Exam</Form.Label>
                      <Form.Control
                        as="select"
                        name="exam_id"
                        value={input.exam_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Exam</option>
                        {exams?.map((item) => {
                          return (
                            <option key={item.exam_id} value={item.exam_id}>
                              {item.exam_cd}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Course</Form.Label>
                      <Form.Control
                        as="select"
                        name="course_id"
                        value={input.course_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Course</option>
                        {courses?.map((item) => {
                          return (
                            <option
                              key={item.course_id}
                              value={item.course_id}
                            >
                              {item.course_title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        as="select"
                        name="subject_id"
                        value={input.subject_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Subject</option>
                        {subject?.map((item) => {
                          return (
                            <option
                              key={item.subject_id}
                              value={item.subject_id}
                            >
                              {item.subject_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        as="select"
                        name="topic_id"
                        value={input.topic_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Topic</option>
                        {topic?.map((item) => {
                          return (
                            <option key={item.topic_id} value={item.topic_id}>
                              {item.topic_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Book</Form.Label>
                      <Form.Control
                        as="select"
                        name="book_id"
                        value={input.book_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Book</option>
                        {books?.map((item) => {
                          return (
                            <option key={item.book_id} value={item.book_id}>
                              {item.book_title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Chapter</Form.Label>
                      <Form.Control
                        as="select"
                        name="chapter_id"
                        value={input.chapter_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Chapter</option>
                        {chapters?.map((item) => {
                          return (
                            <option
                              key={item.chapter_id}
                              value={item.chapter_id}
                            >
                              {item.chapter_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Question Taxonomy</Form.Label>
                      <Form.Control
                        as="select"
                        name="ques_taxonomy_catg"
                        value={input.ques_taxonomy_catg}
                        onChange={handleChange}
                      >
                        <option value="">Select Taxonomy</option>
                        {taxonomy?.map((item) => {
                          return (
                            <option
                              key={item.taxonomy_id}
                              value={item.taxonomy_id}
                            >
                              {item.taxonomy}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Difficulty Level</Form.Label>
                      <Form.Control
                        as="select"
                        name="difficulty_level"
                        value={input.difficulty_level}
                        onChange={handleChange}
                      >
                        <option value="">Select Difficulty</option>
                        {complexity?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.complexity_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Add Tags</Form.Label>
                      <Form.Control
                        as="select"
                        name="tags"
                        value={input.tags}
                        onChange={handleChange}
                      >
                        <option value="">Select Question Tags</option>
                        {tags?.map((item) => {
                          return (
                            <option key={item.tag_id} value={item.tag_id}>
                              {item.tag_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Marks</Form.Label>
                      <Form.Control
                        type="number"
                        name="marks"
                        value={input.marks}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Negative Marks</Form.Label>
                      <Form.Control
                        type="number"
                        name="negative_marking"
                        value={input.negative_marking}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Time Allowed</Form.Label>
                      <Form.Control
                        type="time"
                        name="time_allowed"
                        value={input.time_allowed}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Question Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="ques_status_cd"
                        value={input.ques_status_cd}
                        onChange={handleChange}
                      >
                        <option value="">Select Question Status</option>
                        <option value="New">New</option>
                        <option value="Live">Live</option>
                        <option value="QA">QA</option>
                        <option value="Rework">Rework</option>
                        <option value="Rejected">Rejected</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSubmit}>
                  Next
                </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}
