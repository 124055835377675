import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../../../admin.css';
import Aux from '../../../../hoc/_Aux';
import { site_ip } from '../../../../globalSetting';
 
import SuccessModal from '../../../Common/SuccessModal';
import ErrorModal from '../../../Common/ErrorModal';

export default function Volumes(props) {
    
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    const blankValues = {
        book_id: props.bookId,
        volume_name: "",
        created_by: "",
        display_status: "0"
    }

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [volumeList, setVolumeList] = useState([blankValues]);
    const [confirmationModal, setConfirmationModal] = useState(0);
    useEffect(() => {
        getVolumeById(props.bookId)
    }, [])

    const getVolumeById = (id) => {
        axios
            .get(site_ip + '/getBookVolume/' + id, jwtHeader)
            .then((res) => {
                setVolumeList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const addOneRow = () => {
        let lists = [...volumeList];
        lists.push(blankValues);
        setVolumeList(lists);
    }

    const removeOneRow = (index) => {
        let lists = [...volumeList];
        lists.splice(index, 1);
        setVolumeList(lists);
    }

    const onStepComplete = () => {
        setSuccessMessage(null);
        getVolumeById(props.bookId)
    }

    const handleClose = () => {
        setConfirmationModal(0);
    }

    const onDeleteRequest = (id, index) => {
        if (id === 0) {
            removeOneRow(index)
        } else {
            setConfirmationModal(id)
        }
    }

    const deleteRecord = () => {
        let req = { volume_id: confirmationModal };
        axios.post(site_ip + "/deleteBookVolume", req, jwtHeader)
            .then((res) => {
                getVolumeById(props.bookId)
                setConfirmationModal(0)
            }).catch((err) =>
                console.log(err)
            )
    }

return (
    <Aux>
        <div className='new-content-section'>
            <div>
                <Row>
                    <Col>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        <p>Volume</p>
                                    </th>
                                    <th>
                                        <p>Display Status</p>
                                    </th>
                                    <th>
                                        <p>Action</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {volumeList.map((item, index) => {
                                    return (
                                        <Volume item={item} key={index} setSuccessMessage={(val) => setSuccessMessage(val)} onDeleteRequest={(id) => onDeleteRequest(id, index)} />
                                    )
                                })}
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: "100%" }}>
                            <button onClick={() => addOneRow()} className="primary-plus-button">Add More</button>
                        </div>
                        {/* <div style={{ paddingTop: "8px" }}>
                                <div style={{ display: "flex" }}>
                                    <button onClick={() => onSubmit()} className="primary-plus-button" style={{paddingLeft:"15px!important", paddingRight:"15px"}}>Save</button>
                                </div>
                            </div> */}
                    </Col>

                </Row>
                <Modal centered show={confirmationModal === 0 ? false : true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure to remove this record?</Modal.Body>
                    <Modal.Footer>
                        <button
                            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button
                            style={{ backgroundColor: "#4caf50", marginLeft: "5px", color: "#ffffff" }}
                            className="btn btn-sm" variant="primary" onClick={() => deleteRecord()}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
                <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
                <SuccessModal successMessage={successMessage} setSuccessMessage={() => onStepComplete()} />
            </div>
        </div>
    </Aux>
);
}

const Volume = (props) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [values, setValues] = useState(props.item);

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    useEffect(() => {
        setValues(props.item)
    }, [props.item])
    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value })
    }

    const addVolume = () => {
        let req = { ...values };
        if (req.volume_name.trim() === "") {
            setErrorMessage("Please add volume name.")
            return;
        }
        
        if (values.volume_id === undefined) {
            axios
                .post(site_ip + '/createBookVolume', [req], jwtHeader)
                .then((res) => {
                    props.setSuccessMessage("New Volume added to Book successfully.")
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post(site_ip + '/setBookVolume', req, jwtHeader)
                .then((res) => {
                    props.setSuccessMessage("Volume details updated successfully.")
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <tr>
            <td style={{ width: "70%" }}>
                <div style={{ paddingTop: "10px" }}>
                    <Form.Control
                        type="text"
                        value={values?.volume_name}
                        name="volume_name"
                        onChange={(e) => handleChange("volume_name", e.target.value)}
                    />
                </div>
            </td>
            <td style={{ width: "20%" }}>
                <div style={{ paddingTop: "5px", position: "relative" }}>
                    <Form.Control
                        value={values?.display_status}
                        as="select"
                        name="display_status"
                        onChange={(e) => handleChange("display_status", e.target.value)}
                    >
                        <option value="1">Active</option>
                        <option value="0">In Active</option>
                    </Form.Control>
                </div>
            </td>
            <td style={{ textAlign: 'center', width: "20px" }}>
                <div style={{ display: "flex" }}>
                    <button style={{ padding: "5px", marginTop: "5px", marginRight: "10px", width: "43px", display: "flex", justifyContent: "center" }} onClick={() => addVolume()} className="primary-plus-button">
                        {values.volume_id === undefined ? "Add" : "Update"}
                    </button>
                    <button style={{ padding: "5px", marginTop: "5px", backgroundColor: "#ff4d4d" }} onClick={() => props.onDeleteRequest(values.volume_id === undefined ? 0 : values.volume_id)} className="primary-plus-button">
                        Remove
                    </button>
                </div>
            </td>
            <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
        </tr>
    )
}